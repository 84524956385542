import * as React from "react";

import { validateTokenRequest } from "@api/sessionApi";
import { InvalidTokenPage } from "./InvalidTokenPage";
import { RequestErrorPage } from "./RequestErrorPage";
import { ResetPasswordDone } from "./ResetPasswordDone";
import { ResetPasswordForm } from "./ResetPasswordForm";

export const ResetPasswordPage: React.FC<{ token: string }> = ({ token }) => {
  const [state, setState] = React.useState("unknown");

  React.useEffect(() => {
    if (!token) {
      setState("invalid");
      return;
    }

    validateToken(token).then(
      (valid) => {
        setState(valid ? "valid" : "invalid");
      },
      (error) => {
        setState("error");
      }
    );
  }, [token]);

  const done = React.useCallback(() => {
    setState("done");
  }, []);

  const invalidToken = React.useCallback(() => {
    setState("invalid");
  }, []);

  switch (state) {
    case "unknown":
      return null;
    case "valid":
      return <ResetPasswordForm token={token} done={done} invalidToken={invalidToken} />;
    case "invalid":
      return <InvalidTokenPage />;
    case "error":
      return <RequestErrorPage />;
    case "done":
      return <ResetPasswordDone />;
  }

  return null;
};

const validateToken = async (token: string): Promise<boolean> => {
  const result = await validateTokenRequest({ token });
  if (result.error) {
    throw new Error("failed");
  }
  return result.data.valid;
};
