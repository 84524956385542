import * as React from "react";

import { useMediaQuery, useTheme } from "@mui/material";

import { SimpleTemplateDesktop } from "./SimpleTemplateDesktop";
import { SimpleTemplateMobile } from "./SimpleTemplateMobile";
import { PageHeaderWithSignin } from "@organisms/PageHeaderWithSignin";
import { PageHeaderWithSignup } from "@organisms/PageHeaderWithSignup";
import { PageHeaderWithSignout } from "@organisms/PageHeaderWithSignout";
import { PageHeader } from "@organisms/PageHeader";

export type SimpleTemplateProps = {
  headerType: HeaderType;
  errorMessage?: string;
  busy?: boolean;
  children: React.ReactNode;
  removeErrorMessage?: () => void;
};

type HeaderType = "signin" | "signout" | "signup" | "logo";

export const SimpleTemplate: React.FC<SimpleTemplateProps> = ({
  busy,
  errorMessage,
  headerType,
  children,
  removeErrorMessage,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  if (matches) {
    return (
      <SimpleTemplateMobile
        busy={busy}
        errorMessage={errorMessage}
        header={mobileHeader(headerType)}
        removeErrorMessage={removeErrorMessage}
      >
        {children}
      </SimpleTemplateMobile>
    );
  }

  return (
    <SimpleTemplateDesktop
      busy={busy}
      errorMessage={errorMessage}
      header={desktopHeader(headerType)}
      removeErrorMessage={removeErrorMessage}
    >
      {children}
    </SimpleTemplateDesktop>
  );
};

const mobileHeader = (headerType?: HeaderType) => header(headerType, false);

const desktopHeader = (headerType?: HeaderType) => header(headerType, true);

const header = (headerType: HeaderType | undefined, includeHeader: boolean) => {
  switch (headerType) {
    case "signin":
      return <PageHeaderWithSignin includeLogo={includeHeader} />;
    case "signout":
      return <PageHeaderWithSignout includeLogo={true} />;
    case "signup":
      return <PageHeaderWithSignup includeLogo={includeHeader} />;
    default:
      return <PageHeader includeLogo={includeHeader} />;
  }
};
