import * as React from "react";

import { AccountNextStep, CompanyType } from "@src/types";
import { RadioGroupInput } from "@src/components/atoms";

type AccountNextStepInputProps = {
  companyType: CompanyType;
  value?: string;
  error?: string;
  onValueChange: (value: string) => void;
};

export const AccountNextStepInput: React.FC<AccountNextStepInputProps> = ({
  companyType,
  value,
  error,
  onValueChange,
}) => {
  return (
    <RadioGroupInput
      onValueChange={onValueChange}
      value={value}
      fields={fieldsFor(companyType)}
      error={error}
    />
  );
};

const fieldsFor = (companyType?: CompanyType) => {
  if (companyType === CompanyType.Insurance || companyType === CompanyType.Pension) {
    return insuranceFields;
  }

  if (companyType === CompanyType.Broadband) {
    return telecomsFields;
  }

  if (companyType === CompanyType.Mobile) {
    return mobileFields;
  }

  if (companyType === CompanyType.Telecoms) {
    return telecomsFields;
  }

  if (companyType === CompanyType.TV) {
    return telecomsFields;
  }

  return defaultFields;
};

const defaultFields = [
  { value: AccountNextStep.Cancel, label: "Close the account" },
  {
    value: AccountNextStep.UpdateAccountHolder,
    label: "Update primary account holder",
  },
];

const insuranceFields = [
  { value: AccountNextStep.Cancel, label: "Close the policy" },
  {
    value: AccountNextStep.UpdateAccountHolder,
    label: "Update policy holder",
  },
];

const telecomsFields = [
  { value: AccountNextStep.Cancel, label: "Close the account" },
  { value: AccountNextStep.UpdateAccountHolder, label: "Transfer the account" },
];

const mobileFields = [
  { value: AccountNextStep.Cancel, label: "Close the account" },
  { value: AccountNextStep.UpdateAccountHolder, label: "Transfer the account" },
  { value: AccountNextStep.MoveToPayAsYouGo, label: "Move to Pay as you go" },
  { value: AccountNextStep.MoveToAnotherService, label: "Move to another mobile network" },
];
