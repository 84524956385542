import { identity } from "lodash";
import * as React from "react";

import { Box, Container, Stack } from "@mui/material";

import { BusyOverlay } from "@atoms/BusyOverlay";
import { ErrorSnackbar } from "@organisms/ErrorSnackbar";
import { PageHeader } from "@organisms/PageHeader";
import { Logo } from "@atoms/Logo";

export type CentredTemplateMobileProps = {
  sx?: React.CSSProperties;
  busy?: boolean;
  errorMessage?: string;
  header?: React.ReactNode | null;
  children: React.ReactNode;
  removeErrorMessage?: () => void;
};

export const CentredTemplateMobile: React.FC<CentredTemplateMobileProps> = ({
  sx,
  busy,
  errorMessage,
  header,
  children,
  removeErrorMessage,
}) => {
  return (
    <BusyOverlay hidden={!busy}>
      <Box sx={{ minHeight: "100vh", ...sx }}>
        {header || (
          <Box sx={{ visibility: "hidden" }}>
            <PageHeader actionLabel="No op" onClick={identity} />
          </Box>
        )}

        <Container>
          <Box sx={{ paddingTop: "60px", paddingBottom: "40px" }}>
            <Stack alignItems="center">
              <Logo />
            </Stack>

            <div style={{ height: "32px" }}></div>

            {children}
          </Box>
        </Container>

        <ErrorSnackbar error={errorMessage} onClose={removeErrorMessage} />
      </Box>
    </BusyOverlay>
  );
};
