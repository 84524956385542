import React, { useReducer, createContext, useEffect } from "react";
import {
  putExpressSectionDataByKey,
  getExpressSectionDataByKey,
} from "@pages/ExpressForm/express-data";
import { PersonDetails, NotifierDetails } from "@src/types/NotifierFormData";
import { Document, FileTags } from "@customTypes/Document";
import { ServiceProvider } from "@src/types/ServiceProvider";
import { Account } from "@src/types/Account";

export type DashboardInfo = {
  nok?: PersonDetails;
  executor?: PersonDetails;
  notifier?: NotifierDetails;
  attachments?: Document[];
  serviceProviders: ServiceProvider[];
  intestacyFlow: boolean;
  collectNokDocuments: boolean;
  collectExecutorDocuments: boolean;
  nokIdDocUploaded: boolean;
  executorIdDocUploaded: boolean;
  nokGoaDocUploaded: boolean;
  executorGoaDocUploaded: boolean;
  caseId?: string;
  signature?: string;
  refreshCounter: number;
};

const initialState: DashboardInfo = {
  serviceProviders: [],
  intestacyFlow: false,
  collectNokDocuments: false,
  collectExecutorDocuments: false,
  nokIdDocUploaded: false,
  executorIdDocUploaded: false,
  nokGoaDocUploaded: false,
  executorGoaDocUploaded: false,
  refreshCounter: 0,
};

const DashboardInfoContext = createContext<{
  state: DashboardInfo;
  dispatch: React.Dispatch<any>;
}>({ state: initialState, dispatch: () => undefined });

const actionTypes = {
  SET: "SET",
  SET_ALL: "SET_ALL",
  REFRESH: "REFRESH",
};

function reducer(state: DashboardInfo, action: { type: string; payload: any }): DashboardInfo {
  switch (action.type) {
    case actionTypes.SET:
      return { ...state, ...action.payload };
    case actionTypes.REFRESH:
      return { ...state, refreshCounter: state.refreshCounter + 1 };
    case actionTypes.SET_ALL:
      const {
        persons,
        caseId,
        signature,
        case: { accounts, attachments },
      } = action.payload;
      const serviceProviders = accounts.map((account: Account) => account.serviceProvider);
      const executor = persons.find((person: any) => person.roles.includes("executor"));
      const nok = persons.find((person: any) => person.roles.includes("nok"));
      const notifier = persons.find((person: any) => person.roles.includes("notifier"));
      const intestacyFlow = serviceProviders.some((sp: ServiceProvider) => sp.intestacyFlow);
      const nokIdDocUploaded = attachments.some(({ document: { tags } }: any) =>
        tags?.includes(FileTags.NokId)
      );
      const executorIdDocUploaded = attachments.some(({ document: { tags } }: any) =>
        tags?.includes(FileTags.ExecutorId)
      );
      const nokGoaDocUploaded = attachments.some(({ document: { tags } }: any) =>
        tags?.includes(FileTags.NokGoa)
      );
      const executorGoaDocUploaded = attachments.some(({ document: { tags } }: any) =>
        tags?.includes(FileTags.ExecutorGoa)
      );

      return {
        ...state,
        collectNokDocuments: intestacyFlow && !!nok,
        nokIdDocUploaded: intestacyFlow && !!nok && nokIdDocUploaded,
        nokGoaDocUploaded: intestacyFlow && !!nok && nokGoaDocUploaded,
        collectExecutorDocuments: intestacyFlow && !!executor,
        executorIdDocUploaded: intestacyFlow && !!executor && executorIdDocUploaded,
        executorGoaDocUploaded: intestacyFlow && !!executor && executorGoaDocUploaded,
        intestacyFlow,
        serviceProviders,
        attachments,
        executor,
        nok,
        notifier,
        caseId,
        signature,
      };
    default:
      return state;
  }
}

const DashboardInfoProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const source = getExpressSectionDataByKey("dashboard_info");
    if (source) dispatch({ type: actionTypes.SET, payload: source });
  }, []);

  useEffect(() => {
    putExpressSectionDataByKey("dashboard_info", state);
  }, [state]);

  return (
    <DashboardInfoContext.Provider value={{ state, dispatch }}>
      {children}
    </DashboardInfoContext.Provider>
  );
};

export { DashboardInfoContext, DashboardInfoProvider };
