import * as React from "react";

import { makeStyles } from "@mui/styles";

import { Message } from "./message";
import { MessageAuthor } from "./MessageAuthor";
import { MessageSentAt } from "./MessageSentAt";
import { MessageDocuments } from "./MessageDocuments";
import { Link } from "@atoms/Link";

export const IncomingMessageBlock: React.FC<{
  author: string;
  sent: string;
  messages: ReadonlyArray<Message>;
  lastMessage: Message;
  readonly lastUndeletedMessage: Message | null;
  readonly lastReceivedMessage: Message | null;
  readonly markMessageAsRead: (messageId: string) => Promise<void>;
}> = ({
  author,
  sent,
  messages,
  lastMessage,
  lastUndeletedMessage,
  lastReceivedMessage,
  markMessageAsRead,
}) => {
  const classes = useStyles();

  return (
    <IncomingBlock>
      <MessageAuthor value={author} />
      {messages.map((message, index) => {
        if (message.deletedAt) {
          return (
            <div key={index} className={classes.deletedMessage}>
              This message has been deleted
            </div>
          );
        }

        return (
          <div key={index}>
            <IncomingMessage
              message={message}
              isLastMessage={!!lastReceivedMessage && lastReceivedMessage.id === message.id}
              markMessageAsRead={markMessageAsRead}
            />
            {lastUndeletedMessage && lastUndeletedMessage.id === message.id && (
              <MessageSentAt value={sent} checked={lastMessage.isReadByNotifier} />
            )}
          </div>
        );
      })}
    </IncomingBlock>
  );
};

const IncomingBlock: React.FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.incomingBlock}>{children}</div>;
};

const IncomingMessage: React.FC<{
  readonly message: Message;
  readonly isLastMessage: boolean;
  readonly markMessageAsRead: (messageId: string) => Promise<void>;
}> = ({ message, isLastMessage, markMessageAsRead }) => {
  const { content, documents } = message;
  const classes = useStyles();

  return (
    <div className={classes.incomingMessage}>
      <div style={{ overflow: "auto", maxHeight: "200px" }}>{urlify(content)}</div>
      {documents.length > 0 && <MessageDocuments documents={documents} />}
      {isLastMessage && !message.isReadByNotifier && (
        <MarkAsReadButton message={message} markMessageAsRead={markMessageAsRead} />
      )}
    </div>
  );
};

const MarkAsReadButton: React.FC<{
  readonly message: Message;
  readonly markMessageAsRead: (messageId: string) => Promise<void>;
}> = ({ message, markMessageAsRead }) => {
  const classes = useStyles();

  const markAsRead = React.useCallback(() => {
    markMessageAsRead(message.id).then(
      () => {
        // do nothing
      },
      (_error) => {
        // TODO
      }
    );
  }, [markMessageAsRead, message]);

  return (
    <div className={classes.markAsRead}>
      <Link variant="secondary" onClick={markAsRead}>
        Mark as read
      </Link>
    </div>
  );
};

const useStyles = makeStyles({
  incomingBlock: {
    marginLeft: "10px",
    marginRight: "10%",
    marginBottom: "15px",
  },

  deletedMessage: {
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    color: "#b1b1b1",
    fontSize: "16px",
    fontStyle: "italic",
    marginBottom: "5px",
    padding: "5px 15px",
  },

  incomingMessage: {
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    color: "#555555",
    fontSize: "16px",
    marginBottom: "5px",
    padding: "5px 15px",
  },

  markAsRead: {
    marginTop: "8px",
    cursor: "pointer",
    textAlign: "center",
    padding: "12px 0",
    fontWeight: 600,
    color: "#377A83",
    borderTop: "1px solid white",
    "&:hover": {},
  },
});

function urlify(text: any) {
  var urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
  const parts = text.split(urlRegex); // Split the text into parts
  return parts.map((part: any, index: any) => {
    if (part.match(urlRegex)) {
      // If the part starts with "www.", prepend "https://" to make it a valid link
      const linkUrl = part.startsWith("www.") ? "https://" + part : part;
      return (
        <a key={index} href={linkUrl} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    } else {
      // Otherwise, return the plain text
      return part;
    }
  });
}
