import * as React from "react";

import { Relationship, Titles } from "@customTypes/index";
import {
  cleanedErrors,
  dateStringError,
  validEmailAddress,
  validPhoneNumber,
} from "@utils/Functions";
import { BooleanInput } from "../../../../components/atoms/BooleanInput";
import { DateInput } from "../../../../components/atoms/DateInput";
import { NewTitleInput } from "../../../../components/atoms/TitleInput";
import { useOnChange, useOnValueChange } from "@utils/callbacks";
import { EmailAddressInput } from "../../../../components/atoms/EmailAddressInput";
import { TextInput } from "../../../../components/atoms/TextInput";
import { PhoneNumberTextInput } from "../../../../components/atoms/PhoneNumberTextInput";
import { FormField } from "../../../../components/molecules/FormField";
import { Typography } from "@mui/material";
import { RelationToDeceased } from "../../../../components/molecules/RelationToDeceased";
import { NewAddressInput } from "../../../../components/molecules/AddressInput";
import { FormStack } from "../../../../components/molecules/FormStack";

export type Record = {
  readonly title?: Titles;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly dateOfBirth?: string;
  readonly address?: string;
  readonly city?: string;
  readonly postcode?: string;
  readonly emailAddress?: string;
  readonly phoneNumber?: string;
  readonly isVulnerable?: boolean;
  readonly relationshipToDeceased?: Relationship;
  readonly relationshipToDeceasedOther?: string;
  readonly nok?: boolean;
};

export type Errors = {
  readonly title?: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly dateOfBirth?: string;
  readonly address?: string;
  readonly city?: string;
  readonly postcode?: string;
  readonly emailAddress?: string;
  readonly phoneNumber?: string;
  readonly isVulnerable?: string;
  readonly relationshipToDeceased?: string;
  readonly relationshipToDeceasedOther?: string;
  readonly nok?: string;
};

export const errorsOf: (r?: Record, excludeAddress?: boolean) => Errors = (
  record,
  excludeAddress
) => {
  return cleanedErrors({
    title: record?.title ? undefined : "required",
    firstName: record?.firstName ? undefined : "required",
    lastName: record?.lastName ? undefined : "required",
    dateOfBirth: dateStringError(record?.dateOfBirth),
    address: excludeAddress || record?.address ? undefined : "required",
    city: excludeAddress || record?.city ? undefined : "required",
    postcode: excludeAddress || record?.postcode ? undefined : "required",
    emailAddress: !record?.emailAddress
      ? "required"
      : validEmailAddress(record.emailAddress)
        ? undefined
        : "invalid",
    phoneNumber: !record?.phoneNumber
      ? undefined
      : validPhoneNumber(record.phoneNumber)
        ? undefined
        : "invalid",
    relationshipToDeceased: record?.relationshipToDeceased ? undefined : "required",
    relationshipToDeceasedOther:
      record?.relationshipToDeceased !== Relationship.Other || record?.relationshipToDeceasedOther
        ? undefined
        : "required",
  });
};

export type PersonDetailsFieldsProps = {
  readonly serviceProvider: { customForm?: string };
  readonly excludeAddress?: boolean;
  readonly record: Record;
  readonly errors: any;
  readonly update: any;
};

export const PersonDetailsFields: React.FC<PersonDetailsFieldsProps> = ({
  serviceProvider,
  excludeAddress,
  record,
  errors,
  update,
}) => {
  const onTitleChange = useOnValueChange(update, "title");
  const onIsVulnerableChange = useOnValueChange(update, "isVulnerable");
  const onDateOfBirthChange = useOnValueChange(update, "dateOfBirth");
  const onChange = useOnChange(update);

  return (
    <>
      <FormField>
        <Typography variant="body1">Please provide the following details:</Typography>
      </FormField>

      <FormField halfWidthByItself label="Title">
        <NewTitleInput
          name="title"
          onValueChange={onTitleChange}
          value={record.title || ""}
          error={errors?.title}
        />
      </FormField>

      <FormField halfWidth label="First name">
        <TextInput
          name="firstName"
          value={record.firstName || ""}
          onChange={onChange}
          error={errors?.firstName}
        />
      </FormField>

      <FormField halfWidth label="Last name">
        <TextInput
          name="lastName"
          value={record.lastName || ""}
          onChange={onChange}
          error={errors?.lastName}
        />
      </FormField>

      <FormField halfWidthByItself label="Date of birth">
        <DateInput
          value={record.dateOfBirth || ""}
          onValueChange={onDateOfBirthChange}
          error={errors?.dateOfBirth}
          pastOnly
        />
      </FormField>

      {!excludeAddress && (
        <>
          <FormField label="Address">
            <NewAddressInput
              name="address"
              value={record.address || ""}
              onChange={onChange}
              error={errors?.address}
            />
          </FormField>

          <FormField halfWidth label="City or Town">
            <TextInput
              name="city"
              value={record.city || ""}
              onChange={onChange}
              error={errors?.city}
            />
          </FormField>

          <FormField halfWidth label="Postcode">
            <TextInput
              name="postcode"
              value={record.postcode || ""}
              onChange={onChange}
              error={errors?.postcode}
            />
          </FormField>
        </>
      )}

      <FormField halfWidth label="Phone number">
        <PhoneNumberTextInput
          name="phoneNumber"
          value={record.phoneNumber || ""}
          error={errors?.phoneNumber}
          onChange={onChange}
        />
      </FormField>

      <FormField halfWidth label="Email address">
        <EmailAddressInput
          name="emailAddress"
          value={record.emailAddress || ""}
          error={errors?.emailAddress}
          onChange={onChange}
        />
      </FormField>

      <FormField halfWidthByItself label="The person who died was their...">
        <RelationToDeceased
          name="relationshipToDeceased"
          value={record.relationshipToDeceased || ""}
          otherValue={record.relationshipToDeceasedOther || ""}
          error={errors?.relationshipToDeceased || errors?.relationshipToDeceasedOther}
          onFieldChange={onChange}
        />
      </FormField>

      <FormStack substack spacing={0}>
        <FormField>
          <BooleanInput
            label="This person is vulnerable and will require additional support"
            value={record.isVulnerable}
            onValueChange={onIsVulnerableChange}
          />
        </FormField>

        {serviceProvider.customForm === "portsmouth-water" && record.isVulnerable && (
          <FormField>
            To notify Portsmouth Water of vulnerability,{" "}
            <a
              href="https://www.portsmouthwater.co.uk/customer-services/priority-services/"
              rel="noreferrer"
              target="_blank"
            >
              click here
            </a>{" "}
            to sign up to their priority services register.
          </FormField>
        )}
      </FormStack>
    </>
  );
};
