import { trackEvent } from "@utils/analytics";
import { PropertyStatus } from "@src/types/property";
import monitor from "../../utils/monitoring";
import { apiFetch } from "./restApi";
import { AccountNextStep, EnergyType, MeterType, YesNo } from "@src/types";

export type NewAccountRecord = {
  serviceProvider: {
    id?: string;
    companyName?: string;
    companyType?: string;
  };
  property?: AccountRecordPropertySpec;
  accountNumber?: string;
  lastMeterReading?: string;
  lastMeterReadingDate?: string;
  meterAccess?: string;
  responsibleRejectsArrears?: boolean;
  documentIds?: string[];
  nextStep?: AccountNextStep;
  energyType?: EnergyType;
  gasMeterType?: MeterType;
  meterType?: MeterType;
  electricityMeterType?: MeterType;
  gasLastMeterReadingDate?: string;
  electricityLastMeterReadingDate?: string;
};

export type AccountRecordPropertySpec = {
  id?: string;
  details?: { address?: string; city?: string; postcode?: string };
  status?: PropertyStatus;
  handOverDate?: string;
  lastOccupiedDate?: string;
  ownershipStatus?: string;
  occupier?: {
    id?: string;
    details?: {
      firstName?: string;
      lastName?: string;
      emailAddress?: string;
      phoneNumber?: string;
      isVulnerable?: boolean;
    };
  };
  landlordType?: string;
  landlordName?: string;
  landlordAddress?: string;
  landlordPhoneNumber?: string;
  landlordEmailAddress?: string;
  tenancyEndDate?: string;
};

export type ExistingAccountRecord = NewAccountRecord & {
  id: string;
};

export const createAccount = async (args: {
  caseId: string;
  signature: string | null;
  sector?: string;
  record: any;
}) => {
  const { caseId, signature, sector, record } = args;

  const typeObject = !sector ? {} : { type: sector };

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/accounts`,
      search: !signature ? typeObject : { caseId, sig: signature, ...typeObject },
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: { record },
    });
  } catch (error) {
    monitor.logError({
      event: `POST /notifier/cases/{caseId}/accounts`,
      args,
      error,
    });
    throw error;
  }

  return result;
};

export const updateAccount = async (args: {
  caseId: string;
  signature: string | null;
  sector?: string;
  record: any;
}) => {
  const { caseId, signature, sector, record } = args;

  const typeObject = !sector ? {} : { type: sector };

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/accounts/${record.id}`,
      search: !signature ? typeObject : { caseId, sig: signature, ...typeObject },
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: { record },
    });
  } catch (error) {
    monitor.logError({
      event: `PUT /notifier/cases/{caseId}/accounts/{accountId}`,
      args,
      error,
    });
    throw error;
  }

  return result;
};

export const updateAdditionalHelp = async (args: AdditionalHelpServerRecord) => {
  let result;

  try {
    result = await updateAdditionalHelpRequest(args);
  } catch (error) {
    trackEvent("HTTP error", { request: "Update additional services" });
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      default:
        trackEvent("Request error", { request: "Update additional services", error: result.error });
        throw new Error("Unexpected error; please contact customer support");
    }
  }

  trackEvent("Update additional services");
  return result.data;
};

type AdditionalHelpServerRecord = {
  caseId: string;
  signature: string | null;
  record: StateRecord;
};

type StateRecord = {
  requestMissingAssetSearch?: YesNo;
  willingToContribute?: boolean;
};

const updateAdditionalHelpRequest = async (args: AdditionalHelpServerRecord) => {
  const { caseId, signature, record } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}/additional-help`,
      search: !signature ? undefined : { caseId, sig: signature },
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: { record },
    });
  } catch (error) {
    monitor.logError({
      event: `PUT /notifier/cases/{caseId}/additional-help`,
      args,
      error,
    });
    throw error;
  }

  return result;
};
