import * as React from "react";
import { ProbeUnknown } from "@molecules/ProbeUnknown";
import { cleanedErrors } from "@utils/Functions";
import { TextInput } from "@atoms/TextInput";
import { FormField } from "@molecules/FormField";
import { useOnChange } from "@utils/callbacks";

export type Record = {
  readonly accountNumber?: string;
  readonly sortCode?: string;
};

export type Errors = {
  readonly accountNumber?: string;
  readonly sortCode?: string;
};

export const errorsOf: (r?: Record) => Errors = (record) => {
  return cleanedErrors({
    accountNumber: !record?.accountNumber
      ? undefined
      : /^\d\d\d\d\d\d\d\d$/.test(record.accountNumber)
        ? undefined
        : "invalid (must be 8 digits)",
    sortCode: !record?.sortCode
      ? undefined
      : /^\d\d-?\d\d-?\d\d$/.test(record.sortCode)
        ? undefined
        : "invalid (must be 6 digits)",
  });
};

export type BankingAccountDetailsFieldsProps = {
  readonly record: Record;
  readonly errors: any;
  readonly update: any;
  readonly required?: boolean;
};

export const BankAccountDetailsFields: React.FC<BankingAccountDetailsFieldsProps> = ({
  record,
  errors,
  update,
  required = false,
}) => {
  const onChange = useOnChange(update);
  return (
    <>
      <FormField halfWidth label="Account number">
        <TextInput
          name="accountNumber"
          value={record.accountNumber || ""}
          onChange={onChange}
          error={errors?.accountNumber}
          placeholder="A 8 Digit Number"
          required={true}
        />
      </FormField>

      <FormField halfWidth label="Sort code">
        <TextInput
          name="sortCode"
          value={record.sortCode || ""}
          onChange={onChange}
          error={errors?.sortCode}
          placeholder="A 6 Digit Number"
          required={true}
        />
      </FormField>

      {required === false ? (
        <FormField>
          <ProbeUnknown
            checked={record.accountNumber === "00000000" && record.sortCode === "000000"}
            label={"I don't know the Bank account/sort-code number"}
            update={update}
            defaultValue={{ accountNumber: "00000000", sortCode: "000000" }}
            emptyValue={{ accountNumber: "", sortCode: "" }}
          />
        </FormField>
      ) : null}
    </>
  );
};
