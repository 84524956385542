import personalRepresentative from "@assets/images/personal-representative.svg";
import { Box, Stack, Typography } from "@mui/material";


export const RepresentativeInfoBox = () => {
    return (
        <Stack rowGap={4}>
            <Stack rowGap={2}>
                <Typography variant="body1">
                    <b>What is a "personal representative"?</b>
                </Typography>

                <Typography variant="body1">
                    A <b>personal representative</b> is the person who is legally entitled to administer the
                    estate of the person who has died. This term is used to collectively refer to both{" "}
                    <b>Executors</b> and <b>Administrators</b>.
                </Typography>
            </Stack>

            <Stack rowGap={2}>
                <Typography variant="body1">
                    <b>Am I an "Executor" or an "Administrator"?</b>
                </Typography>

                <Typography variant="body1">
                    Use the diagram below to determine what type of personal representative you are.
                </Typography>
            </Stack>

            <Box sx={{ alignSelf: "center" }}>
                <img
                    src={personalRepresentative}
                    alt="personal representative diagram"
                    width="400"
                    height="auto"
                    style={{ maxWidth: "100%" }}
                />
            </Box>
        </Stack>
    );
};