import * as React from "react";

import { makeStyles } from "@mui/styles";

import check from "@assets/images/check.svg";
import { colors } from "@styles/constants";

export const MessageSentAt: React.FC<{ readonly value: string; readonly checked: boolean }> = ({
  value,
  checked,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.messageSentAt}>
      Sent {value}&nbsp;{" "}
      {checked && <img className={classes.check} src={check} width="10" height="10"></img>}
    </div>
  );
};

const useStyles = makeStyles({
  messageSentAt: {
    fontSize: "12px",
    color: colors.fadedText,
    marginRight: "15px",
    textAlign: "right",
  },

  check: {
    display: "inline",
  },
});
