import * as React from "react";
import { makeStyles } from "@mui/styles";
import { formatDate } from "@utils/Functions";
import { Document } from "@src/types";
import { Message, MessageBlock } from "./message";
import { OutgoingMessageBlock } from "./OutgoingMessage";
import { IncomingMessageBlock } from "./IncomingMessage";

export const Messages: React.FC<{
  readonly lastReceivedMessage: Message | null;
  readonly messageBlocks: ReadonlyArray<MessageBlock>;
  readonly recipient: "sp" | "settld";
  readonly scrollCounter: number;
  readonly markMessageAsRead: (messageId: string) => Promise<void>;
  readonly deleteMessage: (id: string) => Promise<void>;
}> = ({
  messageBlocks,
  lastReceivedMessage,
  recipient,
  scrollCounter,
  markMessageAsRead,
  deleteMessage,
}) => {
  const classes = useStyles();

  const messageEndRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (messageEndRef.current) {
      const div = messageEndRef.current;
      div.scrollTop = div.scrollHeight;
    }
  }, [scrollCounter]);

  return (
    <div ref={messageEndRef} className={classes.messages}>
      {messageBlocks.map((messageBlock, index) => {
        if (messageBlock.lastMessage.ownMessage) {
          return (
            <OutgoingMessageBlock
              key={index}
              recipient={recipient}
              sent={formatDate(messageBlock.lastMessage.insertedAt)}
              messages={messageBlock.messages}
              lastMessage={messageBlock.lastMessage}
              lastUndeletedMessage={messageBlock.lastUndeletedMessage}
              deleteMessage={deleteMessage}
            />
          );
        } else {
          return (
            <IncomingMessageBlock
              key={index}
              author={
                messageBlock.lastMessage.companyName === "Settld"
                  ? "Christine @ Settld"
                  : `${messageBlock.lastMessage.senderName} @ ${messageBlock.lastMessage.companyName}`
              }
              sent={formatDate(messageBlock.lastMessage.insertedAt)}
              messages={messageBlock.messages}
              lastMessage={messageBlock.lastMessage}
              lastReceivedMessage={lastReceivedMessage}
              lastUndeletedMessage={messageBlock.lastUndeletedMessage}
              markMessageAsRead={markMessageAsRead}
            />
          );
        }
      })}
    </div>
  );
};

export const messageBlocksFromMessages = (messages: Message[]): MessageBlock[] => {
  const messageBlocks: MessageBlock[] = [];
  let currentMessageBlock: MessageBlock | null = null;
  for (const message of messages) {
    if (
      currentMessageBlock === null ||
      message.ownMessage !== currentMessageBlock.lastMessage.ownMessage ||
      message.settldMessage !== currentMessageBlock.lastMessage.settldMessage ||
      message.senderName !== currentMessageBlock.lastMessage.senderName ||
      message.companyName !== currentMessageBlock.lastMessage.companyName ||
      message.insertedAt.substring(0, 10) !==
        currentMessageBlock.lastMessage.insertedAt.substring(0, 10) // TODO need to align the timezone
    ) {
      currentMessageBlock = {
        messages: [message],
        lastMessage: message,
        lastUndeletedMessage: message.deletedAt ? null : message,
      };
      messageBlocks.push(currentMessageBlock);
    } else {
      currentMessageBlock.messages.push(message);
      currentMessageBlock.lastMessage = message;
      if (!message.deletedAt) {
        currentMessageBlock.lastUndeletedMessage = message;
      }
    }
  }

  return messageBlocks;
};

export const documentsFromMessages = (
  messages: Message[]
): ReadonlyArray<{ document: Document; message: Message }> => {
  const documentArrays = messages
    .filter((message) => message.documents && message.documents.length > 0)
    .map(
      (message) => message.documents && message.documents.map((document) => ({ document, message }))
    );

  //@ts-ignore
  return [].concat(...documentArrays);
};

const useStyles = makeStyles({
  messages: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: "auto",
  },
});
