import * as React from "react";

import { makeStyles } from "@mui/styles";

import { colors } from "@styles/constants";

export const MessageAuthor: React.FC<{ readonly value: string }> = ({ value }) => {
  const classes = useStyles();
  return <div className={classes.messageAuthor}>{value}</div>;
};

const useStyles = makeStyles({
  messageAuthor: {
    fontSize: "12px",
    color: colors.fadedText,
    marginBottom: "5px",
    marginLeft: "15px",
  },
});
