import * as React from "react";

import { CallOutlined, EmailOutlined } from "@mui/icons-material";
import { Container, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { colors } from "../../styles/constants";
import { EmailLink } from "../atoms/Link";
import { WhatsAppIcon, WhatsAppInfo } from "../atoms/whatsAppHelp";
import { SettldContactInfo } from "@src/utils/Constants";

export const NeedHelpBox: React.FC = () => {
  return (
    <Container
      style={{
        textAlign: "center",
        padding: "25px",
        backgroundColor: colors.infoBoxBackground,
        fontSize: "16px",
      }}
    >
      Need help? Contact
      <br />
      <b>support@settld.care</b>
      <br />
      or call us on
      <br />
      <b>{SettldContactInfo.Telephone}</b>
      <br />
      (9am-5pm
      <br />
      Monday-Friday)
    </Container>
  );
};

export const NeedHelpBox2: React.FC = () => {
  return (
    <Stack rowGap={2}>
      <div>
        <b>Need help?</b>
      </div>

      <ContactingUs />
    </Stack>
  );
};

export const ContactingUs: React.FC = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.contactContainer}>
        <div className={classes.iconContainer}>
          <EmailOutlined fontSize="small" />
        </div>

        <div>
          Email us at{" "}
          <EmailLink to="mailto:support@settld.care" target="_blank">
            support@settld.care
          </EmailLink>
        </div>
      </div>

      <div className={classes.contactContainer}>
        <div className={classes.iconContainer}>
          <CallOutlined fontSize="small" />
        </div>

        <div>Call us at {SettldContactInfo.Telephone} (10am to 6pm, Monday to Friday)</div>
      </div>
      <div className={classes.contactContainer}>
        <div className={classes.iconContainer}>
          <WhatsAppIcon />
        </div>
        <div>
          {" "}
          <WhatsAppInfo />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  contactContainer: { marginBottom: "0.3rem", display: "flex" },

  iconContainer: {
    paddingRight: "10px",
  },
});
