import { Stack, Typography } from "@mui/material";
import { Link } from "@atoms/index";
import { Header } from "../atoms";
import { InfoBoxTrigger } from "../molecules";
import { colors } from "@styles/constants";
import { ServiceProvider } from "@src/types";
import * as React from "react";

export const SubmissionHeader: React.FC<{ sp: ServiceProvider }> = ({ sp }) => {
  return (
    <>
      <Stack rowGap={2}>
        <Header level={1} color={colors.accentTeal}>
          Review and Submit
        </Header>

        <Typography variant="body1">
          The information you have provided will be used to notify{" "}
          {sp.companyName}. Please review the information below before pressing
          ‘submit’.
          <InfoBoxTrigger
            white
            width="448px"
            content={
              <div>
                <p>
                  Service Providers need to see that you have given Settld your
                  permission to contact them. This permission is called a ‘grant
                  of authority’. When you hit submit on the form, you are
                  providing Settld with a grant of authority for up to 12
                  months.
                </p>

                <p>
                  <b>What am I giving Settld permission to do?</b>
                </p>
                <ul>
                  <li>
                    Notify Service Providers of the death, using the information
                    in the form.{" "}
                  </li>
                  <li>
                    Communicate and share information with Service Providers on
                    your behalf.
                  </li>
                  <li>
                    Request Service Providers to close, transfer, amend or gain
                    information about the accounts of the Deceased for you.
                  </li>
                  <li>
                    Receive date of death balances for the accounts of the
                    Deceased.
                  </li>
                </ul>

                <p>
                  <b>Please note, Settld is not permitted to:</b>
                </p>
                <ul>
                  <li>Sign account closure forms on your behalf.</li>
                  <li>
                    Collect money from Service Providers, in respect of the
                    Deceased accounts.
                  </li>
                </ul>

                <p>
                  <b>Is there anything else I should be aware of?</b>
                </p>
                <p>
                  By submitting the form, you are confirming that you are at
                  least 18 years of age and are authorised to act as the
                  Executor or personal representative of the person who died. By
                  agreeing to proceed, you also acknowledge that you have read
                  and understood the Settld{" "}
                  <Link
                    inline
                    target="_blank"
                    to="https://www.settld.care/legal/terms-and-conditions.html"
                  >
                    Terms and Conditions
                  </Link>{" "}
                  and{" "}
                  <Link
                    inline
                    target="_blank"
                    to="https://www.settld.care/legal/privac`y-policy.html"
                  >
                    Privacy Policy
                  </Link>
                  .
                </p>
              </div>
            }
          />
        </Typography>
      </Stack>
    </>
  );
};
