import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Stack, Typography } from "@mui/material";

import { usePageViewEventTracker } from "@utils/analytics";
import urlPaths from "../../urlPaths";
import { Button } from "@atoms/Button";
import { Header } from "@atoms/Header";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { SideImageTemplate } from "@templates/SideImageTemplate";

export interface OnBoardingInfoPageProps {
  navigateNext: string;
}

export const OnBoardingInfoPage: React.FC<OnBoardingInfoPageProps> = ({ navigateNext }) => {
  // usePageViewEventTracker("");
  const navigate = useNavigate();

  const done = React.useCallback(() => {
    setTimeout(() => {
      navigate(navigateNext, { replace: true });
    }, 100);
  }, [navigate,navigateNext]);

  return (
    <SideImageTemplate
      headerType="signout"
      image={<img src="/images/stock/signup.svg" width="100%" alt="Family" />}
    >
      <Stack rowGap={4}>
        <Header level={1}>
          Thank you. Now, let’s focus on closing/transferring their accounts.
        </Header>

        <Stack rowGap={2}>
          <Typography variant="body1">
            <b>What happens now?</b>
          </Typography>

          <Typography variant="body1">
            Our team will verify the details in this form and then notify the listed organisations.
          </Typography>
          <Typography variant="body1">
            You should also receive a confirmation email, to the email address you provided.
          </Typography>
        </Stack>

        <Stack rowGap={2}>
          <Typography variant="body1">
            <b>What do I need to do next?</b>
          </Typography>

          <Typography variant="body1">
            It is common for Settld and/or the organisations we notify to require further
            documentation or clarification from you. We will inform you of any such requests, plus
            all real-time account updates, via your personalised Account Dashboard. From here you
            can also add more accounts.
          </Typography>
        </Stack>

        <FormStack>
          <FormField twoThirdsWidth>
            <Button variant="primary" size="large" onClick={done}>
              Continue
            </Button>
          </FormField>
        </FormStack>
      </Stack>
    </SideImageTemplate>
  );
};
