import { ServiceProvider } from "@customTypes/index";
import { InfoBoxTrigger } from "./InfoBoxTrigger";
import { Stack, Typography } from "@mui/material";

export const IntestacyInfoBox = (serviceProvider: Partial<ServiceProvider>) => {
  if (serviceProvider.customForm === "starling-bank") {
    return (
      <InfoBoxTrigger
        white
        width="720px"
        content={
          <Stack rowGap={2}>
            <Typography variant="body1">
              It’s important to be aware that if you aren’t the legal next of kin or an Executor,
              Starling Bank will need to confirm you have permission to look after the estate from
              the person who is.
            </Typography>

            <img src="/images/starling-intestacy.png" width="100%" alt="Laws of Intestacy" />
          </Stack>
        }
      />
    );
  }
  return (
    <InfoBoxTrigger
      white
      width="720px"
      content={
        <Stack rowGap={2}>
          <Typography variant="body1">
            <b>Please note:</b>
          </Typography>

          <Typography variant="body1">
            <p>
              As per the intestacy rules&nbsp; (when there is no will)&nbsp;, providers require a
              confirmation from the immediate next of kin.&nbsp;&nbsp;Please check who is the next
              of kin in the Family Tree&nbsp; hierarchy&nbsp; here: &nbsp;&nbsp;
              <a href="https://www.settld.care/what-if-theres-no-will-intestacy-rules/">
                What if there is no will
              </a>
            </p>
          </Typography>
          <Typography variant="body1">
            If you're not the immediate next of kin, please provide their full name, relation to
            deceased and address in the messages section of your dashboard, once the form is
            submitted.
          </Typography>
        </Stack>
      }
    />
  );
};
