import { NavigateFunction } from "react-router-dom";

import { insideIframe } from "../../utils/Functions";

export const navigateToURL = (_navigate: NavigateFunction, url: string) => {
  if (insideIframe) {
    window.parent.postMessage(
      {
        type: "settld:replaceLocation",
        url,
      },
      "https://www.settld.care"
    );
    return;
  }

  setTimeout(() => {
    // navigate(url, { replace: true });
    window.location.href = url;
  }, 100);
};
