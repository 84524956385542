import * as React from "react";

import { CompanyType, YesNo, } from "@src/types";
import { FieldRow } from "@molecules/FieldsTable";
import { NotAvailable } from "../../Dashboard/RequestDetails/NotAvailable";
import { DeceasedPersonalDetails } from "./DeceasedPersonalDetails";
import { PersonDetails } from "./PersonDetails";
import { readableAccountNextSteps, readableAccountType } from "@src/utils/accounts";

export const BankAccount = ({
  account,
  serviceProviderType,
}: {
  account: any;
  serviceProviderType: CompanyType;
}) => {
  return (
    <>
      <DeceasedPersonalDetails deceased={account.deceased} />

      <FieldRow
        label="Account number"
        value={account.accountNumber ? account.accountNumber : <NotAvailable />}
      />

      <FieldRow label="Sort code" value={account.sortCode ? account.sortCode : <NotAvailable />} />

      <FieldRow
        label="Account type"
        value={
          account.accountType ? (
            readableAccountType(account.accountType, account.accountTypeOther)
          ) : (
            <NotAvailable />
          )
        }
      />

      <FieldRow label="Customer reference" value={account.holderNumber || <NotAvailable />} />

      <FieldRow label="Joint policy?" value={account.jointClaim === YesNo.Yes ? "Yes" : "No"} />

      <FieldRow
        label="Needs interest or  tax certificate?"
        value={account.needsTaxCertificate ? "Yes" : "No"}
      />

      <FieldRow
        label="Next step"
        value={readableAccountNextSteps(account.nextStep, serviceProviderType)}
      />

      <FieldRow
        label="Responsible"
        value={
          account.responsible.id === "notifier" ? (
            "Myself"
          ) : (
            <PersonDetails person={account.responsible.details} />
          )
        }
      />
    </>
  );
};
