import * as React from "react";

import { Box, Stack, Typography } from "@mui/material";
import { colors } from "../../styles/constants";
import { SectionIcon } from "../atoms/SectionIcon";
import { Check, Close } from "@mui/icons-material";

export type DesktopMenuProps = {
  currentKey: string;
  entries: Array<{ key: string; label: string; done?: boolean }>;
  onSelect: (entry: string) => void;
};

export type MenuItemProps = {
  code: string;
  label: string;
  done?: boolean;
  error?: boolean;
  current?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  code,
  label,
  done,
  error,
  current,
  onClick,
}) => {
  const backgroundColor = current ? colors.primaryText : colors.offWhite;
  const color = !error && done ? colors.lightTeal : current ? colors.offWhite : colors.primaryText;

  return (
    <Box
      sx={{
        backgroundColor,
        color,
        padding: "8px 22px 8px 22px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" columnGap={2} alignItems="center">
          <SectionIcon code={code} />
          <Typography variant="button">{label}</Typography>
        </Stack>
        {error ? (
          <Close fontSize="small" color="error" />
        ) : done ? (
          <Check fontSize="small" color="secondary" />
        ) : null}
      </Stack>
    </Box>
  );
};
