import { Stack, Typography } from "@mui/material";

export const phoneNumberInfoBox = () => {
    return (
        <Stack rowGap={2} sx={{ paddingTop: 2 }}>
            <Typography variant="body1">
                <b>Why do I need to provide a phone number?</b>
            </Typography>

            <Typography variant="body1">
                We request service providers to cease all product and marketing calls to this number.
            </Typography>
        </Stack>
    );
};

export const emailAddressInfoBox = () => {
    return (
        <Stack rowGap={2} sx={{ paddingTop: 2 }}>
            <Typography variant="body1">
                <b>Why do I need to provide an email?</b>
            </Typography>

            <Typography variant="body1">
                We also request service providers to cease all product and marketing communications to this
                email address. <i>If there is no email address please put N/A.</i>
            </Typography>
        </Stack>
    );
};