import * as React from "react";

import { Stack } from "@mui/material";

import { readableRelationship, Relationship } from "@customTypes/index";
import { SelectInput } from "../atoms/SelectInput";
import { TextInput } from "../atoms/TextInput";

export type RelationToDeceasedProps = {
  name: string;
  value: string;
  otherValue: string;
  onFieldChange: (ev: { target: { name: string; value: string } }) => void;
  error?: string;
};

export const RelationToDeceased: React.FunctionComponent<RelationToDeceasedProps> = ({
  name,
  value,
  onFieldChange,
  error,
  otherValue,
}) => {
  const onValueChange = React.useCallback(
    (value) => {
      onFieldChange({ target: { name, value } });
    },
    [name, onFieldChange]
  );

  return (
    <Stack rowGap={2}>
      <SelectInput
        name={name}
        value={value}
        options={options}
        error={value === Relationship.Other ? undefined : error}
        onValueChange={onValueChange}
      />

      {value === Relationship.Other && (
        <TextInput
          name={name + "Other"}
          value={otherValue}
          error={error}
          onChange={onFieldChange}
        />
      )}
    </Stack>
  );
};

//@ts-ignore
const options: { label: string; value: Relationship }[] = Object.values(Relationship)
  .map((value) => {
    if (value === Relationship.RelatedByMarriage) {
      return null;
    }

    return {
      label: readableRelationship(value),
      value,
    };
  })
  .filter((x) => x !== null);
