import * as React from "react";

import { makeStyles } from "@mui/styles";

import { InputBox, SendMessageProcType } from "./InputBox";
import { Messages } from "./Messages";
import { Message, MessageBlock } from "./message";

export type ChatProps = {
  readonly blocks: ReadonlyArray<MessageBlock>;
  readonly recipient: "sp" | "settld";
  readonly lastReceivedMessage: Message | null;
  readonly scrollCounter: number;
  readonly sendMessage: SendMessageProcType;
  readonly markMessageAsRead: (messageId: string) => Promise<void>;
  readonly deleteMessage: (id: string) => Promise<void>;
};

export const Chat: React.FC<ChatProps> = ({
  blocks,
  recipient,
  lastReceivedMessage,
  scrollCounter,
  sendMessage,
  markMessageAsRead,
  deleteMessage,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.messagingContainer}>
      <Messages
        recipient={recipient}
        messageBlocks={blocks}
        lastReceivedMessage={lastReceivedMessage}
        scrollCounter={scrollCounter}
        markMessageAsRead={markMessageAsRead}
        deleteMessage={deleteMessage}
      />

      <InputBox
        lastReceivedMessage={lastReceivedMessage}
        sendMessage={sendMessage}
        markMessageAsRead={markMessageAsRead}
      />
    </div>
  );
};

const useStyles = makeStyles({
  messagingContainer: {
    borderRadius: "4px",
    backgroundColor: "white",
    padding: "15px",
    flexDirection: "column",
    flexGrow: 1,
    display: "flex",
    height: "100%",
  },
});
