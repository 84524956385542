import { SPProvider } from "@store/SPProvider";
import { ExpressUiInfoProvider } from "./ExpressUiInfo";

const ExpressProvider: React.FC = ({ children }) => {
  return (
    <ExpressUiInfoProvider>
      <SPProvider>{children}</SPProvider>
    </ExpressUiInfoProvider>
  );
};
export { ExpressProvider };
