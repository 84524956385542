import * as React from "react";

import { Alert, Snackbar } from "@mui/material";

import { Document, UploadRecord } from "../../../../types/Document";
import { ExtendedAccount } from "../../../../types/ExtendedAccount";
import { AccountDetails, AccountNextStep, CompanyType } from "@customTypes/index";
import { cleanedErrors } from "@utils/Functions";
import { ServiceProvider } from "@customTypes/index";
import { useUpdater } from "@utils/callbacks";
import {
  Errors as PropertyErrors,
  errorsOf as errorsOfProperty,
  Property,
  propertyAccountFieldsFromRecord,
  PropertyFields,
  PropertyStatus,
  Record as PropertyFieldRecord,
  recordFromAccount as propertyRecordFromAccount,
} from "../PropertyFields";
import { Record as ServiceProviderRecord } from "../ServiceProviderFields";
import {
  accountFieldsFromRecord as accountNumberAccountFieldsFromRecord,
  AccountNumberField,
  Errors as AccountNumberErrors,
  errorsOf as accountNumberErrorsOf,
  Record as AccountNumberRecord,
  recordFromAccount as accountNumberRecordFromAccount,
} from "./AccountNumberField";
import {
  accountDocumentsFromRecord as energyMeterInfoFieldsDocumentsFromRecord,
  accountFieldsFromRecord as energyMeterInfoFieldsFromRecord,
  EnergyMeterInfoFields,
  Errors as EnergyMeterInfoFieldsErrors,
  errorsOf as errorsOfEnergyMeterInfoFields,
  Record as EnergyMeterInfoFieldsRecord,
  recordFromAccount as energyMeterInfoFieldsRecordFromAccount,
} from "./EnergyMeterInfoFields";
import {
  accountFieldsFromRecord as waterAccountFieldsFromRecord,
  Errors as WaterAccountFieldsErrors,
  errorsOf as errorsOfWaterAccountFields,
  Record as WaterAccountFieldsRecord,
  recordFromAccount as waterAccountFieldsRecordFromAccount,
  WaterAccountFields,
} from "./WaterAccountFields";
import {
  Errors as ResponsibleErrors,
  errorsOf as errorsOfResponsible,
  Person,
  Record as ResponsibleRecord,
  recordFromAccount as responsibleRecordFromAccount,
  ResponsibleFields,
} from "../ResponsibleFields";
import {
  Errors as DeceasedErrors,
  errorsOf as errorsOfDeceased,
  Record as DeceasedRecord,
  DeceasedDetailsFields,
} from "../DeceasedDetailsFields";

type Record = {
  readonly deceased?: DeceasedRecord;
  readonly property?: PropertyFieldRecord;
  readonly energyMeterInfoFields: EnergyMeterInfoFieldsRecord;
  readonly waterAccountFields: AccountNumberRecord & WaterAccountFieldsRecord;
  readonly responsible?: ResponsibleRecord;
};

type Errors =
  | undefined
  | {
    readonly deceased?: DeceasedErrors;
    readonly property?: PropertyErrors;
    readonly responsible?: ResponsibleErrors;
    readonly energyMeterInfoFields?: EnergyMeterInfoFieldsErrors;
    readonly waterAccountFields?: AccountNumberErrors & WaterAccountFieldsErrors;
  };

const errorsOf: (
  r: Record,
  version: "energy" | "water",
  askForDeceasedDetails: boolean,
  propertyStatus: any
) => Errors = (record, version, askForDeceasedDetails, propertyStatus) => {
  return cleanedErrors({
    deceased: !askForDeceasedDetails ? undefined : errorsOfDeceased(record.deceased, false),
    property: errorsOfProperty(record?.property),
    responsible:
      propertyStatus === PropertyStatus.ExistingOccupier
        ? undefined
        : errorsOfResponsible(record?.responsible, AccountNextStep.Cancel),
    energyMeterInfoFields:
      version !== "energy"
        ? undefined
        : errorsOfEnergyMeterInfoFields(record?.energyMeterInfoFields),
    waterAccountFields:
      version !== "water"
        ? undefined
        : cleanedErrors({
          ...errorsOfWaterAccountFields(record?.waterAccountFields),
          ...accountNumberErrorsOf(record?.waterAccountFields),
        }),
  });
};

const recordFromAccount = (account: AccountDetails | undefined): Record => {
  if (!account) {
    return {
      deceased: {},
      energyMeterInfoFields: energyMeterInfoFieldsRecordFromAccount(undefined),
      waterAccountFields: {
        ...waterAccountFieldsRecordFromAccount(undefined),
        ...accountNumberRecordFromAccount(undefined),
      },
    };
  }

  return {
    deceased: {},
    property: propertyRecordFromAccount(account),
    responsible: responsibleRecordFromAccount(account),
    energyMeterInfoFields: energyMeterInfoFieldsRecordFromAccount(account),
    waterAccountFields: {
      ...waterAccountFieldsRecordFromAccount(account),
      ...accountNumberRecordFromAccount(account),
    },
  };
};

export type UtilitiesAccountFieldsProps = {
  readonly properties: ReadonlyArray<Property>;
  readonly persons: ReadonlyArray<Person>;
  readonly serviceProvider: ServiceProviderRecord;
  readonly account?: AccountDetails;
  readonly setBusy: (b: boolean) => void;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
  readonly createDocumentAndUpload: (
    file: File,
    filename?: string,
    tags?: string[]
  ) => Promise<UploadRecord>;
  readonly onAccountAdded: (
    a: ExtendedAccount,
    p?: {
      newServiceProvider?: ServiceProvider;
      newPerson?: Person;
      newProperty?: Property;
    }
  ) => void;
  readonly version: "energy" | "water";
  readonly updateTemplate?: (p: any) => void;

  readonly saveAccount: (r: { sector: CompanyType; record: any }) => Promise<any>;
  readonly saveRecord?: (r: any) => void;
  readonly savedRecord?: any;
  readonly askForDeceasedDetails?: boolean;
};

export const UtilitiesAccountFields: React.FC<UtilitiesAccountFieldsProps> = ({
  properties,
  persons,
  serviceProvider,
  account,
  setBusy,
  createDocumentAndUpload,
  uploadedFileInfo,
  onAccountAdded,
  version,
  updateTemplate,

  savedRecord,
  saveAccount,
  saveRecord,
  askForDeceasedDetails = false,
}) => {
  const [record, update] = React.useState(savedRecord || recordFromAccount(account));
  const [errors, setErrors] = React.useState({} as Errors);
  const [remoteErrors, setRemoteErrors] = React.useState(undefined as string | undefined);

  const updateDeceasedFields = useUpdater(update, "deceased");
  const updateEnergyMeterInfoFields = useUpdater(update, "energyMeterInfoFields");
  const updateWaterAccountFields = useUpdater(update, "waterAccountFields");
  const updateProperty = useUpdater(update, "property");

  const updateAccountHolder = useUpdater(update, "responsible");

  const propertyStatus = React.useMemo(() => {
    const propertyId = record.property?.propertyId;
    if (propertyId) {
      const property = properties.find(({ id }) => propertyId === id);
      return property?.status || record.property?.propertyStatus; // the property might not have a status (address only)
    }

    return record.property?.propertyStatus;
  }, [record.property?.propertyId, record.property?.propertyStatus, properties]);

  React.useEffect(() => {
    if (
      (propertyStatus === PropertyStatus.Vacant || propertyStatus === PropertyStatus.NewOccupier) &&
      !record.responsible
    ) {
      updateAccountHolder({});
    }

    if (propertyStatus === PropertyStatus.ExistingOccupier && record.responsible) {
      updateAccountHolder(undefined);
    }
  }, [record.responsible, propertyStatus, updateAccountHolder]);

  React.useEffect(() => {
    if (!updateTemplate) return;

    updateTemplate({
      // onNextLabel: "Continue",
      onNext: !serviceProvider?.companyType
        ? undefined
        : () => {
          const errors = errorsOf(record, version, askForDeceasedDetails, propertyStatus);

          if (errors) {
            setErrors(errors);
            if (saveRecord) {
              saveRecord({ source: record });
            }
            return;
          }

          setBusy(true);

          const data = {
            id: account?.id,
            serviceProvider: {
              id: serviceProvider.serviceProviderId,
              companyName: serviceProvider.customProviderName,
              companyType: serviceProvider.companyType,
            },
            deceased: askForDeceasedDetails ? record.deceased : undefined,
            ...propertyAccountFieldsFromRecord(record.property),
            responsible: record.responsible && {
              id: record.responsible?.responsibleId || undefined,
              details: record.responsible?.responsible || undefined,
              bankAccount: record.responsible?.responsibleBankAccount || undefined,
            },
            ...(version !== "energy"
              ? {}
              : energyMeterInfoFieldsFromRecord(record.energyMeterInfoFields)),
            ...(version !== "water"
              ? {}
              : {
                ...waterAccountFieldsFromRecord(record.waterAccountFields),
                ...accountNumberAccountFieldsFromRecord(record.waterAccountFields),
              }),
            // documentIds,
          };

          if (saveRecord) {
            saveRecord({ source: record, target: data });
            return { response: {} };
          }

          // @ts-ignore
          return saveAccount({ sector: version, record: data }).then(
            (
              response: {
                data: {
                  account: ExtendedAccount;
                  newServiceProvider?: ServiceProvider;
                  newPerson?: Person;
                  newProperty?: Property;
                };
              } & { error: Error }
            ) => {
              setBusy(false);
              if (response.error) {
                setRemoteErrors(response.error.message);
                return;
              }
              if (response.data) {
                onAccountAdded(response.data.account, {
                  newServiceProvider: response.data.newServiceProvider,
                  newPerson: response.data.newPerson,
                  newProperty: response.data.newProperty,
                });
                return;
              }
            },
            (err: Error) => {
              console.warn({ err });
              setBusy(false);
              setRemoteErrors("Operation failed. Please try again or contact customer support.");
            }
          );
        },
    });
  }, [
    updateTemplate,
    serviceProvider,
    account,
    onAccountAdded,
    record,
    setBusy,
    version,
    propertyStatus,
    saveAccount,
    saveRecord,
    askForDeceasedDetails,
  ]);

  return (
    <>
      {askForDeceasedDetails && (
        <DeceasedDetailsFields
          record={record.deceased}
          update={updateDeceasedFields}
          errors={errors?.deceased}
        />
      )}

      {version === "water" && (
        <AccountNumberField
          record={record.waterAccountFields}
          update={updateWaterAccountFields}
          errors={errors?.waterAccountFields}
        />
      )}

      <PropertyFields
        serviceProvider={serviceProvider}
        serviceProviderId={serviceProvider.serviceProviderId}
        persons={persons}
        properties={properties}
        record={record.property || {}}
        errors={errors?.property}
        update={updateProperty}
      />

      {record.responsible && (
        <ResponsibleFields
          serviceProvider={serviceProvider}
          persons={persons}
          nextStep={AccountNextStep.Cancel}
          record={record.responsible}
          errors={errors?.responsible}
          update={updateAccountHolder}
        />
      )}

      {version === "energy" ? (
        <EnergyMeterInfoFields
          record={record.energyMeterInfoFields}
          errors={errors?.energyMeterInfoFields}
          update={updateEnergyMeterInfoFields}
          uploadedFileInfo={uploadedFileInfo}
          createDocumentAndUpload={createDocumentAndUpload}
        />
      ) : version === "water" ? (
        <WaterAccountFields
          record={record.waterAccountFields}
          errors={errors?.waterAccountFields}
          update={updateWaterAccountFields}
          propertyStatus={propertyStatus}
          serviceProviderId={serviceProvider.serviceProviderId}
        />
      ) : null}

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteErrors}
        autoHideDuration={6000}
        onClose={() => setRemoteErrors(undefined)}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={() => setRemoteErrors(undefined)}
        >
          {remoteErrors}
        </Alert>
      </Snackbar>

      {/* <Row>
        {serviceProvider?.companyType && (
          <Col xs="auto">
            <FormContinueButton
              busy={busy}
              onClick={onAddAccount}
              hasChanges={true}
              label="Continue"
            />
          </Col>
        )}

        {onCancel && (
          <Col xs="auto" style={cancelLinkStyle}>
            <Button variant="link" onClick={onCancel}>
              Cancel
            </Button>
          </Col>
        )}
      </Row> */}
    </>
  );
};
