import { validEmailAddress } from "@utils/Functions"

export enum InputErrors {
  Required = "Required field",
}
export class ValidationError extends Error {
  errors: { [k: string]: string[] };

  constructor(errors: { [k: string]: string[] }) {
    super("validation error");
    this.errors = errors;
    this.name = "ValidationError";
  }
}

export const errorsFromServer = (ve: ValidationError) => {
  const errors = {
    firstName: ve.errors.firstName ? ve.errors.firstName[0] : undefined,
    lastName: ve.errors.lastName ? ve.errors.lastName[0] : undefined,
    emailAddress: ve.errors.emailAddress ? ve.errors.emailAddress[0] : undefined,
    password: ve.errors.password ? ve.errors.password[0] : undefined,
    passwordConfirmation: ve.errors.passwordConfirmation
      ? ve.errors.passwordConfirmation[0]
      : undefined,
  };

  return JSON.parse(JSON.stringify(errors));
};

