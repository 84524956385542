import * as React from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { Button } from "../../../components/atoms/Button";
import { Link } from "../../../components/atoms/Link";

export type AssetSearchDialogProps = {
  visible: boolean;
  hideDialog: () => void;
  noThanks: () => void;
  findOutMore: () => void;
};

export const AssetSearchDialog: React.FC<AssetSearchDialogProps> = ({
  visible,
  hideDialog,
  noThanks,
  findOutMore,
}) => {
  return (
    <Dialog open={visible} onClose={hideDialog}>
      <DialogTitle>Would you like to check for missing financial assets?</DialogTitle>
      <DialogContent>
        <p>
          <b>Over twenty billion pounds worth of assets are currently left unclaimed in the UK.</b>
        </p>

        <p>
          If someone doesn’t make or update a record of all their financial assets (eg. bank
          accounts, savings, shares, premium bonds, pensions, investments etc.) ahead of death,
          their wealth is often lost. This is because family members or Executors aren’t aware of
          what is left behind, and so the assets go ‘unclaimed’.
        </p>

        <p>
          If you would like support in checking for your loved one’s assets,{" "}
          <b>please click ‘Yes, I want to find out more’</b> and we will send you additional
          information. All accounts found will be added to your dashboard for you to request account
          closure or transfer.
        </p>
      </DialogContent>

      <DialogActions>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            columnGap: "40px",
            alignItems: "baseline",
          }}
        >
          <div>
            <Link onClick={noThanks}>
              <span
                style={{
                  textTransform: "uppercase",
                  color: "black",
                  textDecoration: "underline",
                }}
              >
                No thanks
              </span>
            </Link>
          </div>

          <div>
            <Button variant="primary" size="large" onClick={findOutMore}>
              <span style={{ textTransform: "uppercase" }}>Yes, I want to find out more</span>
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};
