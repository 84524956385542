import * as React from "react";

import { DescriptionOutlined, PictureAsPdfOutlined } from "@mui/icons-material";

import { Document } from "../../types/Document";
import { Link, MessageLink } from "../../components/atoms/Link";
import { Stack } from "@mui/material";

export const DocumentLink: React.FC<{ document: Document }> = ({
  document: { filename, downloadUrl, tags },
}) => {
  return (
    <Stack direction="row" columnGap={2} alignItems="center">
      {fileIcon(filename)}

      <Link to={downloadUrl} target="_blank">
        {documentLabel({ filename, tags })}
      </Link>
    </Stack>
  );
};
export const MessageDocumentLink: React.FC<{ document: Document }> = ({
  document: { filename, downloadUrl, tags },
}) => {
  return (
    <Stack direction="row" columnGap={2} alignItems="center">
      {smallFileIcon(filename)}

      <MessageLink to={downloadUrl} target="_blank" download>
        {documentLabel({ filename, tags })}
      </MessageLink>
    </Stack>
  );
};

const fileIcon = (filename: string) => {
  if (filename.match(/\.pdf/i)) {
    return <PictureAsPdfOutlined />;
  }

  if (filename.match(/.(?:png|gif|jpe?g)/i)) {
    return <PictureAsPdfOutlined />;
  }

  return <DescriptionOutlined />;
};

const smallFileIcon = (filename: string) => {
  if (filename.match(/\.pdf/i)) {
    return <PictureAsPdfOutlined fontSize="small" />;
  }

  if (filename.match(/.(?:png|gif|jpe?g)/i)) {
    return <PictureAsPdfOutlined fontSize="small" />;
  }

  return <DescriptionOutlined fontSize="small" />;
};

const documentLabel = ({ filename, tags }: { filename: string; tags?: string[] }) => {
  if (!tags || tags.length === 0) {
    return filename;
  }

  if (tags.includes("proof_of_address")) {
    return "Proof of address";
  }

  if (tags.includes("proof_of_death")) {
    return "Proof of death";
  }

  if (tags.includes("will")) {
    return "Last will and testament";
  }

  return filename;
};
