import * as React from "react";

import { cleanedErrors } from "@utils/Functions";
import { BooleanInput } from "@atoms/BooleanInput";
import { DateInput } from "@atoms/DateInput";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { useOnValueChange } from "@utils/callbacks";
import { Property } from "@src/types/property";

export type Record = {
  readonly handOverDate?: string;
  readonly handOverDateUnknown?: boolean;
};

export type Errors =
  | undefined
  | {
    readonly handOverDate?: string;
    readonly handOverDateUnknown?: string;
  };

export const recordFromProperty = (property?: Property): Record => {
  if (!property) {
    return {};
  }

  return {
    handOverDate: property.handOverDate,
    handOverDateUnknown: !property.handOverDate,
  };
};

export const propertyFieldsFromRecord = (record?: Record): Partial<Property> => {
  if (!record) {
    return {};
  }

  return {
    handOverDate: record.handOverDate || undefined,
  };
};

export const errorsOf: (r?: Record) => Errors = (r) => {
  return cleanedErrors({
    handOverDate: r?.handOverDate || r?.handOverDateUnknown ? undefined : "required",
    handOverDateUnknown: r?.handOverDate || r?.handOverDateUnknown ? undefined : "required",
  });
};

export const HandOverDateField: React.FC<{
  readonly record: Record;
  readonly update: (f: (record: Record) => Record) => void;
  readonly errors: Errors;
}> = ({ record, update, errors }) => {
  const empty = !record.handOverDate && !record.handOverDateUnknown;

  const onHandOverDateChange = useOnValueChange(update, "handOverDate");

  const updateUnknown = React.useCallback(
    (unknown: boolean) => {
      update((s) => ({
        ...s,
        handOverDate: unknown ? undefined : "",
        handOverDateUnknown: unknown,
      }));
    },
    [update]
  );

  return (
    <FormStack substack spacing={0}>
      <FormField label="When did or will the new occupant(s) move into the property?">
        <DateInput
          value={record.handOverDate || ""}
          onValueChange={onHandOverDateChange}
          error={errors?.handOverDate && empty ? "required" : undefined}
        />
      </FormField>

      <FormField>
        <BooleanInput
          value={record.handOverDateUnknown}
          onValueChange={updateUnknown}
          label="I don't know the move-in date"
          disabled={!!record.handOverDate}
          error={!!errors?.handOverDateUnknown && empty ? "required" : undefined}
        />
      </FormField>
    </FormStack>
  );
};
