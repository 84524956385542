import * as React from "react";

import { makeStyles } from "@mui/styles";

import { colors } from "@styles/constants";
import { formatDate } from "@utils/Functions";
import { Fieldset } from "@molecules/Fieldset";

export type CaseSummaryProps = {
  caseRecord: any;
};
export const CaseSummary: React.FC<CaseSummaryProps> = ({ caseRecord }) => {
  const classes = useStyles();

  return (
    <Fieldset title="Case summary">
      <table>
        <tbody>
          <tr>
            <td className={classes.label}>Deceased</td>
            <td>
              {caseRecord.deceasedFirstName} {caseRecord.deceasedLastName}
            </td>
          </tr>
          <tr>
            <td className={classes.label}>Date of death</td>
            <td>{caseRecord.deceasedAt && formatDate(caseRecord.deceasedAt)}</td>
          </tr>
          <tr>
            <td className={classes.label}>Accounts</td>
            <td>{caseRecord.accounts.length}</td>
          </tr>
          <tr>
            <td className={classes.label}>
              {caseRecord.notifier.role === "executor" ||
              caseRecord.notifier.role === "sole_executor"
                ? "Executor"
                : "Administrator"}
            </td>
            <td>
              {caseRecord.notifier.firstName} {caseRecord.notifier.lastName}
            </td>
          </tr>
        </tbody>
      </table>
    </Fieldset>
  );
};

const useStyles = makeStyles({
  label: {
    color: colors.fadedText,
    paddingRight: "20px",
  },
});
