import * as React from "react";

import { AccountDetails } from "@src/types";
import { cleanedErrors } from "@utils/Functions";
import { BooleanInput } from "@atoms/BooleanInput";
import { useOnChange } from "@utils/callbacks";
import { NewAccountRecord } from "@api/online-api";
import { TextInput } from "@atoms/TextInput";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";

export type Record = {
  readonly accountNumber?: string;
  readonly accountNumberUnknown?: boolean;
};

export type Errors =
  | undefined
  | {
    readonly accountNumber?: string;
    readonly accountNumberUnknown?: string;
  };

export const recordFromAccount = (account?: AccountDetails): Record => {
  if (!account) {
    return {};
  }

  return {
    accountNumber: account.accountNumber,
    accountNumberUnknown: !account.accountNumber,
  };
};

export const accountFieldsFromRecord = (record?: Record): Partial<NewAccountRecord> => {
  if (!record) {
    return {};
  }

  return {
    accountNumber: record.accountNumber || undefined,
  };
};

export const errorsOf: (r?: Record) => Errors = (r) => {
  return cleanedErrors({
    accountNumber: r?.accountNumber || r?.accountNumberUnknown ? undefined : "required",
    accountNumberUnknown: r?.accountNumber || r?.accountNumberUnknown ? undefined : "required",
  });
};

export const AccountNumberField: React.FC<{
  readonly record: Record;
  readonly update: (f: (record: Record) => Record) => void;
  readonly errors: Errors;
}> = ({ record, update, errors }) => {
  const empty = !record.accountNumber && !record.accountNumberUnknown;

  const onChange = useOnChange(update);

  const updateUnknown = React.useCallback(
    (unknown: boolean) => {
      update((s) => ({
        ...s,
        accountNumber: unknown ? undefined : "",
        accountNumberUnknown: unknown,
      }));
    },
    [update]
  );

  return (
    <FormStack substack spacing={0}>
      <FormField label={"Please provide the customer account number:"}>
        <TextInput
          name="accountNumber"
          value={record.accountNumber || ""}
          onChange={onChange}
          error={errors?.accountNumber && empty ? "required" : undefined}
          placeholder=""
          disabled={record.accountNumberUnknown}
        />
      </FormField>

      <FormField>
        <BooleanInput
          value={record.accountNumberUnknown}
          onValueChange={updateUnknown}
          label="I don't know the customer account number"
          disabled={!!record.accountNumber}
          error={!!errors?.accountNumberUnknown && empty ? "required" : undefined}
        />
      </FormField>
    </FormStack>
  );
};
