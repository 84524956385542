import * as React from "react";

import { Box, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { BusyOverlay } from "@atoms/BusyOverlay";
import { FormMenuDesktop } from "@molecules/FormMenuDesktop";
import { FormNextPaneDesktop } from "@molecules/FormNextPaneDesktop";
import { colors } from "@styles/constants";
import { Accordion } from "@molecules/Accordion";
import { PageHeaderWithHelp } from "@organisms/PageHeaderWithHelp";
import { insideIframe } from "@utils/Functions";
import { Header } from "@atoms/Header";
import { WhatsAppHelp } from "src/components/atoms/whatsAppHelp";
import { FormMenuEntry } from "@customTypes/index";

export type ExpressFormTemplateDesktopProps = {
  busy?: boolean;
  currentSection?: string;
  inPartnership: boolean;
  logoURL?: string;
  companyName: string;
  error?: string;
  nextLabel?: string;
  menuEntries: FormMenuEntry[];
  children: React.ReactNode;
  onNext?: () => void;
  onSectionClick: (section: string) => void;
};

export const ExpressFormTemplateDesktop: React.FC<ExpressFormTemplateDesktopProps> = ({
  menuEntries,
  busy,
  currentSection,
  inPartnership,
  logoURL,
  companyName,
  nextLabel,
  children,
  onNext,
  onSectionClick,
}) => {
  return (
    <BusyOverlay hidden={!busy}>
      <Stack rowGap="80px" sx={{ height: "100vh" }}>
        {insideIframe ? null : <PageHeaderWithHelp includeLogo={true} />}

        <Container>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Container>
                <Stack rowGap={6}>
                  <form
                    autoComplete="off"
                    onSubmit={onNext}
                    className="extra-spaced no-side-padding"
                  >
                    {children}
                  </form>
                  <Box sx={{ paddingBottom: 4 }}>
                    <FormNextPaneDesktop
                      busy={busy}
                      nextLabel={nextLabel}
                      saveLabel={null}
                      onNext={onNext}
                    />
                  </Box>
                </Stack>
              </Container>
            </Grid>

            <Grid item xs={4}>
              <Container>
                <Stack rowGap={4}>
                  <C>
                    <Stack rowGap={1}>
                      <Typography
                        variant="body1"
                        sx={{
                          textAlign: "center",
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                        }}
                      >
                        {inPartnership ? "In Partnership with" : "Notifying"}
                      </Typography>
                      {logoURL ? (
                        <img src={logoURL} alt="Logo" width={"100%"} />
                      ) : (
                        <Header center level={3}>
                          {companyName}
                        </Header>
                      )}
                    </Stack>
                  </C>

                  <FormMenuDesktop
                    currentKey={currentSection}
                    entries={menuEntries}
                    onSelect={onSectionClick}
                  />

                  {insideIframe ? null : (
                    <Accordion info title="About Settld">
                      When a loved one dies, use Settld’s free death notification service to inform
                      all companies in one go. Request account closure or transfer, obtain date of
                      death balances, and track everything through to completion in one central
                      place.
                    </Accordion>
                  )}
                </Stack>
              </Container>
              <br></br>
              <WhatsAppHelp />
            </Grid>
          </Grid>
        </Container>
      </Stack>
    </BusyOverlay>
  );
};

const C = styled(Paper)<any>(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : colors.offWhite,
  padding: "20px 24px 20px 24px",
  borderRadius: "16px",
})) as typeof Paper;
