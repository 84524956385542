import * as React from "react";

import { usePageViewEventTracker } from "@utils/analytics";
import { ErrorPane } from "@organisms/ErrorPane";
import { CentredTemplate } from "@templates/CentredTemplate";

export const BugFallbackPage = () => {
  usePageViewEventTracker("Error rendering page");

  return <BugFallbackPageView />;
};

export type BugFallbackPageViewProps = {};

export const BugFallbackPageView: React.FC<BugFallbackPageViewProps> = () => {
  return (
    <CentredTemplate headerType={"logo"}>
      <ErrorPane
        message="Unexpected failure"
        description="Please reload the page. If the problem persists, please contact customer support."
      />
    </CentredTemplate>
  );
};
