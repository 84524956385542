import React from "react";

import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

export type PasswordInputProps = {
  readonly name: string;
  readonly value: string;
  readonly placeholder?: string;
  readonly disabled?: boolean;
  readonly error?: string | null;
  readonly onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  name,
  value,
  placeholder,
  disabled,
  error,
  onChange,
}) => {
  const [show, setShow] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const showHide = React.useCallback(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      setShow(!show);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [show, inputRef]
  );

  return (
    <FormControl error={!!error} sx={{ width: "100%" }}>
      <OutlinedInput
        autoComplete="off"
        disabled={disabled}
        inputRef={inputRef}
        name={name}
        placeholder={placeholder}
        size="small"
        type={show ? "text" : "password"}
        value={value}
        onChange={onChange}
        sx={{ backgroundColor: "white" }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={showHide}
              onMouseDown={showHide}
              edge="end"
            >
              {show ? (
                <VisibilityOffOutlined fontSize="small" color="primary" />
              ) : (
                <VisibilityOutlined fontSize="small" color="primary" />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};
