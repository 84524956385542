import * as React from "react";

export type Property = {
  readonly id: string;
  readonly address: string;
  readonly city: string;
  readonly postcode: string;
};

export const PropertySummary: React.FC<{
  readonly properties: ReadonlyArray<Property>;
  readonly propertyId?: string;
}> = ({ properties, propertyId }) => {
  if (!propertyId) {
    return null;
  }

  const property = properties.find(({ id }) => id === propertyId);

  if (!property) {
    return null;
  }

  const { address, city, postcode } = property;
  const line = [address, city, postcode].filter((x) => x).join(", ");

  return <span>{line}</span>;
};
