import { Stack, Typography } from "@mui/material";
import * as React from "react";
import { colors } from "@styles/constants";

import { Header } from "../../../components/atoms/Header";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";

export type KYCViewProps = {
  expressForm?: boolean;
  onContinue: () => void;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
};

export const KYCView: React.FC<KYCViewProps> = ({
  expressForm,
  onContinue,
  onSectionClick,
  updateTemplate,
}) => {
  React.useEffect(() => {
    updateTemplate({
      currentSection: "kyc",
      nextLabel: "Verify my identity",
      saveLabel: null,
      onNext: onContinue,
      onSectionClick: onSectionClick,
    });
  }, [onContinue, onSectionClick, updateTemplate]);

  return (
    <Stack rowGap={4}>
      <Header level={1} color={expressForm ? colors.accentTeal : undefined}>
        We need to verify that it’s actually you.
      </Header>

      <Stack rowGap={2}>
        <Typography>
          To protect you and the companies we work with, we need to verify your identity.
        </Typography>
        <Typography>
          Settld has partnered with market-leading biometric identity verification provider, Onfido,
          to do this.
        </Typography>
        <Typography>
          Please click the button below and follow the instructions to verify your identity.
        </Typography>
      </Stack>
    </Stack>
  );
};
