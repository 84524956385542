import * as React from "react";

import { Stack, Typography } from "@mui/material";

import { TextInput } from "../../../components/atoms/TextInput";
import { FormField } from "../../../components/molecules/FormField";
import { useOnChange, useOnValueChange } from "@utils/callbacks";
import { RadioGroupInput } from "../../../components/atoms/RadioGroupInput";
import { InfoBoxTrigger } from "../../../components/molecules/InfoBoxTrigger";
import { Person } from "./ResponsibleFields";
import { cleanedErrors } from "@utils/Functions";
import { AccountType, RefundPaymentMethod } from "@customTypes/index";

export type Record = {
  refundPaymentMethod: RefundPaymentMethod | undefined;
  refundBankAccountHolderName?: string;
  refundBankAccountNumber?: string;
  refundBankAccountSortCode?: string;
  refundChequePayableTo?: "notifier" | "responsible";
};

export type Errors = {
  refundPaymentMethod?: string;
  refundBankAccountHolderName?: string;
  refundBankAccountNumber?: string;
  refundBankAccountSortCode?: string;
  refundChequePayableTo?: string;
};

export const readableRefundPaymentMethod = (m: RefundPaymentMethod) => {
  switch (m) {
    case RefundPaymentMethod.BankTransferToAssociatedAccount:
      return "Bank transfer to associated account";
    case RefundPaymentMethod.BankTransferToNewAccount:
      return "Bank transfer to new account";
    case RefundPaymentMethod.Cheque:
      return "Cheque";
  }
};

export type RefundFieldsProps = {
  serviceProvider?: { customForm: string };
  accountType?: AccountType;
  persons: ReadonlyArray<Person>;
  record: Record;
  errors?: Errors;
  update: (proc: (r: Record) => Record) => void;
  notifierIsResponsible: boolean;
  allowTransferToAnotherBankAccount: boolean;
};

export const errorsOf = (r: Record | undefined, notifierIsResponsible: boolean) => {
  return cleanedErrors({
    refundPaymentMethod: r?.refundPaymentMethod ? undefined : "required",
    refundBankAccountHolderName:
      (r?.refundPaymentMethod === RefundPaymentMethod.BankTransferToAssociatedAccount ||
        r?.refundPaymentMethod === RefundPaymentMethod.BankTransferToNewAccount) &&
        !r?.refundBankAccountHolderName
        ? "required"
        : undefined,
    refundBankAccountNumber:
      r?.refundPaymentMethod === RefundPaymentMethod.BankTransferToNewAccount &&
        !r?.refundBankAccountNumber
        ? "required"
        : undefined,
    refundBankAccountSortCode:
      r?.refundPaymentMethod === RefundPaymentMethod.BankTransferToNewAccount &&
        !r?.refundBankAccountSortCode
        ? "required"
        : undefined,
    refundChequePayableTo:
      r?.refundPaymentMethod === RefundPaymentMethod.Cheque &&
        !notifierIsResponsible &&
        !r?.refundChequePayableTo
        ? "required"
        : undefined,
  });
};

export const RefundFields: React.FC<RefundFieldsProps> = ({
  serviceProvider,
  accountType,
  persons,
  record,
  errors,
  notifierIsResponsible,
  allowTransferToAnotherBankAccount,
  update,
}) => {
  const onRefundPaymentMethodValueChange = React.useCallback(
    (refundPaymentMethod) => {
      update((s) => ({
        ...s,
        refundPaymentMethod,
        refundBankAccountHolderName: undefined,
        refundBankAccountNumber: undefined,
        refundBankAccountSortCode: undefined,
        refundChequePayableTo: undefined,
      }));
    },
    [update]
  );

  const onRefundChequePayableTo = useOnValueChange(update, "refundChequePayableTo");

  const onChange = useOnChange(update);

  React.useEffect(() => {
    if (!allowTransferToAnotherBankAccount) {
      update((s) => {
        if (s.refundPaymentMethod === RefundPaymentMethod.BankTransferToNewAccount) {
          return {
            ...s,
            refundPaymentMethod: undefined,
            refundBankAccountHolderName: undefined,
            refundBankAccountNumber: undefined,
            refundBankAccountSortCode: undefined,
            refundChequePayableTo: undefined,
          };
        }

        if (notifierIsResponsible && s.refundChequePayableTo) {
          return {
            ...s,
            refundChequePayableTo: undefined,
          };
        }

        return s;
      });
    }
  }, [allowTransferToAnotherBankAccount, notifierIsResponsible, update]);

  return (
    <>
      <FormField
        label={
          <>
            If a refund is due, how would you like it to be paid?{" "}
            {accountType !== AccountType.PrivateMedicalInsurance && (
              <InfoBoxTrigger
                white
                content={
                  <div>
                    <p>Please note no refunds are made for partial months.</p>
                  </div>
                }
              />
            )}
          </>
        }
      >
        <RadioGroupInput
          fields={
            allowTransferToAnotherBankAccount
              ? refundOptions
              : refundOptionsWithoutAnotherBankTransfer
          }
          value={record.refundPaymentMethod}
          onValueChange={onRefundPaymentMethodValueChange}
          error={errors?.refundPaymentMethod}
        />
      </FormField>

      {record.refundPaymentMethod === RefundPaymentMethod.BankTransferToAssociatedAccount && (
        <FormField>
          <Typography variant="body2" sx={{ color: "rgba(0,0,0,0.6)" }}>
            Please confirm the name of the account holder (as it appears on any bank card or
            statement):
            <InfoBoxTrigger white width="448px" content={renderBankTransferInfoBox()} />
          </Typography>
          <TextInput
            name="refundBankAccountHolderName"
            value={record.refundBankAccountHolderName || ""}
            onChange={onChange}
            error={errors?.refundBankAccountHolderName}
          />
        </FormField>
      )}

      {record.refundPaymentMethod === RefundPaymentMethod.BankTransferToNewAccount && (
        <>
          <FormField halfWidthByItself label="Bank account holder name">
            <TextInput
              name="refundBankAccountHolderName"
              value={record.refundBankAccountHolderName || ""}
              onChange={onChange}
              error={errors?.refundBankAccountHolderName}
            />
          </FormField>

          <FormField halfWidth label="Account number">
            <TextInput
              name="refundBankAccountNumber"
              value={record.refundBankAccountNumber || ""}
              onChange={onChange}
              error={errors?.refundBankAccountNumber}
              placeholder="00000000"
            />
          </FormField>

          <FormField halfWidth label="Sort code">
            <TextInput
              name="refundBankAccountSortCode"
              value={record.refundBankAccountSortCode || ""}
              onChange={onChange}
              error={errors?.refundBankAccountSortCode}
              placeholder="000000"
            />
          </FormField>
        </>
      )}

      {record.refundPaymentMethod === RefundPaymentMethod.Cheque && !notifierIsResponsible && (
        <>
          <FormField label="Who should the cheque be made payable to?">
            <RadioGroupInput
              onValueChange={onRefundChequePayableTo}
              value={record.refundChequePayableTo}
              fields={[
                { value: "notifier", label: "Myself" },
                { value: "responsible", label: "Surviving joint policy holder" },
              ]}
              error={errors?.refundChequePayableTo}
            />
          </FormField>
        </>
      )}
    </>
  );
};

const renderBankTransferInfoBox = () => {
  return (
    <Stack rowGap={2}>
      <Typography variant="body1">
        Please note that the bank account name needs to match the details held by the receiving
        bank. For example, if you write 'Mr G Brown', but the bank account name is 'Mr George
        Brown', this may cause the refund payment to be delayed whilst the correct name is obtained.
      </Typography>
    </Stack>
  );
};

const refundOptions = [
  {
    label: "By bank transfer to the existing bank account associated with the policy",
    value: RefundPaymentMethod.BankTransferToAssociatedAccount,
    info: "Please note that if the attempt to refund to the existing bank account associated with the policy is unsuccessful (eg. the bank account has been closed, frozen or the account name has been updated), AIG will issue a cheque refund instead.",
  },
  {
    label: "By bank transfer to another bank account",
    value: RefundPaymentMethod.BankTransferToNewAccount,
    info: "Please note that AIG may ask you to complete a Form of Discharge before a payment can be credited to another bank account other than the Policyholder’s bank account.",
  },
  {
    label: "By cheque",
    value: RefundPaymentMethod.Cheque,
    info: "Please note AIG may ask you to complete a Form of Discharge before a cheque payment can be issued.",
  },
];

const refundOptionsWithoutAnotherBankTransfer = [
  {
    label: "By bank transfer to the existing bank account associated with the policy",
    value: RefundPaymentMethod.BankTransferToAssociatedAccount,
    info: "Please note that if the attempt to refund to the existing bank account associated with the policy is unsuccessful (eg. the bank account has been closed, frozen or the account name has been updated), AIG will issue a cheque refund instead.",
  },
  {
    label: "By cheque",
    value: RefundPaymentMethod.Cheque,
    info: "Please note AIG may ask you to complete a Form of Discharge before a cheque payment can be issued.",
  },
];
