import * as React from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { Button } from "../../components/atoms/Button";

export type IDVerificationDialogProps = {
  visible: boolean;
  hideDialog: () => void;
};

export const IDVerificationDialog: React.FC<IDVerificationDialogProps> = ({
  visible,
  hideDialog,
}) => {
  return (
    <Dialog open={visible} onClose={hideDialog}>
      <DialogTitle>Before you go, we need to verify that it’s actually you</DialogTitle>
      <DialogContent>
        <p>
          To protect you, the information you have provided and the companies we work with, we need
          to verify your identity.
        </p>

        <p>
          We have partnered with market-leading biometric identity verification provider, Onfido.
        </p>

        <p>
          Please click ‘Verify my identity’ below and follow the instructions to verify your
          identity. We recommend completing this process on your desktop.
        </p>
      </DialogContent>

      <DialogActions>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            columnGap: "40px",
            alignItems: "baseline",
          }}
        >
          <div>
            <Button variant="primary" size="large" onClick={() => {}}>
              <span style={{ textTransform: "uppercase" }}>Verify My Identity</span>
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};
