import * as React from "react";

import { Typography } from "@mui/material";

import { NewAccountRecord } from "@api/online-api";
import { colors } from "@styles/constants";
import { AccountDetails, AccountNextStep } from "@customTypes/index";
import { cleanedErrors, isNWG } from "@utils/Functions";
import { FormField } from "../../../../components/molecules/FormField";
import { FormStack } from "../../../../components/molecules/FormStack";
import { useUpdater } from "@utils/callbacks";
import { Person } from "../ResponsibleFields";
import { ExistingOccupierPropertyDetails } from "./ExistingOccupierPropertyDetails";
import {
  Errors as ExistingOccupierInfoErrors,
  errorsOf as existingOccupierInfoErrorsOf,
  existingOccupierInfoAvailable,
  ExistingOccupierPropertyFields,
  existingOccupierPropertyFieldsFromRecord,
  Record as ExistingOccupierInfoRecord,
} from "./ExistingOccupierPropertyFields";
import { NewOccupierPropertyDetails } from "./NewOccupierPropertyDetails";
import {
  Errors as NewOccupierInfoErrors,
  errorsOf as newOccupierInfoErrorsOf,
  newOccupierInfoAvailable,
  NewOccupierPropertyFields,
  newOccupierPropertyFieldsFromRecord,
  Record as NewOccupierInfoRecord,
} from "./NewOccupierPropertyFields";
import {
  Errors as NewPropertyDetailsErrors,
  errorsOf as newPropertyDetailsErrorsOf,
  NewPropertyDetailsFields,
  Record as NewPropertyDetailsRecord,
} from "./NewPropertyDetailsFields";
import { Property, PropertyStatus } from "../../../../types/property";
import { PropertySelectorField } from "./PropertySelectorField";
import { PropertyStatusDetails } from "./PropertyStatusDetails";
import { PropertyStatusField } from "./PropertyStatusField";
import { VacantPropertyDetails } from "./VacantPropertyDetails";
import {
  Errors as VacantPropertyInfoErrors,
  errorsOf as vacantPropertyInfoErrorsOf,
  Record as VacantPropertyInfoRecord,
  VacantPropertyFields,
  vacantPropertyInfoAvailable,
  vacantPropertyPropertyFieldsFromRecord,
} from "./VacantPropertyFields";

export type Record = {
  _property?: Property;
  propertyId?: string;
  newPropertyDetails?: NewPropertyDetailsRecord;
  propertyStatus?: PropertyStatus;
  vacantPropertyInfo?: VacantPropertyInfoRecord;
  newOccupierInfo?: NewOccupierInfoRecord;
  existingOccupierInfo?: ExistingOccupierInfoRecord;
};

export type Errors =
  | undefined
  | {
    readonly propertyId?: string;
    readonly newPropertyDetails?: NewPropertyDetailsErrors;
    readonly propertyStatus?: string;
    readonly vacantPropertyInfo?: VacantPropertyInfoErrors;
    readonly newOccupierInfo?: NewOccupierInfoErrors;
    readonly existingOccupierInfo?: ExistingOccupierInfoErrors;
  };

export const errorsOf: (r?: Record) => Errors = (r) => {
  const isNewProperty = r?.propertyId === "";
  const statusRequired = !validStatus(r?._property?.status, false);
  return cleanedErrors({
    propertyId: r?.propertyId || isNewProperty ? undefined : "required",
    newPropertyDetails: !r?.newPropertyDetails
      ? undefined
      : newPropertyDetailsErrorsOf(r?.newPropertyDetails),
    propertyStatus: !statusRequired || r?.propertyStatus ? undefined : "required",
    vacantPropertyInfo: !r?.vacantPropertyInfo
      ? undefined
      : vacantPropertyInfoErrorsOf(r?.vacantPropertyInfo),
    newOccupierInfo: !r?.newOccupierInfo ? undefined : newOccupierInfoErrorsOf(r?.newOccupierInfo),
    existingOccupierInfo: !r?.existingOccupierInfo
      ? undefined
      : existingOccupierInfoErrorsOf(r?.existingOccupierInfo),
  });
};

export const errorsOfAddressOnly: (r?: Record) => Errors = (r) => {
  const isNewProperty = r?.propertyId === "";
  return cleanedErrors({
    propertyId: r?.propertyId || isNewProperty ? undefined : "required",
    newPropertyDetails: !r?.newPropertyDetails
      ? undefined
      : newPropertyDetailsErrorsOf(r?.newPropertyDetails),
  });
};

export const recordFromAccount = (account: AccountDetails | undefined): Record => {
  if (!account || !account.propertyId) {
    return {};
  }
  // TODO
  return {
    propertyId: account.propertyId,
    // account.
  };
};

export const propertyAccountFieldsFromRecord = (
  record: undefined | Record
): Partial<NewAccountRecord> => {
  if (!record) {
    return {};
  }

  const property = {
    ...(record.propertyId ? { id: record.propertyId } : { details: record.newPropertyDetails }),
    status: record.propertyStatus,
    ...(record.propertyStatus === PropertyStatus.Vacant
      ? vacantPropertyPropertyFieldsFromRecord(record.vacantPropertyInfo)
      : record.propertyStatus === PropertyStatus.NewOccupier
        ? newOccupierPropertyFieldsFromRecord(record.newOccupierInfo)
        : record.propertyStatus === PropertyStatus.ExistingOccupier
          ? existingOccupierPropertyFieldsFromRecord(record.existingOccupierInfo)
          : null),
  };

  return {
    property,
    nextStep: accountRequestFromPropertyStatus(record.propertyStatus),
  };
};

export const propertyFieldsFromRecordAddressOnly = (
  record: undefined | Record
): Partial<NewAccountRecord> => {
  if (!record) {
    return {};
  }

  return {
    property: record.propertyId
      ? { id: record.propertyId }
      : { details: record.newPropertyDetails },
  };
};

const accountRequestFromPropertyStatus = (ps?: PropertyStatus): AccountNextStep | undefined => {
  switch (ps) {
    case PropertyStatus.ExistingOccupier:
      return AccountNextStep.UpdateAccountHolder;
    case PropertyStatus.NewOccupier:
      return AccountNextStep.Cancel;
    case PropertyStatus.Vacant:
      return AccountNextStep.Cancel;
    default:
      return undefined;
  }
};

export type PropertyFieldsProps = {
  readonly serviceProvider: { customForm?: string };
  readonly label?: string;
  readonly addressOnly?: boolean;
  readonly serviceProviderId?: string;
  readonly persons: ReadonlyArray<Person>;
  readonly properties: ReadonlyArray<Property>;
  readonly record: Record;
  readonly errors?: any;
  readonly update: any;
};

export const PropertyFields: React.FC<PropertyFieldsProps> = ({
  serviceProvider,
  label,
  addressOnly,
  serviceProviderId,
  persons,
  properties,
  record,
  errors,
  update,
}) => {
  const isNewProperty = record.propertyId === "";
  const statusRequired = !validStatus(record._property?.status, !!addressOnly);

  const onPropertyIdChange = React.useCallback(
    (propertyId: any) => {
      if (propertyId) {
        const _property = properties.find((p) => p.id === propertyId);
        if (_property) {
          const propertyStatus = _property.status;
          update({
            _property,
            propertyId,
            newPropertyDetails: undefined,
            propertyStatus,
            vacantPropertyInfo:
              propertyStatus !== PropertyStatus.Vacant || vacantPropertyInfoAvailable(_property)
                ? undefined
                : {},
            newOccupierInfo:
              propertyStatus !== PropertyStatus.NewOccupier || newOccupierInfoAvailable(_property)
                ? undefined
                : {},
            existingOccupierInfo:
              propertyStatus !== PropertyStatus.ExistingOccupier ||
                existingOccupierInfoAvailable(_property)
                ? undefined
                : {},
          });
        } else {
          update({});
        }
        return;
      }

      if (propertyId === "") {
        update({ propertyId, newPropertyDetails: {} });
        return;
      }

      update({});
    },
    [update, properties]
  );

  const onPropertyStatusValueChange = React.useCallback(
    (propertyStatus) => {
      update(({ _property, propertyId, newPropertyDetails }: Record) => ({
        _property,
        propertyId,
        newPropertyDetails,
        propertyStatus,
        vacantPropertyInfo: propertyStatus !== PropertyStatus.Vacant ? undefined : {},
        newOccupierInfo: propertyStatus !== PropertyStatus.NewOccupier ? undefined : {},
        existingOccupierInfo: propertyStatus !== PropertyStatus.ExistingOccupier ? undefined : {},
      }));
    },
    [update]
  );

  const updateNewPropertyDetails = useUpdater(update, "newPropertyDetails");

  const updateVacantPropertyInfo = useUpdater(update, "vacantPropertyInfo");

  const updateNewOccupierInfo = useUpdater(update, "newOccupierInfo");

  const updateExistingOccupierInfo = useUpdater(update, "existingOccupierInfo");

  React.useEffect(() => {
    if (typeof record.propertyId === "string") {
      if (record.propertyId && !record._property) {
        onPropertyIdChange(record.propertyId);
      }
      return;
    }

    if (properties.length === 0) {
      update({
        propertyId: "",
        newPropertyDetails: {},
      });
      return;
    }

    if (properties.length === 1) {
      onPropertyIdChange(properties[0].id);
      return;
    }
  }, [record, properties, update, onPropertyIdChange]);

  return (
    <>
      {properties.length === 0 && record.newPropertyDetails && (
        <>
          <FormField label={label || "What is the property address?"}>
            <NewPropertyDetailsFields
              record={record.newPropertyDetails}
              update={updateNewPropertyDetails}
              errors={errors?.newPropertyDetails}
            />
          </FormField>
        </>
      )}

      {properties.length > 0 && (
        <FormStack substack spacing={0}>
          <PropertySelectorField
            label={label}
            properties={properties}
            record={record}
            errors={errors}
            onPropertyIdChange={onPropertyIdChange}
          />

          {record.newPropertyDetails && properties.length !== 0 && (
            <FormStack indented substack>
              <FormField label={<i>Please enter the property address below:</i>}></FormField>

              <NewPropertyDetailsFields
                record={record.newPropertyDetails}
                update={updateNewPropertyDetails}
                errors={errors?.newPropertyDetails}
              />
            </FormStack>
          )}

          {!addressOnly && (
            <FormField>
              {isNewProperty || (record.propertyId && statusRequired) ? null : record._property ? (
                <PropertyStatusDetails property={record._property} />
              ) : null}

              {record.vacantPropertyInfo ? null : record.propertyStatus === PropertyStatus.Vacant &&
                record._property ? (
                <VacantPropertyDetails property={record._property} />
              ) : null}

              {record.newOccupierInfo ? null : record.propertyStatus ===
                PropertyStatus.NewOccupier && record._property ? (
                <NewOccupierPropertyDetails property={record._property} />
              ) : null}

              {record.existingOccupierInfo ? null : record.propertyStatus ===
                PropertyStatus.ExistingOccupier && record._property ? (
                <ExistingOccupierPropertyDetails property={record._property} />
              ) : null}
            </FormField>
          )}
        </FormStack>
      )}

      {!addressOnly && (isNewProperty || (record.propertyId && statusRequired)) ? (
        <FormStack substack spacing={0}>
          <PropertyStatusField
            value={record.propertyStatus}
            error={errors?.propertyStatus}
            onValueChange={onPropertyStatusValueChange}
            serviceProviderId={serviceProviderId}
          />

          {record.vacantPropertyInfo && isNWG(serviceProviderId) && (
            <FormField>
              <Typography variant="body1" sx={{ color: colors.fadedText }}>
                The water will be left on and available to use for clearing, cleaning and sorting
                the property free of charge. If someone is living at the address or using the water
                for something other than the above, please choose one of the other two options as
                the status of the property.
              </Typography>
            </FormField>
          )}
        </FormStack>
      ) : null}

      {record.vacantPropertyInfo ? (
        <VacantPropertyFields
          record={record.vacantPropertyInfo}
          errors={errors?.vacantPropertyInfo}
          update={updateVacantPropertyInfo}
          serviceProviderId={serviceProviderId}
        />
      ) : null}

      {record.newOccupierInfo ? (
        <NewOccupierPropertyFields
          record={record.newOccupierInfo}
          errors={errors?.newOccupierInfo}
          update={updateNewOccupierInfo}
          serviceProviderId={serviceProviderId}
        />
      ) : null}

      {record.existingOccupierInfo ? (
        <ExistingOccupierPropertyFields
          serviceProvider={serviceProvider}
          persons={persons}
          record={record.existingOccupierInfo}
          errors={errors?.existingOccupierInfo}
          update={updateExistingOccupierInfo}
        />
      ) : null}
    </>
  );
};

const validStatus = (status: PropertyStatus | undefined, addressOnly: boolean) => {
  if (addressOnly) {
    return true;
  }

  if (!status) {
    return false;
  }

  return [
    PropertyStatus.ExistingOccupier,
    PropertyStatus.NewOccupier,
    PropertyStatus.Vacant,
  ].includes(status);
};
