import * as React from "react";

import { InfoBoxTrigger } from "@src/components/molecules";
import { NeedHelpBox2 } from "@molecules/NeedHelpBox";
import { PageHeader } from "./PageHeader";

export type PageHeaderWithHelpProps = {
  includeLogo?: boolean;
};

export const PageHeaderWithHelp: React.FC<PageHeaderWithHelpProps> = ({ includeLogo }) => {
  return (
    <PageHeader
      includeLogo={includeLogo}
      prompt={
        <InfoBoxTrigger left white content={<NeedHelpBox2 />}>
          <span style={{ textDecoration: "underline" }}>Need help?</span>
        </InfoBoxTrigger>
      }
    />
  );
};
