import { Document } from "@src/types";

export type Message = {
  readonly id: string;
  readonly content: string;
  readonly isReadByServiceProvider: boolean;
  readonly isReadByNotifier: boolean;
  readonly isReadBySettld: boolean;
  readonly ownMessage: boolean;
  readonly settldMessage: boolean;
  readonly senderName: string;
  readonly companyName: string;
  readonly insertedAt: string;
  readonly deletedAt?: string | null;
  readonly documents: ReadonlyArray<Document>;
};

export type MessageBlock = {
  readonly messages: Array<Message>;
  lastMessage: Message;
  lastUndeletedMessage: Message | null;
};

export const lastReceivedMessageFromBlocks = (blocks: ReadonlyArray<MessageBlock>) => {
  const len = blocks.length;

  for (let i = 0; i < len; i++) {
    const block = blocks[len - 1 - i];
    if (!block.lastMessage.ownMessage) {
      return block.lastMessage;
    }
  }

  return null;
};
