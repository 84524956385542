const ecstasy = "#f88010";
const geyser = "#dbe5e6";
const ming = "#377a84";
const outerSpace = "#353d3f";
const pomegranate = "#f84010";
const tradewind = "#68aeba";
const white = "#ffffff";

const primary = tradewind;
const primaryDark = ming;
const primaryDarkDark = outerSpace;
const primaryLight = geyser;

const secondary = ecstasy;
const secondaryDark = pomegranate;

const error = pomegranate;

const blocked = "#d47e7e";
const processing = "#ebd897";
const settled = "#8bba98";

const enabled = "#f5f5f0";
const disabled = "#eaeaea";

export const colors = {
  primaryText: "#555555",
  secondaryText: "#b1b1b1",
  lightGrey: "#dfdfdf",
  lighterGray: "#ececec",
  offWhite: "#fcfcfc",
  white: "#ffffff",
  accentTeal: "#377a84", // primary main
  lightTeal: "#b5cdd1", // primary light
  lighterTeal: "#B2DFDB",
  lighestTeal: "#eef4f3",
  accentOrange: "#f88012", // secondary main
  fadedOrange: "#fa9d49", // secondary light
  errorRed: "rgba(183,15,15,1)", // error main
  errorRedFaded: "rgba(183,15,15,0.05)", // error light
  confirmationGreen: "#278618",

  ecstasy,
  geyser,
  ming,
  outerSpace,
  pomegranate,
  tradewind,

  primary,
  primaryDark,
  primaryDarkDark,
  primaryLight,

  secondary,
  secondaryDark,

  enabled,
  disabled,

  blocked,
  processing,
  settled,

  error,

  headerAndFooterBackground: tradewind,
  headerAndFooterText: white,
  titleText: ming,
  bodyText: outerSpace,
  continueButtonBackground: ecstasy,
  saveButtonBackground: geyser,
  cancelButtonBackground: pomegranate,
  doneTick: ming,
  doneText: ecstasy,
  infoBoxBackground: geyser,
  bullets: ming,

  brandBlue: "#020887",
  brandBlueA1: "rgba(2, 8, 135, 0.7)",
  badgeTeal: "#41ead4",
  badgeRed: "#f50744",
  badgeLightPurple: "#9d8df1",
  badgeDarkBlue: "#020887",
  buttonRed: "#d85454",

  veryDarkBlue: "#042f4c",
  darkBlue: "#11659b",
  lightBlue: "#e7eff5",
  black: "#191919",
  gray: "#555555",

  fadedText: "#b1b1b1",
};

export const dimensions = {
  headerHeight: "60px",
  footerClearHeight: "20px",
  footerHeight: "60px",
};
