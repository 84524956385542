import { Stack, Typography } from "@mui/material";
import { AIGAccount } from "@src/pages/ExpressForm/Submit/AIGAccount";
import { BankAccount } from "@src/pages/ExpressForm/Submit/BankAccount";
import { CreditCardsAccount } from "@src/pages/ExpressForm/Submit/CreditCardAccount";
import { DefaultAccount } from "@src/pages/ExpressForm/Submit/DefaultAccount";
import { HastingsDirectAccount } from "@src/pages/ExpressForm/Submit/HastingsDirectAccount";
import { InsuranceAccount } from "@src/pages/ExpressForm/Submit/InsuranceAccount";
import { InvestmentsAccount } from "@src/pages/ExpressForm/Submit/InvestmentsAccount";
import { MobileAccount } from "@src/pages/ExpressForm/Submit/MobileAccount";
import { MortgageAccount } from "@src/pages/ExpressForm/Submit/MortgageAccount";
import { PensionAccount } from "@src/pages/ExpressForm/Submit/PensionAccount";
import { SocialMediaOrEmailAccount } from "@src/pages/ExpressForm/Submit/SocialMediaOrEmailAccount";
import { ServiceProvider, Account, CompanyType } from "@src/types";
import { useMatch, useNavigate } from "react-router-dom";
import { FieldsTableHeader, FieldsTable } from "../molecules";
import { colors } from "@styles/constants";
import { EnergyAccount } from "@src/pages/ExpressForm/Submit/EnergyAccount";
import { WaterAccount } from "@src/pages/ExpressForm/Submit/WaterAccount";
import React from "react";



interface AccountSummaryI {
  sp: ServiceProvider,
  account: Partial<Account>
}
export const ExpressAccountSummary: React.FC<AccountSummaryI> = ({ sp, account }) => {
  const match = useMatch("/express/:slug/*");
  const navigate = useNavigate();
  const onEditAccount = React.useCallback(() => {
    navigate(`${match?.pathnameBase}/account`);
  }, [navigate, match]);
  const accountSectionTitle =
    sp.serviceProviderType === CompanyType.Insurance
      ? "Policy details for the person who died"
      : "Account details for the person who died";

  const accountComponents: { [key: string]: React.FC<any> } = {
    "aig": AIGAccount,
    "hastings-direct": HastingsDirectAccount,
    [CompanyType.Banking]: BankAccount,
    [CompanyType.BuildingSociety]: BankAccount,
    [CompanyType.CreditCards]: CreditCardsAccount,
    [CompanyType.Energy]: EnergyAccount,
    [CompanyType.Insurance]: InsuranceAccount,
    [CompanyType.Investments]: InvestmentsAccount,
    [CompanyType.Mobile]: MobileAccount,
    [CompanyType.Mortgage]: MortgageAccount,
    [CompanyType.Pension]: PensionAccount,
    [CompanyType.SocialMedia]: SocialMediaOrEmailAccount,
    [CompanyType.Email]: SocialMediaOrEmailAccount,
    [CompanyType.Water]: WaterAccount,
    default: DefaultAccount,
  };

  const getAccountComponent = () => {
    if (sp.customForm && accountComponents[sp.customForm]) return accountComponents[sp.customForm];
    if (sp.serviceProviderType && accountComponents[sp.serviceProviderType as CompanyType]) return accountComponents[sp.serviceProviderType as CompanyType];
    return accountComponents.default;
  };
  const AccountComponent = getAccountComponent();

  return <>
    {!account ? (
      <Stack rowGap={2}>
        <FieldsTableHeader title={accountSectionTitle} onEdit={onEditAccount} />
        <Typography variant="body1" color={colors.fadedText}>
          Not completed yet
        </Typography>
      </Stack>
    ) : (
      <FieldsTable
        header={<FieldsTableHeader title={accountSectionTitle} onEdit={onEditAccount} />}
      >
        <AccountComponent account={account} serviceProviderType={sp.serviceProviderType} serviceProvider={sp}/>
      </FieldsTable>
    )}
  </>
}