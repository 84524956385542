import * as React from "react";

import { Alert, Snackbar } from "@mui/material";

export type ErrorSnackbarProps = { error?: string; onClose?: () => void };

export const ErrorSnackbar: React.FC<ErrorSnackbarProps> = ({ error, onClose }) => {
  return (
    <Snackbar
      sx={{ top: "58px" }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={!!error}
      autoHideDuration={10000}
      onClose={onClose}
    >
      <Alert elevation={6} variant="filled" severity="error" onClose={onClose}>
        {error}
      </Alert>
    </Snackbar>
  );
};
