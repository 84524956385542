import * as React from "react";

import { Account } from "@src/types";
import { RequestDetailsField } from "@src/components/molecules";
import { ViewMoreContainer } from "@src/components/molecules";
import { PayeeDetails, PaymentMethod } from "../PayeeDetails";
import { NotAvailable } from "./NotAvailable";
import { readableAccountNextSteps } from "@src/utils/accounts";

export const DefaultAccountDetails: React.FC<{ account: Account }> = ({ account }) => {
  return (
    <>
      <RequestDetailsField label="Notifier request">
        {readableAccountNextSteps(account.nextSteps, account.serviceProvider?.serviceProviderType)}
      </RequestDetailsField>
      <RequestDetailsField label="Customer reference number">
        {account.holderNumber || <NotAvailable />}
      </RequestDetailsField>
      <ViewMoreContainer>
        <PayeeDetails paymentMethod={PaymentMethod.BankTransfer} account={account} />
      </ViewMoreContainer>
    </>
  );
};
