import * as React from "react";

import { ErrorPane } from "@organisms/ErrorPane";
import { CentredTemplate } from "@templates/CentredTemplate";

export type NotAvailablePageProps = {
  title?: string;
  message?: string;
  children?: React.ReactNode;
};

export const NotAvailablePage: React.FC<NotAvailablePageViewProps> = ({
  title,
  message,
  children,
}) => {
  return <NotAvailablePageView title={title} message={message} children={children} />;
};

export type NotAvailablePageViewProps = {
  title?: string;
  message?: string;
  children?: React.ReactNode;
};

export const NotAvailablePageView: React.FC<NotAvailablePageViewProps> = ({
  title = "Not available",
  message = "The page you requested is not available.",
  children = <></>,
}) => {
  return (
    <CentredTemplate headerType={"logo"}>
      <ErrorPane message={title} description={message} />
      {children}
    </CentredTemplate>
  );
};
