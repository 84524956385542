import * as React from "react";
import { useNavigate } from "react-router-dom";

import { usePageViewEventTracker } from "@utils/analytics";
import urlPaths from "../../urlPaths";
import { KYCTemplate } from "@templates/KYCTemplate";
import { createChecks, getApplicantAndToken, getChecks, getReports } from "./api";
import { getCase } from "./api";
import { KYC, KYCApi } from "./KYC";

export const KYCPage: React.FC<{ caseId: string; signature: string | null }> = ({
  caseId,
  signature,
}) => {
  usePageViewEventTracker("KYC");

  const navigate = useNavigate();

  const [busy, setBusy] = React.useState(false);

  const onDone = React.useCallback(() => {
    getCase({ caseId, signature }).then(
      (data) => {
        let { case: caseRecord } = data;

        if (caseRecord?.formSubmittedAt) {
          navigate(urlPaths.status());
        } else {
          navigate(urlPaths.form("kyc"));
        }
      },
      (_) => {
        navigate(urlPaths.root());
      }
    );
  }, [caseId, signature, navigate]);

  const api: KYCApi = React.useMemo(() => {
    return {
      getApplicantAndToken: () => getApplicantAndToken({ caseId, signature }),
      createChecks: ({ variant }) => createChecks({ caseId, signature, variant }),
      getChecks: ({ checkId }) => getChecks({ caseId, signature, checkId }),
      getReports: ({ reportIds }) => getReports({ caseId, signature, reportIds }),
      downloadCheck: () => Promise.resolve({ error: undefined, data: {} }),
    };
  }, [caseId, signature]);

  return (
    <KYCTemplate busy={busy}>
      <KYC api={api} setBusy={setBusy} onDone={onDone} />
    </KYCTemplate>
  );
};
