import * as React from "react";

import { AccountNextStep, CompanyType, } from "@src/types";
import { FieldRow, FieldsTable } from "@molecules/FieldsTable";
import { Person, Responsible } from "./Responsible";
import { readableAccountNextSteps } from "@src/utils/accounts";


type CreditCardsAccountProps = {
  readonly persons: ReadonlyArray<Person>;
  readonly account: {
    companyType?: CompanyType;
    accountNumber?: string;
    nextSteps?: AccountNextStep;
    responsibleId?: string;
  };
};

export const CreditCardsAccount: React.FC<CreditCardsAccountProps> = ({
  persons,
  account: { companyType, accountNumber, nextSteps, responsibleId },
}) => {
  return (
    <FieldsTable>
      <FieldRow label="Credit card number" value={accountNumber} />

      <FieldRow label="Next Step" value={readableAccountNextSteps(nextSteps, companyType)} />

      <FieldRow
        label="Responsible"
        value={<Responsible persons={persons} responsibleId={responsibleId} />}
      />
    </FieldsTable>
  );
};
