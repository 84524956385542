import * as React from "react";

import { cleanedErrors } from "@utils/Functions";
import { TextInput } from "@atoms/TextInput";
import { AddressInput } from "@molecules/AddressInput";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { useOnChange } from "@utils/callbacks";

export type Record = {
  address?: string;
  city?: string;
  postcode?: string;
};

export type Errors =
  | undefined
  | {
    address?: string;
    city?: string;
    postcode?: string;
  };

export const errorsOf: (r?: Record) => Errors = (r) => {
  return cleanedErrors({
    address: r?.address ? undefined : "required",
    city: r?.city ? undefined : "required",
    postcode: r?.postcode ? undefined : "required",
  });
};

export const NewPropertyDetailsFields: React.FunctionComponent<{
  record: Record;
  update: any;
  errors: any;
}> = ({ record, update, errors }) => {
  const onChange = useOnChange(update);

  return (
    <FormStack substack spacing={1}>
      <FormField>
        <AddressInput
          name="address"
          value={record.address || ""}
          onChange={onChange}
          error={errors?.address}
        />
      </FormField>

      <FormField halfWidthByItself>
        <TextInput
          name="city"
          value={record.city || ""}
          onChange={onChange}
          error={errors?.city}
          placeholder="City"
        />
      </FormField>

      <FormField halfWidthByItself>
        <TextInput
          name="postcode"
          value={record.postcode || ""}
          onChange={onChange}
          error={errors?.postcode}
          placeholder="Postcode"
        />
      </FormField>
    </FormStack>
  );
};
