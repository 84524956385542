import * as React from "react";

import { Container, Stack } from "@mui/material";

import { PageHeader } from "@organisms/PageHeader";
import { BusyOverlay } from "@atoms/BusyOverlay";
import { ErrorSnackbar } from "@organisms/ErrorSnackbar";

export type SimpleTemplateMobileProps = {
  busy?: boolean;
  errorMessage?: string;
  header?: React.ReactNode | null;
  children: React.ReactNode;
  removeErrorMessage?: () => void;
};

export const SimpleTemplateMobile: React.FC<SimpleTemplateMobileProps> = ({
  busy,
  errorMessage,
  header,
  children,
  removeErrorMessage,
}) => {
  return (
    <BusyOverlay hidden={!busy}>
      <Stack rowGap="60px" sx={{ minHeight: "100vh" }}>
        {header || <PageHeader includeLogo={true} />}

        <Container>{children}</Container>

        <ErrorSnackbar error={errorMessage} onClose={removeErrorMessage} />
      </Stack>
    </BusyOverlay>
  );
};
