export type Property = {
  readonly id: string;
  readonly address: string;
  readonly city: string;
  readonly postcode: string;
  readonly status: PropertyStatus;
  readonly lastOccupiedDate?: string;
  readonly handOverDate?: string;
  readonly ownershipStatus?: OwnershipStatus;
  readonly occupierId?: string;
};

export enum PropertyStatus {
  Vacant = "vacant",
  NewOccupier = "new_occupier",
  ExistingOccupier = "existing_occupier",
  Other = "other",
}

export enum OwnershipStatus {
  Owned = "owned",
  Rented = "rented",
  Unknown = "unknown",
}

export const newPropertyStatusFields = [
  { value: PropertyStatus.Vacant, label: "The property is vacant" },
  {
    value: PropertyStatus.ExistingOccupier,
    label: "Existing occupant(s) continues living at the property",
  },
  {
    value: PropertyStatus.NewOccupier,
    label: "New occupant(s) has or will imminently move into the property",
  },
];

export const formatPropertyStatus = (status: PropertyStatus) => {
  switch (status) {
    case PropertyStatus.Vacant:
      return "The property is vacant";
    case PropertyStatus.NewOccupier:
      return "New occupant(s) has or will imminently move into the property";
    case PropertyStatus.ExistingOccupier:
      return "Existing occupant(s) continues living at the property";
    case PropertyStatus.Other:
      return "Property status is unknown";
    default:
      return "";
  }
};
