import * as React from "react";

import { DescriptionOutlined, PictureAsPdfOutlined } from "@mui/icons-material";

import { Document } from "../../../types/Document";
import { formatDate } from "@utils/Functions";
import { Link } from "../../../components/atoms/Link";
import { Message } from "./message";

export const ChatDocumentLink: React.FC<{ document: Document; message: Message }> = ({
  document: { filename, downloadUrl },
  message,
}) => {
  return (
    <>
      <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
        {fileIcon(filename)}
        {"\u2003"}
        <Link to={downloadUrl} target="_blank">
          {filename}
        </Link>
      </div>
      sent by {message.ownMessage ? "you" : message.senderName} on {formatDate(message.insertedAt)}
    </>
  );
};

const fileIcon = (filename: string) => {
  if (filename.match(/\.pdf/i)) {
    return <PictureAsPdfOutlined />;
  }

  if (filename.match(/.(?:png|gif|jpe?g)/i)) {
    return <PictureAsPdfOutlined />;
  }

  return <DescriptionOutlined />;
};
