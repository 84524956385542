import * as React from "react";

import { Rating, Stack } from "@mui/material";

import { usePageViewEventTracker } from "@utils/analytics";
import { Button } from "../components/atoms/Button";
import { Header } from "../components/atoms/Header";
import { FormField } from "../components/molecules/FormField";
import { FormStack } from "../components/molecules/FormStack";
import { SimpleTemplate } from "@templates/SimpleTemplate";
import { MultilineTextInput } from "../components/atoms/TextInput";
import { saveUserRatings } from "@api/formApi";

type CaseClosedFeedbackPageProps = { caseId: string; signature: string | null };

export const CaseClosedFeedbackPage: React.FC<CaseClosedFeedbackPageProps> = ({
  caseId,
  signature,
}) => {
  usePageViewEventTracker("Case closed feedback");

  const [state, setState] = React.useState({ rated: false, score: 0 });
  const [comment, setComment] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const sendScore = React.useCallback(
    (score?: number) => {
      if (score) {
        setState((s) => ({
          rated: true,
          score: score || s.score,
        }));
      } else {
        setSubmitted(true);
      }

      saveUserRatings({
        caseId,
        signature,
        subject: "case_closed",
        score: (score || state.score) * 2,
        comment,
      }).catch((error) => {
        console.warn(error);
      });
    },
    [state, comment, caseId, signature]
  );

  const submit = React.useCallback(() => {
    sendScore();
  }, [sendScore]);

  return (
    <CaseClosedFeedbackPageView
      busy={false}
      comment={comment}
      setComment={setComment}
      score={state.score}
      sendScore={sendScore}
      rated={state.rated}
      submit={submit}
      submitted={submitted}
    />
  );
};

export type CaseClosedFeedbackPageViewProps = {
  busy?: boolean;
  comment: string;
  submitted: boolean;
  rated: boolean;
  score: number;
  sendScore: (score?: number) => void;
  setComment: (comment: string) => void;
  submit: () => void;
};

export const CaseClosedFeedbackPageView: React.FC<CaseClosedFeedbackPageViewProps> = ({
  busy,
  comment,
  submitted,
  rated,
  score,
  sendScore,
  setComment,
  submit,
}) => {
  return (
    <SimpleTemplate busy={busy} headerType="logo">
      <Header level={1}>Thank you for using Settld</Header>

      <Stack rowGap={4}>
        <div>
          <p>
            We would be very grateful if you would give us your feedback on your experience of
            Settld. This will help us improve the service for others.
          </p>

          <br />

          <p>
            <b>How was the experience using Settld?</b>
          </p>

          <br />

          <div style={{ textAlign: "center" }}>
            <div style={{ display: "inline-block" }}>
              <Rating
                name="hover-feedback"
                value={score}
                size="large"
                precision={0.5}
                onChange={(event: any, score: number | null) => {
                  if (score) {
                    sendScore(score);
                  }
                }}
              />

              <div
                style={{
                  color: "gray",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "-10px",
                  width: "100%",
                }}
              >
                <div>Bad</div>
                <div>Good</div>
              </div>
            </div>
          </div>

          <br />

          {rated && (
            <>
              <p>
                <b>Why did you give this rating?</b>
              </p>

              <MultilineTextInput
                name="comment"
                value={comment || ""}
                onChange={(ev) => setComment(ev.target.value)}
                disabled={submitted}
              />

              <br />
            </>
          )}

          {rated && submitted && <p>Thank you for your feedback!</p>}
        </div>

        <FormStack>
          <FormField twoThirdsWidth>
            <Button variant="primary" size="large" disabled={busy} onClick={submit}>
              Continue
            </Button>
          </FormField>
        </FormStack>
      </Stack>
    </SimpleTemplate>
  );
};
