import * as React from "react";

import {
  AccountCircleOutlined,
  BusinessOutlined,
  CheckCircleOutline,
  DescriptionOutlined,
  FavoriteBorderOutlined,
  SendOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";

export type SectionIconProps = {
  code: string;
};

export const SectionIcon: React.FC<SectionIconProps> = ({ code }) => {
  switch (code) {
    case "notifier":
      return <AccountCircleOutlined fontSize="inherit" />;
    case "deceased":
      return <FavoriteBorderOutlined fontSize="inherit" />;
    case "accounts":
      return <BusinessOutlined fontSize="inherit" />;
    case "documents":
      return <DescriptionOutlined fontSize="inherit" />;
    case "kyc":
      return <VisibilityOutlined fontSize="inherit" />;
    case "submit":
      return <SendOutlined fontSize="inherit" />;
    default:
      return <CheckCircleOutline fontSize="inherit" />;
  }
};
