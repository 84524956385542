import * as React from "react";
import { cleanedErrors, dateStringError, validPhoneNumber, emailErrors } from "@utils/Functions";
import { Deceased } from "@customTypes/Person";
import { DateInput } from "@atoms/DateInput";
import { TextInput } from "@atoms/TextInput";
import { NewTitleInput } from "@atoms/TitleInput";
import { phoneNumberInfoBox, emailAddressInfoBox } from "@atoms/infoBox";
import { PhoneNumberTextInput } from "@atoms/PhoneNumberTextInput";
import { EmailAddressInput } from "@atoms/EmailAddressInput";
import { FormField } from "@molecules/FormField";
import { InfoBoxTrigger } from "@molecules/InfoBoxTrigger";
import { useOnChange, useOnValueChange } from "@utils/callbacks";
import { Mutable, ReadOnlyErrors } from "@src/utils/Types";


export type Record = Mutable<Deceased>
export type Errors = ReadOnlyErrors<Record>

export const errorsOf: (r: Record | undefined, askForpostCode: boolean) => Errors = (
  record = {},
  askForpostCode
) => {
  return cleanedErrors({
    title: record.title ? undefined : "required",
    firstName: record.firstName ? undefined : "required",
    lastName: record.lastName ? undefined : "required",
    dateOfBirth: dateStringError(record.dateOfBirth),
    dateOfDeath: dateStringError(record.dateOfDeath),
    postcode: !askForpostCode || record.postcode ? undefined : "required",
    email: emailErrors(record.email),
    contactNumber:
      !record.contactNumber || validPhoneNumber(record.contactNumber)
        ? undefined
        : "valid phone number required",
  });
};

export const DeceasedDetailsFields: React.FunctionComponent<{
  readonly askForPostcode?: boolean;
  readonly record: Record;
  readonly errors: any;
  readonly update: any;
}> = ({ askForPostcode, record, errors, update }) => {
  const onTitleChange = useOnValueChange(update, "title");
  const onDateOfBirthChange = useOnValueChange(update, "dateOfBirth");
  const onDateOfDeathChange = useOnValueChange(update, "dateOfDeath");
  const onChange = useOnChange(update);

  return (
    <>
      <FormField halfWidthByItself label="Their title">
        <NewTitleInput
          name="title"
          onValueChange={onTitleChange}
          value={record.title || ""}
          error={errors?.title}
        />
      </FormField>

      <FormField halfWidth label="Their first name">
        <TextInput
          name="firstName"
          value={record.firstName || ""}
          onChange={onChange}
          error={errors?.firstName}
        />
      </FormField>

      <FormField halfWidth label="Their last name">
        <TextInput
          name="lastName"
          value={record.lastName || ""}
          onChange={onChange}
          error={errors?.lastName}
        />
      </FormField>

      <FormField halfWidth label="Date of birth">
        <DateInput
          value={record.dateOfBirth || ""}
          onValueChange={onDateOfBirthChange}
          error={errors?.dateOfBirth}
          pastOnly
        />
      </FormField>

      <FormField halfWidth label="Date of death">
        <DateInput
          value={record.dateOfDeath || ""}
          onValueChange={onDateOfDeathChange}
          error={errors?.dateOfDeath}
          pastOnly
        />
      </FormField>

      {askForPostcode && (
        <FormField halfWidthByItself label="Postcode">
          <TextInput
            name="postcode"
            value={record.postcode || ""}
            onChange={onChange}
            error={errors?.postcode}
          />
        </FormField>
      )}

      <FormField
        halfWidth
        label={
          <span>
            Phone <InfoBoxTrigger white width="448px" content={phoneNumberInfoBox()} />
          </span>
        }
      >
        <PhoneNumberTextInput
          name="contactNumber"
          value={record.contactNumber || ""}
          onChange={onChange}
          error={errors?.contactNumber}
        />
      </FormField>

      <FormField
        halfWidth
        label={
          <span>
            Email <InfoBoxTrigger white width="448px" content={emailAddressInfoBox()} />
          </span>
        }
      >
        <EmailAddressInput
          name="email"
          value={record.email || ""}
          onChange={onChange}
          error={errors?.email}
        />
      </FormField>

    </>
  );
};
