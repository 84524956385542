import monitor from "../../utils/monitoring";
import { apiFetch } from "@api/restApi";

export const getApplicantAndToken = async (args: { firstName: string; lastName: string }) => {
  let result;
  try {
    result = await apiFetch({
      path: `/notifier/express/onfido/get_applicant_and_token`,
      search: { first_name: args.firstName, last_name: args.lastName },
    });
  } catch (error) {
    monitor.logError({
      event: "GET /notifier/express/onfido/get_applicant_and_token",
      args,
      error,
    });
    throw error;
  }

  return result;
};

export const createChecks = async (args: { applicantId: string; variant: string }) => {
  const { applicantId, variant } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/express/onfido/checks`,
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: variant ? { applicantId, variant } : { applicantId },
    });
  } catch (error) {
    monitor.logError({
      event: "POST /notifier/express/onfido/checks",
      args,
      error,
    });
    throw error;
  }

  return result;
};

export const getChecks = async (args: { checkId: string }) => {
  const { checkId } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/express/onfido/checks/${checkId}`,
    });
  } catch (error) {
    monitor.logError({
      event: "GET /notifier/express/onfido/checks/{checkId}",
      args,
      error,
    });
    throw error;
  }

  return result;
};

export const getReports = (args: { reportIds: readonly string[] }) => {
  const { reportIds } = args;

  return Promise.all(
    reportIds.map((reportId) => {
      return getReport({ reportId });
    })
  );
};

const getReport = async (args: { reportId: string }) => {
  const { reportId } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/express/onfido/reports/${reportId}`,
    });
  } catch (error) {
    monitor.logError({
      event: "GET /notifier/express/onfido/reports/{reportId}",
      args,
      error,
    });
    throw error;
  }

  return result;
};
