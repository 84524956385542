export const assertUnreachable = (v: never): never => {
  throw new Error(`Invalid type ${v}.`);
};

export const formatDate = (value: string | Date): string => {
  let d: Date;

  if (typeof value === "string") {
    d = new Date(value);
  } else {
    d = value;
  }

  const day = ("0" + d.getDate()).slice(-2);
  const month = ("0" + (d.getMonth() + 1)).slice(-2);
  const year = d.getFullYear();

  return `${day}-${month}-${year}`;
};

export const formatTime = (value: string | Date): string => {
  let d: Date;

  if (typeof value === "string") {
    d = new Date(value);
  } else {
    d = value;
  }

  const hours = ("0" + d.getHours()).slice(-2);
  const minutes = ("0" + d.getMinutes()).slice(-2);

  return `${hours}:${minutes}`;
};

const emailRE = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const validEmailAddress = (email = ""): boolean => emailRE.test(email);
export const validPhoneNumber = (phoneNumber = ""): boolean =>
  !!phoneNumber && !!phoneNumber.match(/\s*[+]?\s*\d\d\d\d\d/);

export const extractDateString = (date: Date): string => {
  return `${date.getFullYear()}-${twoChars(date.getMonth() + 1)}-${twoChars(date.getDate())}`;
};

const twoChars = (n: number) => (n < 10 ? `0${n}` : `${n}`);

export function filterMap<S, D>(array: Array<S>, proc: (e: S) => D | null | undefined): Array<D> {
  const ret: D[] = [];
  for (const e of array) {
    const value = proc(e);
    if (value != null) {
      ret.push(value);
    }
  }

  return ret;
}

export function fillArray<A>(array: A[], proc: (i: number) => A): A[] {
  for (let i = 0; i < array.length; i++) {
    array[i] = proc(i);
  }

  return array;
}

export const moneyFormat = (n: number) => {
  const str = Math.abs(n / 100)
    .toFixed(2)
    .split(".")
    .map((str) => {
      return split3(str).join(",");
    })
    .join(".");
  return `${n < 0 ? "-" : ""}£${str}`;
};

const split3 = (str: string): string[] => {
  const len = str.length;
  if (len <= 3) {
    return [str];
  }

  return split3(str.substring(0, len - 3)).concat(str.substring(len - 3));
};

export const stringReplaceAll = (str: string, regexp: RegExp, something: any) => {
  let previous;
  do {
    previous = str;
    str = str.replace(regexp, something);
  } while (str != previous);

  return str;
};

export const objectFromEntries = (entries: Array<[string, any]>) => {
  const obj = {} as any;
  entries.forEach(([key, value]) => {
    obj[key] = value;
  });

  return obj;
};

export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
}

export const updateArrayElement = (
  array: ReadonlyArray<any>,
  index: number,
  proc: (e: any) => any
): any[] => {
  return [...array.slice(0, index), proc(array[index]), ...array.slice(index + 1)];
};

export const filterProperties = (object: any, props: string[]) => {
  const acc = {} as { [p: string]: any };
  props.forEach((prop) => {
    acc[prop] = object[prop];
  });
  return acc;
};

export const cleanedErrors = (o: any) => {
  if (o && Object.values(o).every((x) => !x)) {
    return undefined;
  }

  return o;
};

export const waitP = (millis: number) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(undefined), millis);
  });
};

export const isNWG = (id: string | undefined) => {
  return (
    id === "1008694e-f7db-4eec-8f07-b9fae3fb50f4" || // nwg prod
    id === "d2969def-1081-4a44-9c47-a5fed894edd3" || // esw prod
    id === "1bb7b20d-f22a-4a96-9aca-94785741a673" || // nwg staging
    id === "11c71ab0-69f5-44a1-a937-1a0ddf9ba1ea" // esw staging
  );
};

export const filenameFromFileAndPrefix = (file: File, name?: string) => {
  if (!name) return file.name;
  if (/\./.test(name)) return name;

  const extension = file.name.split(".").slice(-1).join(".");
  return extension ? `${name}.${extension}` : name;
};

export const isValidDateString = (str?: string) => {
  return str && /\d\d\d\d-\d\d-\d\d/.test(str);
};

export const dateStringError = (str?: string) => {
  return str ? (isValidDateString(str) ? undefined : "invalid format") : "required";
};

export const insideIframe = window.location !== window.parent.location;

export const toCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(toCamelCase);
  }

  if (typeof obj === "object" && obj !== null) {
    return objectFromEntries(
      Object.entries(obj).map(([key, value]) => [camelCaseString(key), toCamelCase(value)])
    );
  }

  return obj;
};

export const camelCaseString = (str: string) => {
  return stringReplaceAll(str, /_([a-z])/, (_: string, c: string) => c.toUpperCase());
};

export const toSnakeCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(toSnakeCase);
  }

  if (typeof obj === "object" && obj !== null) {
    return objectFromEntries(
      Object.entries(obj).map(([key, value]) => [snakeCaseString(key), toSnakeCase(value)])
    );
  }

  return obj;
};

export const snakeCaseString = (str: string) => {
  return stringReplaceAll(str, /([A-Z])/, (_: string, c: string) => "_" + c.toLowerCase());
};

export function emailErrors(email: string | undefined) {
  // email: record.email || validEmailAddress(record.email) ? undefined : "valid email required",
  if (!email) return undefined;
  if (["n/a", "na"].includes(email)) return undefined;
  if (validEmailAddress(email)) return undefined;
  return "valid email required";
}