import { Typography, Box } from "@mui/material";
import { RadioGroupInput } from "../atoms/RadioGroupInput";
import { FormField } from "./FormField";
import { InfoBoxTrigger } from "./InfoBoxTrigger";
import rulesOfIntestacy from "@assets/images/rulesOfIntestacy.png";

interface NokQuestionProps {
  askForNok: boolean | undefined;
  record: any;
  onNokChange: (a: boolean) => void;
  errors: any;
}
export const NokQuestion: React.FC<NokQuestionProps> = ({
  askForNok,
  record,
  onNokChange,
  errors,
}) => {
  if (!askForNok) return null;
  return (
    <FormField
      label={
        <Typography variant="body1">
          Are you the closest next of kin? &nbsp;
          <InfoBoxTrigger
            white
            width="448px"
            content={
              <Box sx={{ alignSelf: "center" }}>
                <img
                  src={rulesOfIntestacy}
                  alt="rulesOfIntestacy"
                  width="400"
                  height="auto"
                  style={{ maxWidth: "100%" }}
                />
              </Box>
            }
          />
          &nbsp; Organizations will need to see permission <br />
          from the Next of Kin.
        </Typography>
      }
    >
      <br />

      <RadioGroupInput
        error={errors.nok}
        value={record.nok?.toString()}
        fields={[
          { value: "true", label: <>Yes, I am the closest next of kin</> },
          {
            value: "false",
            label: <>No, I am not the closest next of kin</>,
          },
        ]}
        onValueChange={(value) => onNokChange(value === "true")}
      />
    </FormField>
  );
};
