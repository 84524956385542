import * as React from "react";

import { Alert, Box, Snackbar, Stack, Typography } from "@mui/material";

import { colors } from "../../styles/constants";
import { Header, Subheader } from "../../components/atoms/Header";
import { Accordion } from "../../components/molecules/Accordion";
import { SimpleUploadField } from "../../components/organisms/SimpleUploadField";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { RequiredHeader } from "@molecules/RequiredHeader";
import { UploadRecord } from "@src/types/Document";

const currentSection = "documents";

export type DocumentsRecord = {
  readonly proofOfAddress: UploadRecord[];
  readonly proofOfDeath: UploadRecord[];
  readonly willAndProbate: UploadRecord[];
  readonly grantOfRepresentation: UploadRecord[];
};


export type DocumentsViewProps = {
  readonly serviceProvider: { customForm?: string };
  askForProofOfAddress?: boolean;
  askForWillAndProbate?: boolean;
  askForGrantOfRepresentation?: boolean;
  busy: boolean;
  record: DocumentsRecord;
  errors: any;
  hasChanges: boolean;
  remoteError?: string;
  setError: any;
  updateProofOfAddress: any;
  removeProofOfAddress: any;
  updateProofOfDeath: any;
  removeProofOfDeath: any;
  updateWillAndProbate: any;
  removeWillAndProbate: any;
  updateGrantOfRepresentation: any;
  removeGrantOfRepresentation: any;
  onNext: any;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  removeRemoteError: () => void;
};

export const DocumentsView: React.FC<DocumentsViewProps> = ({
  serviceProvider,
  askForProofOfAddress,
  askForWillAndProbate,
  askForGrantOfRepresentation,
  busy,
  record,
  errors,
  hasChanges,
  remoteError,
  setError,
  updateProofOfAddress,
  removeProofOfAddress,
  updateWillAndProbate,
  removeWillAndProbate,
  updateGrantOfRepresentation,
  removeGrantOfRepresentation,
  updateProofOfDeath,
  removeProofOfDeath,
  onNext,
  onSectionClick,
  updateTemplate,
  removeRemoteError,
}) => {
  React.useEffect(() => {
    updateTemplate({
      busy,
      currentSection,
      onNext,
      saveLabel: null,
      onSectionClick,
    });
  }, [busy, onNext, onSectionClick, updateTemplate, hasChanges]);

  return (
    <Stack rowGap={4}>
      <Stack rowGap={2}>
        <Header level={1} color={colors.accentTeal}>
          Your documents
        </Header>

        <Box>
          <Subheader faded>Top tip</Subheader>

          <Typography variant="body1">
            When uploading photographs or scans of documents, please make sure that the image
            contains <b>all four corners of the page</b> and that{" "}
            <b>all text is clear and legible</b>.
          </Typography>
        </Box>
      </Stack>
      <Stack rowGap={2}>
        <Accordion
          teal
          expanded={!(record.proofOfDeath.length === 0)}
          title={
            <RequiredHeader
              showError={!!errors.proofOfDeath || record.proofOfDeath.length === 0}
              title="Death certificate"
            />
          }
        >
          <Stack rowGap={1}>
            <SimpleUploadField
              busy={false}
              entries={record.proofOfDeath}
              upload={updateProofOfDeath}
              remove={removeProofOfDeath}
              error={errors.proofOfDeath}
              setError={(error: any) => setError("proofOfDeath", error)}
            />

            <Typography variant="body1">
              Please upload the death certificate. If a death certificate is not available, please
              upload the <b>Coroner’s Interim Certificate</b>.
            </Typography>
          </Stack>
        </Accordion>

        {askForProofOfAddress && (
          <Accordion
            expanded={!(record.proofOfAddress.length === 0)}
            teal
            title={
              <RequiredHeader
                showError={!!errors.proofOfAddress || record.proofOfAddress.length === 0}
                title="Proof of your address"
              />
            }
          >
            <Stack rowGap={1}>
              <SimpleUploadField
                busy={false}
                entries={record.proofOfAddress}
                upload={updateProofOfAddress}
                remove={removeProofOfAddress}
                error={errors.proofOfAddress}
                setError={(error: any) => setError("proofOfAddress", error)}
              />

              <Typography variant="body1">
                Please upload a <b>Council Tax Bill/Demand Letter</b> (issued within the last 12
                months) <b>OR</b> a <b>Utility Bill</b> (issued within the last 3 months.)
              </Typography>

              <div>
                <Typography variant="body1">
                  If you don’t have either of these documents, please upload one of the following:
                </Typography>

                <ul style={{ margin: 0 }}>
                  <li>
                    A Bank, Building Society or Credit Card Statement (issued within the last 3
                    months)
                  </li>
                  <li>A Tenancy Agreement (issued within the last 12 months)</li>
                </ul>
              </div>
            </Stack>
          </Accordion>
        )}

        {askForWillAndProbate && (
          <Accordion
            teal
            expanded={!(record.willAndProbate.length === 0)}
            title={
              <RequiredHeader
                showError={!!errors.willAndProbate || record.willAndProbate.length === 0}
                title={
                  serviceProvider.customForm === "monzo"
                    ? "Grant of probate, Letter of Administration or Will"
                    : "The Will"
                }
              />
            }
          >
            <Stack rowGap={1}>
              <SimpleUploadField
                busy={false}
                entries={record.willAndProbate}
                upload={updateWillAndProbate}
                remove={removeWillAndProbate}
                error={errors.willAndProbate}
                setError={(error: any) => setError("willAndProbate", error)}
              />

              <Stack rowGap={2}>
                <Typography variant="body1">
                  If the person who died wrote a will, please upload images, scans or copies of each
                  <b> of the pages below </b>. <b>Please do NOT </b>remove the staples in your
                  original Will. Doing so{" "}
                  <b>
                    {" "}
                    could cause serious issues and raise questions as to the validity of your Will.
                  </b>
                </Typography>

                <ol style={{ margin: 0 }}>
                  <li>the title page</li>
                  <li>the page with the Executor details</li>
                  <li>the signature page</li>
                  {/* <li>
                    any official changes to the will, known as a <b>Codicil</b>
                  </li> */}
                </ol>

                <Typography variant="body1">
                  Please note that financial institutions, such as organisations handling
                  investments, will require a copy of the will.
                </Typography>
              </Stack>
            </Stack>
          </Accordion>
        )}

        {askForGrantOfRepresentation && (
          <Accordion
            expanded={!(record.grantOfRepresentation.length === 0)}
            teal
            title={<RequiredHeader showError={false} title="Grant of Representation (optional)" />}
          >
            <Stack rowGap={1}>
              <SimpleUploadField
                busy={false}
                entries={record.grantOfRepresentation}
                error={errors.grantOfRepresentation}
                upload={updateGrantOfRepresentation}
                remove={removeGrantOfRepresentation}
                setError={(error: any) => setError("grantOfRepresentation", error)}
              />

              <Typography variant="body1">
                If you have received Grant of Representation (<b>Grant of Probate</b> or{" "}
                <b>Letters of Administration</b>), please upload a photograph or scan of this
                document.
              </Typography>
            </Stack>
          </Accordion>
        )}
      </Stack>

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteError}
        autoHideDuration={6000}
        onClose={removeRemoteError}
      >
        <Alert elevation={6} variant="filled" severity="error" onClose={removeRemoteError}>
          {remoteError}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
