import * as React from "react";

import { colors } from "@styles/constants";
import { SideImageTemplate } from "../SideImageTemplate";

export type OnboardingTemplateProps = {
  errorMessage?: string;
  busy?: boolean;
  image?: React.ReactNode | null;
  children: React.ReactNode;
  removeErrorMessage?: () => void;
};

export const OnboardingTemplate: React.FC<OnboardingTemplateProps> = ({
  busy,
  errorMessage,
  image,
  children,
  removeErrorMessage,
}) => {
  return (
    <SideImageTemplate
      sx={{ backgroundColor: colors.offWhite }}
      headerType={"signout"}
      busy={busy}
      errorMessage={errorMessage}
      image={image}
      removeErrorMessage={removeErrorMessage}
    >
      {children}
    </SideImageTemplate>
  );
};
