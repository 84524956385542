import * as React from "react";

import { makeStyles } from "@mui/styles";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";

export type GreyPaneProps = {
  title: React.ReactNode;
  right?: React.ReactNode;
  joinedHeaderAndBody?: boolean;
  children: React.ReactNode;
};

export const GreyPane: React.FC<GreyPaneProps> = ({
  title,
  right = null,
  joinedHeaderAndBody = false,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.backdrop}>
      <Header>
        <Stack direction="row" justifyContent="space-between">
          <Box>{title}</Box>
          <Box>{right}</Box>
        </Stack>
      </Header>

      <div className={joinedHeaderAndBody ? classes.joinedBody : classes.body}>{children}</div>
    </div>
  );
};

export const Header: React.FC = ({ children }) => {
  const classes = useStyles();
  return <h6 className={classes.header}>{children}</h6>;
};

const useStyles = makeStyles({
  backdrop: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundColor: "#ecf2f3",
    borderRadius: "4px",
    padding: "20px",
  },

  header: {
    margin: "0",
    paddingBottom: "15px",
    color: "#555555",
    fontSize: "120%",
    fontWeight: "normal",
    borderBottom: "1px solid white",
  },

  body: {
    flexGrow: 1,
    paddingTop: "20px",
  },

  joinedBody: {
    flexGrow: 1,
  },
});
