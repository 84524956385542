const urlPaths = {
  root: () => "/", 
  signin: () => "/sign-in",
  // personalDetailsSignup: () => "/old-sign-up",
  signup: () => "/sign-up",
  forgotPassword: () => "/forgot-password",
  // requirements: () => "/onboarding/requirements",
  onBoardingAdditionalServices: () => "/onboarding/additional-services",
  onBoardingInfo: () => "/onboarding/info-page",
  permission: () => "/onboarding/permission",
  // additionalServices: () => "/additional-services",
  status: () => "/status",
  admin: () => "/admin",
  formRating: () => "/form-rating",
  index: () => "/index.html",
  kyc: () => "/kyc",
  kycExpress: () => "/express-kyc",
  form: (...subpages: string[]) => ["", "form", ...subpages].join("/"),
  expressForm: (slug: string, subpage: string) => `/express/${slug}/${subpage}`,
  resetPassword: (token?: string) => `/reset-password/${token || ":token"}`,
  statusWithDocumentRequest: (accountId?: string) => `/status/${accountId || ":accountId"}`,
};

export default urlPaths;
