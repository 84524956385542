import * as React from "react";
import { useNavigate } from "react-router-dom";

import urlPaths from "../../urlPaths";
import { PageHeader } from "./PageHeader";

export type PageHeaderWithSignupProps = {
  includeLogo?: boolean;
};

export const PageHeaderWithSignup: React.FC<PageHeaderWithSignupProps> = ({ includeLogo }) => {
  const navigate = useNavigate();

  const onSignup = React.useCallback(() => {
    navigate(urlPaths.signup());
  }, [navigate]);

  return <PageHeaderWithSignupView includeLogo={includeLogo} onClick={onSignup} />;
};

export type PageHeaderWithSignupViewProps = {
  includeLogo?: boolean;

  onClick: () => void;
};

export const PageHeaderWithSignupView: React.FC<PageHeaderWithSignupViewProps> = ({
  includeLogo,
  onClick,
}) => {
  return (
    <PageHeader
      includeLogo={includeLogo}
      prompt={"Don't have an account?"}
      actionLabel={"Sign up"}
      onClick={onClick}
    />
  );
};
