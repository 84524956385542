
import { prettyTitle } from "@src/types";
import { formatDate } from "@utils/Functions";
import { FieldRow } from "@molecules/FieldsTable";

export const DeceasedPersonalDetails = ({ deceased }: { deceased: any }) => {
  return (
    <>
      <FieldRow
        label="Name"
        value={`${prettyTitle(deceased.title)} ${deceased.firstName} ${deceased.lastName}`}
      />

      <FieldRow
        label="Date of birth"
        value={deceased.dateOfBirth ? formatDate(deceased.dateOfBirth) : ""}
      />

      <FieldRow
        label="Date of death"
        value={deceased.dateOfDeath ? formatDate(deceased.dateOfDeath) : ""}
      />
    </>
  );
};
