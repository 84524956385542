import * as React from "react";

import logo from "../../assets/logo-dark.png";

export type LogoProps = {
  url?: string;
  onClick?: () => void;
};

export const Logo: React.FC<LogoProps> = ({ url, onClick }) => {
  if (url) {
    return <img onClick={onClick} src={logo} alt="Logo" width={90} height={26} />;
  }

  if (url) {
    return (
      <a href={url}>
        <img src={logo} alt="Logo" width={90} height={26} />;
      </a>
    );
  }

  return <img src={logo} alt="Logo" width={90} height={26} />;
};
