import * as React from "react";

import {
  AddOutlined,
  CreditCardOutlined,
  CurrencyPoundOutlined,
  GroupsOutlined,
  HomeOutlined,
  LightbulbOutlined,
  PhoneOutlined,
  PolicyOutlined,
  SavingsOutlined,
  ShoppingCartOutlined,
  ShowChartOutlined,
  ShowerOutlined,
  SignalCellularAltOutlined,
  SubscriptionsOutlined,
} from "@mui/icons-material";
import { CompanyType } from "@customTypes/index";

export type SectorIconProps = {
  readonly color?:
  | "inherit"
  | "disabled"
  | "action"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";
  readonly fontSize?: "small" | "medium" | "large";
  readonly sector: CompanyType;
};

export const SectorIcon: React.FC<SectorIconProps> = ({ color, fontSize, sector }) => {
  switch (sector) {
    case CompanyType.Banking:
      return <CurrencyPoundOutlined color={color} fontSize={fontSize} />;
    case CompanyType.Broadband:
      return <PhoneOutlined color={color} fontSize={fontSize} />;
    case CompanyType.BuildingSociety:
      return <HomeOutlined color={color} fontSize={fontSize} />;
    case CompanyType.Charities:
      return <SubscriptionsOutlined color={color} fontSize={fontSize} />;
    case CompanyType.CreditCards:
      return <CreditCardOutlined color={color} fontSize={fontSize} />;
    case CompanyType.Ecommerce:
      return <ShoppingCartOutlined color={color} fontSize={fontSize} />;
    case CompanyType.Email:
      return <GroupsOutlined color={color} fontSize={fontSize} />;
    case CompanyType.Energy:
      return <LightbulbOutlined color={color} fontSize={fontSize} />;
    case CompanyType.Insurance:
      return <PolicyOutlined color={color} fontSize={fontSize} />;
    case CompanyType.Investments:
      return <ShowChartOutlined color={color} fontSize={fontSize} />;
    // case CompanyType.MortgageOrLoans:
    //   return <HomeOutlined color={color} fontSize={fontSize} />;
    case CompanyType.Pension:
      return <SavingsOutlined color={color} fontSize={fontSize} />;
    case CompanyType.SocialMedia:
      return <GroupsOutlined color={color} fontSize={fontSize} />;
    case CompanyType.Subscriptions:
      return <SubscriptionsOutlined color={color} fontSize={fontSize} />;
    case CompanyType.Satellite:
      return <SignalCellularAltOutlined color={color} fontSize={fontSize} />;
    case CompanyType.Telecoms:
      return <PhoneOutlined color={color} fontSize={fontSize} />;
    case CompanyType.TV:
      return <SignalCellularAltOutlined color={color} fontSize={fontSize} />;
    case CompanyType.Magazines:
      return <SubscriptionsOutlined color={color} fontSize={fontSize} />;
    case CompanyType.Water:
      return <ShowerOutlined color={color} fontSize={fontSize} />;
    case CompanyType.Other:
    default:
      return <AddOutlined color={color} fontSize={fontSize} />;
  }
};
