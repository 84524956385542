import * as React from "react";

import { Box, Stack, Typography } from "@mui/material";

import { colors } from "../../styles/constants";
import {
  NotifierDetails as NotifierDetailsType,
  NotifierRoles,
  Titles,
} from "@customTypes/index";
import { BooleanInput } from "../../components/atoms/BooleanInput";
import { DateInput } from "../../components/atoms/DateInput";
import { EmailAddressInput } from "../../components/atoms/EmailAddressInput";
import { Header } from "../../components/atoms/Header";
import { PhoneNumberTextInput } from "../../components/atoms/PhoneNumberTextInput";
import { SelectInput } from "../../components/atoms/SelectInput";
import { TextInput } from "../../components/atoms/TextInput";
import { NewTitleInput } from "../../components/atoms/TitleInput";
import { NewAddressInput } from "../../components/molecules/AddressInput";
import { FormField } from "../../components/molecules/FormField";
import { FormStack } from "../../components/molecules/FormStack";
import { InfoBoxTrigger } from "../../components/molecules/InfoBoxTrigger";
import { RelationToDeceased } from "../../components/molecules/RelationToDeceased";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { IDVerificationDialog } from "./IDVerificationDialog";
import { roleOptions } from "@customTypes/NotifierFormData";
import { VulnerableInfoBox } from "@src/components/molecules/VulnerableInfoBox";
import { RepresentativeInfoBox } from "@src/components/atoms/RepresentativeInfoBox";
import { NokQuestion } from "@src/components/molecules/NokQuestion";
import { WillExistsQuestion } from "@src/components/molecules/WillExistsQuestion";
import { ServiceProvider } from "@src/types/ServiceProvider";

const currentSection = "notifier";

export type NotifierRecord = NotifierDetailsType;

export type NotifierRecordErrors = Partial<Record<keyof NotifierDetailsType, string>>;

export type NotifierDetailsViewProps = {
  titleHidden: boolean;
  serviceProvider: ServiceProvider;
  record: NotifierRecord;
  errors: NotifierRecordErrors;
  onRoleChange: (value: string) => void;
  onDateOfBirthChange: (value?: string) => void;
  onNext: () => void;
  onFieldChange: any;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  onIsVulnerableChange: (value: boolean) => void;
  showIDVerificationDialog: boolean;
  hideIDVerificationDialog: () => void;
  onNokChange: any;
  caseData: any;
  setCaseData: (v: any) => void;
};

export const NotifierDetailsView: React.FC<NotifierDetailsViewProps> = ({
  titleHidden,
  serviceProvider,
  errors,
  record,
  onNokChange,
  onRoleChange,
  onDateOfBirthChange,
  onNext,
  onFieldChange,
  onSectionClick,
  onIsVulnerableChange,
  updateTemplate,
  showIDVerificationDialog,
  hideIDVerificationDialog,
  caseData,
  setCaseData,
}) => {
  React.useEffect(() => {
    updateTemplate({
      busy: false,
      currentSection,
      onNext,
      saveLabel: null,
      onSectionClick,
    });
  }, [onNext, onSectionClick, updateTemplate]);
  const askForWill = serviceProvider.intestacyFlow && record.role === "delegated_notifier";
  let askForNok: boolean = false;

  if (serviceProvider.intestacyFlow && record.role === "administrator") {
    askForNok = true;
  }

  if (
    serviceProvider.intestacyFlow &&
    record.role === "delegated_notifier" &&
    !caseData.record.willExists
  ) {
    askForNok = true;
  }

  const isHideIntestacyInfoBox = [NotifierRoles.Administrator, NotifierRoles.DelegatedNotifier].includes(record.role as NotifierRoles);
  return (
    <>
      <IDVerificationDialog
        visible={showIDVerificationDialog}
        hideDialog={hideIDVerificationDialog}
      />

      <Stack rowGap={4}>
        <Header level={1} color={colors.accentTeal}>
          Now, let's find out more about you
        </Header>

        <FormStack>
          <FormField
            halfWidthByItself
            label={
              <span>
                I am a personal representative and I am...{" "}
                <InfoBoxTrigger white width="448px" content={<RepresentativeInfoBox />} />
              </span>
            }
          >
            <SelectInput
              name="role"
              onValueChange={onRoleChange}
              value={record.role || ""}
              options={roleOptions}
              error={errors?.role}
            />
          </FormField>
          <WillExistsQuestion
            caseData={caseData}
            setCaseData={setCaseData}
            serviceProvider={serviceProvider}
            askForWill={askForWill}
          />
          <NokQuestion
            errors={errors}
            record={record}
            onNokChange={onNokChange}
            askForNok={askForNok}
          />
          {(askForNok || askForWill) && <FormField  >
            <Typography>Please provide your details below</Typography>
          </FormField>}

          {!titleHidden && (
            <FormField halfWidthByItself label="Title">
              <NewTitleInput
                name="title"
                onValueChange={(value) => onFieldChange({ target: { name: "title", value } })}
                value={record.title || ""}
                error={errors?.title}
              />
            </FormField>
          )}

          <FormField halfWidth label="First name">
            <TextInput
              name="firstName"
              value={record.firstName || ""}
              error={errors?.firstName}
              onChange={onFieldChange}
            />
          </FormField>

          <FormField halfWidth label="Last name">
            <TextInput
              name="lastName"
              value={record.lastName || ""}
              error={errors?.lastName}
              onChange={onFieldChange}
            />
          </FormField>

          {record.role !== "solicitor" && (
            <FormField halfWidthByItself label="Date of birth">
              <DateInput
                value={record.dateOfBirth || ""}
                onValueChange={onDateOfBirthChange}
                error={errors?.dateOfBirth}
                pastOnly
              />
            </FormField>
          )}

          <FormField label="Address">
            <NewAddressInput
              name="address"
              value={record.address || ""}
              onChange={onFieldChange}
              error={errors?.address}
            />
          </FormField>

          <FormField halfWidth label="City or Town">
            <TextInput
              name="city"
              value={record.city || ""}
              onChange={onFieldChange}
              error={errors?.city}
            />
          </FormField>

          <FormField halfWidth label="Postcode">
            <TextInput
              name="postcode"
              value={record.postcode || ""}
              onChange={onFieldChange}
              error={errors?.postcode}
            />
          </FormField>

          <FormField halfWidth label="Telephone number">
            <PhoneNumberTextInput
              name="contactNumber"
              value={record.contactNumber || ""}
              onChange={onFieldChange}
              error={errors?.contactNumber}
            />
          </FormField>

          <FormField halfWidth label="Email address">
            <EmailAddressInput
              name="email"
              value={record.email || ""}
              onChange={onFieldChange}
              error={errors?.email}
            // autoFocus={updatingNotifierEmailAddress}
            />
          </FormField>

          {record.role !== "solicitor" && (
            <FormStack substack spacing={0}>
              <FormField>
                <BooleanInput
                  label={
                    <span>
                      I feel vulnerable (temporarily or long-term) and would appreciate support{" "}
                      <InfoBoxTrigger white width="448px" content={VulnerableInfoBox()} />
                    </span>
                  }
                  value={record.isVulnerable}
                  onValueChange={onIsVulnerableChange}
                />
              </FormField>

              {serviceProvider.customForm === "portsmouth-water" && record.isVulnerable && (
                <FormField>
                  To notify Portsmouth Water of vulnerability,{" "}
                  <a
                    href="https://www.portsmouthwater.co.uk/customer-services/priority-services/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    click here
                  </a>{" "}
                  to sign up to their priority services register.
                </FormField>
              )}
            </FormStack>
          )}

          {record.role !== "solicitor" && (
            <FormField halfWidthByItself label={<span>The person who died was my... &nbsp;</span>}>
              <br></br>
              <RelationToDeceased
                name="relationshipToDeceased"
                value={record.relationshipToDeceased || ""}
                otherValue={record.relationshipToDeceasedOther || ""}
                error={errors.relationshipToDeceased || errors.relationshipToDeceasedOther}
                onFieldChange={onFieldChange}
              />
            </FormField>
          )}
        </FormStack>
      </Stack>
    </>
  );
};
