import * as React from "react";

import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from "@mui/material";

import { colors } from "../../styles/constants";
import { Header, Subheader } from "../atoms/Header";

export type AccordionProps = {
  info?: boolean;
  teal?: boolean;
  title?: React.ReactNode;
  expanded?: boolean;
  children: React.ReactNode;
};

export const Accordion: React.FC<AccordionProps> = ({
  info,
  teal,
  title,
  expanded = false,
  children,
}) => {
  const [active, setActive] = React.useState(expanded);

  const accordionToggle = React.useCallback(
    (ev) => {
      ev.stopPropagation();
      setActive((active) => !active);
    },
    [setActive]
  );

  if (info) {
    return (
      <InfoStyledAccordion square disableGutters expanded={active} onChange={accordionToggle}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          {typeof title === "string" ? <Subheader>{title}</Subheader> : title}
        </AccordionSummary>

        <AccordionDetails>{children}</AccordionDetails>
      </InfoStyledAccordion>
    );
  }

  if (teal) {
    return (
      <TealStyledAccordion disableGutters expanded={active} onChange={accordionToggle}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          {typeof title === "string" ? (
            <Header level={3} color={colors.accentTeal}>
              {title}
            </Header>
          ) : (
            title
          )}
        </AccordionSummary>

        <AccordionDetails>{children}</AccordionDetails>
      </TealStyledAccordion>
    );
  }

  return (
    <StyledAccordion disableGutters expanded={active} onChange={accordionToggle}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Header level={3} color={colors.accentTeal}>
          {title}
        </Header>
      </AccordionSummary>

      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
};

const StyledAccordion = styled(MuiAccordion)(({ theme }) => ({
  "&": {
    border: `1px solid ${colors.lighterTeal}`,
    borderRadius: theme.spacing(0.5),
    // boxShadow:
    //   "0px 2px 1px -1px rgb(256 0 0 / 20%), 0px 1px 1px 0px rgb(256 0 0 / 14%), 0px 1px 3px 0px rgb(256 0 0 / 12%)",
  },
  "&:before": {
    display: "none",
  },
}));

const InfoStyledAccordion = styled(MuiAccordion)(({ theme }) => ({
  "&": {
    // border: `1px solid ${colors.lighterTeal}`,
    // backgroundColor: colors.lighterTeal,
    borderRadius: theme.spacing(2),
    padding: "5px",
    // boxShadow:
    //   "0px 2px 1px -1px rgb(256 0 0 / 20%), 0px 1px 1px 0px rgb(256 0 0 / 14%), 0px 1px 3px 0px rgb(256 0 0 / 12%)",
  },
  "&:before": {
    display: "none",
  },
}));

const TealStyledAccordion = styled(MuiAccordion)(({ theme }) => ({
  "&": {
    border: `1px solid ${colors.lighterTeal}`,
    backgroundColor: colors.lighterTeal,
    borderRadius: theme.spacing(0.5),
    // boxShadow:
    //   "0px 2px 1px -1px rgb(256 0 0 / 20%), 0px 1px 1px 0px rgb(256 0 0 / 14%), 0px 1px 3px 0px rgb(256 0 0 / 12%)",
  },
  "&:before": {
    display: "none",
  },
}));
