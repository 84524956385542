import { colors } from "@styles/constants";

export const onfidoConfig = ({
  token,
  onComplete,
  onError,
  onModalRequestClose,
  onUserExit,
}: any) => {
  return {
    language: {
      locale: "en_US",
      phrases: {
        doc_capture: {
          prompt: {
            title_license: "What type of licence do you have?",
          },
        },
        doc_select: {
          button_license: "Driver’s licence",
        },
        doc_submit: {
          title_license_back: "Submit licence (back)",
          title_license_front: "Submit licence (front)",
        },
        mobilePhrases: {
          photo_upload: {
            body_license_back: "Take a photo of the back of your licence",
            body_license_front: "Take a photo of the front of your licence",
          },
        },
        photo_upload: {
          body_license_back: "Upload back of licence from your computer",
          body_license_front: "Upload front of licence from your computer",
        },
      },
    },
    // isModalOpen: true,
    containerId: "onfido-mount",
    token: token,
    useModal: false,
    // userDetails: {
    //   smsNumber: '+447500123456'
    // },
    steps: [
      {
        type: "welcome",
        options: {
          title: "Identity verification",
          descriptions: [
            "Settld has partnered with market-leading biometric identity verification provider, Onfido, to protect you and the companies we work with.",
            "Please click ‘Continue’ and follow the instructions to verify your identity.",
            "",
          ],
          nextButton: "Continue",
        },
      },
      {
        type: "document",
        options: {
          documentTypes: {
            driving_licence: {
              country: "GBR",
            },
            national_identity_card: {},
            passport: {},
            residence_permit: {
              country: "GBR",
            },
          },
        },
      },
      // {
      //   type: "face",
      //   options: {
      //     // requestedVariant: 'standard'
      //     // requestedVariant: 'video',
      //     uploadFallback: true,
      //   },
      // },
      // {
      //   type: "complete",
      //   options: {
      //     "message": "Thank you",
      //     "submessage": "More information",
      //   }
      // }
    ],
    customUI: {
      fontFamily: "DM Sans",
      fontFamilySubtitle: "DM Sans",
      fontFamilyBody: "DM Sans",
      // fontSizeTitle:
      // fontSizeSubtitle:
      // fontSizeBody:
      // fontWeightTitle:
      // fontWeightSubtitle:
      // fontWeightBody:

      colorContentTitle: colors.titleText,
      colorContentSubtitle: colors.titleText,
      colorContentBody: colors.bodyText,

      colorContentButtonPrimaryText: colors.bodyText,
      colorBackgroundButtonPrimary: colors.saveButtonBackground,
      colorBackgroundButtonPrimaryHover: colors.saveButtonBackground,
      colorBackgroundButtonPrimaryActive: colors.saveButtonBackground,
      colorBorderButtonPrimary: colors.saveButtonBackground,

      // colorContentButtonSecondaryText: colors.bodyText,
      colorBackgroundButtonSecondary: colors.continueButtonBackground,
      colorBackgroundButtonSecondaryHover: colors.continueButtonBackground,
      colorBackgroundButtonSecondaryActive: colors.continueButtonBackground,
      colorBorderButtonSecondary: colors.continueButtonBackground,

      // colorContentDocTypeButton:
      // colorBackgroundDocTypeButton:
      // colorBorderDocTypeButton:
      colorBorderDocTypeButtonHover: colors.saveButtonBackground,
      // colorBorderDocTypeButtonActive:

      colorContentLinkTextHover: colors.bodyText,
      colorBorderLinkUnderline: colors.saveButtonBackground,
      colorBackgroundLinkHover: colors.saveButtonBackground,
      colorBackgroundLinkActive: colors.saveButtonBackground,
    },
    onComplete,
    onError,
    onModalRequestClose,
    onUserExit,
  };
};
