import React from "react";

import { TextInput } from "./TextInput";

export type EmailAddressInputProps = {
  readonly name: string;
  readonly value: string;
  readonly placeholder?: string;
  readonly disabled?: boolean;
  readonly error?: string | null;
  readonly onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
};

export const EmailAddressInput: React.FC<EmailAddressInputProps> = ({
  name,
  value,
  placeholder,
  disabled,
  error,
  onChange: onChangeProp,
}) => {
  const onChange = React.useCallback(
    (ev) => {
      if (onChangeProp) {
        const { value } = ev.target;
        const v = value && typeof value === "string" ? value.toLowerCase() : "";

        const target = { name: name, value: v };
        onChangeProp({ ...ev, target: target, currentTarget: target });
      }
    },
    [onChangeProp, name]
  );

  return (
    <TextInput
      name={name}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      onChange={onChange}
    />
  );
};
