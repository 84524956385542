import * as React from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AppBar, Box, Drawer, List, Stack, Toolbar, Typography } from "@mui/material";

import progressCircle03 from "../../assets/icons/progress-circle/0-3.svg";
import progressCircle0 from "../../assets/icons/progress-circle/0.svg";
import progressCircle13 from "../../assets/icons/progress-circle/1-3.svg";
import progressCircle1 from "../../assets/icons/progress-circle/1.svg";
import progressCircle23 from "../../assets/icons/progress-circle/2-3.svg";
import progressCircle2 from "../../assets/icons/progress-circle/2.svg";
import progressCircle33 from "../../assets/icons/progress-circle/3-3.svg";
import progressCircle3 from "../../assets/icons/progress-circle/3.svg";
import progressCircle4 from "../../assets/icons/progress-circle/4.svg";
import progressCircle5 from "../../assets/icons/progress-circle/5.svg";
import progressCircle6 from "../../assets/icons/progress-circle/6.svg";
import progressCircleCheck from "../../assets/icons/progress-circle/check.svg";
import { colors } from "../../styles/constants";
import { MenuItem } from "./MenuItem";
import { FormMenuEntry } from "@customTypes/ui";

const progressCircleIcons = [
  progressCircle0,
  progressCircle1,
  progressCircle2,
  progressCircle3,
  progressCircle4,
  progressCircle5,
  progressCircle6,
  progressCircleCheck,
];

const stepNumberFromSection = (entries: FormMenuEntry[], currentKey: string | undefined) => {
  if (!currentKey) {
    return undefined;
  }

  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    if (entry.key === currentKey) {
      return i + 1;
    }
  }
  return undefined;
};

const progressCircleIcons3 = [
  progressCircle03,
  progressCircle13,
  progressCircle23,
  progressCircle33,
  progressCircleCheck,
];


export type FormMenuMobileProps = {
  currentKey?: string;
  entries: Array<FormMenuEntry>;
  onSelect: (entry: string) => void;
};

export const FormMenuMobile: React.FC<FormMenuMobileProps> = ({
  currentKey,
  entries,
  onSelect,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const toggleExpand = React.useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);

  const onEntryClick = React.useCallback(
    (event, entry) => {
      onSelect(entry);
      setExpanded(false);
    },
    [onSelect, setExpanded]
  );

  const doneCount = entries.filter((entry) => entry.done).length;

  const step = stepNumberFromSection(entries, currentKey);

  return (
    <>
      <AppBar
        position="fixed"
        onClick={toggleExpand}
        sx={{ backgroundColor: "#fcfcfc", color: colors.bodyText }}
      >
        <Toolbar>
          <Box></Box>

          <Stack direction="row" columnGap={2} flexGrow={1} alignItems="center">
            {entries.length === 3 ? (
              <>
                <img src={progressCircleIcons3[doneCount]} alt="" />
                <Box sx={{ paddingTop: "10px" }}></Box>
              </>
            ) : entries.length === 6 ? (
              <>
                <img src={progressCircleIcons[doneCount]} alt="" />
                <Box sx={{ paddingTop: "10px" }}></Box>
              </>
            ) : undefined}

            <Stack>
              <Typography variant="body2" sx={{ color: "#b1b1b1" }}>
                {step && `Step ${step}`}
              </Typography>
              <Typography variant="body1" sx={{ textTransform: "uppercase", fontWeight: "500" }}>
                {!currentKey ? "" : entryLabel(entries, currentKey)}
              </Typography>
            </Stack>
          </Stack>

          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Toolbar>
      </AppBar>

      <Drawer anchor={"top"} open={expanded} onClose={() => setExpanded(false)}>
        <List>
          {entries.map(({ key, label, done, error }) => {
            return (
              <MenuItem
                key={key}
                code={key}
                label={label}
                done={done}
                error={error}
                current={currentKey === key}
                onClick={(event) => onEntryClick(event, key)}
              />
            );
          })}
        </List>
      </Drawer>
    </>
  );
};

export const entryLabel = (entries: Array<FormMenuEntry>, currentKey: string) => {
  const entry = entries.find((entry) => entry.key === currentKey);
  if (entry) {
    return entry.label;
  }
  return "";
};
