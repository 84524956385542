import * as React from "react";
import { formatDate } from "@utils/Functions";

import { OwnershipStatus, Property } from "@src/types/property";

export const NewOccupierPropertyDetails: React.FC<{ property: Property }> = ({ property }) => {
  return (
    <span>
      {property.handOverDate &&
      property.ownershipStatus &&
      property.ownershipStatus !== OwnershipStatus.Unknown ? (
        <span>
          It is {property.ownershipStatus} and the new occupant(s) move in on{" "}
          {formatDate(property.handOverDate)}.
        </span>
      ) : property.handOverDate ? (
        <span>The new occupant(s) move in on {formatDate(property.handOverDate)}.</span>
      ) : property.ownershipStatus && property.ownershipStatus !== OwnershipStatus.Unknown ? (
        <span>It is {property.ownershipStatus}.</span>
      ) : null}
    </span>
  );
};
