import * as React from "react";

import { cleanedErrors } from "@utils/Functions";
import { RadioGroupInput } from "@src/components/atoms";
import { FormField } from "@molecules/FormField";
import { FormStack } from "@molecules/FormStack";
import { useUpdater } from "@utils/callbacks";
import {
  PersonDetailsFields,
  Record as PersonDetailsRecord,
  Errors as PersonDetailsErrors,
  errorsOf as errorsOfPersonDetails,
} from "./PersonDetailsFields";
import { defaultResponsible, fieldsFromPersons, Person } from "./responsible";

type Record = {
  readonly responsibleId?: string;
  readonly responsible?: PersonDetailsRecord;
};

type Errors =
  | undefined
  | {
    readonly responsibleId?: string;
    readonly responsible?: PersonDetailsErrors;
  };

export const errorsOfNewAccountHolder: (r?: Record) => Errors = (record) => {
  return cleanedErrors({
    responsibleId: record?.responsibleId || record?.responsibleId === "" ? undefined : "required",
    responsible:
      record?.responsibleId !== "" ? undefined : errorsOfPersonDetails(record?.responsible),
  });
};

export type NewAccountHolderFieldsProps = {
  readonly serviceProvider: { customForm?: string };
  readonly persons: ReadonlyArray<Person>;
  readonly labelOverride?: string;
  readonly record: Record;
  readonly errors: Errors;
  readonly update: any;
};

export const NewAccountHolderFields: React.FC<NewAccountHolderFieldsProps> = ({
  serviceProvider,
  persons,
  labelOverride = "Who will take over the account?",
  record,
  errors,
  update,
}) => {
  const onNewAccountHolderIdChange = React.useCallback(
    (responsibleId) => {
      if (responsibleId) {
        update({ responsibleId });
      } else if (responsibleId === "") {
        update({ responsibleId, responsible: {} });
      } else {
        update({});
      }
    },
    [update]
  );

  const updateNewAccountHolder = useUpdater(update, "responsible");

  React.useEffect(() => {
    if (typeof record.responsibleId === "string") {
      return;
    }

    const person = defaultResponsible(persons);
    if (person) {
      onNewAccountHolderIdChange(person.id);
    }
  }, [record, persons, onNewAccountHolderIdChange]);

  return (
    <FormStack substack spacing={0}>
      <FormField label={labelOverride}>
        <RadioGroupInput
          onValueChange={onNewAccountHolderIdChange}
          value={record.responsibleId}
          fields={fieldsFromPersons(persons, true)}
          error={errors?.responsibleId}
        />
      </FormField>

      {record.responsible && (
        <FormStack indented substack>
          <PersonDetailsFields
            serviceProvider={serviceProvider}
            record={record.responsible}
            update={updateNewAccountHolder}
            errors={errors?.responsible}
          />
        </FormStack>
      )}
    </FormStack>
  );
};
