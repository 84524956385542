import * as React from "react";

import { CloseOutlined, EditOutlined } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { colors } from "@styles/constants";
import { AccountDetails as AccountDetailsType, } from "@customTypes/index";
import { Button } from "../../../components/atoms/Button";
import { Link } from "../../../components/atoms/Link";
import { readableAccountType } from "@src/utils/accounts";

export type AddedAccountCardProps = {
  readonly account: AccountDetailsType;
  readonly index: number;
  readonly onRemove: (index: number) => void;
  readonly onEditAccount: (a: AccountDetailsType) => void;
};

export const AddedAccountCard: React.FC<AddedAccountCardProps> = ({
  account,
  index,
  onRemove: onRemoveIndex,
  onEditAccount,
}) => {
  if (account.additionalServicesAccount === true || account.companyType === "affiliate_partner")
    return null;
  const { companyName, accountType } = account;

  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);

  const onRemove = React.useCallback(() => {
    onRemoveIndex(index);
  }, [onRemoveIndex, index]);

  const onEdit = React.useCallback(() => {
    onEditAccount(account);
  }, [onEditAccount, account]);

  const onRemoveWithConfirmation = React.useCallback(() => {
    setConfirmDelete(true);
  }, [setConfirmDelete]);

  const onActuallyRemove = React.useCallback(() => {
    onRemove();
    setConfirmDelete(false);
  }, [onRemove]);

  return (
    <Paper elevation={6} sx={{ backgroundColor: colors.accentTeal, color: "white" }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" padding={2}>
        <Box>
          {companyName}
          {accountType ? ` - ${readableAccountType(accountType)}` : null}
        </Box>

        {account.source === "express_form" ? (
          <Typography>Notified</Typography>
        ) : (
          <Stack direction="row" alignItems="center" columnGap={2}>
            <Link
              sx={{ color: "white" }}
              inline
              variant="action"
              onClick={onEdit}
              icon={<EditOutlined fontSize="small" />}
            >
              Edit
            </Link>

            <Link
              sx={{ color: "white" }}
              inline
              variant="action"
              onClick={onRemoveWithConfirmation}
              icon={<CloseOutlined fontSize="small" />}
            >
              Remove
            </Link>
          </Stack>
        )}
      </Stack>

      {confirmDelete && (
        <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
          <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove this account?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button variant="secondary" size="medium" onClick={() => setConfirmDelete(false)}>
              Cancel
            </Button>
            <Button variant="primary" size="medium" onClick={onActuallyRemove} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Paper>
  );
};
