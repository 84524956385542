import React from "react";

import { colors } from "../../styles/constants";
import { Typography } from "@mui/material";

export type HeaderProps = {
  level?: 1 | 2 | 3;
  center?: boolean;
  color?: string;
};

export const Header: React.FC<HeaderProps> = ({ level = 1, center, color, children }) => {
  switch (level) {
    case 1:
      return (
        <Typography variant="h4" color={color} sx={center ? { textAlign: "center" } : {}}>
          {children}
        </Typography>
      );
    case 2:
      return (
        <Typography variant="h5" color={color} sx={center ? { textAlign: "center" } : {}}>
          {children}
        </Typography>
      );
    case 3:
      return (
        <Typography variant="h6" color={color} sx={center ? { textAlign: "center" } : {}}>
          {children}
        </Typography>
      );
  }
};

export type SubheaderProps = {
  center?: boolean;
  faded?: boolean;
};

export const Subheader: React.FC<SubheaderProps> = ({ center, faded, children }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        color: faded ? colors.lightTeal : colors.accentTeal,
        ...(center ? { textAlign: "center" } : {}),
      }}
    >
      {children}
    </Typography>
  );
};
