import * as React from "react";

import { Account } from "@src/types";
import { RequestDetailsField } from "@src/components/molecules";
import { ViewMoreContainer } from "@src/components/molecules";
import { PayeeDetails, PaymentMethod } from "../PayeeDetails";
import { NotAvailable } from "./NotAvailable";
import { readableAccountNextSteps, readableAccountType } from "@src/utils/accounts";

export type BankingAccountDetailsProps = { account: Account };

export const BankingAccountDetails: React.FC<BankingAccountDetailsProps> = ({ account }) => {
  return (
    <>
      <RequestDetailsField label="Notifier request">
        {readableAccountNextSteps(account.nextSteps, account.serviceProvider?.serviceProviderType)}
      </RequestDetailsField>
      <RequestDetailsField label="Account number">
        {account.accountNumber || <NotAvailable />}
      </RequestDetailsField>
      <RequestDetailsField label="Sort code">
        {account.sortCode || <NotAvailable />}
      </RequestDetailsField>
      <RequestDetailsField label="Account type">
        {account.accountType ? (
          readableAccountType(account.accountType, account.accountTypeOther)
        ) : (
          <NotAvailable />
        )}
      </RequestDetailsField>
      <RequestDetailsField label="Needs tax certificate">
        {account.needsTaxCertificate ? "Yes" : "No"}
      </RequestDetailsField>
      <ViewMoreContainer>
        <PayeeDetails paymentMethod={PaymentMethod.BankTransfer} account={account} />
      </ViewMoreContainer>
    </>
  );
};
