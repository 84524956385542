import * as React from "react";

import { Box, Grid } from "@mui/material";

export type FormStackProps = {
  indented?: boolean;
  substack?: boolean;
  spacing?: number;
  children: React.ReactNode;
};

export const FormStack: React.FC<FormStackProps> = ({
  indented,
  substack,
  spacing = 2,
  children,
}) => {
  const container = (
    <Grid container spacing={spacing}>
      {children}
    </Grid>
  );

  const box = !indented ? container : <Box sx={{ paddingLeft: 4 }}>{container}</Box>;

  return !substack ? (
    box
  ) : (
    <Grid item xs={12}>
      {box}
    </Grid>
  );
};
