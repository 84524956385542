import * as React from "react";

import { CompanyType } from "@customTypes/index";
import { FieldRow } from "../../../components/molecules/FieldsTable";
import { NotAvailable } from "../../Dashboard/RequestDetails/NotAvailable";
import { DeceasedPersonalDetails } from "./DeceasedPersonalDetails";
import { readableAccountNextSteps } from "@src/utils/accounts";

export const SocialMediaOrEmailAccount = ({
  account,
  serviceProviderType,
}: {
  account: any;
  serviceProviderType: CompanyType;
}) => {
  return (
    <>
      <DeceasedPersonalDetails deceased={account.deceased} />

      <FieldRow label="Email address" value={account.emailAddress || <NotAvailable />} />

      {serviceProviderType === CompanyType.SocialMedia && (
        <>
          <FieldRow label="Phone number" value={account.phoneNumber || <NotAvailable />} />

          <FieldRow label="Email address" value={account.emailAddress || <NotAvailable />} />
        </>
      )}

      <FieldRow
        label="Next step"
        value={readableAccountNextSteps(account.nextStep, serviceProviderType)}
      />
    </>
  );
};
