import * as React from "react";

// @ts-ignore
const testMode = !!window.settldTestModeEnabled;

const titleSuffix =
  process.env["REACT_APP_SETTLD_ENV"] === "production"
    ? "- Settld Help"
    : `- Settld Help (${process.env["REACT_APP_SETTLD_ENV"]})`;

function wrapped0(proc: () => void) {
  if (testMode) {
    return () => {};
  }

  return proc;
}

function wrapped1(proc: (a: any) => void) {
  if (testMode) {
    return () => {};
  }

  return proc;
}

function wrappedTrack<T>(proc: (a: T, b?: Object) => void) {
  if (testMode) {
    return () => {};
  }

  return proc;
}

// export const usePageView = wrapped0(() => {
//   React.useEffect(() => {
//   }, []);
// });

export const useEventTracker = wrappedTrack((name: string, dict) => {
  React.useEffect(() => {
    mixpanel.track(name, dict);
  }, []);
});

export const usePageViewEventTracker = (pageName: string) => {
  const proc = wrappedTrack((pageName) => {
    mixpanel.track("Page view", { page: pageName });

    // @ts-ignore
    gtag("event", "page_view", {
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
      test_mode: testMode,
    });
  });

  React.useEffect(() => {
    proc(pageName);
    document.title = `${pageName} ${titleSuffix}`;
  }, []);
};

export const useSectionViewEventTracker = (sectionName: string) => {
  const proc = wrappedTrack((sectionName) => {
    mixpanel.track("Form section view", { section: sectionName });

    // @ts-ignore
    gtag("event", "page_view", {
      page_title: document.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
      test_mode: testMode,
    });
  });

  React.useEffect(() => {
    proc(sectionName);
    document.title = `${sectionName} ${titleSuffix}`;
  }, []);
};

export const trackPasswordSignup = wrapped0(() => {
  mixpanel.track("Password signup");
  // @ts-ignore
  gtag("event", "password_signup", { test_mode: testMode });
});

export const trackPasswordSignin = wrapped0(() => {
  mixpanel.track("Password signin");
  // @ts-ignore
  gtag("event", "password_signin", { test_mode: testMode });
});

export const trackPersonalDetailsSignup = wrapped0(() => {
  mixpanel.track("Personal details signup");
  // @ts-ignore
  gtag("event", "personal_details_signup", { test_mode: testMode });
});

export const trackSubmitForm = wrapped0(() => {
  mixpanel.track("Submit form");
  // @ts-ignore
  gtag("event", "submit_form", { test_mode: testMode });
});

export const trackSubmitExpressForm = wrapped0(() => {
  mixpanel.track("Submit express form");
  // @ts-ignore
  gtag("event", "submit_express_form", { test_mode: testMode });
});

export const trackEvent = wrappedTrack((name: string, dict) => {
  mixpanel.track(name, dict);
});

export const identify = wrappedTrack((id: string) => {
  mixpanel.identify(id);
});

export const reset = wrapped0(() => {
  mixpanel.reset();
});
