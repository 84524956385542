import * as React from "react";

import { Box, Typography } from "@mui/material";

import { Button } from "../atoms/Button";
import { Link } from "../atoms/Link";

export type FormNextPaneDesktopProps = {
  busy?: boolean;
  nextLabel?: string;
  saveLabel?: string | null;
  onNext?: () => void;
  onSave?: () => void;
};

export const FormNextPaneDesktop: React.FC<FormNextPaneDesktopProps> = ({
  busy,
  nextLabel = "Next",
  saveLabel = "Save & continue later",
  onNext,
  onSave,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "baseline",
        columnGap: "20px",
      }}
    >
      <Button variant="secondary" size="large" disabled={!onNext || busy} onClick={onNext}>
        {nextLabel}
      </Button>

      {saveLabel !== null && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            columnGap: "15px",
          }}
        >
          <Typography variant="body2">or</Typography>
          <Link variant="primary" disabled={!onSave || busy} onClick={onSave}>
            {saveLabel}
          </Link>
        </Box>
      )}
    </Box>
  );
};
