import * as React from "react";

import { Box, CircularProgress } from "@mui/material";

export type BusyOverlayProps = {
  hidden: boolean;
  children: React.ReactNode;
};

export const BusyOverlay: React.FC<BusyOverlayProps> = ({ hidden, children }) => {
  return (
    <Box sx={{ position: "relative" }}>
      {children}

      {!hidden && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "#000000",
              opacity: 0.1,
            }}
          />
          <Box sx={{ marginTop: "100px" }}>
            <CircularProgress color="primary" />
          </Box>
        </Box>
      )}
    </Box>
  );
};
