import * as React from "react";

import { AccountNextStep, CompanyType, YesNo, } from "@src/types";
import { FieldRow } from "@molecules/FieldsTable";
import { NotAvailable } from "../../Dashboard/RequestDetails/NotAvailable";
import { readableRefundPaymentMethod } from "../../NotifierForm/AccountForm/RefundFields";
import { DeceasedPersonalDetails } from "./DeceasedPersonalDetails";
import { PersonDetails } from "./PersonDetails";
import { readableAccountType } from "@src/utils/accounts";

export const AIGAccount = ({ account }: { account: any; serviceProviderType: CompanyType }) => {
  return (
    <>
      <DeceasedPersonalDetails deceased={account.deceased} />

      <FieldRow
        label="Policy number"
        value={account.accountNumber ? account.accountNumber : <NotAvailable />}
      />

      <FieldRow label="Customer reference" value={account.holderNumber || <NotAvailable />} />

      <FieldRow
        label="Account type"
        value={
          account.accountType ? (
            readableAccountType(account.accountType, account.accountTypeOther)
          ) : (
            <NotAvailable />
          )
        }
      />

      <FieldRow label="Joint policy?" value={account.jointClaim === YesNo.Yes ? "Yes" : "No"} />

      <FieldRow
        label="Next step"
        value={
          account.nextStep === AccountNextStep.Cancel ? "Cancel policy" : "Transfer joint policy"
        }
      />

      {account.jointClaim === YesNo.Yes && (
        <FieldRow
          label="Joint policy holder"
          value={
            account.responsible.id === "notifier" ? (
              "Myself"
            ) : (
              <PersonDetails person={account.responsible.details} />
            )
          }
        />
      )}

      {account.refundPaymentMethod && (
        <FieldRow
          label="Refund payment method"
          value={readableRefundPaymentMethod(account.refundPaymentMethod)}
        />
      )}

      {account.refundBankAccountHolderName && (
        <FieldRow label="Refund account holder name" value={account.refundBankAccountHolderName} />
      )}

      {account.refundBankAccountNumber && (
        <FieldRow label="Refund account number" value={account.refundBankAccountNumber} />
      )}

      {account.refundBankAccountSortCode && (
        <FieldRow label="Refund account sort code" value={account.refundBankAccountSortCode} />
      )}

      {account.refundChequePayableTo && (
        <FieldRow
          label="Refund check payable to"
          value={
            account.refundChequePayableTo === "notifier"
              ? "Myself"
              : `${account.responsible.details.firstName} ${account.responsible.details.lastName} `
          }
        />
      )}

      {account.paymentBankAccountHolderName && (
        <FieldRow
          label="Payment account holder name"
          value={account.paymentBankAccountHolderName}
        />
      )}

      {account.paymentBankAccountNumber && (
        <FieldRow label="Payment account number" value={account.paymentBankAccountNumber} />
      )}

      {account.paymentBankAccountSortCode && (
        <FieldRow label="Payment account sort code" value={account.paymentBankAccountSortCode} />
      )}
    </>
  );
};
