import { apiFetch } from "./restApi";
import monitor from "../../utils/monitoring";
import { trackEvent, trackPasswordSignup } from "@utils/analytics";
import { ValidationError } from "@utils/Errors";
import { SignupReferralOptions } from "@utils/Constants";
import { ServerRecord, ServerResponseRecord, RegistrationInfo } from "@utils/Types";

export const signup = async (args: ServerRecord): Promise<ServerResponseRecord> => {
  let result;

  try {
    result = await signupRequest(args);
  } catch (error) {
    trackEvent("HTTP error", { request: "Password signup" });
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      case "validation":
        trackEvent("Request error", { request: "Password signup", error: "Validation failed" });
        throw new ValidationError(result.errors);
      case "user_exists":
        trackEvent("Request error", { request: "Password signup", error: "User exists" });
        throw new Error("You have already registered; please log in.");
      default:
        trackEvent("Request error", { request: "Password signup", error: result.error });
        throw new Error(result.error);
    }
  }

  trackPasswordSignup();
  return result.data;
};

const signupRequest = async (args: ServerRecord) => {
  let result;

  const { referral, referralOther, ...otherArgs } = args;

  try {
    result = await apiFetch({
      path: "/notifier/auth-credentials",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: {
        ...otherArgs,
        referral: referralFrom(referral, referralOther),
      },
    });
  } catch (error) {
    monitor.logError({
      event: "POST /notifier/auth-credentials",
      args,
      error,
    });
    throw error;
  }

  return result;
};

const referralFrom = (referral: string, referralOther: string) => {
  const found = SignupReferralOptions.find(({ value }) => value === referral);
  const str = found ? found.label : referral;

  if (referralOther) {
    return str + " - " + referralOther;
  }

  return str;
};

export const registerUser = async (args: RegistrationInfo) => {
  let result;

  try {
    result = await apiFetch({
      path: "/registrations",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: { ...args },
    });
  } catch (error) {
    monitor.logError({
      event: "POST /registrations",
      args,
      error,
    });
    throw error;
  }

  return result;
};
