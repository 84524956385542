import { identity } from "lodash";
import * as React from "react";

import { Box, Stack, Typography } from "@mui/material";

import { Button } from "../atoms/Button";

export type HeaderActionProps = {
  prompt?: React.ReactNode;
  actionLabel?: string;
  onClick?: () => void;
};

export const HeaderAction: React.FC<HeaderActionProps> = ({ prompt, actionLabel, onClick }) => {
  const button = (
    <Button variant="secondary" size="medium" onClick={onClick || identity}>
      {actionLabel || "Go"}
    </Button>
  );

  return actionLabel || onClick ? (
    <Stack direction="row" justifyContent="flex-end" alignItems="baseline" columnGap={"10px"}>
      <Typography variant="caption">{prompt}</Typography>
      {button}
    </Stack>
  ) : (
    <Stack direction="row" justifyContent="flex-end" alignItems="baseline" columnGap={"10px"}>
      <Box sx={{ visibility: "hidden" }}>{button}</Box>
      <Typography variant="caption">{prompt}</Typography>
    </Stack>
  );
};
