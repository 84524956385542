import * as React from "react";

import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import {
  createMessage,
  deleteMessage,
  getMessages,
  markMessageAsRead,
  refreshMessages,
  submitMessage,
} from "@api/messageApi";

import { Account } from "../../types/Account";
import { Document } from "../../types/Document";
import { Link } from "../../components/atoms/Link";
import { GreyPane } from "../../components/molecules/GreyPane";
import { Chat } from "./Chat";
import { SendMessageProcType } from "./Chat/InputBox";
import { lastReceivedMessageFromBlocks, Message, MessageBlock } from "./Chat/message";
import { documentsFromMessages, messageBlocksFromMessages } from "./Chat/Messages";
import { Files } from "./Files";

const updateIntervalMillis = 60000;

export const AccountUpdates: React.FC<{
  readonly caseId: string;
  readonly signature: string | null;
  readonly account: Account;
  // updateChat depicts whether the accordion is open or closed
  readonly updateChat: boolean;
}> = ({ caseId, signature, account, updateChat }) => {

  const [{ blocks, documents, lastReceivedMessage }, setMessages] = React.useState({
    blocks: [],
    documents: [],
    messages: [],
    lastReceivedMessage: null,
  } as { blocks: ReadonlyArray<MessageBlock>; documents: ReadonlyArray<{ document: Document; message: Message }>; messages: ReadonlyArray<Message>; lastReceivedMessage: Message | null });

  const [scrollCounter, setScrollCounter] = React.useState(0);

  const scrollToBottom = React.useCallback(() => {
    // increments the scrollCounter,triggers a re-render and scrolls the chat view to the bottom.
    setScrollCounter((c) => c + 1);
  }, [setScrollCounter]);

  React.useEffect(() => {
    if (!updateChat) return;

    const updateState = (result: any) => {
      if (result.data) {
        const { messages } = result.data;
        messages.sort((a: any, b: any) => a.insertedAt.localeCompare(b.insertedAt));
        const blocks = messageBlocksFromMessages(messages);
        const lastReceivedMessage = lastReceivedMessageFromBlocks(blocks);
        const documents = documentsFromMessages(messages);
        setMessages({ blocks, documents, messages, lastReceivedMessage });
        scrollToBottom();
      }
    };

    getMessages({ caseId, signature, accountId: account.id }).then(updateState, (_error) => {
      // monitor.trackException(error);
    });

    let interval: NodeJS.Timeout | null;
    let timeout: NodeJS.Timeout | null = setTimeout(() => {
      timeout = null;
      interval = setInterval(() => {
        refreshMessages({ caseId, signature, accountId: account.id }).then(
          updateState,
          (_error) => {
            // monitor.trackException(error);
          }
        );
      }, updateIntervalMillis);
    }, Math.floor(20000 * Math.random()));

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [caseId, signature, account.id, updateChat, setMessages, scrollToBottom]);

  const updateMessages = React.useCallback(async () => {
    return getMessages({ caseId, signature, accountId: account.id }).then(
      (result) => {
        if (result.data) {
          const { messages } = result.data;
          messages.sort((a: any, b: any) => a.insertedAt.localeCompare(b.insertedAt));
          const blocks = messageBlocksFromMessages(messages);
          const lastReceivedMessage = lastReceivedMessageFromBlocks(blocks);
          const documents = documentsFromMessages(messages);
          setMessages({ blocks, documents, messages, lastReceivedMessage });
        }
      },
      (_error) => {
        // monitor.trackException(error);
      }
    );
  }, [caseId, signature, account.id, setMessages]);

  const sendMessage = React.useCallback(
    ({ content, attachment }) => {
      return submitMessage({
        caseId,
        signature,
        accountId: account.id,
        content,
        attachment,
      }).then(
        () => {
          return updateMessages().then(scrollToBottom, (_error) => { });
        },
        (_error) => { }
      );
    },
    [caseId, signature, account.id, updateMessages, scrollToBottom]
  );

  const deleteMessageCallback = React.useCallback(
    (messageId: string) => {
      if (confirm("Are you sure you want to delete this message?")) {
        return deleteMessage({
          caseId,
          signature,
          accountId: account.id,
          messageId,
        }).then(
          () => {
            return updateMessages().then(scrollToBottom, (_error) => { });
          },
          (_error: any) => { }
        );
      }
      return Promise.resolve();
    },
    [caseId, signature, account.id, updateMessages, scrollToBottom]
  );

  const markMessageAsReadCallback = React.useCallback(
    (messageId: string) => {
      return markMessageAsRead({
        caseId,
        signature,
        accountId: account.id,
        messageId,
      }).then(
        () => {
          return updateMessages().catch((_error) => { });
        },
        (_error) => { }
      );
    },
    [caseId, signature, account.id, updateMessages]
  );

  return (
    <AccountUpdatesView
      account={account}
      blocks={blocks}
      documents={documents}
      scrollCounter={scrollCounter}
      lastReceivedMessage={lastReceivedMessage}
      sendMessage={sendMessage}
      markMessageAsRead={markMessageAsReadCallback}
      deleteMessage={deleteMessageCallback}
    />
  );
};

export type AccountUpdatesViewProps = {
  readonly account: Account;
  readonly blocks: ReadonlyArray<MessageBlock>;
  readonly lastReceivedMessage: Message | null;
  readonly documents: ReadonlyArray<{ document: Document; message: Message }>;
  readonly scrollCounter: number;
  readonly sendMessage: SendMessageProcType;
  readonly markMessageAsRead: (messageId: string) => Promise<void>;
  readonly deleteMessage: (messageId: string) => Promise<void>;
};

export const AccountUpdatesView: React.FC<AccountUpdatesViewProps> = ({
  account,
  blocks,
  lastReceivedMessage,
  documents,
  scrollCounter,
  sendMessage,
  markMessageAsRead,
  deleteMessage,
}) => {
  const classes = useStyles();

  const [showingChat, setShowingChat] = React.useState(true);

  const toggleAttachmentDisplay = React.useCallback(() => {
    setShowingChat((b) => !b);
  }, []);

  return (
    <GreyPane
      title={"Account updates"}
      joinedHeaderAndBody={true}
      right={<Link onClick={toggleAttachmentDisplay}>Documents</Link>}
    >
      <div className={classes.outside}>
        <div className={classes.inside}>
          <Chat
            recipient={account.serviceProvider?.isConnected ? "sp" : "settld"}
            blocks={blocks}
            scrollCounter={scrollCounter}
            sendMessage={sendMessage}
            lastReceivedMessage={lastReceivedMessage}
            markMessageAsRead={markMessageAsRead}
            deleteMessage={deleteMessage}
          />
        </div>
      </div>

      {!showingChat && (
        <div className={classes.inside}>
          <div className={classes.glass}>
            <ClosableContainer onClose={toggleAttachmentDisplay}>
              <Files documents={documents} />
            </ClosableContainer>
          </div>
        </div>
      )}
    </GreyPane>
  );
};

const ClosableContainer: React.FC<{ onClose: () => void }> = ({ onClose, children }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.closeContainer}>
        <a className={classes.link} onClick={onClose}>
          <Close />
        </a>
      </div>

      <div>{children}</div>
    </div>
  );
};

const useStyles = makeStyles({
  outside: {
    position: "relative",
    height: "100%",
    minHeight: "300px",
  },

  inside: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },

  glass: {
    backgroundColor: "rgba(256, 256, 256, 0.5)",
    backdropFilter: "blur(6px)",
    width: "100%",
    height: "100%",
    padding: "30px",
  },

  closeContainer: {
    textAlign: "right",
  },

  link: {
    cursor: "pointer",
  },
});
