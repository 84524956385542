import { Typography } from "@mui/material";
import * as React from "react";

import { AccountDetails, MeterAccess } from "@customTypes/index";
import { cleanedErrors } from "@utils/Functions";
import { DateInput } from "../../../../components/atoms/DateInput";
import { TextInput } from "../../../../components/atoms/TextInput";
import { FormField } from "../../../../components/molecules/FormField";
import { FormStack } from "../../../../components/molecules/FormStack";
import { useOnChange, useOnValueChange } from "@utils/callbacks";
import { NewAccountRecord } from "@api/online-api";
import { MeterAccessField } from "./MeterAccessField";

export type Record = {
  readonly meterAccess?: MeterAccess;
  readonly meterReading?: string;
  readonly lastMeterReadingDate?: string;
};

export type Errors =
  | undefined
  | {
    readonly meterAccess?: string;
    readonly meterReading?: string;
    readonly lastMeterReadingDate?: string;
  };

export const errorsOf: (r?: Record) => Errors = (record) => {
  const requiresReadings = record?.meterAccess === MeterAccess.Yes;

  return cleanedErrors({
    meterAccess: record?.meterAccess ? undefined : "required",
    meterReading: !requiresReadings || record?.meterReading ? undefined : "required",
    lastMeterReadingDate:
      !requiresReadings || record?.lastMeterReadingDate ? undefined : "required",
  });
};

export const recordFromAccount = (account: AccountDetails | undefined): Record => {
  if (!account) {
    return {};
  }

  return {
    meterAccess: account.meterAccess,
    meterReading: account.lastMeterReading,
    lastMeterReadingDate: account.lastMeterReadingDate,
  };
};

export const accountFieldsFromRecord = (record?: Record): Partial<NewAccountRecord> => {
  if (!record) return {};
  return {
    meterAccess: record?.meterAccess,
    lastMeterReading: record.meterReading,
    lastMeterReadingDate: record.lastMeterReadingDate,
  };
};

export const MeterInfoField: React.FunctionComponent<{
  record: Record;
  errors?: any;
  update: any;
}> = ({ record, errors, update }) => {
  const onMeterTypeChange = useOnValueChange(update, "meterAccess");
  const onChange = useOnChange(update);
  const onReadingDateChange = useOnValueChange(update, "lastMeterReadingDate");

  return (
    <>
      <MeterAccessField
        onValueChange={onMeterTypeChange}
        value={record.meterAccess}
        error={errors?.meterAccess}
      />

      {record.meterAccess === MeterAccess.Yes && (
        <FormStack indented substack>
          <FormField>
            <Typography variant="body1">Please provide the following details:</Typography>
          </FormField>

          <FormField label="Meter reading (as close to the date of death as possible)">
            <TextInput
              name="meterReading"
              value={record.meterReading || ""}
              onChange={onChange}
              error={errors?.meterReading}
            />
          </FormField>

          <FormField label="Date on which the meter reading above was taken">
            <DateInput
              value={record.lastMeterReadingDate || ""}
              onValueChange={onReadingDateChange}
              error={errors?.lastMeterReadingDate}
              pastOnly
            />
          </FormField>
        </FormStack>
      )}
    </>
  );
};
