import * as React from "react";

import { ChevronLeftOutlined } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";

import { Link } from "./Link";

export type BackLinkProps = {
  readonly onClick?: () => void;
  readonly to?: string;
};

export const BackLink: React.FC<BackLinkProps> = ({ onClick, to, children }) => {
  return (
    <Link to={to} onClick={onClick}>
      <Stack direction="row" columnGap={1} alignItems="center">
        <ChevronLeftOutlined />
        <Box>{children}</Box>
      </Stack>
    </Link>
  );
};
