import * as React from "react";

import { makeStyles } from "@mui/styles";

import { Document } from "@src/types";
import { MessageDocumentLink } from "../DocumentLink";

export const MessageDocuments: React.FC<{ readonly documents: ReadonlyArray<Document> }> = ({
  documents,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {documents.map((document) => {
        return (
          <div key={document.id} className={classes.documentWrapper}>
            <MessageDocumentLink document={document} />
          </div>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    paddingTop: "5px",
  },
  documentWrapper: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    flexGrow: 1,
  },
});
