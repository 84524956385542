import { identity } from "lodash";
import * as React from "react";

import { Box, Container, Stack } from "@mui/material";

import { PageHeader } from "@organisms/PageHeader";
import { BusyOverlay } from "@atoms/BusyOverlay";
import { ErrorSnackbar } from "@organisms/ErrorSnackbar";

export type CentredTemplateDesktopProps = {
  sx?: React.CSSProperties;
  busy?: boolean;
  errorMessage?: string;
  header: React.ReactNode;
  children: React.ReactNode;
  removeErrorMessage?: () => void;
};

export const CentredTemplateDesktop: React.FC<CentredTemplateDesktopProps> = ({
  sx,
  busy,
  errorMessage,
  header,
  children,
  removeErrorMessage,
}) => {
  return (
    <BusyOverlay hidden={!busy}>
      <Stack rowGap="80px" sx={{ minHeight: "100vh", ...sx }}>
        {header || (
          <Box sx={{ visibility: "hidden" }}>
            <PageHeader includeLogo actionLabel="No op" onClick={identity} />
          </Box>
        )}

        <Container maxWidth="sm" sx={{ paddingBottom: 4 }}>
          {children}
        </Container>

        <ErrorSnackbar error={errorMessage} onClose={removeErrorMessage} />
      </Stack>
    </BusyOverlay>
  );
};
