import * as React from "react";
import { useUpdater } from "@utils/callbacks";
import {
  ExistingOccupierFields as ResponsibleFields,
  Record as ExistingOccupierRecord,
  Errors as ExistingOccupierErrors,
  errorsOfExistingOccupier,
  recordFromAccount as existingOccupierRecordFromAccount,
} from "./ExistingOccupierFields";
import { cleanedErrors } from "@utils/Functions";
import { Property } from "@src/types/property";
import { AccountRecordPropertySpec } from "@api/online-api";
import { Person } from "../ResponsibleFields";

export type Record = {
  readonly occupier?: ExistingOccupierRecord;
};

export type Errors = undefined | { readonly occupier?: ExistingOccupierErrors };

export const errorsOf: (r?: Record) => Errors = (r) => {
  return cleanedErrors({
    occupier: errorsOfExistingOccupier(r?.occupier),
  });
};

export const existingOccupierInfoAvailable = (property: Property) => {
  return property.occupierId && true;
};

export const existingOccupierPropertyFieldsFromRecord = (
  record?: Record
): Partial<AccountRecordPropertySpec> => {
  if (!record) {
    return {};
  }

  return {
    occupier: {
      id: record.occupier?.responsibleId || undefined,
      details: record.occupier?.responsible,
    },
  };
};

export type ExistingOccupierPropertyFieldsProps = {
  readonly serviceProvider: { customForm?: string };
  readonly persons: ReadonlyArray<Person>;
  readonly record: Record;
  readonly update: any;
  readonly errors: Errors;
};

export const ExistingOccupierPropertyFields: React.FC<ExistingOccupierPropertyFieldsProps> = ({
  serviceProvider,
  persons,
  record,
  update,
  errors,
}) => {
  const updateAccountHolder = useUpdater(update, "occupier");

  return (
    <ResponsibleFields
      serviceProvider={serviceProvider}
      persons={persons}
      record={record.occupier || {}}
      errors={errors?.occupier}
      update={updateAccountHolder}
    />
  );
};
