import { assertUnreachable } from "../utils/Functions";
import { Account } from "./Account";
import { Identifiable } from "./Identifiable";
import { Executor, NotifierFormData, Relationship, Titles, YesNo } from "./index";
import { Notifier } from "./Notifier";
import { Attachment } from "./Attachment";

export enum CaseStates {
  Closed = "CLOSED",
  Open = "OPEN",
  Submitted = "SUBMITTED",
}

export enum CaseStatuses {
  Cancelled = "CANCELLED",
  Expired = "EXPIRED",
  InProcess = "IN_PROCESS",
  InitialFormSent = "INITIAL_FORM_SENT",
  Settld = "SETTLD",
  UnderReview = "UNDER_REVIEW",
}

export enum NotifierRelationshipToDeceased {
  Child = "CHILD",
  Executor = "EXECUTOR",
  Friend = "FRIEND",
  Husband = "HUSBAND",
  Spouse = "SPOUSE",
}

export enum CaseEventEnum {
  AccountUpdated = "ACCOUNT_UPDATED",
  FormSubmitted = "FORM_SUBMITTED",
  InitialLetterSent = "INITIAL_LETTER_SENT",
  OtherCommunication = "OTHER_COMMUNICATION",
  RequestAction = "REQUEST_ACTION",
  DocumentFromNotifier = "DOCUMENT_FROM_NOTIFIER",
}

export interface CaseEmail extends Identifiable {
  to: string[] | null;
  cc: string[] | null;
  bcc: string[] | null;
  subject: string;
  htmlBody: string;
  textBody: string;
}

export interface CaseEvent extends Identifiable {
  readonly case?: Identifiable;
  readonly account?: Identifiable;
  readonly description: string;
  readonly type: CaseEventEnum;
  readonly insertedAt: string;
  readonly updatedAt: string;
  readonly email: CaseEmail;
}

export interface Case extends Identifiable {
  readonly alternateId: number;
  readonly externalCode?: string;
  readonly state: CaseStates;
  readonly status?: CaseStatuses;
  readonly caseEvents?: ReadonlyArray<CaseEvent>;
  readonly accounts?: ReadonlyArray<Account>;
  readonly attachments?: Attachment[];

  readonly formData?: NotifierFormData;

  readonly additionalExecutors?: ReadonlyArray<Executor>;

  readonly deceasedAt: string;
  readonly deceasedTitle: Titles;
  readonly deceasedFirstName: string;
  readonly deceasedLastName: string;
  readonly deceasedEmail: string;
  readonly deceasedPhone: string;
  readonly additionalInfo?: string;

  readonly notifier: Notifier;
  readonly notifierRelationshipToDeceased: Relationship;
  readonly notifierRelationshipToDeceasedOther: string;

  readonly accountCount: number;
  readonly processingAccountCount: number;
  readonly settledAccountCount: number;
  readonly blockedAccountCount: number;
  readonly formSubmittedAt: string;
  readonly updatedAt: string;

  readonly bypassKYC?: boolean;

  readonly requestFinancialGuidance?: YesNo;
  readonly requestFuneralDirector?: YesNo;
  readonly requestHelpDealingWithDebt?: YesNo;
  readonly requestHelpDealingWithFamilyConflict?: YesNo;
  readonly requestHomeRemoval?: YesNo;
  readonly requestLocalGriefSupport?: YesNo;
  readonly requestMissingAssetSearch?: YesNo;
  readonly requestProbateBridgingLoans?: YesNo;
  readonly requestProbateSolicitor?: YesNo;
  readonly requestPropertyValuationService?: YesNo;
  readonly requestProbateHouseInsurance?: YesNo;
  readonly requestExecutorInsurance?: YesNo;
  readonly requestProbateAndFuneralBridgingLoans?: YesNo;

  readonly will?: boolean;
}

// Helper functions.
export const readableCaseStates = (s: CaseStates): string => {
  switch (s) {
    case CaseStates.Closed:
      return "Closed";
    case CaseStates.Open:
      return "Open";
    case CaseStates.Submitted:
      return "Submitted";
  }
  assertUnreachable(s);
};

export const readableCaseStatuses = (s: CaseStatuses): string => {
  switch (s) {
    case CaseStatuses.Cancelled:
      return "Cancelled";
    case CaseStatuses.Expired:
      return "Expired";
    case CaseStatuses.InProcess:
      return "In process";
    case CaseStatuses.InitialFormSent:
      return "Executor form sent";
    case CaseStatuses.Settld:
      return "Settld";
    case CaseStatuses.UnderReview:
      return "Under review";
  }
  assertUnreachable(s);
};

export const getPrettifiedId = (c: { alternateId: number; externalCode?: string }): string => {
  if (c.externalCode) {
    return c.externalCode;
  }

  const id = c.alternateId.toString();
  const div = c.alternateId;
  const length = 5;

  const pad = "SETLD"
    .split(new RegExp(""))
    .slice(0, length - id.length)
    .map((char) => {
      return (((parseInt(char, 36) + div) % 26) + 10).toString(36).toUpperCase();
    })
    .join("");

  const split = pad.length - (div % (pad.length + 1));
  return "#" + pad.substring(0, split) + id + pad.substring(split);
};
