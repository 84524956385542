import * as React from "react";
import { useNavigate } from "react-router-dom";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Stack, Typography, useMediaQuery, Container, useTheme } from "@mui/material";

import { usePageViewEventTracker } from "@utils/analytics";
import { colors } from "../../styles/constants";
import { insideIframe } from "../../utils/Functions";
import { Button } from "../../components/atoms/Button";
import { Header } from "../../components/atoms/Header";
import { FormField } from "../../components/molecules/FormField";
import { FormStack } from "../../components/molecules/FormStack";
import { SideImageTemplate } from "@templates/SideImageTemplate";
import { putExpressAdditionalServicesData, getExpressData } from "./express-data";
import { navigateToURL } from "./navigateToURL";
import { additionalServices, YesNo, AdditionalHelpDetails } from "@customTypes/index";
import { YesNoCheckboxField } from "@atoms/YesNoField";
import { updateAdditionalservices } from "@api/caseApi";

export type ExpressAdditionalServicesPageProps = {
  serviceProvider: { companyName: string };
  next: () => void;
};

export const ExpressAdditionalServicesPage: React.FC<ExpressAdditionalServicesPageProps> = ({
  serviceProvider,
  next,
}) => {
  usePageViewEventTracker("Express Additional Services");
  const navigate = useNavigate();

  return <ExpressAdditionalServicesPageView serviceProvider={serviceProvider} next={next} />;
};

export type ExpressAdditionalServicesPageViewProps = {
  serviceProvider: { companyName: String };
  next: () => void;
};

export const ExpressAdditionalServicesPageView: React.FC<
  ExpressAdditionalServicesPageViewProps
> = ({ serviceProvider, next }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [services, setServices] = React.useState<AdditionalHelpDetails>({});

  const onFieldChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string | undefined } }) => {
      setServices((s) => ({ ...s, [name]: value }));
    },
    [setServices]
  );

  const onFieldValueChange = (name: string) => (value: YesNo) =>
    onFieldChange({ target: { name, value } });
  const Entry = ({ label, name }: { label: string; name: keyof AdditionalHelpDetails }) => (
    <FormField>
      <YesNoCheckboxField
        label={label}
        value={services[name]}
        onValueChange={onFieldValueChange(name)}
      />
    </FormField>
  );

  const handleNext = React.useCallback(() => {
    putExpressAdditionalServicesData(services);

    var query = new URLSearchParams(new URL(getExpressData().formUrl).search);
    const caseId = query.get("case_id");
    const signature = query.get("sig");
    updateAdditionalservices({ caseId: caseId, signature: signature, record: services })
      .then((s) => {
        // console.log(s);
        next();
      })
      .catch((e) => {
        console.log(e);
        next();
      });
  }, [services, next]);

  return (
    <SideImageTemplate
      headerType="help"
      image={<img src="/images/stock/express-form-submitted.svg" width="100%" alt="Family" />}
    >
      <Stack rowGap={4}>
        {matches ? (
          <>
            <Header level={1} color={colors.accentTeal}>
              {serviceProvider.companyName} successfully notified
            </Header>

            <Header level={2}>Do you need help with other services?</Header>
          </>
        ) : (
          <>
              <Stack columnGap={1} direction="row">
                <CheckCircleOutlineOutlinedIcon fontSize="small" sx={{ color: colors.lightTeal }} />
                <Typography variant="body1" color={colors.accentTeal}>
                <b>{serviceProvider.companyName} successfully notified</b>
                 </Typography>
              </Stack>
            <Header level={1} color={colors.bodyText}>
              Do you need help with other services?
            </Header>
          </>
        )}

        <Typography variant="body1">
          Any services you select from the list below, will be provided via your accounts dashboard.
          This gives you one place to turn to for complete peace of mind.
        </Typography>

        <Container>
          <FormStack spacing={0}>
            {additionalServices.map(([ui, internal_field]: any) => (
              <Entry key={internal_field} label={ui} name={internal_field} />
            ))}
          </FormStack>
        </Container>

        <FormStack>
          <FormField>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="primary" size="large" onClick={handleNext}>
                Skip
              </Button>
              <Button variant="primary" size="large" onClick={handleNext}>
                Next
              </Button>
            </div>
          </FormField>
        </FormStack>
      </Stack>
    </SideImageTemplate>
  );
};
