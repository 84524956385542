import { identity } from "lodash";
import * as React from "react";

import { Stack, Typography } from "@mui/material";

import { Header } from "../../../components/atoms/Header";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { colors } from "@styles/constants";

export type KYCDoneProps = {
  expressForm?: boolean;
  onContinue: () => void;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
};

export const KYCDone: React.FC<KYCDoneProps> = ({
  expressForm,
  onContinue,
  onSectionClick,
  updateTemplate,
}) => {
  React.useEffect(() => {
    updateTemplate({
      currentSection: "kyc",
      onNext: onContinue,
      onSave: identity,
      onSectionClick: onSectionClick,
    });
  }, [onContinue, onSectionClick, updateTemplate]);

  return (
    <Stack rowGap={4}>
      <Header level={1} color={expressForm ? colors.accentTeal : undefined}>
        Thank you for verifying your identity.
      </Header>

      <Typography variant="body1">
        We appreciate you taking the time to ensure Settld remains safe and secure.
      </Typography>
    </Stack>
  );
};
