import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Stack, Typography } from "@mui/material";

import { trackEvent, trackPasswordSignup, usePageViewEventTracker } from "@utils/analytics";
import { signupRequest } from "@api/sessionApi";
import { login } from "@utils/session";
import { BusyOverlay } from "../../components/atoms/BusyOverlay";
import { Button } from "../../components/atoms/Button";
import { Header } from "../../components/atoms/Header";
import { PasswordInput } from "../../components/atoms/PasswordInput";
import { TextInput } from "../../components/atoms/TextInput";
import { FormField } from "../../components/molecules/FormField";
import { FormStack } from "../../components/molecules/FormStack";
import { SideImageTemplate } from "@templates/SideImageTemplate";
import { Link } from "../../components/atoms/Link";

type State = {
  readonly busy: boolean;
  readonly errorMessage: string;
  readonly errors: RecordErrors;
  readonly record: Record;
  readonly validated: boolean;
};

type Record = {
  readonly password: string;
  readonly passwordConfirmation: string;
};

type RecordErrors = {
  readonly password?: string;
  readonly passwordConfirmation?: string;
};

export type PasswordSetupPageProps = {
  readonly nextPageURL: string;
};

export const PasswordSetupPage: React.FC<PasswordSetupPageProps> = ({ nextPageURL }) => {
  const { website } = useParams() as { website: string };

  usePageViewEventTracker("Setup password");

  const [state, setState] = React.useState({
    busy: false,
    errorMessage: "",
    errors: {},
    record: {
      password: "",
      passwordConfirmation: "",
    },
    validated: false,
  } as State);

  const { busy, errorMessage, errors, record, validated } = state;

  const navigate = useNavigate();

  const onChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string } }) => {
      setState((s) => ({
        ...s,
        errors: {
          ...s.errors,
          [name]: undefined,
        },
        record: { ...s.record, [name]: value },
        validated: false,
      }));
    },
    [setState]
  );

  const onReferralValueChange = React.useCallback((value) => {
    setState((s) => ({
      ...s,
      record: { ...s.record, referral: value, referralOther: "" },
      validated: false,
      errors: {
        ...s.errors,
        referral: "",
        referralOther: "",
      },
    }));
  }, []);

  const onSubmit = React.useCallback(
    (ev) => {
      ev.preventDefault();
      const errors = recordErrors(record);

      if (Object.keys(errors).length !== 0) {
        setState((s) => ({ ...s, validated: true, errors, errorMessage: "" }));
        return;
      }

      setState((s) => ({ ...s, busy: true, errors, errorMessage: "" }));

      const data = { ...record, captcha: "none" };

      signup(data).then(
        (data) => {
          setState((s) => ({ ...s, busy: false }));
          const { session } = data;
          login(session.token);
          navigate(nextPageURL, { replace: true });
        },
        (error) => {
          if (error instanceof ValidationError) {
            setState((s) => ({
              ...s,
              busy: false,
              errors: errorsFromServer(error),
              validated: true,
            }));
          } else {
            setState((s) => ({ ...s, busy: false, errorMessage: error.message }));
          }
        }
      );
    },
    [record, navigate, nextPageURL, setState]
  );

  const removeErrorMessage = React.useCallback(() => {
    setState((s) => ({ ...s, errorMessage: "" }));
  }, [setState]);

  return (
    <PasswordSetupPageView
      emailAddress="marco@settld.care"
      validated={validated}
      record={record}
      errors={errors}
      website={website}
      errorMessage={errorMessage}
      busy={busy}
      onSubmit={onSubmit}
      onChange={onChange}
      onReferralValueChange={onReferralValueChange}
      removeErrorMessage={removeErrorMessage}
    />
  );
};

export type PasswordSetupPageViewProps = {
  emailAddress: string;
  validated: any;
  record: any;
  errors: any;
  website: any;
  errorMessage: any;
  busy: any;
  onSubmit: (ev: any) => void;
  onChange: any;
  onReferralValueChange: any;
  removeErrorMessage: () => void;
};

export const PasswordSetupPageView: React.FC<PasswordSetupPageViewProps> = ({
  validated,
  emailAddress,
  record,
  errors,
  errorMessage,
  busy,
  onSubmit,
  onChange,
  removeErrorMessage,
}) => {
  return (
    <BusyOverlay hidden={!busy}>
      <SideImageTemplate
        headerType="help"
        errorMessage={errorMessage}
        removeErrorMessage={removeErrorMessage}
        image={
          <img
            src="/images/stock/signup.svg"
            width="100%"
            alt="Family"
            style={{ paddingTop: "100px" }}
          />
        }
      >
        <form onSubmit={onSubmit} noValidate autoComplete="off">
          <Stack rowGap={4}>
            <Box>
              <Header>Please setup a password</Header>
              <Typography variant="body1">
                To be able to make effective use of Settld service, you need to setup an account
                with us. This will allow you to return to our service to follow the progress of the
                accounts by using your meail address and password.
              </Typography>
            </Box>

            <FormStack>
              <FormField twoThirdsWidthByItself label="Email address">
                <TextInput name="email" disabled value={emailAddress} />
              </FormField>

              <FormField twoThirdsWidthByItself label="Password">
                <PasswordInput
                  name="password"
                  value={record.password || ""}
                  error={!validated ? null : errors.password}
                  onChange={onChange}
                />
              </FormField>

              <FormField twoThirdsWidthByItself label="Confirm password">
                <PasswordInput
                  name="passwordConfirmation"
                  value={record.passwordConfirmation || ""}
                  error={!validated ? null : errors.passwordConfirmation}
                  onChange={onChange}
                />
              </FormField>
            </FormStack>

            <FormStack>
              <FormField>
                <div>
                  By clicking the ‘Continue’ button, you are creating a Settld account and you agree
                  to Settld's{" "}
                  <Link
                    inline
                    target="_blank"
                    to="https://www.settld.care/legal/terms-and-conditions.html"
                  >
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link
                    inline
                    target="_blank"
                    to="https://www.settld.care/legal/privacy-policy.html"
                  >
                    Privacy Policy
                  </Link>
                  .
                </div>
              </FormField>
            </FormStack>

            <FormStack>
              <FormField slightlyMoreThanHalfWidth>
                <Button submit variant="primary" size="large" disabled={busy} fullWidth={true}>
                  Continue
                </Button>
              </FormField>
            </FormStack>
          </Stack>
        </form>

        {/* <div
          className="g-signin2"
          data-onsuccess="(googleUser) => {
            const profile = googleUser.getBasicProfile();
            console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
            console.log('Name: ' + profile.getName());
            console.log('Image URL: ' + profile.getImageUrl());
            console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
          }"
        >
        </div> */}
      </SideImageTemplate>
    </BusyOverlay>
  );
};

const recordErrors = (record: Record) => {
  const errors = {
    password: !record.password
      ? "required"
      : [/[0-9]/, /[a-zA-Z]/, /[^0-9a-zA-Z]/, /......../].every((re) => re.test(record.password))
      ? undefined
      : "Please ensure your password is at least 8 characters in length, and contains a combination of letters, numbers and symbols",
    passwordConfirmation: !record.passwordConfirmation ? "required" : undefined,
  };

  return JSON.parse(JSON.stringify(errors));
};

class ValidationError extends Error {
  errors: { [k: string]: string[] };

  constructor(errors: { [k: string]: string[] }) {
    super("validation error");
    this.errors = errors;
    this.name = "ValidationError";
  }
}

const errorsFromServer = (ve: ValidationError) => {
  const errors = {
    firstName: ve.errors.firstName ? ve.errors.firstName[0] : undefined,
    lastName: ve.errors.lastName ? ve.errors.lastName[0] : undefined,
    emailAddress: ve.errors.emailAddress ? ve.errors.emailAddress[0] : undefined,
    password: ve.errors.password ? ve.errors.password[0] : undefined,
    passwordConfirmation: ve.errors.passwordConfirmation
      ? ve.errors.passwordConfirmation[0]
      : undefined,
  };

  return JSON.parse(JSON.stringify(errors));
};

type ServerResponseRecord = {
  session: {
    caseId: string;
    signature: string;
    token: string;
  };
};

const signup = async (args: ServerRecord): Promise<ServerResponseRecord> => {
  let result;

  try {
    result = await signupRequest(args);
  } catch (error) {
    trackEvent("HTTP error", { request: "Password signup" });
    throw new Error("Error; please try again");
  }

  if (result.error) {
    switch (result.error) {
      case "validation":
        trackEvent("Request error", { request: "Password signup", error: "Validation failed" });
        throw new ValidationError(result.errors);
      case "user_exists":
        trackEvent("Request error", { request: "Password signup", error: "User exists" });
        throw new Error("You have already registered; please log in.");
      default:
        trackEvent("Request error", { request: "Password signup", error: result.error });
        throw new Error(result.error);
    }
  }

  trackPasswordSignup();
  return result.data;
};

type ServerRecord = {
  password: string;
  passwordConfirmation: string;
  captcha: string;
};
