import * as React from "react";
import { Route, Routes, useMatch, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

import { trackEvent } from "@utils/analytics";
import { createDocumentAndUpload, getDocument } from "@api/caseApi";
import monitor from "../../utils/monitoring";
import { apiFetch } from "@api/restApi";
import { Case, CaseStatuses } from "../../types/Case";
import { ExtendedAccount } from "../../types/ExtendedAccount";
import { NotifierProfile } from "../../types/Notifier";
import { NotifierFormData, PersonDetails } from "@customTypes/index";
import urlPaths from "../../urlPaths";

// Import Components
import { earliestUnsubmittedSection, nextSection, pathForSection, Section } from "../../Sections";
import { WithFormTemplate } from "@templates/FormTemplate";
import { NotFoundPage } from "../Error/NotFoundPage";
import { LoadingPage } from "../LoadingPage";
import { ServiceProvider } from "@customTypes/index";
import { Property } from "./AccountForm/PropertyFields";
import { Person } from "./AccountForm/ResponsibleFields";
import { Accounts, isComplete as isAccountsComplete } from "./Accounts";
import {
  DeceasedDetails,
  DeceasedPersistedState,
  deceasedPersistedStateFromForm,
  isComplete as isDeceasedDetailsComplete,
} from "./DeceasedDetails";
import {
  Documents,
  DocumentsPersistedState,
  documentsPersistedStateFromForm,
  isComplete as isDocumentsComplete,
} from "./Documents";
import { isComplete as isKYCComplete } from "./KYC";
import { KYC } from "./KYC/KYC";
import {
  isComplete as isNotifierDetailsComplete,
  NotifierDetails,
  NotifierDetailsPersistedState,
  notifierDetailsPersistedStateFromForm,
} from "./NotifierDetails";
import { Submit } from "./Submit";
import { isWillRequired } from "@src/types/Document";

type NotifierFormState = {
  doesNotExist: boolean;
  error: boolean;
  loading: boolean;
  serviceProviders: Array<ServiceProvider>;
  readonly persons: ReadonlyArray<Person>;
  readonly properties: ReadonlyArray<Property>;
  form: NotifierFormData;
  notifier: NotifierProfile | null;
  caseInfo: null | (Case & { accounts: Array<ExtendedAccount> });
  notifierEmailAddressVerified: boolean;
  source: "express_form" | null;
};

type PersistedState = {
  notifier: NotifierDetailsPersistedState;
  deceased: DeceasedPersistedState;
  documents: DocumentsPersistedState;
};

type PersistedStateProperties =
  | {}
  | NotifierDetailsPersistedState
  | DeceasedPersistedState
  | DocumentsPersistedState;

export const NotifierFormPage: React.FC<{ signature: string | null; caseId: string }> = ({
  signature,
  caseId,
}) => {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    doesNotExist: false,
    error: false,
    missing: false,
    serviceProviders: [],
    persons: [],
    properties: [],
    form: { submittedSections: {}, kycPending: true, submitted: false },
    loading: true,
    notifier: null,
    caseInfo: null,
    notifierEmailAddressVerified: true,
    source: null,
  } as NotifierFormState);

  React.useEffect(() => {
    getCase({ caseId, signature })
      .then((data) => {
        if (!data?.case) return Promise.reject(new Error("Missing data."));

        let {
          case: caseRecord,
          notifierFormState,
          // accounts,
          serviceProviders,
          persons,
          properties,
          form: {
            notifierDetails,
            deceasedDetails,
            caseDocuments,
            executors,
            accounts: formAccounts,
          },
        } = data;

        let { notifier } = caseRecord;

        (caseRecord.accounts || []).forEach((account: any) => {
          if (account.accountEvents) {
            account.accountEvents.sort(
              ({ eventAt: a }: { eventAt: string }, { eventAt: b }: { eventAt: string }) => {
                if (a > b) return -1;
                if (a < b) return 1;
                return 0;
              }
            );
          }
        });

        const n = persons.find((person: Person) => (person.roles || []).includes("notifier"));
        const kycPending = !n || !n.kycCompleted;

        if (caseRecord?.formSubmittedAt) {
          if (caseRecord.status === CaseStatuses.UnderReview) {
            if (kycPending && !caseRecord.bypassKYC) {
              navigate(urlPaths.kyc(), { replace: true });
              return;
            }
          }

          navigate(urlPaths.status(), { replace: true });
          return;
        }

        const form = {
          submittedSections: notifierFormState,
          notifier: notifierDetails,
          deceased: deceasedDetails,
          documents: caseDocuments,
          executors,
          accounts: formAccounts,
          kycPending,
          submitted: !!caseRecord.caseSubmittedAt,
        };

        setState({
          doesNotExist: false,
          loading: false,
          error: false,
          caseInfo: caseRecord,
          serviceProviders,
          persons,
          // accounts,
          properties,
          notifier,
          notifierEmailAddressVerified: notifierEmailAddressVerified(
            persons,
            caseRecord.bypassEmailVerification
          ),
          form,
          source: caseRecord.source,
        });
      })
      .catch((err) => {
        setState((s) => ({ ...s, error: true }));
        console.warn({ err });
      });
  }, [navigate, caseId, signature]);

  if (state.loading) return <LoadingPage />;

  if (state.doesNotExist) return <NotFoundPage />;
  return (
    <ActualNotifierFormPage
      signature={signature}
      caseId={caseId}
      state={state}
      setState={setState}
    />
  );
};

type ActualNotifierFormPageProps = {
  signature: string | null;
  caseId: string;
  state: NotifierFormState;
  setState: any;
};

const ActualNotifierFormPage: React.FC<ActualNotifierFormPageProps> = ({
  caseId,
  signature,
  state: { source, caseInfo, form, serviceProviders, persons, properties },
  setState,
}) => {
  const match = useMatch("/form/*");

  if (!match) throw new Error("Unexpected");

  const navigate = useNavigate();

  const formCompletionState: Record<Section, boolean> = React.useMemo(() => {
    const serviceProvidersMap = serviceProviders.reduce((acc, serviceProvider) => {
      if (serviceProvider.id !== undefined) {
      acc[serviceProvider.id] = serviceProvider;
      }
      return acc;
    }, {} as Record<string, ServiceProvider>);

    const askForProofOfAddress = !!form.accounts?.some(
      (account) => account.companyId && serviceProvidersMap[account.companyId]?.proofOfAddressNeeded
    );
    let askForWillAndProbate = isWillRequired(form?.accounts, form?.notifier, serviceProvidersMap);
    // console.log(askForWillAndProbate);

    return {
      [Section.Accounts]: !!form.submittedSections.accounts && isAccountsComplete(form),
      [Section.Deceased]: !!form.submittedSections.deceased && isDeceasedDetailsComplete(form),
      [Section.Notifier]: !!form.submittedSections.notifier && isNotifierDetailsComplete(form),
      [Section.Documents]:
        !!form.submittedSections.documents &&
        isDocumentsComplete(form, askForProofOfAddress, askForWillAndProbate),
      [Section.KYC]: !!form.submittedSections.kyc && isKYCComplete(form),
      [Section.Submit]: form.submitted,
    };
  }, [form, serviceProviders]);

  const serviceProvidersMap = React.useMemo(() => {
    return serviceProviders.reduce((acc, serviceProvider) => {
      if (serviceProvider.id !== undefined) {
      acc[serviceProvider.id] = serviceProvider;
      }
      return acc;
    }, {} as Record<string, ServiceProvider>);
  }, [serviceProviders]);

  const bypassKYC = React.useMemo(() => {
    if (caseInfo?.bypassKYC) return true;
    return false;
    // if (
    //   form.accounts?.some(
    //     (account: AccountDetails) =>
    //       account.companyId && serviceProvidersMap[account.companyId]?.idDocumentNeeded
    //   )
    // ) {
    //   return false;
    // }

    // return true;
  }, [caseInfo?.bypassKYC]);

  const menuEntries = React.useMemo(() => {
    return formMenuEntries({ kyc: !bypassKYC }).map(({ key, label }) => {
      // @ts-ignore
      const done = !!form.submittedSections[key];

      const error = done && !formCompletionState[key];

      return { key, label, done, error };
    });
  }, [formCompletionState, form, bypassKYC]);

  const [updatingNotifierEmailAddress, setUpdatingNotifierEmailAddress] = React.useState(false);

  const updatePerson = React.useCallback(
    (person: Person) => {
      setState((s: NotifierFormState) => {
        const index = s.persons.findIndex(({ id }: { id: string }) => person.id === id);
        if (index < 0) {
          return {
            ...s,
            persons: s.persons.concat([person]),
          };
        }

        return {
          ...s,
          persons: [...s.persons.slice(0, index), person, ...s.persons.slice(index + 1)],
        };
      });
    },
    [setState]
  );

  const removePerson = React.useCallback(
    (personId: string) => {
      setState((s: NotifierFormState) => {
        const index = s.persons.findIndex(({ id }: { id: string }) => personId === id);
        if (index < 0) {
          return s;
        }

        return {
          ...s,
          persons: [...s.persons.slice(0, index), ...s.persons.slice(index + 1)],
        };
      });
    },
    [setState]
  );

  const updateProperty = React.useCallback(
    (property: Property) => {
      setState((s: NotifierFormState) => {
        const index = s.properties.findIndex(({ id }: { id: string }) => property.id === id);
        if (index < 0) {
          return {
            ...s,
            properties: s.properties.concat([property]),
          };
        }

        return {
          ...s,
          properties: [...s.properties.slice(0, index), property, ...s.properties.slice(index + 1)],
        };
      });
    },
    [setState]
  );

  const updateServiceProvider = React.useCallback(
    (sp: ServiceProvider) => {
      setState((s: NotifierFormState) => {
        const index = s.serviceProviders.findIndex(
          (provider) => sp.id !== undefined && sp.id === provider.id
        );

        if (index < 0) {
          return {
            ...s,
            serviceProviders: s.serviceProviders.concat([sp]),
          };
        }

        return {
          ...s,
          serviceProviders: [
            ...s.serviceProviders.slice(0, index),
            sp,
            ...s.serviceProviders.slice(index + 1),
          ],
        };
      });
    },
    [setState]
  );

  const changeNotifierEmailAddress = React.useCallback(() => {
    setUpdatingNotifierEmailAddress(true);
    navigate(`${match.pathnameBase}${pathForSection(Section.Notifier)}`);
  }, [match.pathnameBase, navigate]);

  const [persistedStates, setPersistedStates] = React.useState<PersistedState>({
    notifier: notifierDetailsPersistedStateFromForm(form),
    deceased: deceasedPersistedStateFromForm(form),
    documents: documentsPersistedStateFromForm(form),
  });

  const persistedStateSetterFor = (
    setPersistedStates: React.Dispatch<React.SetStateAction<PersistedState>>,
    prop: keyof PersistedState
  ) => {
    return (
      newState:
        | PersistedStateProperties
        | ((props: PersistedStateProperties) => PersistedStateProperties)
    ) => {
      setPersistedStates((state: PersistedState) => {
        return {
          ...state,
          [prop]: typeof newState === "function" ? newState(state[prop]) : newState,
        };
      });
    };
  };

  const persistedStateSetters = React.useMemo(() => {
    return {
      notifier: persistedStateSetterFor(setPersistedStates, "notifier"),
      deceased: persistedStateSetterFor(setPersistedStates, "deceased"),
      documents: persistedStateSetterFor(setPersistedStates, "documents"),
    };
  }, [setPersistedStates]);

  // Legacy
  const setActiveSection = React.useCallback(
    (section) => {
      navigate(`${match.pathnameBase}${pathForSection(section)}`);
    },
    [match.pathnameBase, navigate]
  );

  const onSectionClick = React.useCallback(
    (section) => {
      navigate(`${match.pathnameBase}/${section}`);
    },
    [navigate, match.pathnameBase]
  );

  const setForm = React.useCallback(
    (f) => {
      setState((s: any) => {
        const form = typeof f === "function" ? f(s.form) : f;
        return { ...s, form };
      });
    },
    [setState]
  );

  const continueWithoutChanges = React.useCallback(
    (currentSection, doNotAdvance) => {
      if (!doNotAdvance) {
        setActiveSection(nextSection(currentSection, bypassKYC));
      }
    },
    [setActiveSection, bypassKYC]
  );

  const [remoteError, setRemoteError] = React.useState(undefined as string | undefined);
  const [busy, setBusy] = React.useState(false);

  const prepareUpdate = React.useCallback(
    (section: Section, futureForm: NotifierFormData) => {
      setBusy(true);
      setRemoteError(undefined);
      return {
        ...futureForm,
        submittedSections: {
          ...futureForm.submittedSections,
          [section]: true,
        },
      };
    },
    [setBusy, setRemoteError]
  );

  const updateSuccessful = React.useCallback(
    (futureForm: NotifierFormData, section, doNotAdvance) => {
      if (section === Section.Submit) {
        navigate(urlPaths.formRating());
        return;
      }

      setForm(futureForm);
      setBusy(false);
      if (!doNotAdvance) {
        setActiveSection(nextSection(section, bypassKYC));
      }
    },
    [navigate, setBusy, setForm, setActiveSection, bypassKYC]
  );

  const updateFailure = React.useCallback(
    (error: Error) => {
      setBusy(false);
      console.warn({ error });
      setRemoteError("Operation failed. Please try again or contact customer support.");
    },
    [setBusy, setRemoteError]
  );

  const uploadFile = React.useCallback(
    (file: File, filename?: string, tags?: string[]) => {
      setBusy(true);
      setRemoteError(undefined);
      // setState(({ errors: _, ...s }) => s);

      return createDocumentAndUpload(caseId, signature, file, filename, tags);
    },
    [caseId, signature]
  );

  const uploadedFileInfo = React.useCallback(
    (documentId: string) => {
      return getDocument({ caseId, signature, documentId })
        .then((res: any) => {
          if (!res.data) {
            throw new Error(`Missing results for document ${documentId}`);
          }

          return res.data;
        })
        .catch((err: Error) => {
          console.warn(err.message);
          setRemoteError("Failed to download the file you previously uploaded.");
          throw err;
        });
    },
    [caseId, signature]
  );

  const removeRemoteError = React.useCallback(() => {
    setRemoteError(undefined);
  }, [setRemoteError]);
  return (
    <Routes>
      <Route
        path="notifier"
        element={
          <WithFormTemplate
            menuEntries={menuEntries}
            proc={(updateTemplate) => (
              <NotifierDetails
                caseId={caseId}
                signature={signature}
                prepareUpdate={prepareUpdate}
                updateSuccessful={updateSuccessful}
                updateFailure={updateFailure}
                form={form}
                busy={busy}
                continueWithoutChanges={continueWithoutChanges}
                persistedState={persistedStates.notifier}
                setPersistedState={persistedStateSetters.notifier}
                updatePerson={updatePerson}
                updatingNotifierEmailAddress={updatingNotifierEmailAddress}
                setUpdatingNotifierEmailAddress={setUpdatingNotifierEmailAddress}
                onSectionClick={onSectionClick}
                updateTemplate={updateTemplate}
              />
            )}
          />
        }
      />
      <Route
        path="deceased"
        element={
          <WithFormTemplate
            menuEntries={menuEntries}
            proc={(updateTemplate) => (
              <DeceasedDetails
                caseId={caseId}
                signature={signature}
                properties={properties}
                prepareUpdate={prepareUpdate}
                updateSuccessful={updateSuccessful}
                updateFailure={updateFailure}
                form={form}
                busy={busy}
                continueWithoutChanges={continueWithoutChanges}
                remoteError={remoteError}
                persistedState={persistedStates.deceased}
                setPersistedState={persistedStateSetters.deceased}
                onSectionClick={onSectionClick}
                updateTemplate={updateTemplate}
                removeRemoteError={removeRemoteError}
                updateProperty={updateProperty}
              />
            )}
          />
        }
      />
      <Route
        path="accounts/*"
        element={
          <WithFormTemplate
            menuEntries={menuEntries}
            proc={(updateTemplate) => (
              <Accounts
                caseId={caseId}
                signature={signature}
                serviceProviders={serviceProviders}
                persons={persons}
                properties={properties}
                prepareUpdate={prepareUpdate}
                updateSuccessful={updateSuccessful}
                updateFailure={updateFailure}
                form={form}
                busy={busy}
                setBusy={setBusy}
                continueWithoutChanges={continueWithoutChanges}
                remoteError={remoteError}
                updateServiceProvider={updateServiceProvider}
                updatePerson={updatePerson}
                updateProperty={updateProperty}
                uploadedFileInfo={uploadedFileInfo}
                onSectionClick={onSectionClick}
                updateTemplate={updateTemplate}
                removeRemoteError={removeRemoteError}
              />
            )}
          />
        }
      />
      <Route
        path="documents"
        element={
          <WithFormTemplate
            menuEntries={menuEntries}
            proc={(updateTemplate) => (
              <Documents
                caseId={caseId}
                signature={signature}
                form={form}
                serviceProvidersMap={serviceProvidersMap}
                busy={busy}
                persistedState={persistedStates.documents}
                remoteError={remoteError}
                continueWithoutChanges={continueWithoutChanges}
                uploadFile={uploadFile}
                uploadedFileInfo={uploadedFileInfo}
                setPersistedState={persistedStateSetters.documents}
                prepareUpdate={prepareUpdate}
                onSectionClick={onSectionClick}
                updateTemplate={updateTemplate}
                removeRemoteError={removeRemoteError}
                updateSuccessful={updateSuccessful}
                updateFailure={updateFailure}
              />
            )}
          />
        }
      />
      <Route
        path="kyc"
        element={
          <WithFormTemplate
            menuEntries={menuEntries}
            proc={(updateTemplate) => (
              <KYC
                form={form}
                continueWithoutChanges={continueWithoutChanges}
                onSectionClick={onSectionClick}
                updateTemplate={updateTemplate}
              />
            )}
          />
        }
      />
      <Route
        path="submit"
        element={
          <WithFormTemplate
            menuEntries={menuEntries}
            proc={(updateTemplate) => (
              <Submit
                caseId={caseId}
                signature={signature}
                formCompletionState={formCompletionState}
                serviceProviders={serviceProviders}
                persons={persons}
                properties={properties}
                prepareUpdate={prepareUpdate}
                updateSuccessful={updateSuccessful}
                updateFailure={updateFailure}
                form={form}
                busy={busy}
                continueWithoutChanges={continueWithoutChanges}
                remoteError={remoteError}
                changeNotifierEmailAddress={changeNotifierEmailAddress}
                onSectionClick={onSectionClick}
                updateTemplate={updateTemplate}
                removeRemoteError={removeRemoteError}
                uploadedFileInfo={uploadedFileInfo}
                bypassKYC={bypassKYC}
              />
            )}
          />
        }
      />
      <Route
        path="*"
        element={
          <WithFormTemplate
            menuEntries={menuEntries}
            proc={(updateTemplate) => (
              <Router
                busy={busy}
                bypassKYC={bypassKYC}
                form={form}
                match={match}
                onSectionClick={onSectionClick}
                setActiveSection={setActiveSection}
                source={source}
                updateTemplate={updateTemplate}
              />
            )}
          />
        }
      />
    </Routes>
  );
};

const Router = ({
  busy,
  bypassKYC,
  form,
  match,
  onSectionClick,
  setActiveSection,
  source,
  updateTemplate,
}: {
  busy: any;
  bypassKYC: boolean;
  form: any;
  match: any;
  onSectionClick: any;
  setActiveSection: any;
  source: any;
  updateTemplate: any;
}) => {
  const isRoot = match.params["*"] === "";

  const onNext = React.useCallback(() => {
    setActiveSection(Section.Accounts);
  }, [setActiveSection]);

  const onSave = React.useCallback(() => { }, []);

  React.useEffect(() => {
    if (isRoot) {
      const section =
        source === "express_form" && form.accounts.length <= 1
          ? Section.Accounts
          : earliestUnsubmittedSection(form.submittedSections, bypassKYC);
      setTimeout(() => {
        setActiveSection(section, form);
      }, 10);
    }
  }, [source, isRoot, form, setActiveSection, bypassKYC]);

  React.useEffect(() => {
    updateTemplate({
      busy,
      currentSection: undefined,
      saveLabel: null,
      onNext,
      onSectionClick,
    });
  }, [busy, onNext, onSave, onSectionClick, updateTemplate]);

  if (isRoot) {
    return null;
  }

  return <Typography variant="body1">Use the menu to navigate through the form.</Typography>;
};

const getCase = async (args: { caseId: string; signature: string | null }) => {
  let result;

  try {
    result = await getCaseRequest(args);
  } catch (error) {
    throw error;
  }

  if (result.error) {
    switch (result.error) {
      default:
        trackEvent("Request error", { request: "Get case for form", error: result.error });
        throw new Error("Unexpected error; please contact customer support");
    }
  }

  trackEvent("Get case for form");

  return result.data;
};

const getCaseRequest = async (args: { caseId: string; signature: string | null }) => {
  const { caseId, signature } = args;

  let result;
  try {
    result = await apiFetch({
      path: `/notifier/cases/${caseId}`,
      search: !signature ? {} : { caseId, sig: signature },
    });
  } catch (error) {
    monitor.logError({
      event: "GET /notifier/cases/{caseId}",
      args,
      error,
    });
    throw error;
  }

  return result;
};

const notifierEmailAddressVerified = (persons: PersonDetails[], bypass?: boolean) => {
  if (persons) {
    const notifier = persons.find((p) => p.roles?.includes("notifier"));
    return notifier?.emailAddressVerified || bypass || false;
  }
  return bypass || false;
};

export const formMenuEntries = ({ kyc }: { kyc: boolean }) => {
  if (kyc) {
    return formMenuEntriesWithKYC;
  }

  return formMenuEntriesWithoutKYC;
};

export const formMenuEntriesWithoutKYC: Array<{ key: Section; label: string }> = [
  { key: Section.Accounts, label: "Add accounts" },
  { key: Section.Deceased, label: "Person who died" },
  { key: Section.Notifier, label: "Your Details" },
  { key: Section.Documents, label: "Documents" },
  { key: Section.Submit, label: "Review & submit" },
];

export const formMenuEntriesWithKYC: Array<{ key: Section; label: string }> = [
  { key: Section.Accounts, label: "Add accounts" },
  { key: Section.Deceased, label: "Person who died" },
  { key: Section.Notifier, label: "Your Details" },
  { key: Section.Documents, label: "Documents" },
  { key: Section.KYC, label: "Fraud Protection" },
  { key: Section.Submit, label: "Review & submit" },
];
