import { Connection } from "./Relay";
import { Identifiable } from "./Identifiable";
import { assertUnreachable } from "../utils/Functions";

export interface ServiceProvider {
  readonly id?: string;
  readonly approved?: boolean;
  readonly companyName: string;
  readonly serviceProviderType: CompanyType;
  readonly slug?: string;
  readonly isConnected?: boolean;
  readonly cases?: Connection<Identifiable>;
  readonly inPartnership?: boolean;
  readonly customForm?: string;
  readonly deathCertificateNeeded?: boolean;
  readonly willNeeded?: boolean;
  readonly logoUrl?: string;
  readonly expressFormLandingCopy?: string;
  readonly idDocumentNeeded?: boolean;
  readonly intestacyFlow?: boolean;
  readonly proofOfAddressNeeded?: boolean;
  readonly grantOfRepresentationNeeded?: boolean;
  readonly faceSimilarityNeeded?: boolean;
}

export enum CompanyType {
  Banking = "banking",
  Betting = "betting",
  Broadband = "broadband",
  BuildingSociety = "building_society",
  Charities = "charities",
  CreditCards = "credit_cards",
  Ecommerce = "ecommerce",
  Email = "email",
  Energy = "energy",
  Insurance = "insurance",
  Investments = "investments",
  Loans = "loans",
  LoyaltyCards = "loyalty_cards",
  Magazines = "magazines",
  Mobile = "mobile",
  Mortgage = "mortgage",
  Other = "other",
  Pension = "pension",
  SaaS = "saas",
  Satellite = "satellite",
  SocialMedia = "social_media",
  Streaming = "streaming",
  Subscriptions = "subscriptions",
  Telecoms = "telecoms",
  TV = "tv",
  Water = "water",
  affiliatePartner = "affiliate_partner",
}

export const readableCompanyType = (
  t?: CompanyType,
  fallback?: string
): string => {
  switch (t) {
    case CompanyType.Banking:
      return "Banking";
    case CompanyType.Betting:
      return "Betting";
    case CompanyType.Broadband:
      return "Broadband";
    case CompanyType.BuildingSociety:
      return "Building Society";
    case CompanyType.Charities:
      return "Charities";
    case CompanyType.CreditCards:
      return "Credit cards";
    case CompanyType.Ecommerce:
      return "E-commerce";
    case CompanyType.Email:
      return "Email";
    case CompanyType.Energy:
      return "Energy";
    case CompanyType.Insurance:
      return "Insurance";
    case CompanyType.Investments:
      return "Investments";
    case CompanyType.Loans:
      return "Loans";
    case CompanyType.LoyaltyCards:
      return "Loyalty cards";
    case CompanyType.Magazines:
      return "Magazines";
    case CompanyType.Mobile:
      return "Mobile";
    case CompanyType.Mortgage:
      return "Mortgage";
    case CompanyType.Pension:
      return "Pension";
    case CompanyType.SaaS:
      return "SaaS";
    case CompanyType.Satellite:
      return "Satellite";
    case CompanyType.SocialMedia:
      return "Social media";
    case CompanyType.Streaming:
      return "Streaming";
    case CompanyType.Subscriptions:
      return "Subscriptions";
    case CompanyType.Telecoms:
      return "Telecommunications";
    case CompanyType.TV:
      return "Television";
    case CompanyType.Water:
      return "Water";
    case CompanyType.Other:
      return "Other";
    case CompanyType.affiliatePartner:
      return "Partner";
    case undefined:
    case null:
      return fallback || "Unknown";
  }
  assertUnreachable(t);
};
