import * as React from "react";

import { Stack, Typography } from "@mui/material";

import { Header } from "../../components/atoms/Header";
import { HR } from "../../components/atoms/HR";
import { colors } from "../../styles/constants";
import { CompanyType } from "@customTypes/index";

export type ExpressFormHeaderProps = {
  readonly serviceProvider: {
    readonly inPartnership: boolean;
    readonly companyName: string;
    readonly expressFormLandingCopy?: string;
    readonly serviceProviderType: CompanyType;
  };
};

export const ExpressFormHeader: React.FC<ExpressFormHeaderProps> = ({ serviceProvider }) => {
  return (
    <Stack rowGap={4}>
      <Stack rowGap={2}>
        <div></div>

        <Header level={1} color={colors.primaryText}>
          Notify {serviceProvider.companyName} of a bereavement
        </Header>

        {serviceProvider.expressFormLandingCopy ? (
          <Stack
            className="embedded-html"
            rowGap={2}
            dangerouslySetInnerHTML={{ __html: serviceProvider.expressFormLandingCopy }}
          ></Stack>
        ) : (
          <>
            <Typography variant="body1">
              {serviceProvider.inPartnership
                ? `${serviceProvider.companyName} has partnered with Settld to make `
                : "Settld makes "}
              the process of closing or transferring a bereavement{" "}
              {serviceProvider.serviceProviderType === CompanyType.Insurance ? "policy" : "account"}{" "}
              as simple as possible. Please fill in the details below, and our Customer Service team
              will act on your request.
            </Typography>

            <Typography variant="body1">
              You can also use this form to notify all other companies of the death in one go
              (banks, insurers, utilities etc), and to receive updates in one, secure place.
            </Typography>
          </>
        )}
      </Stack>

      <HR />
    </Stack>
  );
};
