import React from "react";
import { LinkButton } from "@atoms/LinkButton";

export const ViewMoreContainer: React.FC = ({ children }) => {
  const [expanded, setExpanded] = React.useState(false);

  const toggle = React.useCallback(() => setExpanded((expanded) => !expanded), []);

  if (!expanded) {
    return (
      <p>
        <LinkButton onClick={toggle}>View more</LinkButton>
      </p>
    );
  }

  return (
    <>
      {children}
      <div>
        <LinkButton onClick={toggle}>View less</LinkButton>
      </div>
    </>
  );
};
