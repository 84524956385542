import * as React from "react";

import { trackEvent } from "@utils/analytics";
import { ErrorPane } from "../components/organisms/ErrorPane";
import { CentredTemplate } from "@templates/CentredTemplate";

const firstTimeout = 1000;
const secondTimeout = 4000;
const thirdTimeout = 6000;

export const LoadingPage = () => {
  const track = React.useCallback((start: number) => {
    trackEvent("Slow loading", { time: Math.round(Date.now() - start / 1000) });
  }, []);

  return <LoadingPageView track={track} />;
};

export type LoadingPageViewProps = {
  track?: (start: number) => void;
};

export const LoadingPageView: React.FC<LoadingPageViewProps> = ({ track }) => {
  const [{ message, description }, setState] = React.useState({
    message: "",
    description: null,
  } as {
    message: string;
    description?: React.ReactElement | string | null;
  });

  React.useEffect(() => {
    const start = Date.now();

    let timeout: NodeJS.Timeout | null = setTimeout(() => {
      track && track(start);
      setState({ message: "Loading..." });

      timeout = setTimeout(() => {
        track && track(start);
        setState({
          message: "Loading...",
          description:
            "There seems to be a problem loading your data; we are still working on it....",
        });

        timeout = setTimeout(() => {
          track && track(start);
          setState({
            message: "Loading failed",
            description: (
              <span>
                We didn't manage to load your data; please reload this page to retry.
                <br />
                If this problem persists, please contact us at{" "}
                <a href="mailto:support@settld.care">support@settld.care</a>
              </span>
            ),
          });
          timeout = null;
        }, thirdTimeout);
      }, secondTimeout);
    }, firstTimeout);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [track]);

  return (
    <CentredTemplate headerType={"logo"}>
      <ErrorPane message={message} description={description} />
    </CentredTemplate>
  );
};
