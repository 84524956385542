import * as React from "react";
import { useNavigate } from "react-router-dom";

import { LocalSession, localSession, setLocalCaseId } from "@utils/session";
import urlPaths from "../urlPaths";
import { LoadingPage } from "./LoadingPage";
import { checkFormStatus, checkOnlineSession } from "@api/sessionApi";

export const RootPage = () => {
  const navigate = useNavigate();

  const moveAuthenticatedUserToNextPage = React.useCallback(
    (formStatus) => {
      const nextPage =
        formStatus.source === "express_form" || formStatus.formSubmittedAt
          ? urlPaths.status()
          : formStatus.notifierFormState.permission
          ? urlPaths.form()
          : formStatus.notifierFormState.requirements
          ? urlPaths.permission()
          : urlPaths.onBoardingAdditionalServices();
      navigate(nextPage, { replace: true });
    },
    [navigate]
  );

  React.useEffect(() => {
    const session = localSession();
    if (session) {
      checkFormStatus(session).then(
        (formStatus) => {
          moveAuthenticatedUserToNextPage(formStatus);
        },
        (_error) => {
          // TODO: do not ignore error
          navigate(urlPaths.signup(), { replace: true });
        }
      );

      return;
    }

    checkOnlineSession().then(({ session, formStatus }: any) => {
      if (!session) {
        navigate(urlPaths.signup(), { replace: true });
        return;
      }

      setLocalCaseId(session.caseId);

      moveAuthenticatedUserToNextPage(formStatus);
    });
  }, [moveAuthenticatedUserToNextPage, navigate]);

  return <LoadingPage />;
};
