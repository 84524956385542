import * as React from "react";

import { makeStyles } from "@mui/styles";

import { Header } from "@atoms/Header";
import { CentredTemplate } from "@templates/CentredTemplate";
import { SettldContactInfo } from "@src/utils/Constants";

export type ForgotPasswordDoneProps = { emailAddress: string };

export const ForgotPasswordDone: React.FC<ForgotPasswordDoneProps> = ({ emailAddress }) => {
  const classes = useStyles();

  return (
    <CentredTemplate headerType="signup">
      <Header>Email sent</Header>

      <p>
        We have sent an email to <span className={classes.emailAddress}>{emailAddress}</span>.
        Please click on the link in the email to reset your password.
      </p>

      <p>
        If you need help logging into your account, please email us at{" "}
        <span className={classes.emailAddress}>
          <b>support@settld.care</b>
        </span>{" "}
        or call the Settld team on <b>{SettldContactInfo.Telephone}</b> (office hours are 9am-5pm Monday to Friday).
      </p>

      <div style={{ paddingTop: "15px" }}></div>
    </CentredTemplate>
  );
};

const useStyles = makeStyles({
  emailAddress: {
    fontWeight: "bold",
  },
});
