import * as React from "react";

export const useDialog = ({ visible: visibleParam }: { visible: boolean } = { visible: false }) => {
  const [dialogVisible, setDialogVisible] = React.useState(visibleParam);

  const showDialog = React.useCallback(() => {
    setDialogVisible(true);
  }, [setDialogVisible]);

  const hideDialog = React.useCallback(() => {
    setDialogVisible(false);
  }, [setDialogVisible]);

  return {
    dialogVisible,
    showDialog,
    hideDialog,
  };
};
