import { identity } from "lodash";
import * as React from "react";

import { Box, Paper, Toolbar } from "@mui/material";

import { FormNextPaneMobile, FormNextPaneMobileContent } from "@molecules/FormNextPaneMobile";
import { FormMenuMobile } from "@molecules/FormMenuMobile";
import { Container } from "@mui/system";
import { BusyOverlay } from "@atoms/BusyOverlay";
import { FormMenuEntry } from "@customTypes/index";

export type FormTemplateMobileProps = {
  menuEntries: FormMenuEntry[];
  busy?: boolean;
  currentSection?: string;
  error?: string;
  nextLabel?: string;
  saveLabel?: string | null;
  children: React.ReactNode;
  onNext?: () => void;
  onSave?: () => void;
  onSectionClick: (section: string) => void;
};

export const FormTemplateMobile: React.FC<FormTemplateMobileProps> = ({
  menuEntries,
  busy,
  currentSection,
  nextLabel,
  saveLabel,
  children,
  onNext,
  onSave,
  onSectionClick,
}) => {
  return (
    <BusyOverlay hidden={!busy}>
      <Box sx={{ height: "100vh" }}>
        <FormMenuMobile
          currentKey={currentSection}
          entries={menuEntries}
          onSelect={onSectionClick}
        />

        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100%" }}>
          {/* Space for the toolbar */}
          <Toolbar />

          <Box sx={{ flex: 1 }}>
            <Container sx={{ paddingTop: "24px", paddingBottom: "24px" }}>
              <form autoComplete="off" onSubmit={onNext} className="extra-spaced no-side-padding">
                {children}
              </form>
            </Container>
          </Box>

          {/* Space for the next pane */}
          <Paper>
            <FormNextPaneMobileContent busy={busy} onSave={identity} />
          </Paper>
        </Box>

        <FormNextPaneMobile
          busy={busy}
          nextLabel={nextLabel}
          saveLabel={saveLabel}
          onNext={onNext}
          onSave={onSave}
        />
      </Box>
    </BusyOverlay>
  );
};
