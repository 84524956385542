import { AdditionalHelpDetails } from "@src/types/NotifierFormData";
import * as React from "react";

type SectionKey =
  | "case"
  | "nok"
  | "executor"
  | "account"
  | "documents"
  | "kyc"
  | "notifier"
  | "additionalServices"
  | "ui_info"
  | "dashboard_info";

export const getExpressData = () => {
  const str = sessionStorage.getItem("express-form-data");
  try {
    return str && JSON.parse(str);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const putExpressData = (data: any) => {
  sessionStorage.setItem("express-form-data", JSON.stringify(data));
};

export const getExpressSectionsData = () => {
  const data = getExpressData();
  return data && data.sections;
};

export const getExpressSectionDataByKey = (key: SectionKey) => {
  const data = getExpressData();
  return data && data.sections && data.sections[key];
};

export const putExpressSectionDataByKey = (key: SectionKey, content: any) => {
  const data = getExpressData() || {};
  data.sections = data.sections || {};
  data.sections[key] = content;
  putExpressData(data);
};

export const clearExpressSectionDataByKey = (key: SectionKey) => {
  const data = getExpressData() || {};
  data.sections = data.sections || {};
  data.sections[key] = undefined;
  putExpressData(data);
};

export const putExpressAdditionalServicesData = (content: AdditionalHelpDetails) => {
  putExpressSectionDataByKey("additionalServices", content);
};

export const getExpressSectionSourceDataByKey = (key: SectionKey) => {
  const data = getExpressSectionDataByKey(key);
  return data && data.source;
};

export const getExpressSectionTargetDataByKey = (key: SectionKey) => {
  const data = getExpressSectionDataByKey(key);
  return data && data.target;
};

export const setExpressSubmitted = (formUrl: string) => {
  const data = getExpressData() || {};
  data.submitted = true;
  data.formUrl = formUrl;
  putExpressData(data);
};

export const useExpressSectionDataByKey = (key: SectionKey) => {
  const [{ data, loaded }, setState] = React.useState({ data: undefined as any, loaded: false });

  const setData = React.useCallback(
    (data: any) => {
      setState({ data, loaded: true });
    },
    [setState]
  );

  React.useEffect(() => {
    const data = getExpressSectionSourceDataByKey(key);

    if (!data) {
      setData(undefined);
      return;
    }

    setData(data);
  }, [key, setData]);

  const saveData = React.useCallback(
    (data: any) => {
      putExpressSectionDataByKey(key, data);
    },
    [key]
  );

  return { data, loaded, setData, saveData };
};
