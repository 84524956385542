import * as React from "react";

import { Stack } from "@mui/material";

import { Document } from "../../types/Document";
import { Header } from "../../components/atoms/Header";
import { AccountForm } from "../NotifierForm/AccountForm";
import { colors } from "../../styles/constants";
import { ExpressFormHeader } from "./ExpressFormHeader";
import { UpdateExpressFormTemplateProc } from "@templates/ExpressFormTemplate";
import { identity } from "lodash";
import { useExpressSectionDataByKey } from "./express-data";
import { CompanyType } from "@customTypes/index";
import { createDocumentAndUpload } from "./createDocumentAndUpload";
import { insideIframe } from "../../utils/Functions";

const currentSection = "account";

export type AccountDetailsProps = {
  readonly serviceProvider: {
    readonly inPartnership: boolean;
    readonly slug: string;
    readonly companyName: string;
    readonly expressFormLandingCopy?: string;
    readonly serviceProviderType: CompanyType;
  };
  readonly next: () => void;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
  readonly onSectionClick: (section: string) => void;
  readonly updateTemplate: UpdateExpressFormTemplateProc;
};

export const AccountDetails: React.FunctionComponent<AccountDetailsProps> = ({
  serviceProvider,
  next,
  uploadedFileInfo,
  updateTemplate,
  onSectionClick,
}) => {
  const { data, loaded, saveData } = useExpressSectionDataByKey(currentSection);

  const updateTemplateDelegate = React.useCallback(
    ({ onNext }) => {
      updateTemplate({
        busy: false,
        currentSection,
        nextLabel: "Next",
        saveLabel: null,
        onNext,
        onSectionClick,
      });
    },
    [onSectionClick, updateTemplate]
  );

  const saveRecord = React.useCallback(
    (data: any) => {
      saveData(data);
      if (data.target) {
        next();
      }
    },
    [next, saveData]
  );

  if (!loaded) {
    return null;
  }

  return (
    <Stack rowGap={4}>
      {insideIframe ? null : <ExpressFormHeader serviceProvider={serviceProvider} />}
      <Header level={2} color={colors.accentTeal}>
        {serviceProvider.serviceProviderType === CompanyType.Insurance ? "Policy" : "Account"}{" "}
        details for the person who died
      </Header>

      <AccountForm
        caseId={""} // TODO: remove
        signature={""} // TODO: remove
        serviceProvider={{ id: "id", ...serviceProvider }}
        serviceProviders={[]}
        properties={[]}
        // @ts-ignore
        persons={[{ id: "notifier", title: "", firstName: "Myself", lastName: "" }]}
        setBusy={identity}
        onAccountUpdated={identity} // TODO
        uploadedFileInfo={uploadedFileInfo}
        createDocumentAndUpload={createDocumentAndUpload}
        updateTemplate={updateTemplateDelegate}
        savedRecord={data}
        saveRecord={saveRecord}
        askForDeceasedDetails={true}
      />
    </Stack>
  );
};
