import * as React from "react";

import { cleanedErrors } from "@utils/Functions";
import { AccountRecordPropertySpec } from "@api/online-api";
import {
  Errors as HandOverDateErrors,
  errorsOf as handOverDateErrorsOf,
  HandOverDateField,
  propertyFieldsFromRecord as handOverDatePropertyFieldsFromRecord,
  Record as HandOverDateRecord,
} from "./HandOverDateField";
import {
  Errors as LastOccupiedDateErrors,
  errorsOf as lastOccupiedDateErrorsOf,
  LastOccupiedDateField,
  propertyFieldsFromRecord as lastOccupiedDatePropertyFieldsFromRecord,
  Record as LastOccupiedDateRecord,
} from "./LastOccupiedDateField";
import {
  Errors as OwnershipStatusErrors,
  errorsOf as ownershipStatusErrorsOf,
  infoAvailable as ownershipStatusInfoAvailable,
  OwnershipStatusField,
  propertyFieldsFromRecord as ownershipStatusPropertyFieldsFromRecord,
  Record as OwnershipStatusRecord,
} from "./OwnershipStatusField";
import { Property } from "@src/types/property";

export type Record = HandOverDateRecord & LastOccupiedDateRecord & OwnershipStatusRecord;

export type Errors = HandOverDateErrors & LastOccupiedDateErrors & OwnershipStatusErrors;

export const errorsOf: (r?: Record) => Errors = (r) => {
  return cleanedErrors({
    ...handOverDateErrorsOf(r),
    ...lastOccupiedDateErrorsOf(r),
    ...ownershipStatusErrorsOf(r),
  });
};

export const newOccupierInfoAvailable = (property: Property) => {
  return ownershipStatusInfoAvailable(property);
};

export const newOccupierPropertyFieldsFromRecord = (
  record?: Record
): Partial<AccountRecordPropertySpec> => {
  if (!record) {
    return {};
  }

  return {
    ...handOverDatePropertyFieldsFromRecord(record),
    ...lastOccupiedDatePropertyFieldsFromRecord(record),
    ...ownershipStatusPropertyFieldsFromRecord(record),
  };
};

export const NewOccupierPropertyFields: React.FC<{
  record: Record;
  update: any;
  errors: any;
  serviceProviderId?: string;
}> = ({ record, update, errors, serviceProviderId }) => {
  return (
    <>
      <LastOccupiedDateField
        record={record}
        update={update}
        errors={errors}
        serviceProviderId={serviceProviderId}
      />
      <OwnershipStatusField
        record={record}
        update={update}
        errors={errors}
        serviceProviderId={serviceProviderId}
      />
      <HandOverDateField record={record} update={update} errors={errors} />
    </>
  );
};
