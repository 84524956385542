import * as React from "react";

import { ForgotPasswordDone } from "./ForgotPasswordDone";
import { ForgotPasswordForm } from "./ForgotPasswordForm";

export const ForgotPasswordPage: React.FC = () => {
  const [emailAddress, setEmailAddress] = React.useState(null as null | string);

  if (emailAddress) {
    return <ForgotPasswordDone emailAddress={emailAddress} />;
  }

  return <ForgotPasswordForm done={setEmailAddress} />;
};
