import { makeStyles } from "@mui/styles";
import * as React from "react";

import { Account } from "@src/types";
import { CompanyType } from "@src/types";
import { GreyPane } from "@molecules/GreyPane";
import { BankingAccountDetails } from "./BankingAccountDetails";
import { DefaultAccountDetails } from "./DefaultAccountDetails";
import { InsuranceAccountDetails } from "./InsuranceAccountDetails";

export const RequestDetails: React.FC<{ readonly account: Account }> = ({ account }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <GreyPane title="Request details">
        {(() => {
          switch (account.serviceProvider?.serviceProviderType) {
            case CompanyType.Banking:
              return <BankingAccountDetails account={account} />;

            case CompanyType.Insurance:
              return <InsuranceAccountDetails account={account} />;

            default:
              return <DefaultAccountDetails account={account} />;
          }
        })()}
      </GreyPane>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "400px",
    backgroundColor: "#ecf2f3",
    borderRadius: "4px",
  },
}));
