import * as React from "react";

import { AccountDetails, YesNo, } from "@src/types";
import { Person, Responsible } from "./Responsible";
import { FieldRow, FieldsTable } from "@molecules/FieldsTable";
import { readableAccountNextSteps, readableAccountType } from "@src/utils/accounts";

export type BankingAccountProps = {
  readonly serviceProvider: { customForm?: string };
  readonly persons: readonly Person[];
  readonly account: AccountDetails;
};

export const BankingAccount: React.FC<BankingAccountProps> = ({
  serviceProvider,
  persons,
  account: {
    companyType,
    accountNumber,
    sortCode,
    holderNumber,
    accountType,
    accountTypeOther,
    nextSteps,
    responsibleId,
    needsTaxCertificate,
    jointClaim,
  },
}) => {
  const customForm = serviceProvider?.customForm || "";

  return (
    <FieldsTable>
      <FieldRow label="Account number" value={accountNumber} />

      <FieldRow label="Sort code" value={sortCode} />

      <FieldRow
        label={customForm === "monzo" ? "Customer email or phone number" : "Customer reference"}
        value={holderNumber}
      />

      <FieldRow label="Account type" value={readableAccountType(accountType, accountTypeOther)} />

      <FieldRow label="Joint account?" value={jointClaim === YesNo.Yes ? "Yes" : "No"} />

      <FieldRow label="Needs tax certificate?" value={needsTaxCertificate ? "Yes" : "No"} />

      <FieldRow label="Next step" value={readableAccountNextSteps(nextSteps, companyType)} />

      <FieldRow
        label="Responsible"
        value={<Responsible persons={persons} responsibleId={responsibleId} />}
      />
    </FieldsTable>
  );
};
