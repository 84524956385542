import * as React from "react";

import { makeStyles } from "@mui/styles";

import { Document } from "@customTypes/Document";
import { PlusButton } from "../../components/atoms/AddFileButton";
import { Fieldset } from "@molecules/index";
import { DocumentLink } from "./DocumentLink";
import { FileUploadDialog } from "./FileUpload";
import { useDialog } from "@src/contexts/useDialog";
import { DashboardInfoContext } from "@src/store/DashboardProvider";
import { Stack, Card, CardContent, Typography } from "@mui/material";
import { getMissingDocuments, MissingDocument } from "./MissingDocument";
export type KeyDocumentsProps = { caseRecord: any };

export const KeyDocuments: React.FC<KeyDocumentsProps> = ({ caseRecord }) => {
  const [uploadedDocuments, setUploadedDocuments] = React.useState<Document[]>([]);

  const documents = [
    ...(caseRecord.notifier.attachments || []).map(({ document }: any) => ({
      ...document,
      tags: ["proof_of_address"],
    })),
    ...caseRecord.attachments.map(({ document }: any) => document),
    ...uploadedDocuments,
  ];
  return <KeyDocumentsView documents={documents} />;
};

export type KeyDocumentsViewProps = {
  documents: Document[];
};

export const KeyDocumentsView: React.FC<KeyDocumentsViewProps> = ({ documents }) => {
  const classes = useStyles();
  const { dialogVisible, showDialog, hideDialog } = useDialog({ visible: false });

  return (
    <div className={classes.relativeContainer}>
      <Fieldset title="Key documents">
        <DocumentList documents={documents} />
      </Fieldset>

      <div className={classes.addButtonContainer}>
        <PlusButton onClick={showDialog} />
        <FileUploadDialog dialogVisible={dialogVisible} hideDialog={hideDialog} />
      </div>
    </div>
  );
};

export const DocumentList: React.FC<{ documents: Document[] }> = ({ documents }) => {
  let missingDocuments = [];
  const { state: dashBoardState } = React.useContext(DashboardInfoContext);
  missingDocuments = getMissingDocuments(dashBoardState);
  // bad data types all around bs
  documents.sort((a, b) => {
    return (
      Date.parse(b.uploadedAt as unknown as string) - Date.parse(a.uploadedAt as unknown as string)
    );
  });

  return (
    <Stack spacing={2}>
      {missingDocuments.map((doc) => (
        <MissingDocument key={doc.filename} {...doc} />
      ))}

      {documents.length === 0 && (
        <Typography variant="body2" color="textSecondary">
          <i>No documents uploaded yet</i>
        </Typography>
      )}

      {documents.map((document) => (
        <div key={document.id} style={{ marginTop: "1rem" }}>
          <DocumentLink document={document} />
        </div>
      ))}
    </Stack>
  );
};

const useStyles = makeStyles({
  relativeContainer: {
    position: "relative",
  },

  addButtonContainer: {
    position: "absolute",
    top: "-2px",
    right: "20px",
  },

  ul: {
    paddingLeft: 0,
    listStylePosition: "inside",
  },
});
