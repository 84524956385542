import * as React from "react";

import { makeStyles } from "@mui/styles";

import { usePageViewEventTracker } from "@utils/analytics";
import { colors } from "../../styles/constants";
import { Button } from "../../components/atoms/Button";
import { Sponsors } from "../../components/organisms/Sponsors";
import { SimpleTemplate } from "@templates/SimpleTemplate";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { fetchData, saveData } from "@api/openBankingApi";

type AccountInfo = {
  displayName: string;
  transactions: Transaction[];
  directDebits: DirectDebit[];
  standingOrders: StandingOrder[];
};

type Transaction = {
  description: string;
  type: string;
  category: string;
  amount: number;
};

type DirectDebit = {
  status: string;
  name: string;
};

type StandingOrder = {
  status: string;
  payee: string;
  reference: string;
};

export type OpenBankingCallbackPageProps = {};

export const OpenBankingCallbackPage: React.FC<OpenBankingCallbackPageProps> = () => {
  usePageViewEventTracker("Open Banking callback");

  const navigate = useNavigate();

  const [accounts, setAccounts] = React.useState(null as null | Array<any>);
  const [error, setError] = React.useState(null as null | string);

  const save = React.useCallback(() => {
    saveData({ accounts }).then(
      () => {
        navigate("/account-search/done", { replace: true });
      },
      (error) => {
        console.warn(error);
        setError("Not saved");
      }
    );
  }, [accounts, navigate]);

  React.useEffect(() => {
    fetchData().then(({ data }) => {
      setAccounts(data);
    }, console.warn);
  }, []);

  const removeErrorMessage = React.useCallback(() => {
    setError(null);
  }, [setError]);

  return (
    <OpenBankingCallbackPageView
      accounts={accounts}
      error={error}
      removeErrorMessage={removeErrorMessage}
      save={save}
    />
  );
};

export type OpenBankingCallbackPageViewProps = {
  accounts: AccountInfo[] | null;
  error: string | null;
  removeErrorMessage: () => void;
  save: () => void;
};

export const OpenBankingCallbackPageView: React.FC<OpenBankingCallbackPageViewProps> = ({
  accounts,
  error,
  removeErrorMessage,
  save,
}) => {
  const classes = useStyles();

  return (
    <SimpleTemplate
      busy={!accounts}
      headerType="logo"
      errorMessage={
        !error
          ? undefined
          : "Error sharing account information; please try again, or contact customer support."
      }
      removeErrorMessage={removeErrorMessage}
    >
      <div className={classes.textContainer}>
        <Stack spacing={4}>
          <div>
            <p>
              Thank you. The transactions, direct debits and standing orders associated with this
              bank account have now been received.
            </p>

            <p>
              Please click the button below to share this information with Settld. We will use the
              data you see here, to notify all recognised organisations.
            </p>

            {!accounts ? null : accounts.length === 0 ? (
              <p>No information available</p>
            ) : (
              <Button variant="primary" size="large" onClick={save}>
                Share Now
              </Button>
            )}
          </div>

          <Sponsors />
        </Stack>
      </div>
    </SimpleTemplate>
  );
};

const Accounts = ({ accounts }: { accounts: AccountInfo[] }) => {
  if (!accounts) {
    return null;
  }

  if (accounts.length === 0) {
    return (
      <div>
        <h3>Accounts</h3>

        <p>No data to show</p>
      </div>
    );
  }

  return (
    <div>
      <h3>Accounts</h3>

      {accounts.map((account) => {
        return (
          <>
            <p>Account: {account.displayName}</p>

            <h5>Transactions</h5>
            <table style={{ tableLayout: "fixed", width: "100%" }}>
              <colgroup>
                <col style={{ width: "50%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Category</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {account.transactions.map((transaction: any) => {
                  return (
                    <tr>
                      <td>{transaction.description}</td>
                      <td>{transaction.type}</td>
                      <td>{transaction.category}</td>
                      <td style={{ textAlign: "right" }}>{transaction.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <br />

            <h5>Direct debits</h5>
            <table>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {account.directDebits.map((transaction: any) => {
                  return (
                    <tr>
                      <td style={{ paddingRight: "40px" }}>{transaction.status}</td>
                      <td>{transaction.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <br />

            <h5>Standing orders</h5>
            <table>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Payee</th>
                  <th>Reference</th>
                </tr>
              </thead>
              <tbody>
                {account.standingOrders.map((transaction: any) => {
                  return (
                    <tr>
                      <td style={{ paddingRight: "40px" }}>{transaction.status}</td>
                      <td style={{ paddingRight: "40px" }}>{transaction.payee}</td>
                      <td>{transaction.reference}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <hr />
          </>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles({
  h1: {
    color: colors.black,
    fontSize: "2rem",
    marginBottom: "1rem",
    fontWeight: 600,
    lineHeight: 1.2,
    marginTop: 0,
  },

  textContainer: {
    color: colors.gray,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
  },

  formContainer: {
    maxWidth: "290px",
  },

  h3: {
    color: colors.veryDarkBlue,
    fontSize: "1.2rem",
    marginBottom: ".5rem",
    fontWeight: "bold",
    lineHeight: 1.2,
    marginTop: 0,
  },

  rightContainer: {
    backgroundColor: colors.lightBlue,
    borderRadius: "5px",
    lineHeight: 2,
    padding: "20px",
  },

  additional: {
    marginBottom: "20px",
  },

  ul: {
    listStyle: "none",
    paddingLeft: 0,
    margin: 0,
  },

  li: {
    "&::before": {
      backgroundColor: "#73a5c4",
      content: '""',
      display: "inline-block",
      height: "10px",
      width: "10px",
      borderRadius: "10px",
      marginRight: "10px",
    },
  },

  sponsors: {
    paddingTop: "30px",
  },

  sponsorsLine: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },

  sponsor: {
    padding: "10px",
    textAlign: "center",
  },

  imgWidth: {
    width: "216px",
  },

  imgHeight: {
    height: "65px",
  },
});
