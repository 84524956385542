import { createTheme } from "@mui/material/styles";
import { colors } from "./constants";

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.accentTeal,
      light: colors.lightTeal,
    },
    secondary: {
      main: colors.accentOrange,
      light: colors.fadedOrange,
    },
    error: {
      main: colors.errorRed,
      light: colors.errorRedFaded,
    },
  },
  typography: {
    fontFamily: "'DM Sans', sans-serif",
    subtitle1: {
      fontSize: "14px",
      fontWeight: "700",
      color: colors.primaryText,
    },
    subtitle2: {
      color: colors.primaryText,
    },
  },
});
