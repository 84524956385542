import * as React from "react";

import { useMediaQuery, useTheme } from "@mui/material";

import { ExpressFormTemplateDesktop } from "./ExpressFormTemplateDesktop";
import { ExpressFormTemplateMobile } from "./ExpressFormTemplateMobile";
import { identity } from "lodash";
import { WhatsAppHelp } from "src/components/atoms/whatsAppHelp";
import { FormMenuEntry } from "@customTypes/index";

export type ExpressFormTemplateProps = {
  noSave?: boolean;
  menuEntries: FormMenuEntry[];
  inPartnership: boolean;
  logoURL?: string;
  companyName: string;
  busy?: boolean;
  currentSection?: string;
  error?: string;
  nextLabel?: string;
  saveLabel?: string | null;
  children: React.ReactNode;
  onNext?: () => void;
  onSave?: () => void;
  onSectionClick: (section: string) => void;
};

export const ExpressFormTemplate: React.FC<ExpressFormTemplateProps> = ({
  menuEntries,
  inPartnership,
  logoURL,
  companyName,
  busy,
  currentSection,
  error,
  children,
  nextLabel,
  onNext,
  onSectionClick,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  if (matches) {
    return (
      <ExpressFormTemplateMobile
        menuEntries={menuEntries}
        busy={busy}
        currentSection={currentSection}
        error={error}
        nextLabel={nextLabel}
        onNext={onNext}
        onSectionClick={onSectionClick}
      >
        {children}
        <br></br>
        <WhatsAppHelp />
      </ExpressFormTemplateMobile>
    );
  }

  return (
    <ExpressFormTemplateDesktop
      menuEntries={menuEntries}
      inPartnership={inPartnership}
      logoURL={logoURL}
      companyName={companyName}
      busy={busy}
      currentSection={currentSection}
      error={error}
      nextLabel={nextLabel}
      onNext={onNext}
      onSectionClick={onSectionClick}
    >
      {children}
    </ExpressFormTemplateDesktop>
  );
};

type State = {
  busy?: boolean;
  currentSection?: string;
  nextLabel?: string;
  saveLabel?: string | null;
  onNext?: () => void;
  onSave?: () => void;
  onSectionClick?: (section: string) => void;
};

const useExpressFormTemplate = () => {
  const [{ busy, currentSection, nextLabel, saveLabel, onNext, onSave, onSectionClick }, update] =
    React.useState<State>({
      onSectionClick: identity,
    });

  return {
    busy,
    currentSection,
    nextLabel,
    saveLabel,
    onNext,
    onSave,
    onSectionClick,
    update,
  };
};

export type UpdateExpressFormTemplateProc = (state: State) => void;

type WithExpressFormTemplateProps = {
  inPartnership: boolean;
  noSave?: boolean;
  logoURL?: string;
  companyName: string;
  menuEntries: FormMenuEntry[];
  proc: (update: UpdateExpressFormTemplateProc) => React.ReactNode;
};

export const WithExpressFormTemplate: React.FC<WithExpressFormTemplateProps> = ({
  inPartnership,
  logoURL,
  companyName,
  menuEntries,
  proc,
}) => {
  const { busy, currentSection, nextLabel, saveLabel, onNext, onSave, onSectionClick, update } =
    useExpressFormTemplate();

  return (
    <ExpressFormTemplate
      menuEntries={menuEntries}
      busy={busy}
      inPartnership={inPartnership}
      logoURL={logoURL}
      companyName={companyName}
      currentSection={currentSection}
      nextLabel={nextLabel}
      saveLabel={saveLabel}
      onNext={onNext}
      onSave={onSave}
      onSectionClick={onSectionClick || identity}
    >
      {proc(update)}
    </ExpressFormTemplate>
  );
};
