import * as React from "react";

import { Box, Stack, Typography } from "@mui/material";

import { usePageViewEventTracker } from "@utils/analytics";
import { Button } from "../../../components/atoms/Button";
import { Header } from "../../../components/atoms/Header";
import { EmailAddressInput } from "../../../components/atoms/EmailAddressInput";
import { FormField } from "../../../components/molecules/FormField";
import { FormStack } from "../../../components/molecules/FormStack";
import { CentredTemplate } from "@templates/CentredTemplate";
import { initiatePasswordReset } from "@api/sessionApi";
import { validEmailAddress } from "@src/utils/Functions";

type State = {
  readonly busy: boolean;
  readonly errorMessage: string;
  readonly record: Record;
  readonly errors: FieldsErrors;
};

type Record = {
  readonly emailAddress: string;
};

interface FieldsErrors {
  readonly emailAddress?: string;
}

export type ForgotPasswordFormProps = {
  done: (a: string) => void;
};

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ done }) => {
  usePageViewEventTracker("Forgot password");

  const [state, setState] = React.useState({
    busy: false,
    errorMessage: "",
    errors: {},
    record: {
      emailAddress: "",
    },
  } as State);

  const { busy, errorMessage, errors, record } = state;

  const removeServerError = React.useCallback(() => {
    setState((s) => ({ ...s, errorMessage: "" }));
  }, [setState]);

  const onChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string } }) => {
      setState((s) => ({
        ...s,
        record: { ...s.record, [name]: value },
        errors: {
          ...s.errors,
          [name]: "",
        },
      }));
    },
    [setState]
  );

  const onSubmit = React.useCallback(
    (ev) => {
      ev.preventDefault();

      const errors = recordErrors(record);

      if (Object.keys(errors).length !== 0) {
        setState((s) => ({ ...s, errors, errorMessage: "" }));
        return;
      }

      setState((s) => ({ ...s, busy: true, errors, errorMessage: "" }));

      const data = { ...record, captcha: "none" };
      // // @ts-ignore
      // window.grecaptcha.ready(() => {
      //   // @ts-ignore
      //   window.grecaptcha.execute(SITE_KEY, { action: "submit" }).then((token: string) => {
      //     submitData({ ...record, captcha: token });
      //   });
      // });

      initiatePasswordReset(data).then(
        (_) => {
          setState((s) => ({ ...s, busy: false }));
          done(record.emailAddress);
        },
        (error) => {
          setState((s) => ({ ...s, busy: false, errorMessage: error.message }));
        }
      );
    },
    [record, done]
  );

  return (
    <ForgotPasswordFormView
      errors={errors}
      record={record}
      onChange={onChange}
      errorMessage={errorMessage}
      removeErrorMessage={removeServerError}
      busy={busy}
      onSubmit={onSubmit}
    />
  );
};

export type ForgotPasswordFormViewProps = {
  errors: any;
  record: any;
  busy: any;
  errorMessage: any;
  removeErrorMessage: () => void;
  onSubmit: (ev: any) => void;
  onChange: any;
};

export const ForgotPasswordFormView: React.FC<ForgotPasswordFormViewProps> = ({
  errors,
  record,
  busy,
  errorMessage,
  removeErrorMessage,
  onSubmit,
  onChange,
}) => {
  return (
    <CentredTemplate
      busy={busy}
      headerType="signup"
      errorMessage={errorMessage}
      removeErrorMessage={removeErrorMessage}
    >
      <form onSubmit={onSubmit} noValidate autoComplete="off">
        <Stack rowGap={4}>
          <Box>
            <Header>Forgot password?</Header>

            <Typography variant="body1">
              Type the address linked to your account and we’ll send you password reset
              instructions.
            </Typography>
          </Box>

          <FormStack>
            <FormField slightlyMoreThanHalfWidth label="Email">
              <EmailAddressInput
                name="emailAddress"
                value={record.emailAddress || ""}
                error={errors.emailAddress}
                onChange={onChange}
              />
            </FormField>
          </FormStack>

          <FormStack>
            <FormField slightlyMoreThanHalfWidth>
              <Button submit variant="primary" size="large" disabled={busy} fullWidth={true}>
                Request password reset
              </Button>
            </FormField>
          </FormStack>

          {/* <Box>
            <Link to={urlPaths.signin()}>Login here</Link>
          </Box> */}
        </Stack>
      </form>
    </CentredTemplate>
  );
};

const recordErrors = (record: Record) => {
  const errors = {
    emailAddress: !record.emailAddress
      ? "required"
      : !validEmailAddress(record.emailAddress)
      ? "invalid"
      : undefined,
  };
  return JSON.parse(JSON.stringify(errors));
};
