import { identity } from "lodash";
import * as React from "react";

import { Box, Container, Grid, Stack } from "@mui/material";

import { PageHeader } from "@organisms/PageHeader";
import { BusyOverlay } from "@atoms/BusyOverlay";
import { ErrorSnackbar } from "@organisms/ErrorSnackbar";
import { insideIframe } from "@utils/Functions";

export type SideImageTemplateDesktopProps = {
  sx?: React.CSSProperties;
  busy?: boolean;
  errorMessage?: string;
  header: React.ReactNode;
  image?: React.ReactNode | null;
  children: React.ReactNode;
  removeErrorMessage?: () => void;
};

export const SideImageTemplateDesktop: React.FC<SideImageTemplateDesktopProps> = ({
  sx,
  busy,
  errorMessage,
  header,
  image,
  children,
  removeErrorMessage,
}) => {
  return (
    <BusyOverlay hidden={!busy}>
      <Stack rowGap="80px" sx={{ minHeight: "100vh", ...sx }}>
        {insideIframe
          ? null
          : header || (
              <Box sx={{ visibility: "hidden" }}>
                <PageHeader includeLogo actionLabel="No op" onClick={identity} />
              </Box>
            )}

        <Container>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Container maxWidth="sm" sx={{ paddingBottom: 4 }}>
                {children}
              </Container>
            </Grid>

            <Grid item xs={5}>
              <Stack alignItems="center">{image}</Stack>
            </Grid>
          </Grid>
        </Container>

        <ErrorSnackbar error={errorMessage} onClose={removeErrorMessage} />
      </Stack>
    </BusyOverlay>
  );
};
