import * as React from "react";

import { useMediaQuery, useTheme } from "@mui/material";

import { SideImageTemplateDesktop } from "./SideImageTemplateDesktop";
import { SideImageTemplateMobile } from "./SideImageTemplateMobile";
import { PageHeaderWithSignin } from "@organisms/PageHeaderWithSignin";
import { PageHeaderWithSignup } from "@organisms/PageHeaderWithSignup";
import { PageHeader } from "@organisms/PageHeader";
import { PageHeaderWithSignout } from "@organisms/PageHeaderWithSignout";
import { PageHeaderWithHelp } from "@organisms/PageHeaderWithHelp";

export type SideImageTemplateProps = {
  sx?: React.CSSProperties;
  headerType: HeaderType;
  errorMessage?: string;
  busy?: boolean;
  image?: React.ReactNode | null;
  children: React.ReactNode;
  removeErrorMessage?: () => void;
};

type HeaderType = "signin" | "signout" | "signup" | "logo" | "help";

export const SideImageTemplate: React.FC<SideImageTemplateProps> = ({
  sx,
  headerType,
  busy,
  errorMessage,
  image,
  children,
  removeErrorMessage,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  if (matches) {
    const showLogo = headerType !== "signout" && headerType !== "help";

    return (
      <SideImageTemplateMobile
        sx={sx}
        busy={busy}
        errorMessage={errorMessage}
        header={header(headerType, !showLogo)}
        showLogo={showLogo}
        removeErrorMessage={removeErrorMessage}
      >
        {children}
      </SideImageTemplateMobile>
    );
  }

  return (
    <SideImageTemplateDesktop
      sx={sx}
      busy={busy}
      errorMessage={errorMessage}
      header={header(headerType, true)}
      image={image}
      removeErrorMessage={removeErrorMessage}
    >
      {children}
    </SideImageTemplateDesktop>
  );
};

const header = (headerType: HeaderType | undefined, includeLogo: boolean) => {
  switch (headerType) {
    case "signin":
      return <PageHeaderWithSignin includeLogo={includeLogo} />;
    case "signout":
      return <PageHeaderWithSignout includeLogo={true} />;
    case "signup":
      return <PageHeaderWithSignup includeLogo={includeLogo} />;
    case "help":
      return <PageHeaderWithHelp includeLogo={includeLogo} />;
    default:
      return <PageHeader includeLogo={includeLogo} />;
  }
};
