import * as React from "react";
import { Titles } from "@src/types";
import { SelectInput } from "@atoms/SelectInput";

export type NewTitleInputProps = {
  name: string;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  onValueChange: (value: string) => void;
};

export const NewTitleInput: React.FC<NewTitleInputProps> = ({
  name,
  value,
  placeholder,
  disabled,
  error,
  onValueChange,
}) => {
  return (
    <SelectInput
      name={name}
      value={value}
      options={options}
      disabled={disabled}
      error={error}
      placeholder={placeholder}
      onValueChange={onValueChange}
    />
  );
};

const options = [
  { value: Titles.Dr, label: "Dr." },
  { value: Titles.Lady, label: "Lady" },
  { value: Titles.Lord, label: "Lord" },
  { value: Titles.Miss, label: "Miss" },
  { value: Titles.Mr, label: "Mr." },
  { value: Titles.Mrs, label: "Mrs." },
  { value: Titles.Ms, label: "Ms." },
  { value: Titles.Prof, label: "Prof." },
  { value: Titles.Rev, label: "Rev." },
  { value: Titles.Sir, label: "Sir" },
];
