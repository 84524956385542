import * as React from "react";

import { clearLocalSession, logout } from "@utils/session";
import urlPaths from "../../urlPaths";
import { InfoBoxTrigger } from "@src/components/molecules";
import { NeedHelpBox2 } from "@molecules/NeedHelpBox";
import { PageHeader } from "./PageHeader";
import { useNavigate } from "react-router-dom";
export type PageHeaderWithSignoutProps = {
  includeLogo?: boolean;
};

export const PageHeaderWithSignout: React.FC<PageHeaderWithSignoutProps> = ({
  includeLogo = true,
}) => {
  const navigate = useNavigate();
  const onLogout = React.useCallback(() => {
    logout();
    clearLocalSession();
    // window.location.replace(urlPaths.signin());
    navigate(urlPaths.signin(), { replace: true });
  }, []);

  return <PageHeaderWithSignoutView includeLogo={includeLogo} onClick={onLogout} />;
};

export type PageHeaderWithSignoutViewProps = {
  includeLogo?: boolean;
  onClick: () => void;
};

export const PageHeaderWithSignoutView: React.FC<PageHeaderWithSignoutViewProps> = ({
  includeLogo = true,
  onClick,
}) => {
  return (
    <PageHeader
      includeLogo={includeLogo}
      prompt={
        <InfoBoxTrigger left white content={<NeedHelpBox2 />}>
          <span style={{ textDecoration: "underline" }}>Need help?</span>
        </InfoBoxTrigger>
      }
      actionLabel={"Logout"}
      onClick={onClick}
    />
  );
};
