import * as React from "react";

import { usePageViewEventTracker } from "@utils/analytics";
import { ErrorPane } from "@organisms/ErrorPane";
import { CentredTemplate } from "@templates/CentredTemplate";

export const NotFoundPage = () => {
  usePageViewEventTracker("Error rendering page");

  return <NotFoundPageView />;
};

export type NotFoundPageViewProps = {};

export const NotFoundPageView: React.FC<NotFoundPageViewProps> = () => {
  return (
    <CentredTemplate headerType={"logo"}>
      <ErrorPane message="Not found" description="The page you requested doesn't exist." />
    </CentredTemplate>
  );
};
