import * as React from "react";

import { TextInput, TextInputProps } from "./TextInput";

export const PhoneNumberTextInput: React.FC<TextInputProps> = ({
  value,
  name,
  onChange,
  error,
  placeholder = "07000 000000",
}) => {
  return (
    <div className={error ? "invalid-form-control" : "valid-form-control"}>
      <TextInput
        name={name}
        placeholder={placeholder}
        value={String(value) || ""}
        error={error}
        onChange={onChange}
      />
    </div>
  );
};
