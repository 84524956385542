import { Typography } from "@mui/material";
import * as React from "react";

import { cleanedErrors } from "@utils/Functions";
import { RadioGroupInput } from "../../../../components/atoms/RadioGroupInput";
import { FormField } from "../../../../components/molecules/FormField";
import { FormStack } from "../../../../components/molecules/FormStack";
import {
  BankAccountDetailsFields,
  Errors as BankAccountErrors,
  errorsOf as errorsOfBankDetails,
  Record as BankAccountRecord,
} from "../BankAccountDetailsFields";
import { useUpdater } from "@utils/callbacks";
import {
  Errors as PersonDetailsErrors,
  errorsOf as errorsOfPersonDetails,
  PersonDetailsFields,
  Record as PersonDetailsRecord,
} from "./PersonDetailsFields";
import { defaultResponsible, fieldsFromPersons, Person } from "./responsible";

type Record = {
  readonly responsibleId?: string;
  readonly responsible?: PersonDetailsRecord;
  readonly responsibleBankAccount?: BankAccountRecord;
};

type Errors =
  | undefined
  | {
    readonly responsibleId?: string;
    readonly responsible?: PersonDetailsErrors;
    readonly responsibleBankAccount?: BankAccountErrors;
  };

export const errorsOfFinalBalanceManager: (r?: Record) => Errors = (record) => {
  return cleanedErrors({
    responsibleId: record?.responsibleId || record?.responsibleId === "" ? undefined : "required",
    responsible:
      record?.responsibleId !== "" ? undefined : errorsOfPersonDetails(record?.responsible),
    responsibleBankAccount: !record?.responsibleBankAccount
      ? undefined
      : errorsOfBankDetails(record?.responsibleBankAccount),
  });
};

export type FinalBalanceManagerFieldsProps = {
  readonly serviceProvider: { customForm?: string };
  readonly persons: ReadonlyArray<Person>;
  readonly labelOverride?: string;
  readonly record: Record;
  readonly errors: Errors;
  readonly update: any;
};

export const FinalBalanceManagerFields: React.FC<FinalBalanceManagerFieldsProps> = ({
  serviceProvider,
  persons,
  labelOverride = "Who will be responsible for the final bill/refunds?",
  record,
  errors,
  update,
}) => {
  const onNewAccountHolderIdChange = React.useCallback(
    (responsibleId) => {
      if (responsibleId) {
        const person = persons.find(({ id }) => id === responsibleId);
        if (!person || person.bankAccountNumber) {
          update({ responsibleId });
        } else {
          update({ responsibleId, responsibleBankAccount: {} });
        }
      } else if (responsibleId === "") {
        update({ responsibleId, responsible: {}, responsibleBankAccount: {} });
      } else {
        update({});
      }
    },
    [update, persons]
  );

  const updateNewAccountHolder = useUpdater(update, "responsible");
  const updateNewAccountHolderBankAccount = useUpdater(update, "responsibleBankAccount");

  React.useEffect(() => {
    if (typeof record.responsibleId === "string") {
      return;
    }

    const person = defaultResponsible(persons);
    if (person) {
      onNewAccountHolderIdChange(person.id);
    }
  }, [record, persons, onNewAccountHolderIdChange]);

  return (
    <FormStack substack spacing={0}>
      <FormField label={labelOverride}>
        <RadioGroupInput
          onValueChange={onNewAccountHolderIdChange}
          value={record.responsibleId}
          fields={fieldsFromPersons(persons, true)}
          error={errors?.responsibleId}
        />
      </FormField>

      {(record.responsible || record.responsibleBankAccount) && (
        <FormStack indented substack>
          {record.responsible && (
            <PersonDetailsFields
              serviceProvider={serviceProvider}
              record={record.responsible}
              update={updateNewAccountHolder}
              errors={errors?.responsible}
            />
          )}

          {record.responsibleBankAccount && (
            <>
              <FormField>
                <Typography variant="body1">Refunds (if any) to be transferred to:</Typography>
              </FormField>

              <BankAccountDetailsFields
                record={record.responsibleBankAccount}
                update={updateNewAccountHolderBankAccount}
                errors={errors?.responsibleBankAccount}
              />
            </>
          )}
        </FormStack>
      )}
    </FormStack>
  );
};
