import * as React from "react";

import {CompanyType,} from "@customTypes/index";
import { FieldRow } from "../../../components/molecules/FieldsTable";
import { NotAvailable } from "../../Dashboard/RequestDetails/NotAvailable";
import { DeceasedPersonalDetails } from "./DeceasedPersonalDetails";
import { PersonDetails } from "./PersonDetails";
import { readableAccountNextSteps, readableAccountType } from "@src/utils/accounts";

export const MobileAccount = ({
  account,
  serviceProviderType,
}: {
  account: any;
  serviceProviderType: CompanyType;
}) => {
  return (
    <>
      <DeceasedPersonalDetails deceased={account.deceased} />

      <FieldRow
        label="Mobile number"
        value={account.phoneNumber ? account.phoneNumber : <NotAvailable />}
      />

      <FieldRow
        label="Account type"
        value={
          account.accountType ? (
            readableAccountType(account.accountType, account.accountTypeOther)
          ) : (
            <NotAvailable />
          )
        }
      />

      <FieldRow
        label="Holder or user"
        value={holderOrUserString(account.holderOrUser) || <NotAvailable />}
      />

      <FieldRow
        label="Next step"
        value={readableAccountNextSteps(account.nextStep, serviceProviderType)}
      />

      <FieldRow
        label="Responsible"
        value={
          account.responsible.id === "notifier" ? (
            "Myself"
          ) : (
            <PersonDetails person={account.responsible.details} />
          )
        }
      />
    </>
  );
};

const holderOrUserString = (holderOrUser?: "holder" | "user" | "both") => {
  if (!holderOrUser) {
    return null;
  }

  return { holder: "Holder", user: "User", both: "Both" }[holderOrUser];
};
