import { WhatsApp } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { SettldContactInfo } from "@utils/Constants";

export const WhatsAppHelp: React.FC = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={1} style={{ alignSelf: "center", textAlign: "center" }}>
        <WhatsAppIcon />
      </Grid>
      <Grid item xs={11}>
        <WhatsAppInfo />
      </Grid>
    </Grid>
  );
};

export const WhatsAppIcon: React.FC = () => {
  return (
    <a href={SettldContactInfo.whatsAppNumberLink1} target="_blank" rel="noopener noreferrer">
      <WhatsApp stroke={"#25D366"} strokeWidth={1} />
    </a>
  );
};

export const WhatsAppInfo: React.FC = () => {
  return (
    <>
      <Typography>
        <a href={SettldContactInfo.whatsAppNumberLink1} target="_blank" rel="noopener noreferrer">
          WhatsApp
        </a>{" "}
        us with any questions.
      </Typography>
    </>
  );
};
