import * as React from "react";

import { RadioGroupInput } from "@src/components/atoms";
import { FormField } from "@molecules/FormField";
import { Property } from "@src/types/property";

export type Record = {
  propertyId?: string;
};

export type Errors =
  | undefined
  | {
      readonly propertyId?: string;
    };

export type PropertySelectorFieldProps = {
  readonly label?: string;
  readonly properties: ReadonlyArray<Property>;
  readonly record: Record;
  readonly errors?: Errors;
  readonly onPropertyIdChange: (propertyId: any) => void;
};

export const PropertySelectorField: React.FC<PropertySelectorFieldProps> = ({
  label,
  properties,
  record,
  errors,
  onPropertyIdChange,
}) => {
  if (properties.length === 0) {
    return null;
  }

  return (
    <FormField label={label || "What is the property address?"}>
      <RadioGroupInput
        onValueChange={onPropertyIdChange}
        value={record.propertyId}
        fields={fieldsFromProperties(properties)}
        error={errors?.propertyId}
      />
    </FormField>
  );
};

const fieldsFromProperties = (properties: ReadonlyArray<Property>) => {
  return properties
    .map(({ id, address, city, postcode }) => {
      return { value: id, label: `${address}, ${city}, ${postcode}` };
    })
    .concat([{ value: "", label: "Other address" }]);
};
