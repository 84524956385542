import * as React from "react";

import { makeStyles } from "@mui/styles";
import { AttachFileOutlined } from "@mui/icons-material";

export type AttachFileButtonProps = {
  disabled?: boolean;
  onFileChange: (ev: React.ChangeEvent) => void;
};

export const AttachFileButton: React.FC<AttachFileButtonProps> = ({ onFileChange, disabled }) => {
  const classes = useStyles();

  return (
    <span>
      <label className={classes.label}>
        <input
          type="file"
          className={classes.input}
          onChange={onFileChange}
          value=""
          disabled={disabled}
        />

        <AttachFileOutlined fontSize="small" />
      </label>
    </span>
  );
};

const useStyles = makeStyles({
  input: {
    display: "none",
  },

  label: {
    height: "35px",
    margin: "0",
    width: "35px",
    cursor: "pointer",
  },
});
