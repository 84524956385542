import {
  AccountNextStep,
  CompanyType,
  Account,
  AccountStatuses,
  AccountType,
  MeterType,
} from "@customTypes/index";
import { assertUnreachable } from "./Functions";

const oneMonth = 1000 * 60 * 60 * 24 * 30;
export const readableAccountStatuses = (s?: AccountStatuses, fallback?: string): string => {
  switch (s) {
    case AccountStatuses.Blocked:
      return "";

    case AccountStatuses.Processing:
      return "processing";

    case AccountStatuses.Settled:
      return "Account Settld";

    default:
      return fallback || "unknown";
  }
  // assertUnreachable(s);
};

export const readableAccountStatus = (account: Account): string => {
  switch (account.status) {
    case AccountStatuses.Blocked:
      return "Processing account.";

    case AccountStatuses.Processing:
      if (account.estimateSettledBy) {
        return `Expecting completion by ${formatDate(account.estimateSettledBy)}`;
      }

      if (account.initialLetterSentAt) {
        return `Expecting completion by ${formatDate(
          new Date(Date.parse(account.initialLetterSentAt) + oneMonth).toISOString()
        )}`;
      }

      return "Processing account.";

    default:
      return "";
  }
};

const accountNextStepDescription = (account: Account) => {
  if (account.nextSteps && account.nextSteps === AccountNextStep.Cancel) {
    return "Account closed.";
  }
  if (account.nextSteps && account.nextSteps === AccountNextStep.UpdateAccountHolder) {
    return "Account reassigned.";
  }
  if (account.nextSteps && account.nextSteps === AccountNextStep.RequestAccountBalance) {
    return "Account balance requested.";
  }

  return "";
};

const accountBalanceDescription = (account: Account) => {
  if (account.finalBalance && account.finalBalance > 0) {
    return "Money will be reimbursed.";
  }

  if (account.finalBalance && account.finalBalance < 0) {
    return "Payment required.";
  }

  return "";
};

const formatDate = (dateStr: string) => {
  const [year, month, day] = dateStr.substring(0, 10).split(/-/);

  return `${day} ${monthName(month)} ${year}`;
};

const monthName = (month: string) => {
  return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][
    parseInt(month, 10) - 1
  ];
};

export const estimateCompletionDate = (accounts: Account[]) => {
  const unknownCompletionDate = accounts.some((account) => {
    return (
      account.status === AccountStatuses.Blocked ||
      (account.status === AccountStatuses.Processing &&
        !account.estimateSettledBy &&
        !account.initialLetterSentAt)
    );
  });

  if (unknownCompletionDate) {
    return null;
  }

  const completionDates = accounts
    .filter((account) => {
      return (
        account.status === AccountStatuses.Processing &&
        (account.estimateSettledBy || account.initialLetterSentAt)
      );
    })
    .map((account) => {
      if (account.estimateSettledBy) {
        return account.estimateSettledBy;
      }

      if (account.initialLetterSentAt) {
        new Date(Date.parse(account.initialLetterSentAt) + oneMonth).toISOString();
      }

      return new Date(Date.now() + oneMonth).toISOString();
    });

  if (completionDates.length === 0) {
    return null;
  }

  completionDates.sort();

  return `We aim to complete your case by ${formatDate(
    completionDates[completionDates.length - 1]
  )}.`;
};

export const readableAccountNextSteps = (
  a?: AccountNextStep | null,
  type?: CompanyType
): string => {
  switch (a) {
    case AccountNextStep.Cancel:
      return type === CompanyType.Insurance ? "Cancel policy" : "Close account";
    case AccountNextStep.MoveToAnotherService:
      return type === CompanyType.Mobile
        ? "Move to a new mobile network"
        : "Move to another service";
    case AccountNextStep.MoveToPayAsYouGo:
      return "Move to Pay as you go";
    case AccountNextStep.RequestAccountBalance:
      return `Request ${
        type === CompanyType.Insurance ? "policy" : "account"
      } balance at the date of death`;
    case AccountNextStep.UpdateAccountHolder:
      return type === CompanyType.Insurance
        ? "Update policy holder"
        : "Update primary account holder";
    case AccountNextStep.RequestInformation:
      return "Request Information";
    case undefined:
    case null:
      return "Unknown";
  }
  assertUnreachable(a as never);
};

export const readableAccountType = (type?: AccountType, other?: string) => {
  switch (type) {
    case AccountType.CurrentAccount:
      return `Current account`;
    case AccountType.SavingsAccount:
      return "Savings account";
    case AccountType.CashISA:
      return "Cash ISA";

    case AccountType.RetirementAccount:
      return "Retirement account";
    case AccountType.Annuities:
      return "Annuities";
    case AccountType.WorkplacePension:
      return "Workplace pension";

    case AccountType.Remortgage:
      return "Remortgage";
    case AccountType.FirstTimeBuyer:
      return "First-time buyer";
    case AccountType.BuyToLet:
      return "Buy-to-let";

    case AccountType.Vehicle:
      return "Vehicle";
    case AccountType.BreakdownCover:
      return "Breakdown cover";
    case AccountType.Home:
      return "Home";
    case AccountType.Health:
      return "Health";
    case AccountType.Travel:
      return "Travel";
    case AccountType.Business:
      return "Business";
    case AccountType.Life:
      return "Life";
    case AccountType.Disability:
      return "Disability";
    case AccountType.Pet:
      return "Pet";

    case AccountType.PayAsYouGo:
      return "Pay as you go";

    case AccountType.Contract:
      return "Contract";

    case AccountType.Personal:
      return "Personal";

    case AccountType.PersonalAndBusiness:
      return "Personal and business accounts";

    case AccountType.PersonalAccidentInsurance:
      return "Personal accident insurance";
    case AccountType.PrivateMedicalInsurance:
      return "Private medical insurance";
    case AccountType.HospitalCashPlan:
      return "Hospital cash plan";
    case AccountType.CancerCover:
      return "Cancer cover";

    case AccountType.Other:
      return other || "Other";
    case AccountType.Unknown:
    case undefined:
    case null:
      return "Unknown";
  }
  assertUnreachable(type);
};

export const readableMeterType = (meterType?: MeterType) => {
  switch (meterType) {
    case MeterType.SmartMeter:
      return "Smart meter";

    case MeterType.Credit:
      return "Credit";

    case MeterType.PayAsYouGo:
      return "Pay as you go";

    case MeterType.Unknown:
      return "Not known";

    case undefined:
    case null:
      return "Unknown";
  }
  assertUnreachable(meterType);
};
