import * as React from "react";

import { Box, Paper, Typography } from "@mui/material";

import { Button } from "../atoms/Button";
import { Link } from "../atoms/Link";

export type FormNextPaneMobileProps = {
  nextOnly?: boolean;
  busy?: boolean;
  nextLabel?: string;
  saveLabel?: string | null;
  onNext?: () => void;
  onSave?: () => void;
};

export const FormNextPaneMobile: React.FC<FormNextPaneMobileProps> = ({
  nextOnly,
  busy,
  nextLabel,
  saveLabel,
  onNext,
  onSave,
}) => {
  return (
    <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
      <FormNextPaneMobileContent
        nextOnly={nextOnly}
        busy={busy}
        nextLabel={nextLabel}
        saveLabel={saveLabel}
        onNext={onNext}
        onSave={onSave}
      />
    </Paper>
  );
};

export const FormNextPaneMobileContent: React.FC<FormNextPaneMobileProps> = ({
  nextOnly,
  busy,
  nextLabel = "Next",
  saveLabel = "Save & continue later",
  onNext,
  onSave,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "extend",
        padding: "20px",
        rowGap: "20px",
      }}
    >
      <Button variant="secondary" size="large" disabled={!onNext || busy} onClick={onNext}>
        {nextLabel}
      </Button>

      {nextOnly ? null : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            columnGap: "15px",
          }}
        >
          {saveLabel === null ? (
            <Typography variant="body2">&nbsp;</Typography>
          ) : (
            <>
              <Typography variant="body2">or</Typography>
              <Link variant="primary" disabled={!onSave || busy} onClick={onSave}>
                {saveLabel}
              </Link>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
