import * as React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "../../styles/constants";
import { trackEvent } from "@utils/analytics";

const intervalMillis = 1000 * 60 * 5;

export const VersionVerification = () => {
  const classes = useStyles();

  const [newVersionAvailable, setNewVersionAvailable] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      getLatestVersion().then(
        (version) => {
          if (version !== process.env["REACT_APP_VERSION"]) {
            setNewVersionAvailable(true);
          }
        },
        (error) => {
          // ignore; user is probably offline
        }
      );
    }, intervalMillis);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!newVersionAvailable) {
    return null;
  }

  return (
    <div className={classes.warningBar}>
      <div>
        <b>A new version of this page is out!</b>
      </div>

      <div>
        Please save any changes you're making, and then refresh the page to get the latest version.
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    fontSize: "14px",
    position: "absolute",
    right: "15px",
    padding: "15px",
    width: "280px",
    bottom: "79px",
    backgroundColor: colors.infoBoxBackground,
    borderRadius: "5px",
  },
  warningBar: {
    backgroundColor: colors.continueButtonBackground,
    color: "white",
    padding: "4px 0",
    textAlign: "center",
  },
});

const getLatestVersion = async () => {
  try {
    return await getLatestVersionRequest();
  } catch (error) {
    trackEvent("HTTP error", { request: "Get latest version" });
    throw error;
  }
};

const getLatestVersionRequest = async () => {
  let result;
  try {
    result = await fetch(`/latest-version.json`);
  } catch (error) {
    throw error;
  }

  return result.json();
};
