import * as React from "react";

import { AddOutlined } from "@mui/icons-material";
import { Box, Paper, Stack } from "@mui/material";

import { colors } from "@styles/constants";

export type AddCardProps = {
  readonly label: string;
  readonly onClick?: () => void;
};

export const AddCard: React.FC<AddCardProps> = ({ label, onClick }) => {
  return (
    <Paper
      elevation={6}
      sx={{
        backgroundColor: colors.offWhite,
        border: `2px dashed ${colors.lightTeal}`,
        color: colors.lightTeal,
        cursor: "pointer",
        "&:hover": {
          color: colors.lighterTeal,
          borderColor: colors.lighterTeal,
        },
      }}
      onClick={onClick}
    >
      <Stack direction="row" justifyContent="center" alignItems="center" padding={2} columnGap={1}>
        <AddOutlined fontSize="small" />

        <Box>{label}</Box>
      </Stack>
    </Paper>
  );
};
