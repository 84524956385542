import React from "react";
import { makeStyles } from "@mui/styles";

export const LinkButton: React.FC<{ onClick: any }> = ({ onClick, children }) => {
  const classes = useStyles();

  return (
    <a className={classes.linkButton} onClick={onClick}>
      {children}
    </a>
  );
};

const useStyles = makeStyles({
  linkButton: {
    textDecoration: "underline !important",
    cursor: "pointer",
    color: "#b1b1b1 !important",
    "&:hover": {
      color: "#b1b1b1 !important",
    },
  },
});
