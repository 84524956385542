import * as React from "react";

import { RadioGroupInput } from "@src/components/atoms";
import { FormField } from "@molecules/FormField";
import { MeterAccess } from "@src/types";

export const MeterAccessField: React.FunctionComponent<{
  value?: MeterAccess;
  error?: string;
  onValueChange: any;
}> = ({ value, error, onValueChange }) => {
  return (
    <FormField label="Are you able to provide a meter reading?">
      <RadioGroupInput
        onValueChange={onValueChange}
        value={value}
        fields={meterAccessFields}
        error={error}
      />
    </FormField>
  );
};
const meterAccessFields = [
  { value: MeterAccess.Yes, label: "Yes" },
  {
    value: MeterAccess.Later,
    label: "Yes, but not right now - will provide a meter reading at a later date",
  },
  { value: MeterAccess.No, label: "No, I do not have access to the meter" },
  { value: MeterAccess.NotMetered, label: "The property is not metered" },
];
