import * as React from "react";
import { useNavigate } from "react-router-dom";

import { identify, reset } from "./analytics";
import { checkOnlineSession } from "@api/sessionApi";

const cookieName = "authToken";

let localCaseId: string | null = null;
export const setLocalCaseId = (caseId: string) => {
  localCaseId = caseId;
};

export const getLocalCaseId = () => {
  return localCaseId;
};

export const login = (token: string) => {
  document.cookie = `${cookieName}=${token}; path=/; max-age=${2 * 60 * 60}`;
};

export const keepSessionAlive = (token: string) => {
  login(token);
};

export const logout = () => {
  document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  clearSessionExpirationNotice();
};

export const loggedIn = () => {
  return !!token();
};

export const token = () => {
  const cookie = getCookie(cookieName);

  if (cookie) {
    ensureSessionExpirationNotice();
  }

  return cookie;
};

const getCookie = (string: string) => {
  const cookies = document.cookie.split(/;\s*/);
  const cookie = cookies.find((cookie) => cookie.startsWith(string + "="));
  return cookie && cookie.substring(string.length + 1);
};

let sessionExpirationInterval: any = null;

const clearSessionExpirationNotice = () => {
  if (sessionExpirationInterval) {
    clearInterval(sessionExpirationInterval);
    sessionExpirationInterval = null;
  }
};

const ensureSessionExpirationNotice = () => {
  if (sessionExpirationInterval) {
    return;
  }

  sessionExpirationInterval = setInterval(() => {
    if (!getCookie(cookieName)) {
      alert("You have been logged.");
      window.location.reload();
    }
  }, 60000);
};

export type LocalSession = {
  caseId: string;
  signature: string;
};

export const useLocalSession = (path: string) => {
  const navigate = useNavigate();
  const [{ caseId, signature }, setState] = React.useState({ caseId: null, signature: null } as {
    caseId: string | null;
    signature: string | null;
  });

  React.useEffect(() => {
    const session = localSession();
    if (session) {
      setState(session);
      return;
    }

    const caseId = getLocalCaseId();
    if (caseId) {
      setState({ caseId, signature: null });
      return;
    }

    checkOnlineSession().then(({ session }: any) => {
      if (!session) {
        navigate(path, { replace: true });
        return;
      }

      setLocalCaseId(session.caseId);
      setState({ caseId: session.caseId, signature: null });
    });
  }, [caseId, signature]);

  return { caseId, signature };
};

export const localSession = () => {
  const caseId = window.sessionStorage.getItem("settldCaseId");
  const signature = window.sessionStorage.getItem("settldSignature");

  if (!caseId || !signature) {
    return null;
  }

  return { caseId, signature };
};

export const setLocalSession = ({ caseId, signature }: LocalSession) => {
  identify(caseId);

  window.sessionStorage.setItem("settldCaseId", caseId);
  window.sessionStorage.setItem("settldSignature", signature);
};

export const clearLocalSession = () => {
  reset();

  sessionStorage.removeItem("case");
  sessionStorage.removeItem("nok");
  sessionStorage.removeItem("executor");
  sessionStorage.removeItem("account");
  sessionStorage.removeItem("documents");
  sessionStorage.removeItem("kyc");
  sessionStorage.removeItem("notifier");
  sessionStorage.removeItem("additionalServices");
  sessionStorage.removeItem("ui_info");
  window.sessionStorage.removeItem("settldCaseId");
  window.sessionStorage.removeItem("settldSignature");
};

export const isUserLoggedIn = () => {
  return !!(localSession() || token());
};
