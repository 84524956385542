import { ServiceProvider } from "@customTypes/ServiceProvider";
import { useReducer, createContext } from "react";

export type SPErrors = Partial<Record<keyof ServiceProvider, string>>;

export type SPInfo = {
  record: ServiceProvider;
  errors: SPErrors;
};

const initialState = { record: {}, errors: {} } as SPInfo;

const SPContext = createContext<{ state: SPInfo; dispatch: React.Dispatch<any> }>({
  state: initialState,
  dispatch: () => null,
});

const actionTypes = {
  SET_SP: "SET_SP",
};

function reducer(state: SPInfo, action: { type: string; payload: any }): SPInfo {
  switch (action.type) {
    case actionTypes.SET_SP:
      return { record: { ...state.record, ...action.payload }, errors: { ...state.errors } };
    default:
      return initialState;
  }
}
const SPProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <SPContext.Provider value={{ state, dispatch }}>{children}</SPContext.Provider>;
};

export { SPContext, SPProvider };
