import * as React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "@styles/constants";

export const Fieldset: React.FC<{ title: string }> = ({ title, children }) => {
  const classes = useStyles();
  return (
    <div>
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>{title}</legend>
        <div>{children}</div>
      </fieldset>
    </div>
  );
};

const useStyles = makeStyles({
  fieldset: {
    border: `1px solid #DFDFDF`,
    padding: "0 1.4em 1.4em",
    margin: "0 0 1.5em 0",
    boxShadow: "0px 0px 0px 0px #000",
  },
  legend: {
    color: colors.continueButtonBackground,
    padding: "0 10px",
    width: "auto",
  },
});
