import * as React from "react";

import urlPaths from "../../../urlPaths";
import { Header } from "@atoms/Header";
import { Link } from "@atoms/Link";
import { CentredTemplate } from "@templates/CentredTemplate";

export type ResetPasswordDoneProps = {};

export const ResetPasswordDone: React.FC<ResetPasswordDoneProps> = () => {
  return (
    <CentredTemplate headerType="signup">
      <Header>New password set</Header>

      <p>You have successfully reset your password.</p>

      <p>
        Please{" "}
        <Link inline to={urlPaths.signin()}>
          login here
        </Link>
        .
      </p>

      <div style={{ paddingTop: "15px" }}></div>
    </CentredTemplate>
  );
};
