import * as React from "react";

export const useOnChange = (update: any) => {
  return React.useCallback(
    ({ target: { name: property, value } }: any) => {
      update((s: any) => ({
        ...s,
        [property]: typeof value === "function" ? value(s[property]) : value,
      }));
    },
    [update]
  );
};

export const useOnValueChange = (update: any, property: string) => {
  return React.useCallback(
    (value: any) => {
      update((s: any) => ({
        ...s,
        [property]: typeof value === "function" ? value(s[property]) : value,
      }));
    },
    [update]
  );
};

export const useUpdater = (update: any, prop: string) => {
  return React.useCallback(updater(update, prop), [update]);
};

const updater = (update: any, prop: string) => {
  return (moreState: any) => {
    update((s: any) => {
      s = s || {};
      return {
        ...s,
        [prop]: typeof moreState === "function" ? moreState(s[prop]) : moreState,
      };
    });
  };
};
