import * as React from "react";

import { Box, Container, Stack } from "@mui/material";

import { Logo } from "../atoms/Logo";
import { HeaderAction } from "../molecules/HeaderAction";
import { Link } from "../atoms/Link";

export type PageHeaderProps = {
  includeLogo?: boolean;
  prompt?: React.ReactNode;
  actionLabel?: string;
  onClick?: () => void;
};

export const PageHeader: React.FC<PageHeaderProps> = ({
  includeLogo,
  prompt,
  actionLabel,
  onClick,
}) => {
  return (
    <Box sx={{ paddingTop: "16px" }}>
      <Container>
        <Stack direction="row" justifyContent="space-between">
          {!includeLogo ? (
            <div />
          ) : (
            <Link inline target="_blank" to={"https://www.settld.care/"}>
              <Logo />
            </Link>
          )}

          <HeaderAction prompt={prompt} actionLabel={actionLabel} onClick={onClick} />
        </Stack>
      </Container>
    </Box>
  );
};
