import * as React from "react";
import { Titles, prettyTitle } from "@src/types";

export type Person = {
  readonly id: string;
  readonly title: Titles;
  readonly firstName: string;
  readonly lastName: string;
  readonly roles: string[];
};

export type ResponsibleProps = {
  readonly persons: ReadonlyArray<Person>;
  readonly responsibleId?: string;
};

export const Responsible: React.FC<ResponsibleProps> = ({ persons, responsibleId }) => {
  if (!responsibleId) {
    return null;
  }

  const person = persons.find(({ id }) => id === responsibleId);

  if (!person) {
    return null;
  }

  const { title, firstName, lastName } = person;

  return <span>{`${prettyTitle(title)} ${firstName} ${lastName}`}</span>;
};
