import * as React from "react";
import { useNavigate } from "react-router-dom";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import { usePageViewEventTracker } from "@utils/analytics";
import { colors } from "../../styles/constants";
import { insideIframe } from "../../utils/Functions";
import { Button } from "../../components/atoms/Button";
import { Header } from "../../components/atoms/Header";
import { FormField } from "../../components/molecules/FormField";
import { FormStack } from "../../components/molecules/FormStack";
import { SideImageTemplate } from "@templates/SideImageTemplate";
import { getExpressData } from "./express-data";
import { navigateToURL } from "./navigateToURL";

export type ExpressFormSubmittedPageProps = {
  serviceProvider: { companyName: string };
};

export const ExpressFormSubmittedPage: React.FC<ExpressFormSubmittedPageProps> = ({
  serviceProvider,
}) => {
  usePageViewEventTracker("Express form submitted");
  const navigate = useNavigate();

  const [busy, setBusy] = React.useState(false);

  const done = React.useCallback(
    (url: string) => {
      setBusy(true);

      navigateToURL(navigate, url);
    },
    [setBusy, navigate]
  );

  return <ExpressFormSubmittedPageView busy={busy} serviceProvider={serviceProvider} done={done} />;
};

export type ExpressFormSubmittedPageViewProps = {
  busy?: boolean;
  serviceProvider: { companyName: String };
  done: (url: string) => void;
};

export const ExpressFormSubmittedPageView: React.FC<ExpressFormSubmittedPageViewProps> = ({
  busy,
  serviceProvider,
  done,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const expressData = getExpressData();

  return (
    <SideImageTemplate
      headerType="help"
      busy={busy}
      image={<img src="/images/stock/express-form-submitted.svg" width="100%" alt="Family" />}
    >
      <Stack rowGap={4}>
        {matches ? (
          <>
            <Header level={1} color={colors.accentTeal}>
              {serviceProvider.companyName} successfully notified
            </Header>

            <Header level={2}>Would you like to notify more companies?</Header>
          </>
        ) : (
          <>
            <Stack columnGap={1} direction="row">
              <CheckCircleOutlineOutlinedIcon fontSize="small" sx={{ color: colors.lightTeal }} />
              <Typography variant="body1" color={colors.accentTeal}>
                <b>{serviceProvider.companyName} successfully notified</b>
              </Typography>
            </Stack>
            <Header level={1} color={colors.bodyText}>
              Would you like to notify more companies?
            </Header>
          </>
        )}

        <Typography variant="body1">
          Click ‘Go to dashboard’ below to go to your account dashboard and check the progress of
          your request. You can also use it to add more accounts and notify other companies of the
          death in one go (banks, insurers, utilities &amp; many more), and to receive updates in
          one, secure place. Settld’s service is free.
        </Typography>

        <FormStack>
          <FormField twoThirdsWidth>
            {!expressData.formUrl ? null : insideIframe ? (
              <Button
                variant="primary"
                size="large"
                disabled={busy}
                onClick={() => done(expressData.formUrl)}
              >
                Go to dashboard
              </Button>
            ) : (
              <Button variant="primary" size="large" disabled={busy} href={expressData.formUrl}>
                Go to dashboard
              </Button>
            )}
          </FormField>
        </FormStack>
      </Stack>
    </SideImageTemplate>
  );
};
