import * as React from "react";

import { Account } from "@src/types";
import { CompanyType } from "@src/types";
import { Header } from "@molecules/GreyPane";
import { RequestDetailsField } from "@src/components/molecules";

export enum PaymentMethod {
  BankTransfer = "bank_transfer",
  Cheque = "cheque",
}

export type PayeeDetailsProps = {
  readonly account: Account;
  readonly paymentMethod: PaymentMethod;
};

export const PayeeDetails: React.FC<PayeeDetailsProps> = ({ account, paymentMethod }) => {
  if (!account.responsible) {
    return null;
  }

  return (
    <>
      <div style={{ height: "20px" }}></div>

      <Header>Payee details</Header>

      <RequestDetailsField label="Payee name">
        {/* @ts-ignore */}
        {account.responsible.firstName} {account.responsible.lastName}
      </RequestDetailsField>

      {paymentMethod === PaymentMethod.BankTransfer ? (
        <>
          <RequestDetailsField label="Account number">
            {/* @ts-ignore */}
            {account.responsible.bankAccountNumber}
          </RequestDetailsField>

          <RequestDetailsField label="Sort code">
            {/* @ts-ignore */}
            {account.responsible.bankSortCode}
          </RequestDetailsField>
        </>
      ) : (
        <>
          <RequestDetailsField label="Payee address">
            {[account.responsible.address, account.responsible.city, account.responsible.postcode]
              .map((s) => s && s.trim())
              .join(", ")}
          </RequestDetailsField>

          {account.serviceProvider?.serviceProviderType === CompanyType.Water && (
            <RequestDetailsField label="Payment method">
              Credit balances will be paid by cheque
            </RequestDetailsField>
          )}
        </>
      )}
    </>
  );
};
