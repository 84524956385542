import * as React from "react";

import { InputLabel } from "@mui/material";

export type LabelProps = {
  children: React.ReactNode;
};

export const Label: React.FC<LabelProps> = ({ children }) => {
  return <InputLabel sx={{ fontSize: "small" }}>{children}</InputLabel>;
};
