import { Paper, Stack } from "@mui/material";
import { colors } from "@src/styles/constants";

export type RequiredHeaderProps = {
  showError: boolean;
  title: string;
};

export const RequiredHeader: React.FC<RequiredHeaderProps> = ({ showError, title }) => {
  return (
    <Stack flexGrow={1} direction="row" justifyContent="space-between" alignItems="baseline">
      <b style={{ color: colors.bodyText }}>{title}</b>
      {showError && (
        <Paper
          elevation={0}
          sx={{
            backgroundColor: colors.offWhite,
            padding: "4px 32px",
            marginRight: "16px",
            color: colors.errorRed,
          }}
        >
          Required
        </Paper>
      )}
    </Stack>
  );
};
