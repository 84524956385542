import * as React from "react";
import { useNavigate } from "react-router-dom";

import MuiLink from "@mui/material/Link";
import { makeStyles } from "@mui/styles";

import { colors } from "../../styles/constants";
import { Box, Stack } from "@mui/material";

export const EmailLink: React.FC<{
  readonly onClick?: () => void;
  to?: string;
  target?: "_blank";
  download?: boolean;
}> = ({ to, onClick, target, children, download }) => {
  const classes = useStyles();

  return (
    <a className={classes.emailLink} href={to} target={to} onClick={onClick} download={download}>
      {children}
    </a>
  );
};

export const OldLink: React.FC<{
  readonly onClick?: () => void;
  readonly to?: string;
  readonly target?: "_blank";
  readonly download?: boolean;
  readonly otherColor?: boolean;
}> = ({ to, onClick, target, children, download, otherColor }) => {
  const classes = useStyles();

  return (
    <a
      className={otherColor ? classes.linkAlternative : classes.link}
      href={to}
      target={target}
      onClick={onClick}
      download={download}
    >
      {children}
    </a>
  );
};

export type LinkProps = {
  readonly sx?: React.CSSProperties;
  readonly variant?: "primary" | "secondary" | "text" | "action";
  readonly rel?: string;
  readonly icon?: React.ReactNode;
  readonly to?: string;
  readonly target?: "_blank";
  readonly disabled?: boolean;
  readonly inline?: boolean;
  readonly children: React.ReactNode;
  readonly download?: string;
  readonly onClick?: () => void;
};

export const Link: React.FC<LinkProps> = ({
  sx,
  variant = "text",
  rel,
  download,
  icon,
  to,
  target,
  disabled,
  inline,
  children,
  onClick,
}) => {
  const navigate = useNavigate();

  const variantStyle =
    variant === "primary"
      ? styles.primary
      : variant === "secondary"
      ? styles.secondary
      : variant === "action"
      ? styles.action
      : styles.text;

  const typographyVariant =
    variant === "primary" || variant === "secondary" ? "button" : inline ? "inherit" : "body1";

  const underline = variant === "text" ? "always" : "none";

  const isInternalLink = to && to.startsWith("/") && !target;

  const content = !icon ? (
    children
  ) : (
    <Stack direction="row" columnGap="6px" alignItems="center">
      {icon}
      <Box>{children}</Box>
    </Stack>
  );

  return (
    <MuiLink
      sx={{ ...variantStyle, ...sx }}
      download={download}
      rel={rel}
      component={inline || target ? "a" : "button"}
      variant={typographyVariant}
      href={!to || isInternalLink ? undefined : to}
      target={target}
      disabled={disabled}
      onClick={isInternalLink ? onClick || (() => navigate(to)) : onClick}
      underline={underline}
    >
      {content}
    </MuiLink>
  );
};

export const NewsLink = Link;

export const MessageLink: React.FC<{
  readonly onClick?: () => void;
  to?: string;
  target?: "_blank";
  download?: boolean;
}> = ({ to, onClick, target, children, download }) => {
  const classes = useStyles();

  return (
    <a
      className={classes.messageLink}
      href={to}
      target={target}
      onClick={onClick}
      download={download}
    >
      {children}
    </a>
  );
};

const styles = {
  primary: {
    color: colors.accentOrange,
    "&:hover": {
      color: colors.fadedOrange,
    },
    "&:disabled": {
      color: colors.lightGrey,
    },
    cursor: "pointer",
  },
  secondary: {
    color: colors.accentTeal,
    "&:hover": {
      color: colors.lightTeal,
    },
    "&:disabled": {
      color: colors.lightGrey,
    },
    cursor: "pointer",
  },
  text: {
    color: colors.primaryText,
    textDecorationColor: colors.primaryText,
    "&:hover": {
      color: colors.secondaryText,
    },
    "&:disabled": {
      color: colors.lightGrey,
    },
    cursor: "pointer",
  },
  action: {
    color: colors.lightTeal,
    textDecorationColor: colors.lightTeal,
    "&:hover": {
      color: colors.lighterTeal,
    },
    "&:disabled": {
      color: colors.lighestTeal,
    },
    cursor: "pointer",
  },
};

const useStyles = makeStyles({
  // button: {
  //   color: colors.continueButtonBackground,
  //   verticalAlign: "baseline",
  // },
  chevron: {
    fontSize: "2rem",
    verticalAlign: "middle",
  },

  link: {
    color: `${colors.continueButtonBackground} !important`,
    cursor: "pointer",
    "&:hover": {
      color: colors.continueButtonBackground,
    },

    "&:active": {
      color: colors.continueButtonBackground,
    },
  },

  linkAlternative: {
    color: `${"#377A83"} !important`,
    cursor: "pointer",
    "&:hover": {
      color: "#377A83",
      textDecoration: "underline !important",
    },

    "&:active": {
      color: "#377A83",
      textDecoration: "underline !important",
    },
  },

  messageLink: {
    color: `${"#f6b071"} !important`,
    cursor: "pointer",
    "&:hover": {
      color: "#f6b071",
      textDecoration: "underline !important",
    },

    "&:active": {
      color: "#f6b071",
      textDecoration: "underline !important",
    },
  },

  emailLink: {
    color: `${colors.bodyText} !important`,
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      color: colors.bodyText,
    },

    "&:active": {
      color: colors.bodyText,
    },
  },

  text: {
    borderWidth: "2px",
    display: "block",
    textDecoration: "underline",
    color: `${colors.primaryText} !important`,
    "&:hover": {
      textDecoration: "underline",
      color: `${colors.secondaryText} !important`,
    },
    "&:disabled": {
      color: `${colors.lightGrey} !important`,
    },
  },
});

interface ExternalLinkProps {
  to: string;
  text: string;
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({ to, text }) => {
  return (
    <>
      {" "}
      <a target="_blank" href={to} rel="noopener noreferrer">
        {text}
      </a>
    </>
  );
};
