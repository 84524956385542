import { Stack } from "@mui/material";
import * as React from "react";
import { TextInput } from "../atoms/TextInput";

export const AddressInput: React.FC<{
  name: string;
  value: string | undefined;
  onChange: Function;
  error?: string;
  placeholder?: string;
}> = ({ name, value, onChange, error, placeholder }) => {
  const [address, ...moreLines] = value?.split("\n") || [];
  const addressLine2 = moreLines.join("");

  const [state, setState] = React.useState({ line2: addressLine2 });

  const localLine2 = addressLine2 || state.line2;

  const onLocalChange = React.useCallback(
    ({ target: { name: localName, value } }: { target: { name: string; value: string } }) => {
      if (localName === "address") {
        onChange({
          target: { name, value: value && localLine2 ? value + "\n" + localLine2 : value || "" },
        });
      } else if (address) {
        onChange({ target: { name, value: address + "\n" + value } });
        setState({ line2: value });
      } else {
        setState({ line2: value });
      }
    },
    [name, address, localLine2, onChange, setState]
  );

  return (
    <div>
      <TextInput
        name="address"
        value={address}
        onChange={onLocalChange}
        error={error}
        placeholder={placeholder || "Address line 1"}
      />

      <div style={{ height: "0.5em" }}></div>

      <TextInput
        name="address2"
        value={localLine2}
        onChange={onLocalChange}
        placeholder="Address line 2 (optional)"
      />
    </div>
  );
};

export type NewAddressInputProps = {
  name: string;
  value: string | undefined;
  onChange: Function;
  error?: string;
  placeholder?: string;
};

export const NewAddressInput: React.FC<NewAddressInputProps> = ({
  name,
  value,
  onChange,
  error,
  placeholder,
}) => {
  const addressName = name + "_address";
  const address2Name = name + "_address2";

  const [address, ...moreLines] = value?.split("\n") || [];
  const addressLine2 = moreLines.join("");

  const [state, setState] = React.useState({ line2: addressLine2 });

  const localLine2 = addressLine2 || state.line2;

  const onLocalChange = React.useCallback(
    ({ target: { name: localName, value } }: { target: { name: string; value: string } }) => {
      if (localName === addressName) {
        onChange({
          target: { name, value: value && localLine2 ? value + "\n" + localLine2 : value || "" },
        });
      } else if (address) {
        onChange({ target: { name, value: address + "\n" + value } });
        setState({ line2: value });
      } else {
        setState({ line2: value });
      }
    },
    [name, address, localLine2, onChange, setState, addressName]
  );

  return (
    <Stack rowGap={1}>
      <TextInput
        name={addressName}
        value={address}
        onChange={onLocalChange}
        error={error}
        placeholder={placeholder || "Address line 1"}
      />

      <TextInput
        name={address2Name}
        value={localLine2}
        onChange={onLocalChange}
        placeholder="Address line 2 (optional)"
      />
    </Stack>
  );
};
