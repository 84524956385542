import * as React from "react";

import { AccountDetails, AccountNextStep } from "@src/types";
import { cleanedErrors } from "@utils/Functions";
import {
  Errors as BankAccountErrors,
  Record as BankAccountRecord,
} from "../BankAccountDetailsFields";
import {
  errorsOfFinalBalanceManager,
  FinalBalanceManagerFields,
} from "./FinalBalanceManagerFields";
import { errorsOfNewAccountHolder, NewAccountHolderFields } from "./NewAccountHolderFields";
import {
  Errors as PersonDetailsErrors,
  Record as PersonDetailsRecord,
} from "./PersonDetailsFields";
import { Person } from "./responsible";

export type { Person } from "./responsible";

export type Record = {
  readonly responsibleId?: string;
  readonly responsible?: PersonDetailsRecord;
  readonly responsibleBankAccount?: BankAccountRecord;
};

export type Errors =
  | undefined
  | {
    readonly responsibleId?: string;
    readonly responsible?: PersonDetailsErrors;
    readonly responsibleBankAccount?: BankAccountErrors;
  };

export const errorsOf: (
  r: undefined | Record,
  accountRequest: AccountNextStep | undefined
) => Errors = (record, accountRequest) => {
  return cleanedErrors(
    accountRequest === AccountNextStep.RequestAccountBalance ||
      accountRequest === AccountNextStep.UpdateAccountHolder
      ? errorsOfNewAccountHolder(record)
      : accountRequest === AccountNextStep.Cancel
        ? errorsOfFinalBalanceManager(record)
        : undefined
  );
};

export const recordFromAccount = (account: AccountDetails | undefined): Record => {
  if (!account) {
    return {};
  }

  return { responsibleId: account.responsibleId };
};

type ResponsibleFieldsProps = {
  readonly serviceProvider: { customForm?: string };
  readonly persons: ReadonlyArray<Person>;
  readonly labelOverride?: string;
  readonly nextStep?: AccountNextStep;
  readonly record: Record;
  readonly errors: any;
  readonly update: any;
};

export const ResponsibleFields: React.FC<ResponsibleFieldsProps> = ({
  serviceProvider,
  persons,
  labelOverride,
  nextStep,
  record,
  errors,
  update,
}) => {
  return nextStep === AccountNextStep.RequestAccountBalance ? (
    <NewAccountHolderFields
      serviceProvider={serviceProvider}
      persons={persons}
      labelOverride={labelOverride || "Who will be responsible for the account?"}
      record={record}
      errors={errors}
      update={update}
    />
  ) : nextStep === AccountNextStep.UpdateAccountHolder ||
    nextStep === AccountNextStep.MoveToPayAsYouGo ? (
    <NewAccountHolderFields
      serviceProvider={serviceProvider}
      persons={persons}
      labelOverride={labelOverride}
      record={record}
      errors={errors}
      update={update}
    />
  ) : nextStep === AccountNextStep.Cancel || nextStep === AccountNextStep.MoveToAnotherService ? (
    <FinalBalanceManagerFields
      serviceProvider={serviceProvider}
      persons={persons}
      labelOverride={labelOverride}
      record={record}
      errors={errors}
      update={update}
    />
  ) : null;
};
