import React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "@styles/constants";

export const SubmitButton: React.FC<{ label: string; disabled?: boolean }> = ({
  label,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <input type="submit" className={disabled ? classes.disabled : classes.enabled} value={label} />
  );
};

const useStyles = makeStyles({
  enabled: {
    color: colors.primary,
    backgroundColor: colors.enabled,
    border: "none",
    borderRadius: "30px",
    padding: "5px 20px",
    textTransform: "uppercase",

    "&:hover": {
      color: "white",
      backgroundColor: "#377A84",
    },

    "&:active": {
      color: colors.primary,
      backgroundColor: colors.enabled,
    },
  },

  disabled: {
    color: "white",
    backgroundColor: colors.disabled,
    border: "none",
    borderRadius: "30px",
    padding: "5px 20px",
    textTransform: "uppercase",
  },
});
