import * as React from "react";

import { PageHeaderWithSignout } from "@organisms/PageHeaderWithSignout";
import { CentredTemplateDesktop } from "../CentredTemplate/CentredTemplateDesktop";

export type KYCTemplateDesktopProps = {
  busy?: boolean;
  children: React.ReactNode;
};

export const KYCTemplateDesktop: React.FC<KYCTemplateDesktopProps> = ({ busy, children }) => {
  return (
    <CentredTemplateDesktop busy={busy} header={<PageHeaderWithSignout />}>
      {children}
    </CentredTemplateDesktop>
  );
};
