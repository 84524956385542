import * as React from "react";
import { formatDate } from "@utils/Functions";

import { OwnershipStatus, Property } from "@src/types/property";

export const VacantPropertyDetails: React.FC<{ property: Property }> = ({ property }) => {
  return (
    <span>
      {property.lastOccupiedDate &&
      property.ownershipStatus &&
      property.ownershipStatus !== OwnershipStatus.Unknown ? (
        <span>
          It is {property.ownershipStatus} and was last occupied on{" "}
          {formatDate(property.lastOccupiedDate)}.
        </span>
      ) : property.lastOccupiedDate ? (
        <span>It was last occupied on {formatDate(property.lastOccupiedDate)}.</span>
      ) : property.ownershipStatus && property.ownershipStatus !== OwnershipStatus.Unknown ? (
        <span>It is {property.ownershipStatus}.</span>
      ) : null}
    </span>
  );
};
