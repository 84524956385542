import React from "react";

import { FormControl, FormHelperText, TextField } from "@mui/material";

export type TextInputProps = {
  readonly name: string;
  readonly value: string;
  readonly placeholder?: string;
  readonly disabled?: boolean;
  readonly error?: string | null;
  readonly onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  readonly required?: boolean;
};

export const TextInput: React.FC<TextInputProps> = ({
  name,
  value,
  placeholder,
  disabled,
  error,
  onChange,
  required,
}) => {
  return (
    <Input
      name={name}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      type="text"
      onChange={onChange}
      required={required}
    />
  );
};

export const MultilineTextInput: React.FC<TextInputProps> = ({
  name,
  value,
  placeholder,
  disabled,
  error,
  onChange,
  required,
}) => {
  return (
    <Input
      disabled={disabled}
      error={error}
      multiline
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      required={required}
    />
  );
};

type InputProps = {
  readonly disabled?: boolean;
  readonly error?: string | null;
  readonly inputRef?: React.Ref<HTMLInputElement>;
  readonly multiline?: boolean;
  readonly name: string;
  readonly placeholder?: string;
  readonly type?: string;
  readonly value: string;
  readonly onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  readonly required?: boolean;
};

const Input: React.FC<InputProps> = ({
  disabled,
  error,
  inputRef,
  multiline,
  name,
  placeholder,
  type,
  value,
  onChange,
  required,
}) => {
  const onBlur = React.useCallback(
    (ev) => {
      if (onChange) {
        const { value } = ev.target;
        const v = value && typeof value === "string" ? value.trim() : "";

        const target = { name: name, value: v };
        onChange({ ...ev, target: target, currentTarget: target });
      }
    },
    [onChange, name]
  );

  return (
    <FormControl error={!!error} sx={{ width: "100%" }}>
      <TextField
        // className={className}
        error={!!error}
        disabled={disabled}
        name={name}
        multiline={multiline}
        minRows={multiline ? 2 : undefined}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        ref={inputRef}
        size="small"
        type={type}
        value={value}
        sx={{ "& .MuiInputBase-input": { borderRadius: 1, backgroundColor: "white" } }}
        required={required}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};
