
const localMonitor = () => {
  const logError = ({ event, args, error }: { event: string; args: Object; error: any }) => {
    console.error({ event, args, error });
  };

  return {
    logError,

    logErrorList: ({
      event,
      args,
      errors,
    }: {
      event: string;
      args: Object;
      errors: readonly Error[];
    }) => {
      errors.forEach((error) => {
        logError({ event, args, error });
      });
    },

    ErrorBoundary: ({ children }: any) => {
      return <>{children}</>;
    },
  };
};

const appsignalMonitor = () => {
  const { ErrorBoundary } = require("@appsignal/react");
  const Appsignal = require("@appsignal/javascript").default;

  const appsignal = new Appsignal({
    key: process.env["REACT_APP_APPSIGNAL_KEY"],
    revision: process.env["REACT_APP_VERSION"],
  });

  appsignal.use(require("@appsignal/plugin-breadcrumbs-network").plugin);
  appsignal.use(require("@appsignal/plugin-path-decorator").plugin);
  appsignal.use(require("@appsignal/plugin-window-events").plugin);

  const logError = ({ event, args, error }: { event: string; args: Object; error: any }) => {
    appsignal.sendError(error, (span: any) => {
      span.setAction(event);
      span.setParams(args);
    });
  };

  return {
    logError,

    logErrorList: ({
      event,
      args,
      errors,
    }: {
      event: string;
      args: Object;
      errors: readonly Error[];
    }) => {
      errors.forEach((error) => {
        logError({ event, args, error });
      });
    },

    ErrorBoundary: ({ fallback, children }: any) => {
      return (
        <ErrorBoundary
          instance={appsignal}
          // tags={{ tag: "value" }}
          fallback={fallback}
        >
          {children}
        </ErrorBoundary>
      );
    },
  };
};

const monitor = process.env["REACT_APP_SETTLD_ENV"] === "local" ? localMonitor : appsignalMonitor;

export default monitor();
