import * as React from "react";
import { FileTags, fileTagsToUiInfo } from "@customTypes/Document";
import { Stack } from "@mui/material";
import { Link } from "../../components/atoms/Link";

import { DashboardInfo, DashboardInfoContext } from "@src/store/DashboardProvider";
import { PictureAsPdfOutlined } from "@mui/icons-material";
import { uploadDocuments } from "./FileUpload";

interface FileInfoI {
  filename: string;
  fileTag: FileTags;
}

export function getMissingDocuments(dashBoardState: DashboardInfo): Array<FileInfoI> {
  const missingDocuments = [];
  if (dashBoardState.intestacyFlow) {
    if (dashBoardState.collectExecutorDocuments) {
      dashBoardState.executorIdDocUploaded ||
        missingDocuments.push({
          filename: fileTagsToUiInfo[FileTags.ExecutorId],
          fileTag: FileTags.ExecutorId,
        });
      dashBoardState.executorGoaDocUploaded ||
        missingDocuments.push({
          filename: fileTagsToUiInfo[FileTags.ExecutorGoa],
          fileTag: FileTags.ExecutorGoa,
        });
    }
    if (dashBoardState.collectNokDocuments) {
      dashBoardState.nokIdDocUploaded ||
        missingDocuments.push({
          filename: fileTagsToUiInfo[FileTags.NokId],
          fileTag: FileTags.NokId,
        });
      dashBoardState.nokGoaDocUploaded ||
        missingDocuments.push({
          filename: fileTagsToUiInfo[FileTags.NokGoa],
          fileTag: FileTags.NokGoa,
        });
    }
  }
  return missingDocuments;
}

export const MissingDocument: React.FC<FileInfoI> = ({ filename, fileTag }) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { state: dashBoardState, dispatch } = React.useContext(DashboardInfoContext);

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      // Handle the selected files here
      await uploadDocuments(
        Array.from(files) as File[],
        fileTag,
        dashBoardState.caseId,
        dashBoardState.signature
      );
      // refresh dashboard
      dispatch({ type: "REFRESH" });
    }
  };

  return (
    <Stack direction="row" columnGap={2} alignItems="center" onClick={handleFileClick}>
      <input type="file" ref={fileInputRef} hidden multiple onChange={handleFileChange} />
      <PictureAsPdfOutlined color="error" />
      <Link to="">
        <span style={{ color: "red" }}>{filename}</span>
      </Link>
    </Stack>
  );
};
