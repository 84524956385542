import { assertUnreachable } from "@utils/Functions";

export type NotifierFormState = {
  readonly requirements?: boolean;
  readonly permission?: boolean;
  readonly notifier?: boolean;
  readonly deceased?: boolean;
  readonly executors?: boolean;
  readonly accounts?: boolean;
  readonly documents?: boolean;
  readonly kyc?: boolean;
  readonly submit?: boolean;
};

export enum Section {
  Accounts = "accounts",
  Deceased = "deceased",
  Notifier = "notifier",
  Documents = "documents",
  KYC = "kyc",
  Submit = "submit",
}

export const nextSection = (section: Section, bypassKYC: boolean): Section => {
  switch (section) {
    case Section.Accounts:
      return Section.Deceased;
    case Section.Deceased:
      return Section.Notifier;
    case Section.Notifier:
      return Section.Documents;
    case Section.Documents:
      return bypassKYC ? Section.Submit : Section.KYC;
    case Section.KYC:
      return Section.Submit;
    case Section.Submit:
      return Section.Accounts;
  }
  assertUnreachable(section);
};

export const sectionForPath = (pathname: string): Section => {
  return (
    {
      "/accounts": Section.Accounts,
      "/documents": Section.Documents,
      "/notifier": Section.Notifier,
      "/deceased": Section.Deceased,
      "/kyc": Section.KYC,
      "/submit": Section.Submit,
    }[pathname] || Section.Accounts
  );
};

export const pathForSection = (section: Section): string => {
  switch (section) {
    case Section.Accounts:
      return "/accounts";
    case Section.Documents:
      return "/documents";
    case Section.Notifier:
      return "/notifier";
    case Section.Deceased:
      return "/deceased";
    case Section.KYC:
      return "/kyc";
    case Section.Submit:
      return "/submit";
  }
  assertUnreachable(section);
};

export const furthestSubmittedSection = (state: NotifierFormState): Section | null => {
  if (state.submit) return Section.Submit;
  if (state.kyc) return Section.KYC;
  if (state.documents) return Section.Documents;
  if (state.notifier) return Section.Notifier;
  if (state.deceased) return Section.Deceased;
  if (state.accounts) return Section.Accounts;
  return null;
};

export const earliestUnsubmittedSection = (
  state: NotifierFormState,
  bypassKYC: boolean
): Section | null => {
  if (!state.accounts) return Section.Accounts;
  if (!state.deceased) return Section.Deceased;
  if (!state.notifier) return Section.Notifier;
  if (!state.documents) return Section.Documents;
  if (!state.kyc && !bypassKYC) return Section.KYC;
  if (!state.submit) return Section.Submit;
  return null;
};
