import * as React from "react";

import { makeStyles } from "@mui/styles";

import { Header, Subheader } from "@atoms/Header";
import { CentredTemplate } from "@templates/CentredTemplate";

export type RequestErrorPageProps = {};

export const RequestErrorPage: React.FC<RequestErrorPageProps> = () => {
  const classes = useStyles();

  return (
    <CentredTemplate headerType="signup">
      <Header>Reset password</Header>
      <Subheader>Request error</Subheader>

      <p>
        An unexpected error has occurred. Please try again in a few minutes (by reloading this page)
        or contact us at <span className={classes.emailAddress}>support@settld.care</span>
      </p>

      <div style={{ paddingTop: "15px" }}></div>
    </CentredTemplate>
  );
};

const useStyles = makeStyles({
  emailAddress: {
    fontWeight: "bold",
  },
});
