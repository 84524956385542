import * as React from "react";

import { Box, Grid, Paper, Stack } from "@mui/material";

import { CompanyType } from "@customTypes/index";
import { SectorIcon } from "../atoms/SectorIcon";
import { colors } from "../../styles/constants";

export type SectorGroup = {
  label: string;
  sectors: ReadonlyArray<CompanyType>;
  newSPSector: CompanyType;
};

type TitleInputProps = {
  value: SectorGroup | undefined;
  onValueChange: (value: SectorGroup) => void;
};

const sectorGroups: ReadonlyArray<SectorGroup> = [
  { label: "Banking", sectors: [CompanyType.Banking], newSPSector: CompanyType.Banking },
  {
    label: "Building Society",
    sectors: [CompanyType.BuildingSociety],
    newSPSector: CompanyType.BuildingSociety,
  },
  { label: "Insurance", sectors: [CompanyType.Insurance], newSPSector: CompanyType.Insurance },
  { label: "Pensions", sectors: [CompanyType.Pension], newSPSector: CompanyType.Pension },
  {
    label: "Investments",
    sectors: [CompanyType.Investments],
    newSPSector: CompanyType.Investments,
  },
  {
    label: "Energy",
    sectors: [CompanyType.Energy],
    newSPSector: CompanyType.Energy,
  },
  { label: "Water", sectors: [CompanyType.Water], newSPSector: CompanyType.Water },
  {
    label: "Credit cards/Loans",
    sectors: [CompanyType.CreditCards, CompanyType.Mortgage, CompanyType.Loans],
    newSPSector: CompanyType.Other,
  },
  {
    label: "Telecommunications",
    sectors: [
      CompanyType.Broadband,
      CompanyType.Mobile,
      CompanyType.Satellite,
      CompanyType.Telecoms,
      CompanyType.TV,
    ],
    newSPSector: CompanyType.Other,
  },
  {
    label: "Subscriptions",
    sectors: [
      CompanyType.Charities,
      CompanyType.Ecommerce,
      CompanyType.LoyaltyCards,
      CompanyType.Magazines,
      CompanyType.SaaS,
      CompanyType.Streaming,
      CompanyType.Subscriptions,
    ],
    newSPSector: CompanyType.Other,
  },
  {
    label: "Social Media",
    sectors: [CompanyType.Email, CompanyType.SocialMedia],
    newSPSector: CompanyType.SocialMedia,
  },
  {
    label: "Other",
    sectors: [CompanyType.Betting, CompanyType.Other],
    newSPSector: CompanyType.Other,
  },
];

export const findGroupFromCompanyType = (companyType?: CompanyType): SectorGroup | null => {
  if (!companyType) {
    return null;
  }

  return sectorGroups.find((group) => group.sectors.includes(companyType)) || null;
};

export const CompanyTypeInput: React.FC<TitleInputProps> = (props) => {
  const { onValueChange, value } = props;

  return (
    <Grid container spacing={2} alignItems="stretch">
      {sectorGroups.map((group, index) => {
        return (
          <SectorItem
            key={index}
            focus={value === group}
            label={group.label}
            sector={group.sectors[0]}
            onValueChange={() => onValueChange(group)}
          />
        );
      })}
    </Grid>
  );
};

type SectorItemProps = {
  focus?: boolean;
  label: string;
  sector: CompanyType;
  onValueChange: () => void;
};

const SectorItem: React.FC<SectorItemProps> = ({ focus, label, sector, onValueChange }) => {
  return (
    <Grid item xs={6} sm={3} sx={{ minHeight: "100px" }}>
      <Paper
        variant="outlined"
        sx={{
          height: "100%",
          padding: 1,
          backgroundColor: focus ? colors.accentTeal : "transparent",
          cursor: "pointer",
        }}
        onClick={onValueChange}
      >
        <Stack justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Box style={{ color: focus ? "white" : colors.fadedText }}>
            <SectorIcon fontSize="large" color="inherit" sector={sector} />
          </Box>

          <Box sx={{ textAlign: "center", color: focus ? "white" : "inherit" }}>{label}</Box>
        </Stack>
      </Paper>
    </Grid>
  );
};
