import * as React from "react";
import { Route, Routes } from "react-router-dom";

import { NewAccountPage } from "./NewAccountPage";
import { StatusPage } from "./StatusPage";

export const Router: React.FC<{
  readonly signature: string | null;
  readonly caseId: string;
  readonly accountId?: string;
}> = ({ signature, caseId, accountId }) => {
  return (
    <Routes>
      <Route path="/" element={<StatusPage caseId={caseId} signature={signature} />} />

      <Route path="accounts">
        <Route path="new" element={<NewAccountPage caseId={caseId} signature={signature} />} />
      </Route>

      {/* Legacy */}
      <Route
        path=":accountId"
        element={<StatusPageWrapper caseId={caseId} signature={signature} />}
      />
    </Routes>
  );
};

const StatusPageWrapper: React.FC<{
  readonly caseId: string;
  readonly signature: string | null;
}> = ({ caseId, signature }) => {
  return <StatusPage caseId={caseId} signature={signature} />;
};
