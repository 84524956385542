import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { colors } from "../../styles/constants";
import { PersonDetails, Relationship, Titles } from "../../types/NotifierFormData";
import { BooleanInput } from "../../components/atoms/BooleanInput";
import { DateInput } from "../../components/atoms/DateInput";
import { EmailAddressInput } from "../../components/atoms/EmailAddressInput";
import { Header } from "../../components/atoms/Header";
import { PhoneNumberTextInput } from "../../components/atoms/PhoneNumberTextInput";
import { TextInput } from "../../components/atoms/TextInput";
import { NewTitleInput } from "../../components/atoms/TitleInput";
import { NewAddressInput } from "../../components/molecules/AddressInput";
import { FormField } from "../../components/molecules/FormField";
import { FormStack } from "../../components/molecules/FormStack";
import { InfoBoxTrigger } from "../../components/molecules/InfoBoxTrigger";
import { RelationToDeceased } from "../../components/molecules/RelationToDeceased";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { VulnerableInfoBox } from "@src/components/molecules";

const currentSection = "Executor";

export type PersonDetailsErrors = Partial<Record<keyof PersonDetails, string>>;

export type ExecutorDetailsViewProps = {
  titleHidden: boolean;
  serviceProvider: { customForm?: string };
  record: PersonDetails;
  errors: PersonDetailsErrors;

  onRoleChange: (value: string) => void;
  onDateOfBirthChange: (value?: string) => void;
  onNext: () => void;
  onFieldChange: any;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  onIsVulnerableChange: (value: boolean) => void;
  showIDVerificationDialog: boolean;
  hideIDVerificationDialog: () => void;
};

export const ExecutorDetailsView: React.FC<ExecutorDetailsViewProps> = ({
  serviceProvider,
  errors,
  record,
  onDateOfBirthChange,
  onNext,
  onFieldChange,
  onSectionClick,
  onIsVulnerableChange,
  updateTemplate,
}) => {
  React.useEffect(() => {
    updateTemplate({
      busy: false,
      currentSection,
      onNext,
      saveLabel: null,
      onSectionClick,
    });
  }, [onNext, onSectionClick, updateTemplate]);

  return (
    <>
      <Stack rowGap={4}>
        <Header level={1} color={colors.accentTeal}>
          Executor Information
        </Header>
        <Typography variant="body1">
          <b>Please provide details for an Executor of the Will.</b> When you submit this form, they
          will be sent an email asking them to sign a grant of authority. The grant of authority
          gives you (and Settld) permission to act on their behalf. We cannot proceed without it.
        </Typography>
        <FormStack>
          <FormField halfWidthByItself label="Title">
            <NewTitleInput
              name="title"
              onValueChange={(value) => onFieldChange({ target: { name: "title", value } })}
              value={record.title || ""}
              error={errors?.title}
            />
          </FormField>

          <FormField halfWidth label="First name">
            <TextInput
              name="firstName"
              value={record.firstName || ""}
              error={errors?.firstName}
              onChange={onFieldChange}
            />
          </FormField>

          <FormField halfWidth label="Last name">
            <TextInput
              name="lastName"
              value={record.lastName || ""}
              error={errors?.lastName}
              onChange={onFieldChange}
            />
          </FormField>

          {record.role !== "solicitor" && (
            <FormField halfWidthByItself label="Date of birth">
              <DateInput
                value={record.dateOfBirth || ""}
                onValueChange={onDateOfBirthChange}
                error={errors?.dateOfBirth}
                pastOnly
              />
            </FormField>
          )}

          <FormField label="Address">
            <NewAddressInput
              name="address"
              value={record.address || ""}
              onChange={onFieldChange}
              error={errors?.address}
            />
          </FormField>

          <FormField halfWidth label="City or Town">
            <TextInput
              name="city"
              value={record.city || ""}
              onChange={onFieldChange}
              error={errors?.city}
            />
          </FormField>

          <FormField halfWidth label="Postcode">
            <TextInput
              name="postcode"
              value={record.postcode || ""}
              onChange={onFieldChange}
              error={errors?.postcode}
            />
          </FormField>

          <FormField halfWidth label="Telephone number">
            <PhoneNumberTextInput
              name="contactNumber"
              value={record.contactNumber || ""}
              onChange={onFieldChange}
              error={errors?.contactNumber}
            />
          </FormField>

          <FormField halfWidth label="Email address">
            <EmailAddressInput
              name="email"
              value={record.email || ""}
              onChange={onFieldChange}
              error={errors?.email}
            // autoFocus={updatingExecutorEmailAddress}
            />
          </FormField>

          {record.role !== "solicitor" && (
            <FormStack substack spacing={0}>
              <FormField>
                <BooleanInput
                  label={
                    <span>
                      I consider this person to be vulnerable (temporarily or long-term) and think they may appreciate additional support.
                      <InfoBoxTrigger white width="448px" content={VulnerableInfoBox()} />
                    </span>
                  }
                  value={record.isVulnerable}
                  onValueChange={onIsVulnerableChange}
                />
              </FormField>

              {serviceProvider.customForm === "portsmouth-water" && record.isVulnerable && (
                <FormField>
                  To notify Portsmouth Water of vulnerability,{" "}
                  <a
                    href="https://www.portsmouthwater.co.uk/customer-services/priority-services/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    click here
                  </a>{" "}
                  to sign up to their priority services register.
                </FormField>
              )}
            </FormStack>
          )}

          {record.role !== "solicitor" && (
            <FormField
              halfWidthByItself
              label={<span>The person who died was their... &nbsp;</span>}
            >
              <br></br>
              <RelationToDeceased
                name="relationshipToDeceased"
                value={record.relationshipToDeceased || ""}
                otherValue={record.relationshipToDeceasedOther || ""}
                error={errors.relationshipToDeceased || errors.relationshipToDeceasedOther}
                onFieldChange={onFieldChange}
              />
            </FormField>
          )}
        </FormStack>
      </Stack>
    </>
  );
};


