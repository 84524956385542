import * as React from "react";

import { useMediaQuery, useTheme } from "@mui/material";

import { KYCTemplateDesktop } from "./KYCTemplateDesktop";
import { KYCTemplateMobile } from "./KYCTemplateMobile";

export type KYCTemplateProps = {
  busy?: boolean;
  children: React.ReactNode;
};

export const KYCTemplate: React.FC<KYCTemplateProps> = ({ busy, children }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  if (matches) {
    return <KYCTemplateMobile busy={busy}>{children}</KYCTemplateMobile>;
  }

  return <KYCTemplateDesktop busy={busy}>{children}</KYCTemplateDesktop>;
};
