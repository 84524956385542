import React from "react";
import { makeStyles } from "@mui/styles";
import { colors } from "@styles/constants";

export const RequestDetailsField: React.FC<{ label: React.ReactNode }> = ({ label, children }) => {
  const classes = useStyles();

  return (
    <p>
      <span className={classes.fieldLabel}>{label}</span>
      <br />
      <span className={classes.fieldValue}>{children}</span>
    </p>
  );
};

const useStyles = makeStyles({
  fieldLabel: {
    color: colors.fadedText,
    textTransform: "uppercase",
    letterSpacing: "0.6px",
    fontSize: "smaller",
  },

  fieldValue: {
    color: "#555555",
  },
});
