import * as React from "react";

import { Stack, Typography } from "@mui/material";

import { Header } from "../atoms/Header";

export type ErrorPaneProps = {
  readonly title?: React.ReactNode;
  readonly message?: React.ReactNode;
  readonly description?: React.ReactNode;
};

export const ErrorPane: React.FC<ErrorPaneProps> = ({ title, message, description }) => {
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
      <Header level={1}>{title}</Header>
      <Header level={2}>{message}</Header>
      <Typography variant="body1">{description}</Typography>
    </Stack>
  );
};
