import * as React from "react";

import { Box, Container, Stack } from "@mui/material";

import { PageHeaderWithSignout } from "@organisms/PageHeaderWithSignout";
import { BusyOverlay } from "@atoms/BusyOverlay";

export type KYCTemplateMobileProps = {
  busy?: boolean;
  children: React.ReactNode;
};

export const KYCTemplateMobile: React.FC<KYCTemplateMobileProps> = ({ busy, children }) => {
  return (
    <BusyOverlay hidden={!busy}>
      <Stack sx={{ height: "100vh", rowGap: "16px", paddingBottom: "16px" }}>
        <PageHeaderWithSignout />

        <Container sx={{ flex: 1 }}>
          <Box sx={{ height: "100%", position: "relative" }}>{children}</Box>
        </Container>
      </Stack>
    </BusyOverlay>
  );
};
