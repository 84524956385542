import * as React from "react";

import { Box, Stack } from "@mui/material";

import { colors } from "@styles/constants";
import { Document } from "../../../../types/Document";
import { AccountDetails, YesNo, } from "@customTypes/index";
import { Person, Responsible } from "./Responsible";
import { FieldRow, FieldsTable } from "../../../../components/molecules/FieldsTable";
import { readableAccountNextSteps, readableAccountType } from "@src/utils/accounts";

export const MortgageAccount: React.FC<{
  readonly persons: ReadonlyArray<Person>;
  readonly account: AccountDetails;
  readonly documents: ReadonlyArray<Document>;
}> = ({ persons, account, documents }) => {
  const {
    companyType,
    holderNumber,
    accountType,
    accountTypeOther,
    jointClaim,
    nextSteps,
    responsibleId,
  } = account;

  return (
    <FieldsTable>
      <FieldRow label="Customer reference" value={holderNumber} />

      <FieldRow label="Mortgage type" value={readableAccountType(accountType, accountTypeOther)} />

      <FieldRow label="Joint policy?" value={jointClaim === YesNo.Yes ? "Yes" : "No"} />

      <FieldRow label="Next step" value={readableAccountNextSteps(nextSteps, companyType)} />

      <FieldRow
        label="Responsible"
        value={<Responsible persons={persons} responsibleId={responsibleId} />}
      />

      {documents && documents.length > 0 && (
        <FieldRow
          label="Documents"
          value={
            <Stack>
              {documents.map((doc, index) => (
                <Box key={index}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={doc.downloadUrl}
                    style={{ color: colors.primary }}
                  >
                    {doc.filename}
                  </a>
                </Box>
              ))}
            </Stack>
          }
        />
      )}
    </FieldsTable>
  );
};
