import * as React from "react";

import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { colors } from "@styles/constants";
import { AttachFileButton } from "../../../components/atoms/AttachFileButton";
import { SubmitButton } from "../../../components/atoms/SubmitButton";
import { Message } from "./message";

export type SendMessageProcType = (props: {
  content: string;
  attachment: File | null;
}) => Promise<void>;

export const InputBox: React.FC<{
  readonly sendMessage: SendMessageProcType;
  readonly lastReceivedMessage: Message | null;
  readonly markMessageAsRead: (messageId: string) => Promise<void>;
}> = ({ lastReceivedMessage, sendMessage: submitMessage, markMessageAsRead }) => {
  const [{ attachment, typedText, sending, error }, setState] = React.useState<{
    attachment: File | null;
    typedText: string;
    sending: boolean;
    error?: string;
  }>({
    attachment: null,
    typedText: "",
    sending: false,
  });

  const sendActive =
    !sending &&
    typedText.length > 0 &&
    (!lastReceivedMessage || lastReceivedMessage.isReadByNotifier);
  const textActive = !sending;

  const textChanged = React.useCallback((event) => {
    event.preventDefault();
    const typedText = event.target.value;
    setState((s) => ({ ...s, typedText }));
  }, []);

  const sendMessage = React.useCallback(
    (ev) => {
      ev.preventDefault();
      if (!sendActive) {
        return;
      }

      setState((s) => ({ ...s, sending: true }));

      submitMessage({
        content: typedText,
        attachment,
      }).then(
        (_) => {
          console.log("Successfully sent message");
          setState((s) => ({ attachment: null, typedText: "", sending: false }));
        },
        (error) => {
          console.log("Failed to send message", error);
          setState((s) => ({
            ...s,
            sending: false,
            error: "Failed to send message; please try again.",
          }));
        }
      );
    },
    [attachment, typedText, sendActive]
  );

  const addFile = React.useCallback(
    (ev) => {
      const file = ev.target.files[0];
      setState((s) => ({ ...s, attachment: file }));
    },
    [setState]
  );

  const removeFile = React.useCallback(() => {
    if (sending) {
      return;
    }

    setState((s) => ({ ...s, attachment: null }));
  }, [setState, sending]);

  const ensureLastMessageRead = React.useCallback(() => {
    if (lastReceivedMessage && !lastReceivedMessage.isReadByNotifier) {
      markMessageAsRead(lastReceivedMessage.id).then(
        () => {
          // do nothing
        },
        (_error) => {
          // TODO
        }
      );
    }
  }, [lastReceivedMessage]);

  return (
    <InputBoxUI
      attachment={attachment}
      sendActive={sendActive}
      textActive={textActive}
      typedText={typedText}
      error={error}
      addFile={addFile}
      removeFile={removeFile}
      sendMessage={sendMessage}
      textChanged={textChanged}
      ensureLastMessageRead={ensureLastMessageRead}
    />
  );
};

export const InputBoxUI: React.FC<{
  attachment: File | null;
  sendActive: boolean;
  textActive: boolean;
  typedText: string;
  error: string | undefined;

  addFile: (event: React.ChangeEvent<Element>) => void;
  removeFile: () => void;
  sendMessage: (event: React.FormEvent<HTMLFormElement>) => void;
  textChanged: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  ensureLastMessageRead: () => void;
}> = ({
  attachment,
  sendActive,
  textActive,
  typedText,
  error,

  addFile,
  removeFile,
  sendMessage,
  textChanged,
  ensureLastMessageRead,
}) => {
  const classes = useStyles();

  return (
    <div>
      <form onSubmit={sendMessage}>
        <div className={classes.relativeContainer}>
          {attachment ? (
            <InputBoxWithAttachment
              attachment={attachment}
              textActive={textActive}
              typedText={typedText}
              textChanged={textChanged}
              removeFile={removeFile}
              ensureLastMessageRead={ensureLastMessageRead}
            />
          ) : (
            <InputBoxWithoutAttachment
              textActive={textActive}
              typedText={typedText}
              textChanged={textChanged}
              addFile={addFile}
              ensureLastMessageRead={ensureLastMessageRead}
            />
          )}
        </div>

        <div className={classes.inputBoxSendButtonRow}>
          <div className={classes.error}>{error}</div>

          <div>
            <SubmitButton label="Send" disabled={!sendActive} />
          </div>
        </div>
      </form>
    </div>
  );
};

const InputBoxWithAttachment: React.FC<{
  attachment: File;
  textActive: boolean;
  typedText: string;
  textChanged: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  removeFile: () => void;
  ensureLastMessageRead: () => void;
}> = ({ typedText, textChanged, textActive, attachment, removeFile, ensureLastMessageRead }) => {
  const classes = useStyles();

  return (
    <div className={classes.inputBoxContainer}>
      <textarea
        className={classes.inputBoxTextarea}
        placeholder="Type a message here"
        rows={2}
        value={typedText}
        onChange={textChanged}
        disabled={!textActive}
        onFocus={ensureLastMessageRead}
      />

      <div className={classes.attachmentContainer}>
        <div className={classes.inputBoxAttachmentName}>{attachment ? attachment.name : null}</div>

        <a className={classes.link} onClick={removeFile}>
          <Close />
        </a>
      </div>
    </div>
  );
};

const InputBoxWithoutAttachment: React.FC<{
  textActive: boolean;
  typedText: string;
  textChanged: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  addFile: (event: React.ChangeEvent<Element>) => void;
  ensureLastMessageRead: () => void;
}> = ({ typedText, textChanged, textActive, addFile, ensureLastMessageRead }) => {
  const classes = useStyles();

  return (
    <div className={classes.inputBoxContainer}>
      <textarea
        className={classes.inputBoxTextarea}
        placeholder="Type a message here"
        rows={3}
        value={typedText}
        onChange={textChanged}
        disabled={!textActive}
        onFocus={ensureLastMessageRead}
      />

      <div className={classes.attachButtonContainer}>
        <AttachFileButton onFileChange={addFile} disabled={!textActive} />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  relativeContainer: {
    position: "relative",
  },

  attachButtonContainer: {
    position: "absolute",
    bottom: "5px",
    right: "10px",
  },

  attachmentContainer: {
    display: "flex",
    padding: "0 10px 10px 10px",
  },

  inputBoxContainer: {
    borderColor: "#dfdfdf",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "5px",
    "&:focus-within": {
      outline: `2px solid ${colors.fadedText}`,
      outlineOffset: "-2px",
    },
    marginBottom: "10px",
  },

  inputBoxTextarea: {
    display: "block",
    border: "none",
    outline: "none",
    borderRadius: "5px",
    color: "black",
    padding: "10px",
    resize: "none",
    width: "100%",
    "&::placeholder": {
      color: "#b1b1b1",
    },
  },

  inputBoxSendButtonRow: {
    display: "flex",
    justifyContent: "space-between",
  },

  inputBoxAttachmentName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    flexGrow: 1,
    color: `${colors.continueButtonBackground} !important`,
  },

  link: {
    cursor: "pointer",
  },

  error: {
    color: colors.error,
    paddingLeft: "11px",
    paddingRight: "10px",
  },
});
