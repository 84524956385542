import * as React from "react";

import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";

export type SelectInputProps = {
  name: string;
  value: string;
  options: Array<{ label: string; value: string }>;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  onValueChange: (value: string) => void;
};

export const SelectInput: React.FC<SelectInputProps> = ({
  name,
  value,
  options,
  placeholder,
  disabled,
  error,
  onValueChange,
}) => {
  const handleChange = React.useCallback(
    (ev) => {
      onValueChange(ev.target.value);
    },
    [onValueChange]
  );

  return (
    <FormControl error={!!error} sx={{ width: "100%" }}>
      <Select
        autoComplete="off"
        disabled={disabled}
        displayEmpty={!!placeholder}
        name={name}
        size="small"
        value={value}
        onChange={handleChange}
        sx={{
          width: "100%",
          backgroundColor: "white",
        }}
      >
        {placeholder && (
          <MenuItem disabled value="">
            <em>{placeholder || ""}</em>
          </MenuItem>
        )}

        {options.map(({ label, value }, index) => {
          return (
            <MenuItem key={index} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};
