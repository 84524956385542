import * as React from "react";
import { useNavigate } from "react-router-dom";

import urlPaths from "../../urlPaths";
import { PageHeader } from "./PageHeader";

export type PageHeaderWithSigninProps = {
  includeLogo?: boolean;
};

export const PageHeaderWithSignin: React.FC<PageHeaderWithSigninProps> = ({ includeLogo }) => {
  const navigate = useNavigate();

  const onLogin = React.useCallback(() => {
    navigate(urlPaths.signin());
  }, [navigate]);

  return <PageHeaderWithSigninView includeLogo={includeLogo} onClick={onLogin} />;
};

export type PageHeaderWithSigninViewProps = {
  includeLogo?: boolean;
  onClick: () => void;
};

export const PageHeaderWithSigninView: React.FC<PageHeaderWithSigninViewProps> = ({
  includeLogo,
  onClick,
}) => {
  return (
    <PageHeader
      includeLogo={includeLogo}
      prompt={"Already have an account?"}
      actionLabel={"Log in"}
      onClick={onClick}
    />
  );
};
