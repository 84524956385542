import * as React from "react";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Modal,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { colors } from "../../styles/constants";
import { InfoOutlined } from "@mui/icons-material";
import { Button } from "../atoms/Button";
import { Link } from "../atoms/Link";

export type InfoBoxTriggerProps = {
  left?: boolean;
  white?: boolean;
  width?: string;
  content: React.ReactNode;
  children?: React.ReactElement;
};

export const InfoBoxTrigger: React.FC<InfoBoxTriggerProps> = ({
  left,
  white,
  width,
  children,
  content,
}) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <>
      <HtmlTooltip
        disableFocusListener
        disableTouchListener
        white={white}
        width={width}
        placement={left ? "left-start" : "right-start"}
        title={<Box>{content}</Box>}
      >
        <span onClick={() => setDialogOpen(true)}>
          {children || <InfoOutlined fontSize="small" color="secondary" />}
        </span>
      </HtmlTooltip>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogContent>
          <Box>{content}</Box>
        </DialogContent>
        <DialogActions>
          <Box sx={{ paddingBottom: 1, paddingRight: 2 }}>
            <Link variant="secondary" onClick={() => setDialogOpen(false)}>
              Close
            </Link>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

const HtmlTooltip = styled(
  ({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />,
  { shouldForwardProp: (prop) => prop !== "width" && prop !== "white" }
)<TooltipProps & { white?: boolean; width?: string }>(({ white, width, theme }) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: white ? "white" : colors.infoBoxBackground,
      border: "none",
      borderRadius: "15px",
      boxShadow: theme.shadows[6],
      color: colors.primaryText,
      fontSize: "16px",
      fontWeight: "normal",
      maxWidth: width ? "none" : "400px",
      minWidth: width ? "none" : "300px",
      padding: theme.spacing(3),
      width: width,
    },
  };
});
