import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";

import { Account } from "../../types/Account";
import { Attachment } from "../../types/Attachment";
import { Case } from "../../types/Case";
import { YesNo } from "@customTypes/index";
import { Button } from "../../components/atoms/Button";
import { YesNoCheckboxField } from "../../components/atoms/YesNoField";
import { Accordion } from "../../components/molecules/Accordion";
import { updateAdditionalservices } from "@api/caseApi";
import { additionalServices, AdditionalHelpDetails } from "@customTypes/index";

export type AdditionalServicesProps = {
  caseId: string;
  signature: string | null;
  readonly caseRecord: Case & { accounts: Array<Account>; attachments?: Attachment[] };
};

const fromCase = (caseRecord: Case): AdditionalHelpDetails => {
  var result = {} as AdditionalHelpDetails;
  additionalServices.forEach(([ui, key]) => {
    // Use the field as a key to access the corresponding value in 'caseRecord'
    result[key] = caseRecord[key];
  });

  return result;
};

export const AdditionalServicesAccordion: React.FC<AdditionalServicesProps> = (props) => {
  const [show, setShow] = React.useState(false);

  const showDialog = React.useCallback(() => setShow(true), [setShow]);
  const hideDialog = React.useCallback(() => setShow(false), [setShow]);

  return (
    <>
      <Accordion expanded={true} title="Additional services">
        <AdditionalServices {...props} showDialog={showDialog} />
      </Accordion>
      <Dialog open={show} onClose={hideDialog}>
        <DialogTitle>Selected partners updated</DialogTitle>
        <DialogContent>
          <p>Our team will be in touch with you shortly to discuss your additional services.</p>
        </DialogContent>

        <DialogActions>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "40px",
              alignItems: "baseline",
            }}
          >
            <div>
              <Button variant="primary" size="small" onClick={hideDialog}>
                <span style={{ textTransform: "uppercase" }}>OK</span>
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AdditionalServices: React.FC<AdditionalServicesProps & { showDialog: () => void }> = ({
  caseId,
  signature,
  caseRecord,
  showDialog,
}) => {
  const [record, setRecord] = React.useState(fromCase(caseRecord));

  const onFieldChange = React.useCallback(
    ({ target: { name, value } }: { target: { name: string; value: string | undefined } }) => {
      setRecord((s) => ({ ...s, [name]: value }));
    },
    [setRecord]
  );

  const onFieldValueChange = (name: string) => (value: YesNo) =>
    onFieldChange({ target: { name, value } });

  const Entry = ({ label, name }: { label: string; name: keyof AdditionalHelpDetails }) => (
    <Grid item xs={12} md={4}>
      <YesNoCheckboxField
        nopadding
        smallFont
        label={label}
        value={record[name]}
        onValueChange={onFieldValueChange(name)}
      />
    </Grid>
  );

  // React.useEffect(() => {
  //   if (!localStorage.getItem("additional-services-requested")) {
  //     setShow(true);
  //   }
  // }, []);

  const onSubmit = React.useCallback(() => {
    updateAdditionalservices({
      caseId,
      signature,
      record,
    }).then(
      () => {
        localStorage.setItem("additional-services-requested", "true");
        showDialog();
        setTimeout(() => window.location.reload(), 3000);
      },
      (error: any) => {
        console.error(error);
        showDialog();
      }
    );
  }, [caseId, signature, record]);

  return (
    <Stack rowGap={1}>
      <div>Select all that apply, and we’ll connect you to a trusted Settld Partner</div>

      <Grid container style={{ paddingLeft: "9px" }}>
        {additionalServices.map(([ui, internal_field]: any) => (
          <Entry key={internal_field} label={ui} name={internal_field} />
        ))}
      </Grid>

      <Box>
        <Button variant="primary" size="small" onClick={onSubmit}>
          Submit
        </Button>
      </Box>
    </Stack>
  );
};

const useStyles = makeStyles({
  relativeContainer: {
    position: "relative",
  },

  addButtonContainer: {
    position: "absolute",
    top: "-2px",
    right: "20px",
  },

  ul: {
    paddingLeft: 0,
    listStylePosition: "inside",
  },
});
