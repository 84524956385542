import * as React from "react";

import { Box, Paper, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "../../styles/constants";
import { MenuItem, MenuItemProps } from "./MenuItem";
import { FormMenuEntry } from "@customTypes/index";

export type FormMenuDesktopProps = {
  currentKey?: string;
  entries: Array<FormMenuEntry>;
  onSelect: (entry: string) => void;
};

export const FormMenuDesktop: React.FC<FormMenuDesktopProps> = ({
  currentKey,
  entries,
  onSelect,
}) => {
  const onEntryClick = React.useCallback(
    (_event, key) => {
      onSelect(key);
    },
    [onSelect]
  );

  const doneCount = entries.filter((entry) => entry.done).length;

  return (
    <Box sx={{ width: "100%" }}>
      <Container>
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: "20px" }}>
          <Typography variant="subtitle1">
            Your progress ({doneCount}/{entries.length})
          </Typography>
          <Stack spacing={2}>
            {entries.map(({ key, label, done, error }) => {
              return (
                <Item
                  key={key}
                  code={key}
                  label={label}
                  done={done}
                  error={error}
                  current={currentKey === key}
                  onClick={(event) => onEntryClick(event, key)}
                />
              );
            })}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

const Container = styled(Paper)<any>(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : colors.lighterTeal,
  padding: "20px 24px 30px 24px",
  borderRadius: "16px",
})) as typeof Paper;

const Item: React.FC<MenuItemProps> = ({ code, label, done, error, current, onClick }) => {
  return (
    <Paper sx={{ borderRadius: "8px", overflow: "hidden" }}>
      <MenuItem
        code={code}
        label={label}
        done={done}
        error={error}
        current={current}
        onClick={onClick}
      />
    </Paper>
  );
};
