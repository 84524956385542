import "promise-polyfill/src/polyfill";
import "unfetch/polyfill";
import "abortcontroller-polyfill";
import "./styles/index.scss";

import * as React from "react";
import { BrowserRouter, Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import monitor from "@utils/monitoring";
import { setLocalSession, useLocalSession } from "@utils/session";
import { theme } from "./styles/stylesheet";
import urlPaths from "./urlPaths";
import { AdminEntrypoint } from "./pages/AdminEntrypoint";
import { ForgotPasswordPage } from "./pages/Authentication/ForgotPasswordPage/ForgotPasswordPage";
import { ResetPasswordPage } from "./pages/Authentication/ResetPasswordPage/ResetPasswordPage";
import { SigninPage } from "./pages/Authentication/SigninPage";
import { SignupPage } from "./pages/Authentication/SignupPage";
import { CaseClosedFeedbackPage } from "./pages/CaseClosedFeedbackPage";
import { StatusPage } from "./pages/Dashboard";
import { BrokenLinkPage } from "./pages/Error/BrokenLinkPage";
import { BugFallbackPage } from "./pages/Error/BugFallbackPage";
import { ExpressFormPage } from "./pages/ExpressForm";
import { FormRatingPage } from "./pages/FormEpilogue/FormRatingPage";
import { KYCPage } from "./pages/KYCPage";
import { NotifierFormPage } from "./pages/NotifierForm";
import { PermissionPage } from "./pages/Onboarding/PermissionPage";
import { OpenBankingCallbackPage } from "./pages/OpenBanking/CallbackPage";
import { OpenBankingDonePage } from "./pages/OpenBanking/DonePage";
import { OpenBankingErrorPage } from "./pages/OpenBanking/ErrorPage";
import { OpenBankingLinkPage } from "./pages/OpenBanking/LinkPage";
import { RootPage } from "./pages/RootPage";
import { ExpressKYCPage } from "./pages/ExpressForm/ExpressKYCPage";
import { RegistrationPage } from "./pages/registration/RegistrationPage";
import { OnBoardingAdditionalServicesPage } from "./pages/Onboarding/OnboardingAdditionalServicesPage";
import { OnBoardingInfoPage } from "./pages/Onboarding/OnboardingInfoPage";
import { AdditionalServicesPage } from "./pages/FormEpilogue/AdditionalServicesSimplePage";
import { ExpressProvider } from "@store/ExpressProvider";
import { DashboardInfoProvider } from "./store/DashboardProvider";

// flow
// submit -> 8
// kyc -> 7
// documents -> 6
// accounts -> 5
// executors -> 4
// deceased -> 3
// notifier -> 2
// permission -> 1
// requirements -> 0 -> 0.1 onboarding additional services -> 0.2 onboarding info page

export const App = (): JSX.Element => {
  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <monitor.ErrorBoundary fallback={() => <BugFallbackPage />}>
          <BrowserRouter>
            <Routes>
              <Route path="b2c" element={<Navigate to="/" replace />} />
              <Route path="B2C" element={<Navigate to="/" replace />} />
              <Route path="bbc-signup" element={<Navigate to="/" replace />} />
              <Route path="cruse-signup" element={<Navigate to="/" replace />} />
              <Route path="greenfunerals" element={<Navigate to="/" replace />} />
              <Route path="poppys" element={<Navigate to="/" replace />} />

              <Route path="regform-registrar" element={<RegistrationPage />} />
              <Route path="/notifier-form/index.html" element={<LegacyURL />} />
              <Route path="index.html" element={<RootPage />}></Route>
              <Route path="/" element={<RootPage />}></Route>

              <Route
                path="sign-up"
                element={<SignupPage nextPageURL={urlPaths.onBoardingAdditionalServices()} />}
              />
              <Route
                path="sign-up/:website"
                element={<SignupPage nextPageURL={urlPaths.onBoardingAdditionalServices()} />}
              />
              {/* <Route path="onboarding/requirements" element={<WrappedRequirementsPage />} /> */}
              <Route
                path="onboarding/additional-services"
                element={<WrappedOnboardingAdditionalServicesPage />}
              />
              <Route path="onboarding/info-page" element={<WrappedOnBoardingInfoPage />} />
              <Route path="onboarding/permission" element={<WrappedPermissionPage />} />

              <Route
                path="express/:slug/*"
                element={
                  <ExpressProvider>
                    <ExpressFormPage />
                  </ExpressProvider>
                }
              />

              <Route path="express-kyc" element={<ExpressKYCPage />} />

              <Route path="sign-in" element={<SigninPage nextPageURL={urlPaths.root()} />} />
              <Route path="forgot-password" element={<ForgotPasswordPage />} />
              <Route path="reset-password/:token" element={<WrappedResetPasswordPage />} />

              <Route path="form/*" element={<WrappedNotifierFormPage />} />

              <Route path="kyc" element={<WrappedKYCPage />} />

              <Route path="form-rating" element={<WrappedFormRatingPage />} />
              <Route path="additional-services" element={<WrappedAdditionalServicesPage />} />

              <Route path="status/*" element={<WrappedStatusPage />} />

              <Route path="account-search" element={<WrappedOpenBankingLinkPage />} />
              <Route path="account-search/callback" element={<OpenBankingCallbackPage />} />
              <Route path="account-search/done" element={<OpenBankingDonePage />} />
              <Route path="account-search/error" element={<WrappedOpenBankingErrorPage />} />

              <Route path="case-closed-feedback" element={<WrappedCaseClosedFeedbackPage />} />

              <Route path="admin/*" element={<AdminEntrypoint />} />

              <Route path="*" element={<BrokenLinkPage />} />
            </Routes>
          </BrowserRouter>
        </monitor.ErrorBoundary>
      </LocalizationProvider>{" "}
    </ThemeProvider>
  );
};

const WrappedResetPasswordPage = () => {
  const { token } = useParams() as { token: string };

  if (!token) {
    return null;
  }

  return <ResetPasswordPage token={token} />;
};

// const WrappedRequirementsPage = () => {
//   const { caseId, signature } = useLocalSession(urlPaths.signup());

//   if (!caseId) {
//     return null;
//   }

//   return (
// <RequirementsPage caseId={caseId} signature={signature} nextPageURL={urlPaths.permission()} />
//   );
// };
const WrappedOnBoardingInfoPage = () => {
  const { caseId, signature } = useLocalSession(urlPaths.signup());

  if (!caseId) return null;

  return <OnBoardingInfoPage navigateNext={urlPaths.permission()} />;
};
const WrappedOnboardingAdditionalServicesPage = () => {
  const { caseId, signature } = useLocalSession(urlPaths.signup());

  if (!caseId) {
    return null;
  }

  return (
    <OnBoardingAdditionalServicesPage
      caseId={caseId}
      signature={signature}
      navigateNext={urlPaths.onBoardingInfo()}
    />
  );
};
const WrappedPermissionPage = () => {
  const { caseId, signature } = useLocalSession(urlPaths.signup());

  if (!caseId) {
    return null;
  }

  return <PermissionPage caseId={caseId} signature={signature} nextPageURL={urlPaths.form()} />;
};

const WrappedNotifierFormPage = () => {
  const { caseId, signature } = useLocalSession(urlPaths.signup());

  if (!caseId) {
    return null;
  }

  return <NotifierFormPage caseId={caseId} signature={signature} />;
};

const WrappedKYCPage = () => {
  const { caseId, signature } = useLocalSession(urlPaths.signup());

  if (!caseId) {
    return null;
  }

  return <KYCPage caseId={caseId} signature={signature} />;
};

const WrappedAdditionalServicesPage = () => {
  const { caseId, signature } = useLocalSession(urlPaths.signup());

  if (!caseId) {
    return null;
  }

  return <AdditionalServicesPage caseId={caseId} signature={signature} />;
};

const WrappedFormRatingPage = () => {
  const { caseId, signature } = useLocalSession(urlPaths.signup());

  if (!caseId) {
    return null;
  }

  return <FormRatingPage caseId={caseId} signature={signature} />;
};

const WrappedStatusPage = () => {
  const { caseId, signature } = useLocalSession(urlPaths.signup());
  const { accountId, admin } = useParams() as {
    accountId: string | undefined;
    admin: string | undefined;
  };

  if (!caseId) return null;

  return (<DashboardInfoProvider>
    <StatusPage caseId={caseId} signature={signature} accountId={accountId} />;
  </DashboardInfoProvider>);
};

const legacyWrapper = (
  component: React.ComponentType<{ caseId: string; signature: string | null }>
) => {
  return () => {
    const navigate = useNavigate();
    const [{ caseId, signature }, setState] = React.useState({ caseId: null, signature: null } as {
      caseId: string | null;
      signature: string | null;
    });

    React.useEffect(() => {
      const query = new URLSearchParams(window.location.search);
      const caseId = query.get("case_id") || null;
      const signature = query.get("sig") || null;

      if (!caseId) {
        navigate(`/not-found`, { replace: true });
        return;
      }

      setState({ caseId, signature });
    }, [caseId, signature, navigate]);

    if (!caseId) {
      return null;
    }

    return React.createElement(component, { caseId, signature });
  };
};

const WrappedOpenBankingLinkPage = legacyWrapper(OpenBankingLinkPage);
const WrappedOpenBankingErrorPage = legacyWrapper(OpenBankingErrorPage);
const WrappedCaseClosedFeedbackPage = legacyWrapper(CaseClosedFeedbackPage);

const LegacyURL = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const caseId = query.get("case_id") || null;
    const signature = query.get("sig") || null;

    if (!caseId || !signature) {
      navigate(`/`);
      return;
    }

    setLocalSession({ caseId, signature });

    navigate(urlPaths.root());
  }, [navigate]);

  return null;
};
