import * as React from "react";

import { makeDocument } from "../../mock-data";
import { WithExpressFormTemplate } from "@templates/ExpressFormTemplate";
import { NotAvailablePage } from "../Error/NotAvailable";
import { AccountDetails } from "./AccountDetails";
import { Documents } from "./Documents";
import { KYC } from "./KYC";
import { NotifierDetails } from "./NotifierDetails";
import { Submit } from "./Submit/Submit";
import { ExpressAdditionalServicesPage } from "./ExpressAdditionalServices";
import { ExecutorDetails } from "./ExecutorDetails";
import { NokDetails } from "./NokDetails";
export interface ExpressComponentProps {
  serviceProvider: any;
  formMenuEntries: any;
  submitted: boolean;
  match: any;
  refresh?: any;
  navigate?: any;
  onSectionClick: any;
  collectNokDetails: boolean;
  collectExecutorDetails: boolean;
  willExists: boolean;
  isCollectDocuments: boolean; isKyc: boolean;
}

export const AlreadySubmittedPage: React.FC<{ match: any }> = ({ match }) => {
  return (
    <NotAvailablePage title="No Longer Available" message="You have Already Submitted the Form">
      <div style={{ textAlign: "center" }}>
        <a href={`${match.pathnameBase}/submitted`}> Go to Submitted</a>
      </div>
    </NotAvailablePage>
  );
};

export const NotSubmittedPage: React.FC<{ match: any }> = ({ match }) => {
  return (
    <NotAvailablePage title="Not  Available" message="You have Not Submitted the Form">
      <div style={{ textAlign: "center" }}>
        <a href={`${match.pathnameBase}/submit`}> Go to Forms</a>
      </div>
    </NotAvailablePage>
  );
};

export const GetAccountsComponent: React.FC<ExpressComponentProps> = ({
  serviceProvider,
  formMenuEntries,
  match,
  refresh,
  navigate,
  onSectionClick, submitted
}): JSX.Element => {
  if (submitted) return <AlreadySubmittedPage match={match} />;

  const afterAccount = React.useCallback(() => {
    refresh();
    navigate(`${match.pathnameBase}/notifier`);
  }, [match.pathnameBase, navigate, refresh]);

  return (
    <WithExpressFormTemplate
      noSave
      inPartnership={serviceProvider.inPartnership}
      logoURL={serviceProvider.logoUrl}
      companyName={serviceProvider.companyName}
      menuEntries={formMenuEntries}
      proc={(updateTemplate) => (
        <AccountDetails
          serviceProvider={serviceProvider}
          next={afterAccount}
          updateTemplate={updateTemplate}
          onSectionClick={onSectionClick}
          uploadedFileInfo={() => Promise.resolve(makeDocument())} // TODO
        />
      )}
    />
  );
};

export const GetNotiferComponent: React.FC<ExpressComponentProps> = ({
  serviceProvider,
  formMenuEntries,
  match,
  refresh,
  navigate,
  onSectionClick,
  collectExecutorDetails,
  collectNokDetails,
  isKyc, isCollectDocuments, submitted
}): JSX.Element => {
  if (submitted) return <AlreadySubmittedPage match={match} />;

  const afterNotifier = React.useCallback(() => {
    refresh();
    if (collectExecutorDetails) {
      navigate(`${match.pathnameBase}/executor`);
    } else if (collectNokDetails) {
      navigate(`${match.pathnameBase}/nok`);
    } else if (isCollectDocuments) {
      navigate(`${match.pathnameBase}/documents`);
    } else if (isKyc) {
      navigate(`${match.pathnameBase}/kyc`);
    } else {
      navigate(`${match.pathnameBase}/submit`);
    }
  }, [match.pathnameBase, navigate, refresh, collectExecutorDetails, collectNokDetails,isCollectDocuments,isKyc]);

  return (
    <WithExpressFormTemplate
      noSave
      inPartnership={serviceProvider.inPartnership}
      logoURL={serviceProvider.logoUrl}
      companyName={serviceProvider.companyName}
      menuEntries={formMenuEntries}
      proc={(updateTemplate) => (
        <NotifierDetails
          serviceProvider={serviceProvider}
          next={afterNotifier}
          updateTemplate={updateTemplate}
          onSectionClick={onSectionClick}
        />
      )}
    />
  );
};

export const GetExecutorComponent: React.FC<ExpressComponentProps> = ({
  serviceProvider,
  formMenuEntries,
  match,
  refresh,
  navigate,
  onSectionClick,
  collectNokDetails, isCollectDocuments, isKyc, submitted
}): JSX.Element => {
  if (submitted) return <AlreadySubmittedPage match={match} />;

  const afterExecutor = React.useCallback(() => {
    refresh();
    if (collectNokDetails) {
      navigate(`${match.pathnameBase}/nok`);
    } else if (isCollectDocuments) {
      navigate(`${match.pathnameBase}/documents`);
    } else if (isKyc) {
      navigate(`${match.pathnameBase}/kyc`);
    } else {
      navigate(`${match.pathnameBase}/submit`);
    }
  }, [match.pathnameBase, navigate, refresh, collectNokDetails,isCollectDocuments,isKyc]);

  return (
    <WithExpressFormTemplate
      noSave
      inPartnership={serviceProvider.inPartnership}
      logoURL={serviceProvider.logoUrl}
      companyName={serviceProvider.companyName}
      menuEntries={formMenuEntries}
      proc={(updateTemplate) => (
        <ExecutorDetails
          serviceProvider={serviceProvider}
          next={afterExecutor}
          updateTemplate={updateTemplate}
          onSectionClick={onSectionClick}
        />
      )}
    />
  );
};

export const GetNokComponent: React.FC<ExpressComponentProps> = ({
  serviceProvider,
  formMenuEntries,
  match,
  refresh,
  navigate,
  onSectionClick, isCollectDocuments, isKyc, submitted
}): JSX.Element => {
  if (submitted) return <AlreadySubmittedPage match={match} />;

  const afterNok = React.useCallback(() => {
    refresh();
    if (isCollectDocuments) {
      navigate(`${match.pathnameBase}/documents`);
    } else if (isKyc) {
      navigate(`${match.pathnameBase}/kyc`);
    } else {
      navigate(`${match.pathnameBase}/submit`);
    }
  }, [match.pathnameBase, navigate, refresh,isCollectDocuments,isKyc]);

  return (
    <WithExpressFormTemplate
      noSave
      inPartnership={serviceProvider.inPartnership}
      logoURL={serviceProvider.logoUrl}
      companyName={serviceProvider.companyName}
      menuEntries={formMenuEntries}
      proc={(updateTemplate) => (
        <NokDetails
          serviceProvider={serviceProvider}
          next={afterNok}
          updateTemplate={updateTemplate}
          onSectionClick={onSectionClick}
        />
      )}
    />
  );
};
export const GetDocumentsComponent: React.FC<ExpressComponentProps> = ({
  serviceProvider,
  formMenuEntries,
  match,
  refresh,
  navigate,
  onSectionClick, isKyc, submitted
}): JSX.Element => {
  if (submitted) return <AlreadySubmittedPage match={match} />;

  const afterDocuments = React.useCallback(() => {
    refresh();
    if (isKyc) {
      navigate(`${match.pathnameBase}/kyc`);
    } else {
      navigate(`${match.pathnameBase}/submit`);
    }
  }, [serviceProvider, match.pathnameBase, navigate, refresh,isKyc]);

  return (
    <WithExpressFormTemplate
      logoURL={serviceProvider.logoUrl}
      inPartnership={serviceProvider.inPartnership}
      companyName={serviceProvider.companyName}
      menuEntries={formMenuEntries}
      proc={(updateTemplate) => (
        <Documents
          serviceProvider={serviceProvider}
          next={afterDocuments}
          updateTemplate={updateTemplate}
          onSectionClick={onSectionClick}
          busy={false} // TODO
          remoteError={undefined} // TODO
          removeRemoteError={() => { }}
        />
      )}
    />
  );
};
export const GetKYCComponent: React.FC<ExpressComponentProps> = ({
  serviceProvider,
  formMenuEntries,
  match,
  refresh,
  navigate,
  onSectionClick, submitted
}): JSX.Element => {
  if (submitted) return <AlreadySubmittedPage match={match} />;

  const afterKYC = React.useCallback(() => {
    refresh();
    navigate(`${match.pathnameBase}/submit`);
  }, [match.pathnameBase, navigate, refresh]);

  return (
    <WithExpressFormTemplate
      noSave
      inPartnership={serviceProvider.inPartnership}
      logoURL={serviceProvider.logoUrl}
      companyName={serviceProvider.companyName}
      menuEntries={formMenuEntries}
      proc={(updateTemplate) => (
        <KYC next={afterKYC} updateTemplate={updateTemplate} onSectionClick={onSectionClick} />
      )}
    />
  );
};

export const GetSubmitComponent: React.FC<ExpressComponentProps> = ({
  serviceProvider,
  formMenuEntries,
  match,
  onSectionClick, submitted
}): JSX.Element => {
  if (submitted) return <AlreadySubmittedPage match={match} />;

  var afterSubmit = React.useCallback(() => {
    window.location.href = `${match.pathnameBase}/additional-services`;
  }, [match.pathnameBase]);

  return (
    <WithExpressFormTemplate
      noSave
      inPartnership={serviceProvider.inPartnership}
      logoURL={serviceProvider.logoUrl}
      companyName={serviceProvider.companyName}
      menuEntries={formMenuEntries}
      proc={(updateTemplate) => (
        <Submit
          serviceProvider={serviceProvider}
          next={afterSubmit}
          updateTemplate={updateTemplate}
          onSectionClick={onSectionClick}
          uploadedFileInfo={() => Promise.resolve(makeDocument())} // TODO
        />
      )}
    />
  );
};

export const GetAdditionalServicesComponent: React.FC<ExpressComponentProps> = ({
  serviceProvider,
  match, submitted
}): JSX.Element => {
  var afterSubmit: () => void = React.useCallback(() => {
    window.location.href = `${match.pathnameBase}/submitted`;
  }, [match.pathnameBase]);
  return (
    <>
      <ExpressAdditionalServicesPage next={afterSubmit} serviceProvider={serviceProvider} />
    </>
  );
};
