import * as React from "react";

import { isNWG } from "@utils/Functions";
import { RadioGroupInput } from "@src/components/atoms";
import { FormField } from "@molecules/FormField";
import { InfoBoxTrigger } from "@src/components/molecules";
import { newPropertyStatusFields, PropertyStatus } from "@src/types/property";

export type PropertyStatusFieldsProps = {
  value?: PropertyStatus;
  error?: string;
  onValueChange: any;
  serviceProviderId?: string;
};

export const PropertyStatusField: React.FC<PropertyStatusFieldsProps> = ({
  value,
  error,
  onValueChange,
  serviceProviderId,
}) => {
  return (
    <FormField
      label={
        <span>
          What is the current status of the property?{" "}
          {isNWG(serviceProviderId) && <InfoBoxTrigger content={renderInfoBox()} />}
        </span>
      }
    >
      <RadioGroupInput
        onValueChange={onValueChange}
        value={value}
        fields={newPropertyStatusFields}
        error={error}
      />
    </FormField>
  );
};

const renderInfoBox = () => {
  return (
    <div>
      <p>
        <b>Why do I need to provide this information?</b>
      </p>

      <p>
        Please note we need to understand the property status to determine what happens to the
        account.
      </p>

      <p>
        If the property is vacant or new occupants have moved in, we will assist you in closing the
        account.
      </p>

      <p style={{ marginBottom: 0 }}>
        If the property continues to be occupied by a surviving occupant, we will assist you in
        transferring the account to that surviving occupant.
      </p>
    </div>
  );
};
