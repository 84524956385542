import * as React from "react";

import { CompanyType, YesNo } from "@customTypes/index";
import { FieldRow } from "../../../components/molecules/FieldsTable";
import { NotAvailable } from "../../Dashboard/RequestDetails/NotAvailable";
import { DeceasedPersonalDetails } from "./DeceasedPersonalDetails";
import { PersonDetails } from "./PersonDetails";
import { readableAccountNextSteps } from "@src/utils/accounts";


export const CreditCardsAccount = ({
  account,
  serviceProviderType,
}: {
  account: any;
  serviceProviderType: CompanyType;
}) => {
  return (
    <>
      <DeceasedPersonalDetails deceased={account.deceased} />

      <FieldRow
        label="Credit card number"
        value={account.accountNumber ? account.accountNumber : <NotAvailable />}
      />

      <FieldRow label="Joint policy?" value={account.jointClaim === YesNo.Yes ? "Yes" : "No"} />

      <FieldRow
        label="Next step"
        value={readableAccountNextSteps(account.nextStep, serviceProviderType)}
      />

      <FieldRow
        label="Responsible"
        value={
          account.responsible.id === "notifier" ? (
            "Myself"
          ) : (
            <PersonDetails person={account.responsible.details} />
          )
        }
      />
    </>
  );
};
