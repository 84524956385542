import * as React from "react";

import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";

export type BooleanInputProps = {
  label: React.ReactNode;
  value?: boolean;
  disabled?: boolean;
  error?: string;
  onValueChange: (value: boolean) => void;
  nopadding?: boolean;
  smallFont?: boolean;
};

export const BooleanInput: React.FC<BooleanInputProps> = ({
  label,
  value,
  disabled,
  error,
  nopadding,
  smallFont,
  onValueChange,
}) => {
  const handleChange = React.useCallback(
    (ev) => {
      onValueChange(ev.target.checked);
    },
    [onValueChange]
  );

  return (
    <FormControl error={!!error} disabled={disabled}>
      <FormControlLabel
        control={
          <Checkbox
            style={{ padding: nopadding ? 0 : "9px" }}
            checked={!!value}
            onChange={handleChange}
          />
        }
        label={smallFont ? <span style={{ fontSize: "0.8rem" }}>{label}</span> : label}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};
