import monitor from "@utils/monitoring";
import { apiFetch } from "@api/restApi";

export const fetchData = async () => {
  try {
    return await apiFetch({
      path: `/openbanking/get-data${window.location.search}`,
      method: "GET",
    });
  } catch (error) {
    monitor.logError({
      event: "GET /openbanking/get-data",
      args: {},
      error,
    });
    throw error;
  }
};

export const saveData = async ({ accounts }: { accounts: any }) => {
  try {
    return await apiFetch({
      path: `/openbanking/statements${window.location.search}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: { statement: { accounts } },
    });
  } catch (error) {
    monitor.logError({
      event: "POST /openbanking/statements",
      args: {},
      error,
    });
    throw error;
  }
};
